import { useCallback, useContext, useEffect, useState } from 'react';
import DatasetDefinitionsContext from '../../../contexts/DatasetDefinitionsContext';
import captureException from '../../../services/captureException';

const useEntityField = (dataType: string) => {
  const { getPerformanceConfig, datasets, getBonusProgram } = useContext(
    DatasetDefinitionsContext,
  );
  const getEntityField = useCallback(() => {
    if (dataType === 'driverDays' || dataType === 'driverWeeks') {
      return 'driver';
    }

    if (dataType === 'truckDays' || dataType === 'truckWeeks') {
      return 'truck';
    }

    if (dataType === 'trailerDays' || dataType === 'trailerWeeks') {
      return 'trailer';
    }

    const dataset = datasets.find((d) => d.type === dataType);
    if (!dataset) {
      return undefined;
    }

    if (dataset.isPerformance) {
      const perfConfig = getPerformanceConfig(dataType);
      if (!perfConfig) {
        const error = new Error(dataType);
        error.name = `Entity field not found for dataType`;
        captureException(error);
        return undefined;
      }

      return perfConfig.entity;
    }

    if (dataset.datasetType === 'scoring') {
      const bonusProgram = getBonusProgram(dataset.type);

      if (!bonusProgram) {
        const e = new Error(dataset.type);
        e.name = 'Bonus Program not found for dataType';
        captureException(e);
        return undefined;
      }

      return bonusProgram.entityType;
    }

    return undefined;
  }, [dataType, datasets, getBonusProgram, getPerformanceConfig]);

  const [entityField, setEntityField] = useState<string | undefined>(() =>
    getEntityField(),
  );

  useEffect(() => {
    setEntityField(getEntityField());
  }, [getEntityField]);

  return entityField;
};

export default useEntityField;
