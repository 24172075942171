import React from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Form from 'kingpin/forms/Form';
import FormHeader from 'kingpin/forms/FormHeader';
import ColorDot from 'kingpin/navigation/PortalPicker/ColorDot';
import FormContent from 'kingpin/forms/FormContent';
import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';
import Badge from 'components/Badge';
import Row from 'components/Common/Row';
import getTypeName from '../getContentTypeName';

const RemovePortalAccessConfirmation = ({
  isOpen,
  close,
  portal,
  onRemoveConfirmed,
  isLoading,
  contentName,
  type,
}: {
  isOpen: boolean;
  close: () => void;
  portal: ExecutivePortal | EngagementPortal | BonusPortal;
  onRemoveConfirmed: () => Promise<void>;
  isLoading: boolean;
  contentName: string;
  type: ExecutivePortalPublishableContentType;
}) => {
  const effectedUsers = portal.usersIdsWithAccess.length;

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={close} shouldScrollInViewport={false} autoFocus={false}>
          <Form>
            <FormHeader
              title={`Remove '${contentName}' From`}
              onClose={isLoading ? undefined : close}
              titleExtra={
                <div style={{ marginLeft: 8 }}>
                  <Badge
                    badgeType={'Default'}
                    text={portal.name}
                    iconBefore={
                      <div style={{ marginRight: 8, display: 'flex' }}>
                        <ColorDot color={portal.color} height={8} width={8} />
                      </div>
                    }
                  />
                </div>
              }
            />
            <FormContent>
              <Typography.Body type={'Body 13'} color={'#333333'}>
                {`Removing this ${getTypeName(type)} will mean ${effectedUsers} user${effectedUsers > 1 ? 's' : ''} with access to this portal will no longer be able to view it`}
              </Typography.Body>
            </FormContent>
            <Row spaceBetween centerAlign>
              <Button
                size={'Small'}
                type={'Primary'}
                label="Cancel"
                onClick={close}
              />
              <Button
                size={'Small'}
                type={'Danger Secondary'}
                label="Remove Access"
                onClick={onRemoveConfirmed}
                isLoading={isLoading}
                isDisabled={isLoading}
              />
            </Row>
          </Form>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default RemovePortalAccessConfirmation;
