import { useContext, useMemo } from 'react';
import DatasetDefinitionsContext from '../../../../contexts/DatasetDefinitionsContext';
import PerformanceBoardSlideOutContext from '../../contexts/PerformanceBoardSlideOutContext';

const DEFAULT_INTERVAL = {
  interval: undefined,
  intervalLength: undefined,
  startDate: undefined,
};

const useIntervalConfig = () => {
  const { board } = useContext(PerformanceBoardSlideOutContext);
  const { getPerformanceConfig, datasets, getBonusProgram } = useContext(
    DatasetDefinitionsContext,
  );
  const dataset = useMemo(() => {
    return datasets.find((d) => d.type === board.dataType);
  }, [board.dataType, datasets]);

  const intervalConfig = useMemo(() => {
    if (!dataset) {
      return DEFAULT_INTERVAL;
    }

    if (dataset.isPerformance || dataset.datasetType === 'performance') {
      const config = getPerformanceConfig(dataset.type);
      if (!config) {
        return DEFAULT_INTERVAL;
      }

      return {
        interval: config.interval,
        intervalLength: config.intervalLength,
        startDate: config.startDate,
      };
    }

    if (dataset.datasetType === 'scoring') {
      const program = getBonusProgram(dataset.type);
      if (!program) {
        return DEFAULT_INTERVAL;
      }

      return {
        interval: program.cadence,
        intervalLength: program.cadenceInterval,
        startDate: program.startDate,
      };
    }

    return DEFAULT_INTERVAL;
  }, [dataset, getBonusProgram, getPerformanceConfig]);

  return intervalConfig;
};

export default useIntervalConfig;
