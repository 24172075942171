import aguid from 'aguid';
import { DateTime } from 'luxon';
import { buildShowWorkSpace } from '../navigation/appRoutes';

const bonusPeriodReadyForSignOff = ({
  workSpace,
  bonusPeriod,
}: {
  workSpace: WorkSpace;
  bonusPeriod: BonusPeriod | Scoring.BonusPeriod;
}): Notifications.BasicNotification => ({
  id: aguid(),
  isBasic: true,
  type: 'BONUS_PERIOD_READY_FOR_SIGN_OFF',
  seen: false,
  read: false,
  deleted: false,
  createdOn: DateTime.utc().toISO(),
  emailSent: false,
  link: buildShowWorkSpace(workSpace.id),
  inAppSubject: `${bonusPeriod.label} is now ready for sign off`,
  inAppBody: `The bonus period (${bonusPeriod.label}) is now ready for sign off`,
  inAppLinkText: 'Open campaign',
  emailSubject: `${bonusPeriod.label} is now ready for sign off`,
  emailBodyLn1: `The bonus period (${bonusPeriod.label}) is now ready for sign off`,
  emailBodyLn2: '',
  emailLinkText: 'Log in to sign off',
});

export default bonusPeriodReadyForSignOff;
