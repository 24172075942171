import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import Row from '../../Common/Row';
import Button from 'kingpin/atoms/Button';
import Inputs from 'components/Inputs';

const WorkSpaceReportWizard = ({
  options,
  onAddClicked,
  onBackClicked,
  isValid,
}: {
  options: DropdownOption[];
  onAddClicked: () => void;
  onBackClicked: () => void;
  isValid: boolean;
}) => (
  <div>
    <div style={{ marginBottom: 24 }}>
      <Typography.Header type={'H5'}>Add Report</Typography.Header>
    </div>
    <div style={{ marginBottom: 32 }}>
      <div style={{ marginBottom: 4 }}>
        <Typography.Body type="Label">Report</Typography.Body>
      </div>
      <Inputs.Dropdown
        options={options}
        isSearchEnabled
        fullWidth
        placeholder="Select a Report"
      />
    </div>
    <Row spaceBetween>
      <Button
        onClick={onBackClicked}
        type="Secondary"
        size="Small"
        label="Back"
      />
      <Button
        onClick={onAddClicked}
        isDisabled={!isValid}
        size="Small"
        type="Primary"
        label="Add"
      />
    </Row>
  </div>
);

export default WorkSpaceReportWizard;
