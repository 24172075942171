import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import UsersContext from 'contexts/UsersContext';
import AccountPickerContext from 'contexts/AccountPickerContext';
import userTypeCheckers from 'contextProviders/SplashScreenProviders/UserAndAccountProviders/UsersProvider/userTypeCheckers';
import useLockedDebouncedEffect from 'hooks/useLockedDebouncedEffect';
import isDefined from 'isDefined';
import STORE from 'store';

interface GetMappedUsersArgs {
  allUsers: (UserManagement.SignedUpUser | UserManagement.PendingUser)[];
  entityId: string;
}

const useMappedUsers = (entityId: string) => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { allUsers, isLoading: isLoadingUsers } = useContext(UsersContext);
  const [mappedUsers, setMappedUsers] = useState<MappedUser[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getMappedUsers = useCallback(
    async (args: GetMappedUsersArgs): Promise<MappedUser[]> => {
      setIsLoading(true);
      const { allUsers, entityId } = args;

      const promises = allUsers
        .filter((u) => !u.isDeleted)
        .map(async (user): Promise<undefined | MappedUser> => {
          const userContentSettings = await STORE.users
            .getUserContentSettingsRef({
              accountId: selectedAccountId,
              userId: user.id,
            })
            .get();

          if (!userContentSettings.exists) {
            return undefined;
          }
          const settings = userContentSettings.data();
          if (!settings) {
            return undefined;
          }
          if (settings.mode === 'normal') {
            return undefined;
          }

          const mapping = settings.mappings.find(
            (m) => m.entityId === entityId,
          );
          if (!mapping) {
            return undefined;
          }

          return {
            filterValue: mapping.primaryFieldValue,
            user,
            additionalFilters: mapping.additionalFilters || [],
            accessToOtherMappedUserIds: mapping.accessToOtherMappedUserIds,
            entityId: mapping.entityId,
          };
        });

      const results = await Promise.all(promises);
      return results.filter(isDefined);
    },
    [selectedAccountId],
  );

  const args = useMemo(() => {
    if (isLoadingUsers) {
      return undefined;
    }

    return {
      allUsers: allUsers.filter(userTypeCheckers.isSignedUpOrPendingUser),
      entityId,
    };
  }, [allUsers, entityId, isLoadingUsers]);

  const handleResponse = useCallback((response: MappedUser[]) => {
    setMappedUsers(response);
    setIsLoading(false);
  }, []);

  /**
   * We need to trigger a loading state immediately here to prevent a janky
   * when a mapped user switches between their mapped entities
   */
  useEffect(() => {
    setIsLoading(true);
  }, [args]);

  useLockedDebouncedEffect({
    args,
    responseHandler: handleResponse,
    callback: getMappedUsers,
  });

  return { mappedUsers, setMappedUsers, isLoading };
};

export default useMappedUsers;
