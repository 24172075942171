import React, { useCallback, useState } from 'react';
import Typography from '../../kingpin/atoms/Typography';
import Card from '../../kingpin/atoms/Card';

import styled from 'styled-components';
import Row from '../../components/Common/Row';
import Icon from '../../kingpin/atoms/Icon';
import Badge from '../../kingpin/atoms/Badge';
import Button from '../../kingpin/atoms/Button';
import KingpinCard from '../../kingpin/atoms/Card';
import EstimateMarkerSrc from './estimate-marker.svg';
import SlashSrc from './slash.png';
import Tooltip from '../../components/Tooltip';
import usePopup from '../../hooks/usePopup';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { HeaderType } from '../../kingpin/atoms/Typography/Header';

const DashBorderDiv = styled.div`
  border-bottom: 1px #c3c3c3 dashed;
`;

const AcceptanceCriteriaCard = styled(KingpinCard)`
  border-color: #bbccff;
  background-color: #f7f9ff;
`;

export const ProjectedPayTooltip = () => {
  return (
    <div style={{ textAlign: 'center', width: 260 }}>
      <div style={{ marginBottom: 8 }}>
        <Typography.Body type={'Body 13'} color="white">
          This number is a potential payout based on achieving acceptance
          criteria
        </Typography.Body>
      </div>
      <Typography.Body type={'Body 13 Bold'} color="white">
        Total Miles: 10,000
      </Typography.Body>
    </div>
  );
};

export const ProjectedPayMarker = () => (
  <div style={{ position: 'absolute', top: -1, right: 0, display: 'flex' }}>
    {/* eslint-disable-next-line jsx-a11y/alt-text */}
    <img src={EstimateMarkerSrc} style={{ width: 8, height: 8 }} />
  </div>
);

const ProjectedPay = ({
  pay,
  headerType,
}: {
  pay: string;
  headerType: HeaderType;
}) => {
  return (
    <div
      style={{
        position: 'relative',
        padding: 8,
        marginRight: -8,
      }}
    >
      <Tooltip content={<ProjectedPayTooltip />}>
        <Typography.Header type={headerType}>{pay}</Typography.Header>
      </Tooltip>
      <ProjectedPayMarker />
    </div>
  );
};

const PeriodSelectorItemDiv = styled(Row)<{ isSelected: boolean }>`
  background-color: ${(props) => (props.isSelected ? '#F7F9FF' : 'white')};
  padding: 12px 16px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: #f3f6ff;
  }
`;

const PeriodSelectorItem = ({
  label,
  isSelected,
  pay,
  isGood,
  onClick,
}: {
  label: string;
  isSelected: boolean;
  pay?: string;
  isGood?: boolean;
  onClick: () => void;
}) => {
  return (
    <PeriodSelectorItemDiv
      spaceBetween
      centerAlign
      onClick={onClick}
      isSelected={isSelected}
    >
      <Row centerAlign style={{ gap: 8 }}>
        <div style={{ opacity: isSelected ? 1 : 0 }}>
          <Icon icon={'checkmark'} />
        </div>
        <Typography.Body type={isSelected ? 'Body 13 Bold' : 'Body 13'}>
          {label}
        </Typography.Body>
      </Row>
      <div>
        {pay && (
          <Row centerAlign style={{ gap: 8 }}>
            <Typography.Body type={isSelected ? 'Body 13 Bold' : 'Body 13'}>
              {pay}
            </Typography.Body>
            {isGood && <span style={{ color: '#D66969' }}>▼</span>}
            {!isGood && <span style={{ color: '#36B37E' }}>▲</span>}
          </Row>
        )}
        {!pay && <Badge text={'Ongoing'} badgeType={'Info'} />}
      </div>
    </PeriodSelectorItemDiv>
  );
};

const PeriodSelector = ({
  mode,
  openOnGoing,
  openClosed,
}: {
  mode: 'ongoing' | 'closed';
  openOnGoing: () => void;
  openClosed: () => void;
}) => {
  const { isOpen, open, close } = usePopup();
  const onOngoingClicked = useCallback(() => {
    openOnGoing();
    close();
  }, [close, openOnGoing]);
  const onClosedClicked = useCallback(() => {
    openClosed();
    close();
  }, [close, openClosed]);

  return (
    <div>
      <Button
        size={'Small'}
        type={'Secondary'}
        label={mode === 'ongoing' ? "October '24" : "September '24"}
        onClick={isOpen ? close : open}
        iconAfter={'chevron-up-down'}
      />
      <ModalTransition>
        {isOpen && (
          <Modal
            onClose={close}
            shouldScrollInViewport={false}
            autoFocus={false}
          >
            <div>
              <Row
                style={{ padding: '16px', borderBottom: '1px solid #F0F0F0' }}
                spaceBetween
                centerAlign
              >
                <Typography.Header type={'H4'}>
                  Select a period
                </Typography.Header>
                <Button
                  size={'Small'}
                  type={'Secondary'}
                  icon={'cross'}
                  onClick={close}
                />
              </Row>
              <div>
                <PeriodSelectorItem
                  isSelected={mode === 'ongoing'}
                  label={"October 24'"}
                  onClick={onOngoingClicked}
                />
                <PeriodSelectorItem
                  isSelected={mode === 'closed'}
                  label={"September 24'"}
                  onClick={onClosedClicked}
                  pay={'$224.86'}
                  isGood={false}
                />
                <PeriodSelectorItem
                  isSelected={false}
                  label={"August 24'"}
                  onClick={onClosedClicked}
                  pay={'$362.72'}
                  isGood={false}
                />
                <PeriodSelectorItem
                  isSelected={false}
                  label={"July 24'"}
                  onClick={onClosedClicked}
                  pay={'411.67'}
                  isGood
                />
                <PeriodSelectorItem
                  isSelected={false}
                  label={"July 24'"}
                  onClick={onClosedClicked}
                  pay={'$243.21'}
                  isGood={false}
                />
              </div>
            </div>
          </Modal>
        )}
      </ModalTransition>
    </div>
  );
};

const PeriodSummary = ({
  periodLabel,
  periodPay,
  badge,
  projectedPay,
  mode,
  openOnGoing,
  openClosed,
}: {
  periodLabel: string;
  periodPay: string;
  badge: React.ReactElement;
  projectedPay?: string;
  mode: 'ongoing' | 'closed';
  openOnGoing: () => void;
  openClosed: () => void;
}) => {
  return (
    <div>
      <div style={{ marginBottom: 8 }}>
        <Row spaceBetween centerAlign>
          <Typography.Header type={'H3'}>Overview</Typography.Header>
          <PeriodSelector
            mode={mode}
            openOnGoing={openOnGoing}
            openClosed={openClosed}
          />
        </Row>
      </div>
      <KingpinCard>
        <Row spaceBetween centerAlign>
          <div style={{ flex: 1 }}>
            <Typography.Header type={'H4'}>Period</Typography.Header>
          </div>
          <div style={{ flex: 1 }}>
            <Typography.Header type={'H4'}>Status</Typography.Header>
          </div>
          <div style={{ flex: 1 }}>
            <Typography.Header type={'H4'}>Earned</Typography.Header>
          </div>

          {projectedPay && (
            <div style={{ flex: 1 }}>
              <Typography.Header type={'H4'}>Available</Typography.Header>
            </div>
          )}
        </Row>
        <Row spaceBetween centerAlign>
          <div style={{ flex: 1 }}>
            <Typography.Header type={'H2'}>{periodLabel}</Typography.Header>
          </div>
          <div style={{ flex: 1 }}>
            <div style={{ width: 'fit-content' }}>{badge}</div>
          </div>
          <div style={{ flex: 1 }}>
            <Typography.Header type={'H2'}>{periodPay}</Typography.Header>
          </div>
          {!!projectedPay && (
            <div style={{ flex: 1 }}>
              <div style={{ width: 'fit-content', marginLeft: -8 }}>
                <ProjectedPay pay={projectedPay} headerType="H2" />
              </div>
            </div>
          )}
        </Row>
      </KingpinCard>
    </div>
  );
};

const AcceptanceCriteria = () => {
  const { isOpen, open, close } = usePopup();

  return (
    <div>
      <div style={{ marginBottom: 8 }}>
        <div>
          <Typography.Header type={'H3'}>Rules</Typography.Header>
        </div>
      </div>
      <AcceptanceCriteriaCard>
        <Row spaceBetween centerAlign>
          <div>
            <Typography.Header type={'H3'}>Bonus Qualifier</Typography.Header>
            {isOpen && (
              <div>
                <Typography.Body type={'Body 12'}>
                  To qualify for any bonus
                </Typography.Body>
              </div>
            )}
          </div>
          <Button
            type={'Ghost'}
            size={'Small'}
            icon={isOpen ? 'chevron-up' : 'chevron-down'}
            onClick={isOpen ? close : open}
          />
        </Row>
        {isOpen && (
          <div style={{ marginTop: 16 }}>
            <DashBorderDiv />
            <Row centerAlign style={{ gap: 16, marginTop: 16 }}>
              <Icon
                icon={'circleCheckmark2'}
                color="#36B37E"
                width={24}
                height={24}
              />
              <div>
                <div>
                  <Typography.Body type={'Body 14'}>
                    Total Miles
                  </Typography.Body>
                </div>
                <Typography.Body type={'Body 12'} color={'#333333'}>
                  Current 11,500 / Target 10,000
                </Typography.Body>
              </div>
            </Row>
          </div>
        )}
      </AcceptanceCriteriaCard>
    </div>
  );
};

const MilesCategory2 = () => {
  const { isOpen, open, close } = usePopup();

  return (
    <Card>
      <Row spaceBetween centerAlign style={{ marginBottom: isOpen ? 16 : 0 }}>
        <Row centerAlign>
          {!isOpen && (
            <div style={{ marginRight: 8 }}>
              <Icon icon={'circleCheckmark2'} width={24} height={24} />
            </div>
          )}
          <Typography.Header type={'H3'}>{`Miles`}</Typography.Header>
        </Row>

        <div>
          <Row centerAlign style={{ gap: 8 }}>
            <div>
              <Typography.Body type={'Body 12'} color={'#808080'}>
                $0.02 cpm
              </Typography.Body>
            </div>
            <div>
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <img src={SlashSrc} />
            </div>
            <Typography.Header type={'H4'}>$220.00</Typography.Header>
            <Button
              type={'Ghost'}
              size={'Small'}
              icon={isOpen ? 'chevron-up' : 'chevron-down'}
              onClick={isOpen ? close : open}
            />
          </Row>
        </div>
      </Row>
      {isOpen && (
        <>
          <DashBorderDiv />
          {isOpen && (
            <>
              <div style={{ marginTop: 16, padding: 12 }}>
                <Row centerAlign spaceBetween>
                  <Row centerAlign>
                    <div style={{ marginRight: 8, position: 'relative' }}>
                      <div
                        style={{
                          width: 24,
                          height: 24,
                          borderRadius: 12,
                          backgroundColor: '#C9EBDC',
                        }}
                      />
                      <div
                        style={{
                          position: 'absolute',
                          right: 11,
                          top: 24,
                          width: 2,
                          height: 24,
                          zIndex: 10,
                          backgroundColor: '#C9EBDC',
                        }}
                      />
                    </div>
                    <Typography.Body
                      type={'Body 14'}
                    >{`> 10,000 miles`}</Typography.Body>
                  </Row>
                  <Typography.Body
                    type={'Body 14'}
                  >{`$0.01 CPM`}</Typography.Body>
                </Row>
              </div>
              <div
                style={{
                  position: 'relative',
                  backgroundColor: '#F6F6F6',
                  padding: 12,
                  borderRadius: 8,
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: 16,
                    left: -18,
                  }}
                >
                  <Icon icon={'chevron-right'} color={'#777777'} />
                </div>
                <div
                  style={{
                    position: 'absolute',
                    top: 16,
                    right: -18,
                  }}
                >
                  <Icon icon={'chevron-left'} color={'#777777'} />
                </div>
                <Row centerAlign spaceBetween>
                  <Row centerAlign>
                    <div style={{ marginRight: 8, position: 'relative' }}>
                      <Icon icon={'circleCheckmark2'} width={24} height={24} />
                      <div
                        style={{
                          position: 'absolute',
                          right: 11,
                          top: 24,
                          width: 2,
                          height: 24,
                          backgroundColor: '#D9D9D9',
                          zIndex: 10,
                        }}
                      />
                    </div>
                    <Typography.Body
                      type={'Body 14'}
                    >{`> 11,000 miles`}</Typography.Body>
                  </Row>
                  <Typography.Body
                    type={'Body 14'}
                  >{`$0.02 CPM`}</Typography.Body>
                </Row>
              </div>
              <div style={{ padding: 12 }}>
                <Row centerAlign spaceBetween>
                  <Row centerAlign>
                    <div style={{ marginRight: 8 }}>
                      <div
                        style={{
                          width: 24,
                          height: 24,
                          borderRadius: 12,
                          backgroundColor: '#EEEFF1',
                        }}
                      />
                    </div>
                    <Typography.Body
                      type={'Body 14'}
                    >{`> 12,000 miles`}</Typography.Body>
                  </Row>
                  <Typography.Body
                    type={'Body 14'}
                  >{`$0.03 CPM`}</Typography.Body>
                </Row>
              </div>
            </>
          )}
        </>
      )}
    </Card>
  );
};

export const MilesCategory = ({ noTitle }: { noTitle?: boolean }) => {
  return (
    <Card>
      {!noTitle && (
        <>
          <Row spaceBetween centerAlign style={{ marginBottom: 16 }}>
            <Typography.Header type={'H2'}>Miles</Typography.Header>
            <div>
              <Row centerAlign style={{ gap: 8 }}>
                <div>
                  <Typography.Body type={'Body 12'} color={'#808080'}>
                    $0.02 cpm
                  </Typography.Body>
                </div>
                <div>
                  {/* eslint-disable-next-line jsx-a11y/alt-text */}
                  <img src={SlashSrc} />
                </div>
                <Typography.Header type={'H5'}>$220.00</Typography.Header>
              </Row>
            </div>
          </Row>
          <DashBorderDiv />
        </>
      )}

      <div style={{ marginTop: noTitle ? 0 : 16, padding: '8px 4px' }}>
        <Row centerAlign spaceBetween>
          <Row centerAlign style={{ gap: 16 }}>
            <Icon
              icon={'circleCheckmark2'}
              color="#36B37E"
              width={24}
              height={24}
            />
            <div>
              <Typography.Body type={'Body 14'}>
                {'> 10,000 miles'}
              </Typography.Body>
            </div>
          </Row>
          <div>
            <Typography.Body type={'Body 12'} color={'#333333'}>
              $0.01 CPM
            </Typography.Body>
          </div>
        </Row>
      </div>
      <div
        style={{
          padding: '8px 4px',
          backgroundColor: '#F8F8F8',
        }}
      >
        <Row centerAlign spaceBetween>
          <Row centerAlign style={{ gap: 16 }}>
            <Icon
              icon={'circleCheckmark2'}
              color="#36B37E"
              width={24}
              height={24}
            />
            <div>
              <Typography.Body type={'Body 14'}>
                {'> 11,000 miles'}
              </Typography.Body>
            </div>
          </Row>
          <div>
            <Typography.Body type={'Body 12'} color={'#333333'}>
              $0.02 CPM
            </Typography.Body>
          </div>
        </Row>
      </div>
      <div style={{ padding: '8px 4px' }}>
        <Row centerAlign spaceBetween>
          <Row centerAlign style={{ gap: 16 }}>
            <Icon icon={'missed'} color="#C3C3C3" width={24} height={24} />
            <div>
              <Typography.Body type={'Body 14'}>
                {'> 12,000 miles'}
              </Typography.Body>
            </div>
          </Row>
          <div>
            <Typography.Body type={'Body 12'} color={'#333333'}>
              $0.03 CPM
            </Typography.Body>
          </div>
        </Row>
      </div>
    </Card>
  );
};

export const SafetyCategory = ({
  isClosed,
  noTitle,
}: {
  isClosed?: boolean;
  noTitle?: boolean;
}) => {
  const { isOpen, open, close } = usePopup(noTitle ? true : false);

  return (
    <Card>
      {!noTitle && (
        <>
          <Row
            spaceBetween
            centerAlign
            style={{ marginBottom: isOpen ? 16 : 0 }}
          >
            <Row centerAlign>
              {!isOpen && (
                <div style={{ marginRight: 8 }}>
                  <Icon icon={'missed'} width={24} height={24} />
                </div>
              )}
              <Typography.Header
                type={'H3'}
              >{`Safety${isOpen ? '' : ' 2/3'}`}</Typography.Header>
            </Row>

            <div>
              <Row centerAlign style={{ gap: 8 }}>
                <div>
                  <Typography.Body type={'Body 12'} color={'#808080'}>
                    $0.01 cpm
                  </Typography.Body>
                </div>
                <div>
                  {/* eslint-disable-next-line jsx-a11y/alt-text */}
                  <img src={SlashSrc} />
                </div>
                {isClosed && (
                  <Typography.Header type={'H4'} color={'#8E8E8E'}>
                    $0.00
                  </Typography.Header>
                )}
                {!isClosed && (
                  <div style={{ marginRight: 4 }}>
                    <ProjectedPay pay={'$115.00'} headerType={'H4'} />
                  </div>
                )}
                <Button
                  type={'Ghost'}
                  size={'Small'}
                  icon={isOpen ? 'chevron-up' : 'chevron-down'}
                  onClick={isOpen ? close : open}
                />
              </Row>
            </div>
          </Row>
          {isOpen && <DashBorderDiv />}
        </>
      )}

      {isOpen && (
        <>
          <Row centerAlign style={{ gap: 16, marginTop: noTitle ? 0 : 16 }}>
            <Icon
              icon={'circleCheckmark2'}
              color="#36B37E"
              width={24}
              height={24}
            />
            <div>
              <div>
                <Typography.Body type={'Body 14 Bold'}>
                  Accidents
                </Typography.Body>
              </div>
              <Typography.Body type={'Body 14'} color={'#333333'}>
                Current 0 / Target 0
              </Typography.Body>
            </div>
          </Row>
          <div style={{ marginTop: 16 }}>
            <DashBorderDiv />
          </div>
          <Row centerAlign style={{ gap: 16, marginTop: 16 }}>
            <Icon
              icon={'circleCheckmark2'}
              color="#36B37E"
              width={24}
              height={24}
            />
            <div>
              <div>
                <Typography.Body type={'Body 14 Bold'}>
                  No Violations
                </Typography.Body>
              </div>
              <Typography.Body type={'Body 12'} color={'#333333'}>
                Current 0 / Target 0
              </Typography.Body>
            </div>
          </Row>
          <div style={{ marginTop: 16 }}>
            <DashBorderDiv />
          </div>
          <Row centerAlign style={{ gap: 16, marginTop: 16 }}>
            <Icon icon={'missed'} width={24} height={24} />
            <div>
              <div>
                <Typography.Body type={'Body 14 Bold'}>
                  Safety Meetings Attended
                </Typography.Body>
              </div>
              <Typography.Body type={'Body 12'} color={'#333333'}>
                Current 0 / Target 2
              </Typography.Body>
            </div>
          </Row>
        </>
      )}
    </Card>
  );
};

export const FuelCategory = ({
  isClosed,
  noTitle,
}: {
  isClosed?: boolean;
  noTitle?: boolean;
}) => {
  const { isOpen, open, close } = usePopup(noTitle ? true : false);

  return (
    <Card>
      {!noTitle && (
        <>
          <Row
            spaceBetween
            centerAlign
            style={{ marginBottom: isOpen ? 16 : 0 }}
          >
            <Row centerAlign>
              {!isOpen && (
                <div style={{ marginRight: 8 }}>
                  <Icon icon={'missed'} width={24} height={24} />
                </div>
              )}
              <Typography.Header
                type={'H3'}
              >{`Fuel${isOpen ? '' : ' 1/2'}`}</Typography.Header>
            </Row>

            <div>
              <Row centerAlign style={{ gap: 8 }}>
                <div>
                  <Typography.Body type={'Body 12'} color={'#808080'}>
                    $0.01 cpm
                  </Typography.Body>
                </div>
                <div>
                  {/* eslint-disable-next-line jsx-a11y/alt-text */}
                  <img src={SlashSrc} />
                </div>
                {isClosed && (
                  <Typography.Header type={'H5'} color={'#8E8E8E'}>
                    $0.00
                  </Typography.Header>
                )}
                {!isClosed && (
                  <div style={{ marginRight: 4 }}>
                    <ProjectedPay pay={'$115.00'} headerType={'H4'} />
                  </div>
                )}
                <Button
                  type={'Ghost'}
                  size={'Small'}
                  icon={isOpen ? 'chevron-up' : 'chevron-down'}
                  onClick={isOpen ? close : open}
                />
              </Row>
            </div>
          </Row>
          {isOpen && <DashBorderDiv />}
        </>
      )}

      {isOpen && (
        <>
          <Row centerAlign style={{ gap: 16, marginTop: noTitle ? 0 : 16 }}>
            <Icon icon={'missed'} width={24} height={24} />
            <div>
              <div>
                <Typography.Body type={'Body 14 Bold'}>MPG</Typography.Body>
              </div>
              <Typography.Body type={'Body 14'} color={'#333333'}>
                Current 7.0 / Target 7.4
              </Typography.Body>
            </div>
          </Row>
          <div style={{ marginTop: 16 }}>
            <DashBorderDiv />
          </div>
          <Row centerAlign style={{ gap: 16, marginTop: 16 }}>
            <Icon
              icon={'circleCheckmark2'}
              color="#36B37E"
              width={24}
              height={24}
            />
            <div>
              <div>
                <Typography.Body type={'Body 14 Bold'}>
                  Compliance
                </Typography.Body>
              </div>
              <Typography.Body type={'Body 14'} color={'#333333'}>
                Current 92% / Target 90%
              </Typography.Body>
            </div>
          </Row>
        </>
      )}
    </Card>
  );
};

export const PayRow = ({
  left,
  right,
  isThing,
}: {
  left: string;
  right: string;
  isThing: boolean;
}) => {
  return (
    <Row centerAlign style={{ gap: 8 }}>
      <div>
        <Typography.Body type={'Body 12'} color={'#808080'}>
          {left}
        </Typography.Body>
      </div>
      <div>
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img src={SlashSrc} />
      </div>
      {!isThing && (
        <Typography.Header type={'H5'} color={'#8E8E8E'}>
          {right}
        </Typography.Header>
      )}
      {isThing && <ProjectedPay pay={right} headerType={'H4'} />}
    </Row>
  );
};

const InsightCardDiv = styled.div`
  padding: 16px;
  background-color: #fbfbfb;
  border: 1px solid #f0f0f0;
`;

const InsightCard = ({ insight }: { insight: string }) => {
  return (
    <InsightCardDiv>
      <Row centerAlign style={{ gap: 12 }}>
        <Icon icon={'dollar'} />
        <Typography.Body type={'Body 14'}>{insight}</Typography.Body>
      </Row>
    </InsightCardDiv>
  );
};

const Insights = () => {
  return (
    <div>
      <div style={{ marginBottom: 12 }}>
        <div>
          <Typography.Header type={'H4'}>Insights</Typography.Header>
        </div>
        <div>
          <Typography.Body type={'Body 14'}>
            You could earn up to another $330.00
          </Typography.Body>
        </div>
      </div>
      <div style={{ gap: 16, display: 'flex', flexDirection: 'column' }}>
        <InsightCard
          insight={
            'Attend 2 more safety meetings to earn the full safety bonus ($115.00)'
          }
        />
        <InsightCard
          insight={
            'Increase your MPG to 7.4 by the end of the month to earn your full Fuel bonus (currently 7.1) ($115.00)'
          }
        />
        <InsightCard
          insight={
            'Drive another 500 miles to increase your pay per mile to $0.03'
          }
        />
      </div>
    </div>
  );
};

const DriverScoringProgramReport = ({ isOngoing }: { isOngoing?: boolean }) => {
  const [mode, setMode] = useState<'ongoing' | 'closed'>(
    isOngoing === undefined ? 'ongoing' : isOngoing ? 'ongoing' : 'closed',
  );
  const openOnGoing = () => {
    setMode('ongoing');
  };
  const openClosed = () => {
    setMode('closed');
  };

  if (mode === 'ongoing') {
    return (
      <div
        style={{
          width: '100%',
          overflowY: 'scroll',
        }}
      >
        <div style={{ marginBottom: 24 }}>
          <PeriodSummary
            badge={<Badge text={'Ongoing'} badgeType={'Warning'} />}
            projectedPay={'$550.00'}
            periodLabel={'February 2025'}
            periodPay={'$220.00'}
            mode={mode}
            openOnGoing={openOnGoing}
            openClosed={openClosed}
          />
        </div>

        <div style={{ marginBottom: 24 }}>
          <Insights />
        </div>

        <div style={{ marginBottom: 24 }}>
          <AcceptanceCriteria />
        </div>

        <div style={{ marginBottom: 24 }}>
          <MilesCategory2 />
        </div>

        <div style={{ marginBottom: 24 }}>
          <SafetyCategory />
        </div>

        <div style={{ marginBottom: 124 }}>
          <FuelCategory />
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        width: '100%',
        overflowY: 'scroll',
      }}
    >
      <div style={{ marginBottom: 24 }}>
        <PeriodSummary
          badge={<Badge text={'Cycle Complete'} badgeType={'Success'} />}
          periodLabel={'January 2025'}
          periodPay={'$220.00'}
          mode={mode}
          openOnGoing={openOnGoing}
          openClosed={openClosed}
        />
      </div>

      <div style={{ marginBottom: 24 }}>
        <AcceptanceCriteria />
      </div>

      <div style={{ marginBottom: 24 }}>
        <MilesCategory2 />
      </div>

      <div style={{ marginBottom: 24 }}>
        <SafetyCategory isClosed />
      </div>

      <div style={{ marginBottom: 124 }}>
        <FuelCategory isClosed />
      </div>
    </div>
  );
};

export default DriverScoringProgramReport;
