import React, { useContext } from 'react';
import GoalSearchBar from './GoalSearchBar';
import GoalFilteringContext from '../../../contexts/GoalFilteringContext';

const GoalSearchBarContainer = () => {
  const { searchText, setSearchText } = useContext(GoalFilteringContext);

  return (
    <GoalSearchBar searchText={searchText} setSearchText={setSearchText} />
  );
};

export default GoalSearchBarContainer;
