import React, { createContext } from 'react';
import useBonusPortals from './useBonusPortals';

interface BonusPortalsContextType {
  availableBonusPortals: BonusPortal[];
  allBonusPortals: BonusPortal[];
  isLoadingBonusPortals: boolean;
}

export const BonusPortalsContext = createContext<BonusPortalsContextType>({
  availableBonusPortals: [],
  allBonusPortals: [],
  isLoadingBonusPortals: true,
});

const BonusPortalsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { availableBonusPortals, allBonusPortals, isLoading } =
    useBonusPortals();

  return (
    <BonusPortalsContext.Provider
      value={{
        availableBonusPortals,
        allBonusPortals,
        isLoadingBonusPortals: isLoading,
      }}
    >
      {children}
    </BonusPortalsContext.Provider>
  );
};

export default BonusPortalsProvider;
