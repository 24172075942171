import React, { useCallback, useContext, useState } from 'react';
import STORE from 'store';
import AccountPickerContext from 'contexts/AccountPickerContext';
import ManageAccessModal from './ManageAccessModal';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import getTimeStamp from '../../../getTimeStamp';
import portalTypeCheckers from '../../../types/portalTypeCheckers';
import portalNavItemTypeCheckers from '../../../types/portalNavItemTypeCheckers';

const ManageAccessModalContainer = ({
  type,
  typeId,
  contentName,
  close,
  isPublished,
}: {
  type: ExecutivePortalPublishableContentType;
  typeId: string;
  contentName: string;
  close: () => void;
  isPublished: boolean;
}) => {
  const { id: currentUserId } = useContext(CurrentUserContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedPortals, setSelectedPortals] = useState<
    (ExecutivePortal | EngagementPortal)[]
  >([]);
  const [successMessage, setSuccessMessage] = useState<string | undefined>();

  const showSuccessMessage = useCallback(() => {
    const message =
      selectedPortals.length === 1
        ? `Published to '${selectedPortals[0].name}' Portal`
        : `Published to ${selectedPortals.length} Portals`;
    setSuccessMessage(message);
    setTimeout(() => {
      setSuccessMessage(undefined);
    }, 10000);
  }, [selectedPortals]);

  const publishToPortal = useCallback(
    async ({
      portal,
      type,
    }: {
      portal: EngagementPortal | ExecutivePortal | BonusPortal;
      type: ExecutivePortalPublishableContentType;
    }): Promise<void> => {
      const typeChecker = (() => {
        if (type === 'scorecard') {
          return portalNavItemTypeCheckers.isScorecardsIndex;
        } else if (type === 'dashboard') {
          return portalNavItemTypeCheckers.isDashboardsIndex;
        } else if (type === 'report') {
          return portalNavItemTypeCheckers.isReportsIndex;
        }
        return undefined;
      })();
      if (!typeChecker) {
        return;
      }

      const section = portal.navigationSettings.sections.find((s) =>
        s.items.some(typeChecker),
      );
      if (!section) {
        return;
      }
      const item = section.items.find(typeChecker);
      if (!item) {
        return;
      }

      const newPortal: EngagementPortal | ExecutivePortal | BonusPortal = {
        ...portal,
        updatedBy: currentUserId,
        updatedOn: getTimeStamp(),
        navigationSettings: {
          ...portal.navigationSettings,
          sections: portal.navigationSettings.sections.map((s) => {
            if (s.key === section.key) {
              return {
                ...s,
                items: s.items.map((i) => {
                  if (i.key === item.key) {
                    if (portalNavItemTypeCheckers.isReportsIndex(i)) {
                      return {
                        ...i,
                        reportIds: [...i.reportIds, typeId],
                      };
                    } else if (portalNavItemTypeCheckers.isScorecardsIndex(i)) {
                      return {
                        ...i,
                        scorecardIds: [...i.scorecardIds, typeId],
                      };
                    } else if (portalNavItemTypeCheckers.isDashboardsIndex(i)) {
                      return {
                        ...i,
                        dashboardIds: [...i.dashboardIds, typeId],
                      };
                    }
                    return i;
                  }
                  return i;
                }),
              };
            }
            return s;
          }),
        },
      };

      if (portalTypeCheckers.isEngagementPortal(newPortal)) {
        return STORE.contentDistributions
          .getEngagementPortalsRef({ accountId: selectedAccountId })
          .doc(portal.id)
          .set(newPortal);
      } else if (portalTypeCheckers.isExecutivePortal(newPortal)) {
        return STORE.contentDistributions
          .getExecutivePortalsRef({ accountId: selectedAccountId })
          .doc(portal.id)
          .set(newPortal);
      } else if (portalTypeCheckers.isBonusPortal(newPortal)) {
        return STORE.contentDistributions
          .getBonusPortalsRef({ accountId: selectedAccountId })
          .doc(portal.id)
          .set(newPortal);
      }
    },
    [currentUserId, selectedAccountId, typeId],
  );

  const onPublishClicked = useCallback(async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const promises = selectedPortals.map((portal) => {
      return publishToPortal({
        portal,
        type,
      });
    });

    await Promise.all(promises);
    showSuccessMessage();
    setSelectedPortals([]);
    setIsLoading(false);
  }, [isLoading, publishToPortal, selectedPortals, showSuccessMessage, type]);

  return (
    <ManageAccessModal
      selectedPortals={selectedPortals}
      setSelectedPortals={setSelectedPortals}
      isPublished={isPublished}
      contentName={contentName}
      type={type}
      typeId={typeId}
      isLoading={isLoading}
      successMessage={successMessage}
      onPublishClicked={onPublishClicked}
      close={close}
    />
  );
};

export default ManageAccessModalContainer;
