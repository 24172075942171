import { useCallback } from 'react';
import portalTypeCheckers from '../../types/portalTypeCheckers';
import portalNavItemTypeCheckers from '../../types/portalNavItemTypeCheckers';

const useGetMappedEntityViews = (): ((
  portal: Portal,
) => PortalsNav.MappedEntityView[]) => {
  const useGetMappedEntityViews = useCallback(
    (portal: Portal): PortalsNav.MappedEntityView[] => {
      if (portalTypeCheckers.isAdminPortal(portal)) {
        return [];
      }

      return portal.navigationSettings.sections.reduce((a, b) => {
        const newItems = b.items.filter(
          portalNavItemTypeCheckers.isMappedEntityView,
        );
        return [...a, ...newItems];
      }, [] as PortalsNav.MappedEntityView[]);
    },
    [],
  );

  return useGetMappedEntityViews;
};

export default useGetMappedEntityViews;
