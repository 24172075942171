import { useCallback, useContext, useEffect, useState } from 'react';
import { ExecutivePortalsContext } from './ExecutivePortalsProvider';
import { EngagementPortalsContext } from './EngagementPortalsProvider';
import useAdminPortal from './useAdminPortal';
import isDefined from '../../../../isDefined';
import { BonusPortalsContext } from './BonusPortalsProvider';

const useHasAvailablePortal = () => {
  const { availableExecutivePortals, isLoadingExecutivePortals } = useContext(
    ExecutivePortalsContext,
  );
  const { availableEngagementPortals, isLoadingEngagementPortals } = useContext(
    EngagementPortalsContext,
  );
  const { availableBonusPortals, isLoadingBonusPortals } =
    useContext(BonusPortalsContext);
  const adminPortal = useAdminPortal();

  const getAvailablePortals = useCallback(
    () =>
      [
        adminPortal,
        ...availableExecutivePortals,
        ...availableEngagementPortals,
        ...availableBonusPortals,
      ].filter(isDefined),
    [
      adminPortal,
      availableEngagementPortals,
      availableExecutivePortals,
      availableBonusPortals,
    ],
  );
  const getHasAvailablePortal = useCallback(() => {
    return getAvailablePortals().length > 0;
  }, [getAvailablePortals]);

  const [availablePortals, setAvailablePortals] = useState<Portal[]>(() =>
    getAvailablePortals(),
  );
  const [hasAvailablePortal, setHasAvailablePortal] = useState<boolean>(() =>
    getHasAvailablePortal(),
  );

  useEffect(() => {
    setAvailablePortals(getAvailablePortals());
    setHasAvailablePortal(getHasAvailablePortal());
  }, [getAvailablePortals, getHasAvailablePortal]);

  const isLoadingPortals =
    isLoadingExecutivePortals ||
    isLoadingEngagementPortals ||
    isLoadingBonusPortals;

  return {
    availablePortals,
    hasAvailablePortal,
    isLoadingPortals,
  };
};

export default useHasAvailablePortal;
