import React from 'react';
import ShadowedList from '../../../../ShadowedList';
import { WidgetWrapper } from '../MetricSlideoutWizard';
import { LabelDiv } from '../../../../../screens/Boards/PerformanceBoardForm';
import Typography from '../../../../../kingpin/atoms/Typography';
import Inputs from '../../../../Inputs';
import IntervalPicker from '../../IntervalPicker/IntervalPicker';
import WidgetFooter from '../../WidgetFooter';
import FilterPlates from '../../../../FilterPlates';
import DragAndDropList from '../../../../DragAndDropList';
import Card from '../../../../../kingpin/atoms/Card';
import DragHandle from '../../../../DragAndDropList/DragHandle';
import Row from '../../../../Common/Row';
import Button from '../../../../../kingpin/atoms/Button';
import Dropdown from '../../../../Inputs/Dropdown';

const RelatedDocumentsSlideoutWizard = ({
  title,
  onTitleChanged,
  datasetOptions,
  fieldOptions,
  fields,
  setFields,
  widgetDateRange,
  setWidgetDateRange,
  draftSection,
  saveWidget,
  close,
}: {
  title: string;
  onTitleChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
  datasetOptions: DropdownOption[];
  fieldOptions: DropdownOption[];
  fields: string[];
  setFields: React.Dispatch<React.SetStateAction<string[]>>;
  widgetDateRange: PerformanceBoardTypes.SlideOut.SlideOutDateRange;
  setWidgetDateRange: React.Dispatch<
    React.SetStateAction<PerformanceBoardTypes.SlideOut.SlideOutDateRange>
  >;
  draftSection?: PerformanceBoardTypes.SlideOut.RelatedDocumentsSection;
  saveWidget: () => void;
  close: () => void;
}) => (
  <>
    <ShadowedList>
      <WidgetWrapper>
        <div style={{ marginBottom: 24 }}>
          <LabelDiv>
            <Typography.Body type="Label">Widget Title</Typography.Body>
          </LabelDiv>
          <Inputs.TextInput
            value={title}
            placeholder="Enter Title"
            onChange={onTitleChanged}
            maxLength={40}
            autoFocus
            data-testid={'title'}
          />
        </div>
        <div style={{ marginBottom: 24 }}>
          <LabelDiv>
            <Typography.Body type="Label">Dataset</Typography.Body>
          </LabelDiv>
          <Inputs.Dropdown options={datasetOptions} />
        </div>
        <div style={{ marginBottom: 24 }}>
          <LabelDiv>
            <Typography.Body type="Label">Fields</Typography.Body>
          </LabelDiv>
          <div style={{ marginBottom: 8 }}>
            <DragAndDropList
              items={fields}
              onOrderChanged={setFields}
              droppableId={'fields-order'}
              renderItem={(field) => (
                <Card key={field} style={{ padding: 8, marginBottom: 4 }}>
                  <Row centerAlign spaceBetween>
                    <Row centerAlign>
                      <DragHandle />
                      <Typography.Body type={'Button Text'}>
                        {field}
                      </Typography.Body>
                    </Row>
                    <Button
                      size={'Small'}
                      type={'Secondary'}
                      icon="cross"
                      onClick={() => {
                        setFields((current) =>
                          current.filter((f) => f !== field),
                        );
                      }}
                    />
                  </Row>
                </Card>
              )}
            />
          </div>
          <Row centerAlign spaceBetween>
            <div />
            <Dropdown options={fieldOptions} placeholder="Add Field" />
          </Row>
        </div>
        <div style={{ marginBottom: 24 }}>
          <IntervalPicker
            widgetDateRange={widgetDateRange}
            setWidgetDateRange={setWidgetDateRange}
          />
        </div>
        <div style={{ marginBottom: 24 }}>
          <LabelDiv>
            <Typography.Body type="Label">Scope</Typography.Body>
          </LabelDiv>
          <FilterPlates isScope />
        </div>
      </WidgetWrapper>
    </ShadowedList>
    <WidgetFooter
      saveWidget={saveWidget}
      sectionType="Metric Widget"
      isDisabled={!draftSection}
      close={close}
    />
  </>
);
export default RelatedDocumentsSlideoutWizard;
