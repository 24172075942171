import React, { useContext, useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import WorkSpaceBoardWizard from './WorkSpaceBoardWizard';
import setWorkSpace from '../../../api/workspaces/setWorkSpace';
import BoardsContext from '../../../contexts/BoardsContext';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import { isTargetsApp } from '../../../isWorkSpace';
import STORE from '../../../store';

const WorkSpaceBoardWizardContainer = ({
  workSpace,
  onBackClicked,
  close,
}: {
  workSpace: WorkSpace | TargetsApp.App;
  onBackClicked: () => void;
  close: () => void;
}) => {
  const { accountRef, selectedAccountId } = useContext(AccountPickerContext);
  const { boards } = useContext(BoardsContext);
  const currentUser = useContext(CurrentUserContext);
  const { trackEvent } = useContext(AnalyticsContext);

  const [selectedBoardId, setSelectedBoardId] = useState<string>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [unusedBoards, setUnusedBoards] = useState<
    (PerformanceBoardTypes.Board | Board)[]
  >([]);
  useEffect(() => {
    setUnusedBoards(
      boards.filter(
        (b) =>
          !workSpace.tabs.some((t) => t.type === 'board' && t.typeId === b.id),
      ),
    );
  }, [boards, workSpace.tabs]);

  const [boardOptions, setBoardOptions] = useState<DropdownOption[]>([]);
  useEffect(() => {
    setBoardOptions(
      unusedBoards.map((b) => ({
        label: b.name,
        onSelected: () => {
          setSelectedBoardId(b.id);
        },
        isSelected: selectedBoardId === b.id,
      })),
    );
  }, [selectedBoardId, unusedBoards]);

  const onSave = () => {
    const newTab = {
      type: 'board' as 'board',
      typeId: selectedBoardId,
    };

    if (isTargetsApp(workSpace)) {
      const newApp = {
        ...workSpace,
        tabs: [...workSpace.tabs, newTab],
        updatedBy: currentUser.id,
        updatedOn: DateTime.utc().toISO(),
      };
      STORE.contentDistributions
        .getTargetsApps({ accountId: selectedAccountId })
        .doc(newApp.id)
        .set(newApp)
        .then(() => {
          setIsSaving(false);
          trackEvent('Targets App - Tab added', { tabType: 'board' });
          close();
        });
    } else {
      const newWorkSpace = {
        ...workSpace,
        tabs: [...workSpace.tabs, newTab],
        updatedBy: currentUser.id,
        updatedOn: DateTime.utc().toISO(),
      } as WorkSpace;
      setWorkSpace(newWorkSpace, accountRef).then(() => {
        setIsSaving(false);
        trackEvent('Workspace - Tab added', { tabType: 'board' });
        close();
      });
    }
  };

  return (
    <WorkSpaceBoardWizard
      selectedBoardId={selectedBoardId}
      boardOptions={boardOptions}
      isSaving={isSaving}
      onSave={onSave}
      onBackClicked={onBackClicked}
    />
  );
};

export default WorkSpaceBoardWizardContainer;
