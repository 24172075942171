import { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import STORE from '../../../../store';
import AccountPickerContext from '../../../../contexts/AccountPickerContext';
import {
  NATIONAL_CARRIERS_ACCOUNT_ID,
  NAVAJO_ACCOUNT_ID,
} from '../../../../constants';

const useBonusPrograms = () => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const [bonusPrograms, setBonusPrograms] = useState<Scoring.BonusProgram[]>(
    [],
  );
  const [bonusPeriodsLookup, setBonusPeriodsLookup] = useState<{
    [program: string]: Scoring.BonusPeriod[] | undefined;
  }>({});

  useEffect(() => {
    if (
      selectedAccountId === NAVAJO_ACCOUNT_ID ||
      selectedAccountId === NATIONAL_CARRIERS_ACCOUNT_ID
    ) {
      setBonusPrograms([]);
      setBonusPeriodsLookup({});
      return;
    }

    STORE.scoring
      .getScoringProgramsRef({
        accountId: selectedAccountId,
      })
      .get()
      // TODO: Remove ignores and mocks
      // @ts-ignore
      .then((docs) => {
        const newPrograms: Scoring.BonusProgram[] = [];
        // @ts-ignore
        docs.forEach((doc) => {
          newPrograms.push(doc.data());
        });
        setBonusPrograms(newPrograms);
        setBonusPeriodsLookup({});
      });
  }, [selectedAccountId]);

  useEffect(() => {
    const listeners = bonusPrograms.map((p) => {
      return (
        STORE.scoring
          .getScoringPeriodsRef({
            accountId: selectedAccountId,
            programDoc: p.id,
          })
          // TODO: Remove ignores and mocks
          // @ts-ignore
          .onSnapshot((periodDocs) => {
            const newPeriods: Scoring.BonusPeriod[] = [];
            // @ts-ignore
            periodDocs.forEach((doc) => {
              newPeriods.push(doc.data());
            });

            const sortedPeriods = _.sortBy(newPeriods, 'startDate');
            setBonusPeriodsLookup((current) => {
              return {
                ...current,
                [p.id]: sortedPeriods,
              };
            });
          })
      );
    });

    return () => {
      listeners.forEach((l) => l());
    };
  }, [bonusPrograms, selectedAccountId]);

  const getBonusProgram = useCallback(
    (dataset: string) => {
      return bonusPrograms.find((p) => p.dataset === dataset);
    },
    [bonusPrograms],
  );

  return { bonusPrograms, bonusPeriodsLookup, getBonusProgram };
};

export default useBonusPrograms;
