import React, { useContext } from 'react';

import LocalTasksContext from '../../contexts/Tasks/LocalTasksContext';
import usePopup from '../../hooks/usePopup';
import Row from '../Common/Row';
import CanvasCardTitle from '../CanvasCardTitle';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';
import Inputs from '../Inputs';
import TaskForm from './TaskForm';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';

const AddButton = () => {
  const { isOpen, open, close } = usePopup();

  return (
    <>
      <Button
        onClick={open}
        type="Tertiary"
        size="Small"
        label="Add Task"
        icon="add"
      />
      <ModalTransition>
        {isOpen && (
          <Modal
            onClose={close}
            shouldScrollInViewport={false}
            autoFocus={false}
          >
            <TaskForm close={close} onCancelled={close} />
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export const ShowCompletedToggle = () => {
  const { isShowingCompleted, setIsShowingCompleted } =
    useContext(LocalTasksContext);

  return (
    <Row style={{ marginRight: 16 }} centerAlign>
      <div style={{ marginRight: 8 }}>
        <Inputs.Checkbox
          isChecked={isShowingCompleted}
          onToggled={setIsShowingCompleted}
        />
      </div>

      <Typography.Body type="Body 12">Show completed</Typography.Body>
    </Row>
  );
};

const TasksListHeader = () => {
  return (
    <div>
      <Row
        spaceBetween
        centerAlign
        style={{
          width: '100%',
          height: 56,
          padding: '0px 16px',
        }}
      >
        <CanvasCardTitle title={'Tasks'} />
        <Row centerAlign>
          <ShowCompletedToggle />
          <AddButton />
        </Row>
      </Row>
    </div>
  );
};

export default TasksListHeader;
