import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import Row from '../Common/Row';
import Typography from 'kingpin/atoms/Typography';
import { UserRow } from '../PerformanceTargetsBoard/PerformanceSummaryTab/PerformanceSummaryTab';
import TaskForm from './TaskForm';
import Comments from '../Comments';
import useTaskItem from '../../hooks/useTaskItem';
import DoneCircle from './DoneCircle';
import TaskContext from '../../contexts/TaskContext';
import deleteTask from '../../contextProviders/TasksProvider/deleteTask';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import LocalTimelineContext from '../../contexts/Timeline/LocalTimelineContext';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import usePopup from '../../hooks/usePopup';
import ConfirmationModal from '../ConfirmationModal';
import Button from 'kingpin/atoms/Button';
import Colors2 from '../../theme/Colors2';
export const TASK_POPUP_MODAL_HEIGHT = 530;

const TaskFormWrapper = styled.div`
  margin-right: 32px;
  box-shadow: 1px 0px 2px -1px rgba(9, 45, 66, 0.25);
`;

const TaskPopup = ({
  task,
  onClose,
}: {
  task: Tasks.Task;
  onClose: () => void;
}) => {
  const item = useTaskItem(task);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const { addEvent } = useContext(LocalTimelineContext);
  const [extraWatcherIds, setExtraWatcherIds] = useState<string[]>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const {
    isOpen: isConfirmingDelete,
    open: openDeleteConfirmation,
    close: closeDeleteConfirmation,
  } = usePopup();

  const stopEditing = useCallback(() => {
    setIsEditing(false);
  }, []);

  useEffect(() => {
    setExtraWatcherIds([task.assignedTo, task.createdBy]);
  }, [task.assignedTo, task.createdBy]);

  const onDeleteConfirmed = useCallback(() => {
    deleteTask(task, selectedAccountId).then(() => {
      trackEvent('Task - Deleted', {
        taskableType: task.taskableType,
        taskableId: task.taskableId,
      });
      if (addEvent) {
        addEvent({
          actionText: 'deleted a task',
          contextText: task.title,
        }).then(() => {
          onClose();
        });
      } else {
        onClose();
      }
    });
  }, [selectedAccountId, addEvent, onClose, task, trackEvent]);

  if (!item) {
    return null;
  }

  return (
    <>
      <div
        data-testid={`${task.id}-popup`}
        style={{
          padding: '16px 24px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Row
          spaceBetween
          centerAlign
          style={{
            marginBottom: 16,
            borderBottom: `1px solid ${Colors2.Border}`,
            paddingBottom: 12,
          }}
        >
          <div>
            <Row centerAlign style={{ height: 42 }}>
              <div style={{ width: 18, marginRight: 12 }}>
                <DoneCircle task={task} />
              </div>
              <Typography.Header type="H4">{task.title}</Typography.Header>
            </Row>
          </div>

          <Row centerAlign>
            {!isEditing && (
              <>
                <div style={{ marginRight: 8 }}>
                  <Button
                    type="Secondary"
                    size="Small"
                    label="Edit"
                    testId={'edit-task'}
                    onClick={() => {
                      setIsEditing(true);
                    }}
                    icon={'edit-filled'}
                  />
                </div>
                <div style={{ marginRight: 8 }}>
                  <Button
                    type="Danger"
                    size="Small"
                    label="Delete"
                    testId={'delete-task'}
                    onClick={openDeleteConfirmation}
                    icon={'cross'}
                  />
                </div>
              </>
            )}

            <Button
              onClick={onClose}
              type="Secondary"
              size="Small"
              icon="cross"
            />
          </Row>
        </Row>
        <Row style={{ flex: 1 }}>
          {isEditing && (
            <TaskFormWrapper style={{ flex: 1, height: '100%' }}>
              <TaskForm
                close={onClose}
                onCancelled={stopEditing}
                task={task}
                hideTitle
              />
            </TaskFormWrapper>
          )}
          {!isEditing && (
            <TaskFormWrapper
              style={{ flex: 1, height: '100%', paddingRight: 24 }}
            >
              {task.description !== '' && (
                <div style={{ marginBottom: 24 }}>
                  <div style={{ marginBottom: 4 }}>
                    <Typography.Header type="H5">Description</Typography.Header>
                  </div>
                  <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                    <Typography.Body type={'Body 14'}>
                      {task.description}
                    </Typography.Body>
                  </div>
                </div>
              )}

              <div style={{ marginBottom: 16 }}>
                <Row>
                  <div style={{ marginRight: 8, flex: 1 }}>
                    <div style={{ marginBottom: 4 }}>
                      <Typography.Body type="Label">Due Date</Typography.Body>
                    </div>
                    <Typography.Body type="Body 12">
                      {task.dueDate}
                    </Typography.Body>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div style={{ marginBottom: 4 }}>
                      <Typography.Body type="Label"> Assignee</Typography.Body>
                    </div>
                    <UserRow userId={task.assignedTo} noEmail />
                  </div>
                </Row>
              </div>
            </TaskFormWrapper>
          )}
          <div style={{ flex: 1, height: '100%' }}>
            <div style={{ marginBottom: 4 }}>
              <Typography.Header type="H5">Comments</Typography.Header>
            </div>
            <TaskContext.Provider value={{ task }}>
              <Comments
                inputOnTop
                commentableId={`${item.id}-${task.id}`}
                commentableType={`${task.taskableType}-task`}
                emailLink={window.location.pathname}
                mentionedEmailBody={`mentioned you on the task ${task.title} associated with:`}
                watcherEmailBody={`commented on the task ${task.title} associated with:`}
                emailBodyLn1Extra={item.title}
                extraWatcherIds={extraWatcherIds}
              />
            </TaskContext.Provider>
          </div>
        </Row>
      </div>
      <ConfirmationModal
        isOpen={isConfirmingDelete}
        close={closeDeleteConfirmation}
        title={'Delete task?'}
        confirmText={'Delete'}
        cancelText="Cancel"
        body={'You are about to delete this task. Are you sure?'}
        onConfirmed={onDeleteConfirmed}
      />
    </>
  );
};

export default TaskPopup;
