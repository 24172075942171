import React, { forwardRef, useRef, useState } from 'react';
import { flip, offset, shift } from '@floating-ui/dom';
import { arrow, FloatingArrow } from '@floating-ui/react';

import { AltTooltipDiv, TooltipDiv, TOOLTIP_COLOR } from './styled';
import {
  useFloating,
  autoUpdate,
  useInteractions,
  useHover,
} from '@floating-ui/react';
import ReactPortal from '../ReactPortal';

export const TOOLTIP_PORTAL_DIV_ID = 'TOOLTIP_PORTAL_DIV_ID';

const Anchor = forwardRef<
  HTMLDivElement,
  {
    children: JSX.Element | JSX.Element[];
    isFlexAnchor?: boolean;
    testId?: string;
  }
>(
  (
    {
      children,
      isFlexAnchor,
      testId,
    }: {
      children: JSX.Element | JSX.Element[];
      isFlexAnchor?: boolean;
      testId?: string;
    },
    ref,
  ) => (
    <div
      ref={ref}
      style={{ overflow: 'hidden', display: isFlexAnchor ? 'flex' : 'block' }}
      className={'tooltip-anchor'}
      data-testid={testId}
    >
      {children}
    </div>
  ),
);

const Tooltip = ({
  content,
  position,
  children,
  isAltTooltip,
  isFlexAnchor,
  shouldMaintainConstantDomTree,
  testId,
}: {
  content:
    | JSX.Element
    | JSX.Element[]
    | (JSX.Element | undefined | string | number)[]
    | string
    | string[]
    | number
    | undefined
    | null;
  position?: 'top' | 'left' | 'bottom' | 'right';
  children: JSX.Element | JSX.Element[];
  isAltTooltip?: boolean;
  hideTooltipOnClick?: boolean;
  onLongOpenClosed?: () => void;
  tag?: string;
  delay?: number;
  isFlexAnchor?: boolean;
  shouldMaintainConstantDomTree?: boolean;
  testId?: string;
}) => {
  const arrowRef = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const TooltipComponent = isAltTooltip ? AltTooltipDiv : TooltipDiv;
  const { refs, context, floatingStyles } = useFloating({
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: position,
    strategy: 'absolute',
    middleware: [
      flip(),
      offset(8),
      shift(),
      arrow({
        element: arrowRef,
      }),
    ],
  });
  const hover = useHover(
    context,
    process.env.NODE_ENV === 'test'
      ? undefined
      : {
          delay: { open: 600, close: 0 },
        },
  );
  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  if (!content && !shouldMaintainConstantDomTree) {
    return <>{children}</>;
  }

  const arrowColor = isAltTooltip ? 'white' : TOOLTIP_COLOR;

  return (
    <>
      <Anchor
        ref={refs.setReference}
        {...getReferenceProps()}
        isFlexAnchor={isFlexAnchor}
        testId={testId}
      >
        {children}
      </Anchor>
      {isOpen && (
        <ReactPortal elementId={TOOLTIP_PORTAL_DIV_ID} isTestModeDisabled>
          <TooltipComponent
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <FloatingArrow
              ref={arrowRef}
              context={context}
              fill={arrowColor}
              stroke={arrowColor}
              color={arrowColor}
            />
            {content}
          </TooltipComponent>
        </ReactPortal>
      )}
    </>
  );
};

export default Tooltip;
