import React from 'react';

interface PopupContextType {
  selectedReport?: ReportType;
  selectedBoard?: PerformanceBoardTypes.Board | Board;
  isOpen: boolean;
  close: () => void;
  openPopupReport: ({
    drillDowns,
    origin,
    scorecardPeriodSettings,
    dashboardGadget,
    selectedReport,
    unSavedFilter,
  }: {
    drillDowns: FixedFilter[];
    origin: 'Dashboard' | 'Goal' | 'Scorecard' | 'DriverScoringProgramReport';
    scorecardPeriodSettings?: {
      selectedPeriod: Period;
      availablePeriods: Period[];
      kpi: Scorecards.ScorecardKpi | Scorecards.ManualKpiRow;
    };
    dashboardGadget?: DashboardGadget;
    selectedReport: ReportType;
    unSavedFilter?: UnSavedFilter;
  }) => void;
  openPopupBoard: ({
    popupFilters,
    scope,
    bonusPeriodId,
    popupDateRange,
    origin,
    scorecardPeriodSettings,
    dashboardGadget,
    selectedBoard,
  }: {
    popupFilters: FilterPlateType[];
    scope: FilterPlateType[];
    bonusPeriodId?: string;
    popupDateRange?: DateRangeInput;
    origin: 'Dashboard' | 'Goal' | 'Scorecard' | 'DriverScoringProgramReport';
    scorecardPeriodSettings?: {
      selectedPeriod: Period;
      availablePeriods: Period[];
      kpi: Scorecards.ScorecardKpi;
    };
    dashboardGadget?: DashboardGadget;
    selectedBoard: PerformanceBoardTypes.Board | Board;
    unSavedFilter?: UnSavedFilter;
  }) => void;
  poppedUpKpi?: Scorecards.ScorecardKpi | Scorecards.ManualKpiRow;
  dashboardGadget?: DashboardGadget;
  origin?: 'Dashboard' | 'Goal' | 'Scorecard' | 'DriverScoringProgramReport';
}

const PopupContext = React.createContext<PopupContextType>(
  {} as PopupContextType,
);

export default PopupContext;
