import React, { useCallback, useContext, useMemo, useState } from 'react';
import getIdentifier from '../../../getIdentifier';
import STORE from '../../../store';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import { useNavigate } from 'react-router-dom';
import { buildShowBoard } from '../../../navigation/appRoutes';
import Form from '../../../kingpin/forms/Form';
import FormHeader from '../../../kingpin/forms/FormHeader';
import FormContent from '../../../kingpin/forms/FormContent';
import Typography from '../../../kingpin/atoms/Typography';
import TextInput from '../../../kingpin/atoms/TextInput';
import Row from '../../Common/Row';
import Button from '../../../kingpin/atoms/Button';
import Modal from '@atlaskit/modal-dialog';

const PerformanceBoardCopyPopup = ({
  board,
  close,
}: {
  board: PerformanceBoardTypes.Board;
  close: () => void;
}) => {
  const navigate = useNavigate();
  const { selectedAccountId } = useContext(AccountPickerContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newId] = useState<string>(getIdentifier());
  const [name, setName] = useState<string>('');
  const draftBoard = useMemo((): PerformanceBoardTypes.Board | undefined => {
    if (name !== '') {
      return {
        ...board,
        id: newId,
        name,
      };
    }
  }, [board, name, newId]);

  const onSaveClicked = useCallback(async () => {
    if (!draftBoard) {
      return;
    }
    setIsLoading(true);

    await STORE.contentDistributions
      .getPerformanceBoardsRef({
        accountId: selectedAccountId,
      })
      .doc(newId)
      .set(draftBoard);

    setIsLoading(false);
    navigate(buildShowBoard(draftBoard.id));
    close();
  }, [close, draftBoard, navigate, newId, selectedAccountId]);

  return (
    <Modal onClose={close} shouldScrollInViewport={false} autoFocus={false}>
      <Form>
        <FormHeader title={'Copy Board'} onClose={close} />
        <FormContent>
          <div style={{ marginBottom: 16 }}>
            <div style={{ marginBottom: 4 }}>
              <Typography.Body type={'Label'}>Name</Typography.Body>
              <TextInput
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
          </div>
        </FormContent>
        <Row spaceBetween centerAlign>
          <div />
          <Button
            size={'Small'}
            type={'Primary'}
            onClick={onSaveClicked}
            isLoading={isLoading}
            isDisabled={!draftBoard}
            label={'Copy'}
          />
        </Row>
      </Form>
    </Modal>
  );
};

export default PerformanceBoardCopyPopup;
