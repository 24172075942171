import React, { useCallback, useContext, useEffect, useState } from 'react';
import getTimeStamp from 'getTimeStamp';
import CurrentUserContext from 'contexts/CurrentUserContext';
import AccountPickerContext from 'contexts/AccountPickerContext';
import STORE from 'store';

import TargetsAppContext from '../TargetsAppContext';
import tabTypeCheckers from '../tabTypeCheckers';
import isDefined from '../../../../isDefined';
import Tabs from 'components/Tabs';

const ManageTabsButton = () => {
  // Local tabs to prevent jank between drop and firestore snapshot update
  const [fixedTabs, setFixedTabs] = useState<TargetsApp.ClientTab[]>([]);
  const [variableTabs, setVariableTabs] = useState<TargetsApp.ClientTab[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { app, tabs } = useContext(TargetsAppContext);
  const currentUser = useContext(CurrentUserContext);
  const { selectedAccountId } = useContext(AccountPickerContext);

  const updateLocalTabs = useCallback((newTabs: TargetsApp.ClientTab[]) => {
    setFixedTabs(newTabs.filter(tabTypeCheckers.isOverviewTab));
    setVariableTabs(newTabs.filter((t) => !tabTypeCheckers.isOverviewTab(t)));
  }, []);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    updateLocalTabs(tabs);
  }, [isLoading, tabs, updateLocalTabs]);

  const onTabRemoved = useCallback(
    (tab: TargetsApp.ClientTab) => {
      const newTabs = tabs.filter((t) => t.key !== tab.key);

      setIsLoading(true);
      updateLocalTabs(newTabs);
      const newApp = {
        ...app,
        tabs: newTabs,
        updatedBy: currentUser.id,
        updatedAt: getTimeStamp(),
      };
      STORE.contentDistributions
        .getTargetsApps({
          accountId: selectedAccountId,
        })
        .doc(newApp.id)
        .set(newApp)
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    },
    [app, currentUser.id, selectedAccountId, tabs, updateLocalTabs],
  );

  const onOrderChanged = useCallback(
    (newVariableOrder: TargetsApp.ClientTab[]) => {
      const newOrder = [...fixedTabs, ...newVariableOrder].filter(isDefined);
      setIsLoading(true);
      updateLocalTabs(newOrder);
      const newApp = {
        ...app,
        tabs: newOrder,
        updatedBy: currentUser.id,
        updatedAt: getTimeStamp(),
      };

      STORE.contentDistributions
        .getTargetsApps({
          accountId: selectedAccountId,
        })
        .doc(newApp.id)
        .set(newApp)
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    },
    [app, currentUser.id, fixedTabs, selectedAccountId, updateLocalTabs],
  );

  return (
    <Tabs.ManageTabsButton
      fixedTabs={fixedTabs}
      variableTabs={variableTabs}
      onOrderChanged={onOrderChanged}
      onTabRemoved={onTabRemoved}
    />
  );
};
export default ManageTabsButton;
