import { useContext, useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import PeriodsContext from 'contexts/PeriodsContext';
import useIntervalConfig from '../PerformanceBoardSlideOut/IntervalPicker/useIntervalConfig';

const useFullDataInterval = ({
  mode,
  n,
  isToDate,
}: PerformanceBoardTypes.SlideOut.SlideOutDateRange) => {
  const { selectedPeriod } = useContext(PeriodsContext);
  const [dateInterval, setDateInterval] = useState<DateRangeInput>();
  const [dateScope, setDateScope] = useState<DateRangeInput>();
  const { interval, intervalLength, startDate } = useIntervalConfig();

  if (!selectedPeriod) {
    throw new Error('Selected period is undefined');
  }

  useEffect(() => {
    if (!interval || !intervalLength || !startDate || (!n && mode === 'Last')) {
      setDateInterval(undefined);
      setDateScope(undefined);
      return;
    }

    const currentDateISO = DateTime.local().toISO();
    const currentDate = DateTime.fromISO(currentDateISO);
    const currentStart = DateTime.fromISO(startDate);
    const selectedEndDate = DateTime.fromISO(selectedPeriod.endDate);

    // Return if dataset startDate is in the future
    if (currentDate < currentStart) {
      setDateInterval(undefined);
      setDateScope(undefined);
      return;
    }

    // Get all intervals from dataset startDate to endDate (current/selected)
    const getAllIntervals = ({ endDate }: { endDate: DateTime }) => {
      let currentStart = DateTime.fromISO(startDate);
      let currentEnd = currentStart
        .plus({
          [interval]: intervalLength,
        })
        .minus({
          days: 1,
        });

      const intervals = [];
      while (currentEnd < endDate) {
        currentStart = currentStart.plus({
          [interval]: intervalLength,
        });
        currentEnd = currentEnd.plus({
          [interval]: intervalLength,
        });

        intervals.push({ currentStart, currentEnd });
      }

      return intervals;
    };

    const intervalsSlideout = getAllIntervals({
      endDate: currentDate,
    });

    const intervalsScope = getAllIntervals({
      endDate: selectedEndDate,
    });

    const handleCurrentInterval = ({
      intervals,
      currentEndDate,
    }: {
      intervals: {
        currentStart: DateTime;
        currentEnd: DateTime;
      }[];
      currentEndDate: DateTime;
    }) => {
      if (intervals.length === 0) {
        return undefined;
      }
      const returnStartDate =
        intervals[intervals.length - 1].currentStart.toISODate();

      const returnEndDate = isToDate
        ? currentEndDate.toISODate()
        : intervals[intervals.length - 1].currentEnd.toISODate();

      return {
        startDate: returnStartDate,
        endDate: returnEndDate,
      };
    };

    const handleLastInterval = ({
      intervals,
      currentEndDate,
    }: {
      intervals: {
        currentStart: DateTime;
        currentEnd: DateTime;
      }[];
      currentEndDate: DateTime;
    }) => {
      if (!n || intervals.length === 0) {
        return;
      }

      const intervalsLen = intervals.length - 1;
      const completedIntervalsLen = intervalsLen < n ? intervalsLen : n;

      const returnStartDate =
        intervals[
          intervalsLen - completedIntervalsLen
        ].currentStart.toISODate();

      const returnEndDate = isToDate
        ? currentEndDate.toISODate()
        : intervals[intervalsLen - 1].currentEnd.toISODate();

      return {
        startDate: returnStartDate,
        endDate: returnEndDate,
      };
    };

    if (mode === 'Current Interval') {
      setDateInterval(
        handleCurrentInterval({
          intervals: intervalsSlideout,
          currentEndDate: currentDate,
        }),
      );
      setDateScope(
        handleCurrentInterval({
          intervals: intervalsScope,
          currentEndDate: selectedEndDate,
        }),
      );
    } else {
      if (!n) {
        setDateInterval(undefined);
        setDateScope(undefined);
        return;
      }

      setDateInterval(
        handleLastInterval({
          intervals: intervalsSlideout,
          currentEndDate: currentDate,
        }),
      );
      setDateScope(
        handleLastInterval({
          intervals: intervalsScope,
          currentEndDate: selectedEndDate,
        }),
      );
    }
  }, [interval, intervalLength, isToDate, mode, n, selectedPeriod, startDate]);

  return {
    dateInterval,
    dateScope,
  };
};

export default useFullDataInterval;
