import React, { useContext, useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import WorkSpaceScorecardWizard from './WorkSpaceScorecardWizard';
import ScorecardsContext from '../../../contexts/ScorecardsContext';
import setWorkSpace from '../../../api/workspaces/setWorkSpace';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import { isTargetsApp } from '../../../isWorkSpace';
import STORE from '../../../store';

const WorkSpaceScorecardWizardContainer = ({
  workSpace,
  onBackClicked,
  close,
}: {
  workSpace: WorkSpace | TargetsApp.App;
  onBackClicked: () => void;
  close: () => void;
}) => {
  const { accountRef, selectedAccountId } = useContext(AccountPickerContext);
  const { scorecards } = useContext(ScorecardsContext);
  const currentUser = useContext(CurrentUserContext);
  const { trackEvent } = useContext(AnalyticsContext);

  const [selectedScorecard, setSelectedScorecard] = useState<
    Scorecards.Scorecard | undefined
  >();
  const [options, setOptions] = useState<DropdownOption[]>([]);
  const isValid = !!selectedScorecard;

  const onAddClicked = () => {
    if (!isValid || !selectedScorecard) {
      return;
    }

    const newTab = {
      type: 'scorecards' as 'scorecards',
      typeId: selectedScorecard.id,
    };
    if (isTargetsApp(workSpace)) {
      const newApp = {
        ...workSpace,
        tabs: [...workSpace.tabs, newTab],
        updatedBy: currentUser.id,
        updatedOn: DateTime.utc().toISO(),
      };
      STORE.contentDistributions
        .getTargetsApps({ accountId: selectedAccountId })
        .doc(newApp.id)
        .set(newApp)
        .then(() => {
          trackEvent('Targets App - Tab added', { tabType: 'scorecard' });
          close();
        });
    } else {
      const newWorkSpace = {
        ...workSpace,
        tabs: [...workSpace.tabs, newTab],
        updatedBy: currentUser.id,
        updatedOn: DateTime.utc().toISO(),
      } as WorkSpace;
      setWorkSpace(newWorkSpace, accountRef).then(() => {
        trackEvent('Workspace - Tab added', { tabType: 'scorecard' });
        close();
      });
    }
  };

  useEffect(() => {
    const unusedScorecards = scorecards.filter(
      (r) =>
        !workSpace.tabs.some(
          (t) => t.type === 'scorecards' && t.typeId === r.id,
        ),
    );

    const newOptions = unusedScorecards
      .map((r) => ({
        key: r.id,
        label: r.title,
        isSelected: !!selectedScorecard && r.id === selectedScorecard.id,
        onSelected: () => {
          setSelectedScorecard(r);
        },
      }))
      .sort((a, b) => {
        const aL = a.label.toLowerCase();
        const bL = b.label.toLowerCase();

        if (aL > bL) {
          return 1;
        } else if (aL === bL) {
          return 0;
        } else {
          return -1;
        }
      });

    setOptions(newOptions);
  }, [scorecards, selectedScorecard, workSpace.tabs]);

  return (
    <WorkSpaceScorecardWizard
      options={options}
      onBackClicked={onBackClicked}
      onAddClicked={onAddClicked}
      isValid={isValid}
    />
  );
};

export default WorkSpaceScorecardWizardContainer;
