import React, { useCallback, useContext, useEffect, useState } from 'react';
import PerformanceBoardSlideOutContext from 'components/PerformanceBoards/contexts/PerformanceBoardSlideOutContext';
import Row from 'components/Common/Row';
import DragHandle from 'components/DragAndDropList/DragHandle';
import Column from 'screens/DataManager/DatasetDefinitions/Column';
import Typography from 'kingpin/atoms/Typography';
import DragAndDropListContext from 'components/DragAndDropList/DragAndDropListContext';
import DisplaySection from './DisplaySection';
import Colors2 from 'theme/Colors2';
import AnalyticsContext from 'contexts/AnalyticsContext';
import PermissionGates from 'components/PermissionGates';
import PERMISSIONS from 'permissionDefinitions';
import RolesContext from 'contexts/RolesContext';
import PERFORMANCE_BOARD_PERMISSIONS from 'permissionDefinitions/performanceBoard';
import sectionTypeCheckers from '../sectionTypeCheckers';
import ContextMenu, { Option } from 'kingpin/atoms/ContextMenu';

const WIDGET_HEADER_AFTER_TITLE_DIV_ID = 'WIDGET_HEADER_AFTER_TITLE_DIV_ID';
const WIDGET_HEADER_BEFORE_OPTIONS_DIV_ID =
  'WIDGET_HEADER_BEFORE_OPTIONS_DIV_ID';

export const getBonusHeaderAfterTitleDivId = (
  section: PerformanceBoardTypes.SlideOut.BonusCategorySection,
) => `${WIDGET_HEADER_AFTER_TITLE_DIV_ID}-${section.id}`;
export const getBonusHeaderBeforeOptionsDivId = (
  section: PerformanceBoardTypes.SlideOut.BonusCategorySection,
) => `${WIDGET_HEADER_BEFORE_OPTIONS_DIV_ID}-${section.id}`;

const WidgetHeader = ({
  title,
  section,
  onRemoveClicked,
  onEditClicked,
  isHovering,
  isDragging,
  setIsItemDragging,
}: {
  title: string;
  section: PerformanceBoardTypes.SlideOut.SlideOutSection;
  onRemoveClicked: () => void;
  onEditClicked: () => void;
  isHovering: boolean;
  isDragging: boolean;
  setIsItemDragging: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { currentPermissions } = useContext(RolesContext);

  const hasEditPermission = currentPermissions.includes(
    PERFORMANCE_BOARD_PERMISSIONS.MODIFY_WIDGETS,
  );
  return (
    <Row spaceBetween centerAlign>
      <Column style={{ height: 42 }}>
        <div
          style={{
            padding: '0px 4px',
            visibility:
              (isHovering || isDragging) && hasEditPermission
                ? 'visible'
                : 'hidden',
          }}
        >
          <DragHandle
            noMargin
            testId="testWidget"
            setIsItemDragging={setIsItemDragging}
          />
        </div>
        <Row style={{ gap: 8 }} centerAlign>
          <Typography.Header type="H5">{title}</Typography.Header>
          {sectionTypeCheckers.isBonusCategorySection(section) && (
            <div id={getBonusHeaderAfterTitleDivId(section)} />
          )}
        </Row>
      </Column>
      <Row centerAlign>
        {sectionTypeCheckers.isBonusCategorySection(section) && (
          <div id={getBonusHeaderBeforeOptionsDivId(section)} />
        )}
        <div id={WIDGET_HEADER_BEFORE_OPTIONS_DIV_ID} />
        <PermissionGates.Has
          requiredPermission={PERMISSIONS.PERFORMANCE_BOARD.MODIFY_WIDGETS}
        >
          <div style={{ marginRight: '12px' }}>
            <ContextMenu testId={`${title}-testId`} icon="dots-menu">
              <>
                {title !== 'Comments' && (
                  <Option
                    label="Edit"
                    icon="edit-filled"
                    onClick={onEditClicked}
                  />
                )}
              </>
              <Option label="Delete" icon="cross" onClick={onRemoveClicked} />
            </ContextMenu>
          </div>
        </PermissionGates.Has>
      </Row>
    </Row>
  );
};

const SectionListItem = ({
  sectionId,
  section,
  removeSection,
}: {
  sectionId: string;
  section: PerformanceBoardTypes.SlideOut.SlideOutSection | undefined;
  removeSection: (sectionId: string) => void;
}) => {
  const { setNavState } = useContext(PerformanceBoardSlideOutContext);
  const { isDragging } = useContext(DragAndDropListContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [isItemDragging, setIsItemDragging] = useState<boolean>(false);

  useEffect(() => {
    if (!isDragging) {
      setIsItemDragging(false);
    }
  }, [isDragging]);

  const onRemoveClicked = useCallback(() => {
    removeSection(sectionId);
    trackEvent('Board - Slide out - Widget Deleted');
  }, [removeSection, sectionId, trackEvent]);

  const onEditClicked = useCallback(() => {
    if (!section) {
      return null;
    }

    setNavState({
      slideoutMode: 'Creating',
      creatingSectionType: section.sectionType,
      editingSection: section,
    });

    trackEvent('Board - Slide out - Edit Widget Clicked');
  }, [section, setNavState, trackEvent]);

  if (!section) {
    return null;
  }

  return (
    <div
      style={{
        marginBottom: '8px',
        paddingBottom: '8px',
        backgroundColor: isHovering && !isDragging ? '#f5f5f5' : undefined,
        borderRadius: '4px',
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div
        style={{
          border: isItemDragging
            ? `1px solid ${Colors2.Secondary.info}`
            : undefined,
          borderRadius: isDragging ? '4px' : undefined,
          boxShadow: isDragging
            ? '0 3px 8px 3px rgba(53, 45, 67, 0.07)'
            : undefined,
          marginBottom: isDragging ? '16px' : undefined,
        }}
      >
        <WidgetHeader
          title={section.title}
          section={section}
          onRemoveClicked={onRemoveClicked}
          onEditClicked={onEditClicked}
          isHovering={isHovering}
          isDragging={isDragging}
          setIsItemDragging={setIsItemDragging}
        />
      </div>
      {!isDragging && (
        <div
          style={{
            padding: '0 30px',
          }}
        >
          <DisplaySection section={section} />
        </div>
      )}
    </div>
  );
};

export default SectionListItem;
