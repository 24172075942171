import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import * as aguid from 'aguid';
import { DateTime } from 'luxon';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';

import TypePicker from './TypePicker';
import WorkSpaceReportWizard from '../WorkSpaceReportWizard';
import WorkSpaceScorecardWizard from '../WorkSpaceScorecardWizard';
import WorkSpaceDashboardWizard from '../WorkSpaceDashboardWizard';
import WorkSpaceBoardWizard from '../WorkSpaceBoardWizard';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import setWorkSpace from '../../../api/workspaces/setWorkSpace';
import { isTargetsApp } from '../../../isWorkSpace';
import STORE from '../../../store';

const Content = styled.div`
  padding: 24px 16px;
`;

const AddTabWizardContainer = ({
  close,
  workSpace,
}: {
  close: () => void;
  workSpace: WorkSpace | TargetsApp.App;
}) => {
  const [tabType, setTabType] = useState<string | undefined>();
  const [step, setStep] = useState<number>(1);
  const currentUser = useContext(CurrentUserContext);
  const { accountRef, selectedAccountId } = useContext(AccountPickerContext);
  const [isSaving, setIsSaving] = useState(false);
  const { trackEvent } = useContext(AnalyticsContext);

  const onNextClicked = () => {
    setStep(step + 1);
  };
  const onBackClicked = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const onSaveGoals = () => {
    if (isTargetsApp(workSpace)) {
      return;
    }

    setIsSaving(true);
    const newTab = {
      type: 'goals' as 'goals',
      typeId: aguid(),
    };
    const newWorkSpace = {
      ...workSpace,
      tabs: [...workSpace.tabs, newTab],
      updatedBy: currentUser.id,
      updatedOn: DateTime.utc().toISO(),
    };
    setWorkSpace(newWorkSpace, accountRef).then(() => {
      setIsSaving(false);
      trackEvent('Workspace - Tab added', { tabType: 'goals' });
      close();
    });
  };

  const onSaveTasks = () => {
    setIsSaving(true);
    if (isTargetsApp(workSpace)) {
      const newTab = {
        type: 'tasks' as 'tasks',
      };
      const newApp = {
        ...workSpace,
        tabs: [...workSpace.tabs, newTab],
        updatedBy: currentUser.id,
        updatedOn: DateTime.utc().toISO(),
      };
      STORE.contentDistributions
        .getTargetsApps({
          accountId: selectedAccountId,
        })
        .doc(newApp.id)
        .set(newApp)
        .then(() => {
          setIsSaving(false);
          trackEvent('Targets App - Tab added', { tabType: 'tasks' });
          close();
        });
    } else {
      const newTab = {
        type: 'tasks' as 'tasks',
        typeId: aguid(),
      };
      const newWorkSpace = {
        ...workSpace,
        tabs: [...workSpace.tabs, newTab],
        updatedBy: currentUser.id,
        updatedOn: DateTime.utc().toISO(),
      };
      setWorkSpace(newWorkSpace, accountRef).then(() => {
        setIsSaving(false);
        trackEvent('Workspace - Tab added', { tabType: 'tasks' });
        close();
      });
    }
  };

  if (step === 1) {
    return (
      <TypePicker
        type={tabType}
        setType={setTabType}
        onNextClicked={onNextClicked}
        close={close}
        workSpace={workSpace}
        onSaveGoals={onSaveGoals}
        onSaveTasks={onSaveTasks}
        isSaving={isSaving}
      />
    );
  }
  if (step === 2) {
    if (tabType === 'Report') {
      return (
        <WorkSpaceReportWizard
          workSpace={workSpace}
          onBackClicked={onBackClicked}
          close={close}
        />
      );
    }
    if (tabType === 'Dashboard') {
      return (
        <WorkSpaceDashboardWizard
          workSpace={workSpace}
          onBackClicked={onBackClicked}
          close={close}
        />
      );
    }
    if (tabType === 'Board') {
      return (
        <WorkSpaceBoardWizard
          workSpace={workSpace}
          onBackClicked={onBackClicked}
          close={close}
        />
      );
    }
    if (tabType === 'Scorecard') {
      return (
        <WorkSpaceScorecardWizard
          workSpace={workSpace}
          onBackClicked={onBackClicked}
          close={close}
        />
      );
    }

    alert('Something went wrong');
    close();
  }
  return null;
};

const Gate = ({
  isOpen,
  close,
  workSpace,
}: {
  isOpen: boolean;
  close: () => void;
  workSpace: WorkSpace | TargetsApp.App;
}) => {
  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={close} shouldScrollInViewport={false} autoFocus={false}>
          <Content>
            <AddTabWizardContainer close={close} workSpace={workSpace} />
          </Content>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default Gate;
