import React, { useContext } from 'react';
import styled from 'styled-components';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import usePopup from 'hooks/usePopup';
import Button from 'kingpin/atoms/Button';

import Row from '../Common/Row';
import ManageAccessModal from './ManageAccessModal';
import useIsPublished from './useIsPublished';
import ContextMenu, { Option } from '../../kingpin/atoms/ContextMenu';
import ShareModal from './ShareModal';
import EntityDetailsContext from '../../screens/EntityDetailsShow/EntityDetailsContext';
import PERMISSIONS from '../../permissionDefinitions';
import PermissionGates from 'components/PermissionGates';

const Divider = styled.div`
  background-color: #d9d9d9;
  width: 1px;
  height: 24px;
  margin: 0px 16px;
`;

const ShareButton = ({
  isPublished,
  isShareEnabled,
  hasDivider,
  openShare,
  openAccess,
  type,
}: {
  isPublished: boolean;
  isShareEnabled: boolean;
  hasDivider?: boolean;
  openShare: () => void;
  openAccess: () => void;
  type: ExecutivePortalPublishableContentType | 'workSpace';
}) => {
  if (type === 'workSpace') {
    return (
      <div style={{ marginRight: 16 }}>
        <ContextMenu label="Share" icon="shared" iconAfter="chevron-down">
          <Option label="Share" onClick={openShare} icon="shared" />
        </ContextMenu>
      </div>
    );
  }

  if (!isPublished) {
    return (
      <Row centerAlign>
        <Button
          type="Primary"
          size="Small"
          label="Publish"
          onClick={openAccess}
        />
        {hasDivider && <Divider />}
      </Row>
    );
  }

  if (isShareEnabled) {
    return (
      <div style={{ marginRight: 16 }}>
        <ContextMenu label="Share" icon="shared" iconAfter="chevron-down">
          <Option label="Share" onClick={openShare} icon="shared" />
          <PermissionGates.Has
            requiredPermission={PERMISSIONS.CONTENT_ACCESS.ADD_USERS_TO_PORTAL}
          >
            <Option label="Manage Access" onClick={openAccess} icon="group" />
          </PermissionGates.Has>
        </ContextMenu>
      </div>
    );
  }

  return (
    <PermissionGates.Has
      requiredPermission={PERMISSIONS.CONTENT_ACCESS.ADD_USERS_TO_PORTAL}
    >
      <div style={{ marginRight: 16 }}>
        <Button
          size={'Small'}
          type={'Secondary'}
          label={'Manage Access'}
          onClick={openAccess}
        />
      </div>
    </PermissionGates.Has>
  );
};

const ShareContentButton = ({
  type,
  typeId,
  contentName,
  hasDivider,
}: {
  type: ExecutivePortalPublishableContentType | 'workSpace';
  typeId: string;
  contentName: string;
  hasDivider?: boolean;
}) => {
  const entityDetailsContext = useContext(EntityDetailsContext);

  const isPublished = useIsPublished({
    type,
    typeId,
  });
  const isShareEnabled =
    type === 'report' ||
    type === 'dashboard' ||
    type === 'scorecard' ||
    type === 'workSpace';
  const {
    isOpen: isAccessOpen,
    open: openAccess,
    close: closeAccess,
  } = usePopup();
  const {
    isOpen: isShareOpen,
    open: openShare,
    close: closeShare,
  } = usePopup();

  if (!!entityDetailsContext) {
    return null;
  }

  return (
    <>
      <ShareButton
        isPublished={isPublished}
        isShareEnabled={isShareEnabled}
        hasDivider={hasDivider}
        openShare={openShare}
        openAccess={openAccess}
        type={type}
      />
      <ModalTransition>
        {isAccessOpen && type !== 'workSpace' && (
          <Modal
            onClose={closeAccess}
            shouldScrollInViewport={false}
            autoFocus={false}
          >
            <ManageAccessModal
              type={type}
              typeId={typeId}
              contentName={contentName}
              close={closeAccess}
              isPublished={isPublished}
            />
          </Modal>
        )}
        {isShareOpen && isShareEnabled && (
          <Modal
            onClose={closeShare}
            shouldScrollInViewport={false}
            autoFocus={false}
          >
            <ShareModal
              type={type}
              typeId={typeId}
              contentName={contentName}
              close={closeShare}
            />
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default ShareContentButton;
