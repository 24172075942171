import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';

import ChartSvg from '../../icons/Chart.svg';
import MetricsSvg from '../../icons/Metrics.svg';
import SimpleGridSvg from '../../icons/SimpleGrid.svg';
import PropertiesSvg from '../../icons/Properties.svg';
import CommentsSvg from '../../icons/Comments.svg';

import PerformanceBoardSlideOutContext from 'components/PerformanceBoards/contexts/PerformanceBoardSlideOutContext';
import useCreateCommentsSection from 'components/PerformanceBoards/hooks/useCreateCommentsSection';
import AnalyticsContext from 'contexts/AnalyticsContext';
import WidgetItem from './WidgetItem';

const Wrapper = styled.div`
  padding: 16px 54px;

  display: flex;
  flex-direction: column;
`;

const PerformanceWidgets = ({
  openWidget,
  isEnabled,
}: {
  openWidget: (type: PerformanceBoardTypes.SlideOut.SectionType) => void;
  isEnabled: boolean;
}) => {
  return (
    <>
      <WidgetItem
        iconSvg={ChartSvg}
        title="Performance Graph"
        description={
          isEnabled
            ? 'Graph the difference above or below target, Accompanied by a grid'
            : 'Your Dataset has no performance fields'
        }
        onClick={() =>
          isEnabled ? openWidget('Performance Graph') : undefined
        }
        isDisabled={!isEnabled}
      />
      <WidgetItem
        iconSvg={ChartSvg}
        title="Target Progress"
        description={
          isEnabled
            ? 'Display a list of targets'
            : 'Your Dataset has no performance fields'
        }
        onClick={() => (isEnabled ? openWidget('Target Progress') : undefined)}
        isDisabled={!isEnabled}
      />
    </>
  );
};

const BonusWidgets = ({
  openWidget,
  isEnabled,
}: {
  openWidget: (type: PerformanceBoardTypes.SlideOut.SectionType) => void;
  isEnabled: boolean;
}) => {
  return (
    <>
      <WidgetItem
        iconSvg={ChartSvg}
        title="Bonus Category"
        description={
          isEnabled
            ? 'Display how this entity performed for a bonus category'
            : 'Your Dataset has no bonus categories'
        }
        onClick={() => (isEnabled ? openWidget('Bonus Category') : undefined)}
        isDisabled={!isEnabled}
      />
    </>
  );
};

const EmptySlideout = () => {
  const { setNavState, performanceFields, bonusCategories } = useContext(
    PerformanceBoardSlideOutContext,
  );
  const { trackEvent } = useContext(AnalyticsContext);

  const createCommentsSection = useCreateCommentsSection();

  const openWidget = useCallback(
    (type: PerformanceBoardTypes.SlideOut.SectionType) => {
      if (type === 'Comments') {
        createCommentsSection();
        trackEvent('Board - Slide out - Comments Widget Added');
      } else {
        setNavState({
          slideoutMode: 'Creating',
          creatingSectionType: type,
        });

        trackEvent('Board - Slide out - Widget type selected', {
          widgetType: type,
        });
      }
    },
    [createCommentsSection, setNavState, trackEvent],
  );

  return (
    <Wrapper>
      {performanceFields.length !== 0 && (
        <PerformanceWidgets openWidget={openWidget} isEnabled />
      )}
      {bonusCategories.length !== 0 && (
        <BonusWidgets openWidget={openWidget} isEnabled />
      )}
      <WidgetItem
        iconSvg={MetricsSvg}
        title="Metric Widget"
        description="Display a list of KPIs"
        onClick={() => openWidget('Metric Widget')}
      />
      <WidgetItem
        iconSvg={SimpleGridSvg}
        title="Simple Grid"
        description="Display raw data for current interval"
        onClick={() => openWidget('Simple Grid')}
      />
      <WidgetItem
        iconSvg={PropertiesSvg}
        title="Properties"
        description="Display latest information for the entity such as Hire date for a driver"
        onClick={() => openWidget('Properties')}
      />
      <WidgetItem
        iconSvg={PropertiesSvg}
        title={'Related Documents'}
        description={'Display a list of documents relating to this entity'}
        onClick={() => openWidget('Related Documents')}
      />
      <WidgetItem
        iconSvg={CommentsSvg}
        title="Comments"
        description="Allow users to leave comments"
        onClick={() => openWidget('Comments')}
      />
      {performanceFields.length === 0 && (
        <PerformanceWidgets openWidget={openWidget} isEnabled={false} />
      )}
      {bonusCategories.length === 0 && (
        <BonusWidgets openWidget={openWidget} isEnabled={false} />
      )}
    </Wrapper>
  );
};

export default EmptySlideout;
