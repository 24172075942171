import React, { useContext, useMemo } from 'react';
import NavSectionContext from '../../navigation/NavSectionContext';
import portalNavItemTypeCheckers from '../../types/portalNavItemTypeCheckers';
import GoalFilteringProvider from '../../contextProviders/GoalFilteringProvider';
import GoalsList from '../../components/Goals/GoalsList';
import { ScreenWrapper } from '../../navigation/styles';

const PortalGoals = () => {
  const { navSection } = useContext(NavSectionContext);

  const goalIds = useMemo(() => {
    if (!navSection) {
      return [];
    }

    const goalsItem = navSection.items.find(
      portalNavItemTypeCheckers.isGoalsIndex,
    );
    if (!goalsItem) {
      return [];
    }

    return goalsItem.goalIds;
  }, [navSection]);

  return (
    <ScreenWrapper>
      <div style={{ padding: '16px 32px' }}>
        <GoalFilteringProvider goalIdsGreenList={goalIds}>
          <GoalsList />
        </GoalFilteringProvider>
      </div>
    </ScreenWrapper>
  );
};

export default PortalGoals;
