const isReportTab = (tab: TargetsApp.ClientTab): tab is TargetsApp.ReportTab =>
  tab.type === 'report';

const isScorecardTab = (
  tab: TargetsApp.ClientTab,
): tab is TargetsApp.ScorecardTab => tab.type === 'scorecards';

const isDashboardTab = (
  tab: TargetsApp.ClientTab,
): tab is TargetsApp.DashboardTab => tab.type === 'dashboard';

const isBoardTab = (tab: TargetsApp.ClientTab): tab is TargetsApp.BoardTab =>
  tab.type === 'board';

const isTasksTab = (tab: TargetsApp.ClientTab): tab is TargetsApp.TasksTab =>
  tab.type === 'tasks';

const isOverviewTab = (
  tab: TargetsApp.ClientTab,
): tab is TargetsApp.OverviewTab => tab.type === 'overview';

const tabTypeCheckers = {
  isReportTab,
  isScorecardTab,
  isDashboardTab,
  isBoardTab,
  isTasksTab,
  isOverviewTab,
};

export default tabTypeCheckers;
