import firebase from 'firebase/compat/app';
import withoutNulls from '../../api/search/withoutNulls';
import getBonusPortalsRef from './getBonusPortalsRef';

const SIGN_OFFS_DOC = 'boardSignOffs';

const bonusPortalBoardSignOffsConverter = {
  toFirestore(app: Scoring.BoardSignOff): firebase.firestore.DocumentData {
    return withoutNulls({ ...app });
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<Scoring.BoardSignOff>,
    options: firebase.firestore.SnapshotOptions,
  ): Scoring.BoardSignOff {
    const data: Scoring.BoardSignOff = {
      ...snapshot.data(options),
    };

    return data;
  },
};

const getBonusPortalBoardSignOffsRef = ({
  accountId,
  bonusPortalId,
  db,
}: {
  accountId: string;
  bonusPortalId: string;
  db?: firebase.firestore.Firestore;
}) => {
  return getBonusPortalsRef({
    accountId,
    db,
  })
    .doc(bonusPortalId)
    .collection(SIGN_OFFS_DOC)
    .withConverter(bonusPortalBoardSignOffsConverter);
};

export default getBonusPortalBoardSignOffsRef;
