import { gql } from '@apollo/client';

const buildQuery = ({
  dataType,
  filters,
  dateScope,
}: RelatedDocumentsTypes.FetchDocumentsArgs) => ({
  query: gql`
    query FetchRecords(
      $dataType: String!
      $filters: [FilterInput]!
      $dateScope: [DateRangeInput!]!
      $sortBy: [SortField]
    ) {
      fetchRecords(
        dataType: $dataType
        filters: $filters
        dateScope: $dateScope
        sortBy: $sortBy
      )
    }
  `,
  variables: {
    dataType,
    filters,
    dateScope,
    sortBy: {
      field: 'date',
      sort: 'desc',
    },
  },
});

const fetchRecords = async ({
  dataType,
  dateScope,
  filters,
  client,
}: RelatedDocumentsTypes.FetchDocumentsArgs): Promise<ElasticDocument[]> => {
  const query = buildQuery({
    dataType,
    filters,
    dateScope,
    client,
  });
  const response = await client.query(query);
  return response.data.fetchRecords;
};

export default fetchRecords;
