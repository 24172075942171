import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';
import Row from '../../Common/Row';
import Inputs from 'components/Inputs';

const WorkSpaceDashboardWizard = ({
  options,
  selectedDashboard,
  onAddClicked,
  onBackClicked,
  isSaving,
}: {
  options: DropdownOption[];
  selectedDashboard?: DashboardType;
  onAddClicked: () => void;
  onBackClicked: () => void;
  isSaving: boolean;
}) => (
  <div>
    <div style={{ marginBottom: 24 }}>
      <Typography.Header type={'H5'}>Add Dashboard</Typography.Header>
    </div>
    <div style={{ marginBottom: 32 }}>
      <div style={{ marginBottom: 4 }}>
        <Typography.Body type="Label">Dashboard</Typography.Body>
      </div>
      <Inputs.Dropdown
        isSearchEnabled
        options={options}
        fullWidth
        placeholder={'Select a Dashboard'}
      />
    </div>
    <Row spaceBetween>
      <Button
        onClick={onBackClicked}
        type="Secondary"
        size="Small"
        label="Back"
      />
      <Button
        isLoading={isSaving}
        onClick={onAddClicked}
        isDisabled={!selectedDashboard}
        type="Primary"
        size="Small"
        label="Add"
      />
    </Row>
  </div>
);

export default WorkSpaceDashboardWizard;
