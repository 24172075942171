import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import { AppsContext } from '../../../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/AppsProvider';
import appTypeCheckers from '../../../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/AppsProvider/appTypeCheckers';
import Row from '../../../../../components/Common/Row';
import Icon from '../../../../atoms/Icon';
import { useNavigate } from 'react-router-dom';
import { PortalsContext } from '../../../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';
import Button from '../../../../atoms/Button';
import portalTypeCheckers from '../../../../../types/portalTypeCheckers';
import AccountPickerContext from '../../../../../contexts/AccountPickerContext';
import TextInput from '../../../../atoms/TextInput';
import _ from 'lodash';
import appRoutes from '../../../../../navigation/appRoutes';
import Tooltip from '../../../../../components/Tooltip';

type AppSwitchingMode =
  | {
      type: 'app selection';
    }
  | {
      type: 'portal selection';
      app: Apps.GeneralApp;
    }
  | {
      type: 'account selection';
    };

const AppTitle = styled.h3`
  font-family: Inter;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0;
  margin: 0px;
`;

const AppIconName = ({ app }: { app: Apps.App }) => (
  <Row centerAlign style={{ gap: 12 }}>
    <Icon icon={app.icon} width={24} height={24} />
    <div>
      <AppTitle>{app.label}</AppTitle>
    </div>
  </Row>
);

const ItemDiv = styled.div<{ isFocused?: boolean; isDisabled?: boolean }>`
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 8px;

  ${(props) => props.isFocused && 'background-color: #EBF1FF'};

  &:hover {
    background-color: #f6f6f6;
  }

  ${(props) =>
    props.isDisabled &&
    `
    cursor: not-allowed;
    
    &:hover {
    background-color: #E9E9E9;
  }
  `}
`;

const GeneralAppItem = ({
  app,
  close,
  setMode,
}: {
  app: Apps.GeneralApp;
  close: () => void;
  setMode: React.Dispatch<React.SetStateAction<AppSwitchingMode>>;
}) => {
  const { setSelectedContent } = useContext(AppsContext);
  const { onPortalSelected } = useContext(PortalsContext);

  const onClick = useCallback(() => {
    if (app.portals.length === 1) {
      setSelectedContent({ type: 'App', app });
      const p = app.portals[0];
      onPortalSelected(p);
      close();
      return;
    }

    setMode({
      type: 'portal selection',
      app,
    });
  }, [app, close, onPortalSelected, setMode, setSelectedContent]);

  const isDisabled = app.portals.length === 0;

  return (
    <Tooltip
      content={isDisabled ? 'This App is still under development' : undefined}
    >
      <ItemDiv
        onClick={isDisabled ? undefined : onClick}
        isDisabled={isDisabled}
      >
        <AppIconName app={app} />
        {app.portals.length > 1 && <Icon icon={'chevron-right'} />}
      </ItemDiv>
    </Tooltip>
  );
};

const AnalyticsAppItem = ({
  app,
  close,
}: {
  app: Apps.AnalyticsApp;
  close: () => void;
}) => {
  const { setSelectedContent } = useContext(AppsContext);
  const { availablePortals, onPortalSelected } = useContext(PortalsContext);
  const execPortals = useMemo(() => {
    return availablePortals.filter(portalTypeCheckers.isExecutivePortal);
  }, [availablePortals]);

  const onClick = useCallback(() => {
    setSelectedContent({
      type: 'App',
      app,
    });
    if (execPortals.length > 0) {
      onPortalSelected(execPortals[0]);
    }
    close();
  }, [app, close, execPortals, onPortalSelected, setSelectedContent]);

  const isDisabled = execPortals.length === 0;

  return (
    <Tooltip
      content={isDisabled ? 'This App is still under development' : undefined}
    >
      <ItemDiv
        onClick={isDisabled ? undefined : onClick}
        isDisabled={isDisabled}
      >
        <AppIconName app={app} />
      </ItemDiv>
    </Tooltip>
  );
};

const AppSwitcherPopupDiv = styled.div`
  padding: 16px 12px;
  border-radius: 16px;
  width: 293px;
`;

const TitleDiv = styled.div`
  padding: 4px 8px;
`;

const AppsSectionTitle = styled.h5`
  margin: 0px;
  font-family: Inter;
  font-weight: 500;
  font-size: 13px;
  line-height: 15.73px;
  letter-spacing: 0;
  color: #808080;
`;

const Divider = styled.div`
  height: 1px;
  background: #ececec;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const AppsSection = ({
  close,
  setMode,
}: {
  close: () => void;
  setMode: React.Dispatch<React.SetStateAction<AppSwitchingMode>>;
}) => {
  const { apps } = useContext(AppsContext);
  return (
    <div>
      <TitleDiv>
        <AppsSectionTitle>FleetOps Apps</AppsSectionTitle>
      </TitleDiv>

      {apps.length === 0 && <AppTitle>No Apps found</AppTitle>}
      {apps.map((app) => {
        if (appTypeCheckers.isGeneralApp(app)) {
          return (
            <GeneralAppItem
              app={app}
              key={app.id}
              close={close}
              setMode={setMode}
            />
          );
        }
        return <AnalyticsAppItem app={app} key={app.id} close={close} />;
      })}
    </div>
  );
};

const SettingsItem = styled(ItemDiv)`
  height: 36px;
`;

const ContentManagementLink = ({ close }: { close: () => void }) => {
  const { setSelectedContent } = useContext(AppsContext);
  const navigate = useNavigate();
  const onClick = useCallback(() => {
    setSelectedContent({
      type: 'Content Management',
    });
    navigate('/admin');
    close();
  }, [close, navigate, setSelectedContent]);

  return (
    <SettingsItem onClick={onClick}>
      <Row centerAlign style={{ gap: 12 }}>
        <Icon icon={'apps-content-management'} width={16} height={16} />
        <div>
          <AppTitle>Content Management</AppTitle>
        </div>
      </Row>
    </SettingsItem>
  );
};

const SystemSettingsLink = ({ close }: { close: () => void }) => {
  const { setSelectedContent } = useContext(AppsContext);
  const navigate = useNavigate();
  const onClick = useCallback(() => {
    setSelectedContent({ type: 'System Settings' });
    navigate('/admin/settings');
    close();
  }, [close, navigate, setSelectedContent]);

  return (
    <SettingsItem onClick={onClick}>
      <Row centerAlign style={{ gap: 12 }}>
        <Icon icon={'apps-settings'} width={16} height={16} />
        <div>
          <AppTitle>System Settings</AppTitle>
        </div>
      </Row>
    </SettingsItem>
  );
};

const ChangeAccountLink = ({
  setMode,
}: {
  setMode: React.Dispatch<React.SetStateAction<AppSwitchingMode>>;
}) => {
  const onClick = useCallback(() => {
    setMode({ type: 'account selection' });
  }, [setMode]);
  return (
    <SettingsItem onClick={onClick}>
      <Row centerAlign style={{ gap: 12 }}>
        <Icon icon={'chevron-down'} width={16} height={16} />
        <div>
          <AppTitle>Change Account</AppTitle>
        </div>
      </Row>
    </SettingsItem>
  );
};

const SettingsSection = ({ close }: { close: () => void }) => {
  return (
    <div>
      <ContentManagementLink close={close} />
      <SystemSettingsLink close={close} />
    </div>
  );
};

const PortalLink = ({
  app,
  portal,
  close,
}: {
  app: Apps.GeneralApp;
  portal: Portal;
  close: () => void;
}) => {
  const { setSelectedContent } = useContext(AppsContext);
  const { onPortalSelected } = useContext(PortalsContext);

  const onClick = useCallback(() => {
    onPortalSelected(portal);
    setSelectedContent({
      type: 'App',
      app,
    });
    close();
  }, [app, close, onPortalSelected, portal, setSelectedContent]);

  return (
    <SettingsItem onClick={onClick}>
      <Row centerAlign style={{ gap: 12 }}>
        <AppTitle>{portal.name}</AppTitle>
      </Row>
    </SettingsItem>
  );
};

const PortalSelection = ({
  close,
  app,
  setMode,
}: {
  close: () => void;
  app: Apps.GeneralApp;
  setMode: React.Dispatch<React.SetStateAction<AppSwitchingMode>>;
}) => {
  const portals = app.portals;
  return (
    <>
      <Row>
        <Button
          size={'Small'}
          type={'Secondary'}
          icon={'chevron-left'}
          label={'Back To Apps'}
          onClick={() => {
            setMode({ type: 'app selection' });
          }}
        />
      </Row>

      <Divider />
      <TitleDiv>
        <AppsSectionTitle>{`Select a ${app.label} Program`}</AppsSectionTitle>
      </TitleDiv>
      {portals.map((p) => (
        <PortalLink close={close} portal={p} key={p.id} app={app} />
      ))}
    </>
  );
};

const AccountSelection = ({
  close,
  setMode,
}: {
  close: () => void;
  setMode: React.Dispatch<React.SetStateAction<AppSwitchingMode>>;
}) => {
  const { accounts, setSelectedAccount, selectedAccount } =
    useContext(AccountPickerContext);
  const navigate = useNavigate();
  const [searchText, setSearchText] = React.useState<string>('');
  const filteredAccounts = useMemo(() => {
    if (searchText === '') {
      return _.sortBy(accounts, 'accountName');
    }

    return _.sortBy(
      accounts.filter((a) =>
        a.accountName.toLowerCase().includes(searchText.toLowerCase()),
      ),
      'accountName',
    );
  }, [accounts, searchText]);

  const [focusedAccount, setFocusedHoveredAccount] = useState<
    FleetOps.UserAccountAccess | undefined
  >(() => (filteredAccounts.length > 0 ? filteredAccounts[0] : undefined));

  const focusedAccountIndex = useMemo(() => {
    if (!focusedAccount) {
      return undefined;
    }

    return filteredAccounts.findIndex(
      (a) => a.accountId === focusedAccount.accountId,
    );
  }, [filteredAccounts, focusedAccount]);

  useEffect(() => {
    if (
      focusedAccount &&
      !filteredAccounts.some((a) => a.accountId === focusedAccount.accountId)
    ) {
      setFocusedHoveredAccount(undefined);
    }
  }, [filteredAccounts, focusedAccount]);

  useEffect(() => {
    if (focusedAccount === undefined && filteredAccounts.length > 0) {
      setFocusedHoveredAccount(filteredAccounts[0]);
    }
  }, [filteredAccounts, focusedAccount]);

  const onKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (focusedAccountIndex === undefined) {
        if (filteredAccounts.length === 0) {
          return;
        }
        setFocusedHoveredAccount(filteredAccounts[0]);
        return;
      }

      if (event.code === 'ArrowUp') {
        if (focusedAccountIndex === 0) {
          return;
        }
        setFocusedHoveredAccount(filteredAccounts[focusedAccountIndex - 1]);
        return;
      }

      if (event.code === 'ArrowDown') {
        if (focusedAccountIndex === filteredAccounts.length - 1) {
          return;
        }

        setFocusedHoveredAccount(filteredAccounts[focusedAccountIndex + 1]);
        return;
      }

      if (event.code === 'Enter' && focusedAccount) {
        setSelectedAccount(focusedAccount);
        close();
        return;
      }
    },
    [
      close,
      filteredAccounts,
      focusedAccount,
      focusedAccountIndex,
      setSelectedAccount,
    ],
  );

  useEffect(() => {
    window.addEventListener('keyup', onKeyUp);

    return () => {
      window.removeEventListener('keyup', onKeyUp);
    };
  }, [onKeyUp]);

  return (
    <>
      <Row>
        <Button
          size={'Small'}
          type={'Secondary'}
          icon={'chevron-left'}
          label={'Back To Apps'}
          onClick={() => {
            setMode({ type: 'app selection' });
          }}
        />
      </Row>

      <Divider />
      <TitleDiv>
        <AppsSectionTitle>{`Select a Customer`}</AppsSectionTitle>
      </TitleDiv>
      <div style={{ marginBottom: 4 }}>
        <TextInput
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          autoFocus
        />
      </div>

      <div style={{ overflowY: 'scroll', maxHeight: 400 }}>
        {filteredAccounts.map((account) => (
          <SettingsItem
            onClick={() => {
              setSelectedAccount(account);
              navigate(appRoutes.home);
              close();
            }}
            key={account.accountId}
            isFocused={
              focusedAccount && focusedAccount.accountId === account.accountId
            }
          >
            <Row centerAlign style={{ gap: 12, width: '100%' }} spaceBetween>
              <AppTitle>{account.accountName}</AppTitle>
              {selectedAccount.accountId === account.accountId && (
                <Icon icon={'checkmark'} />
              )}
            </Row>
          </SettingsItem>
        ))}
      </div>
    </>
  );
};

const AppSwitcherPopup = ({ close }: { close: () => void }) => {
  const { accounts } = useContext(AccountPickerContext);
  const [mode, setMode] = useState<AppSwitchingMode>({
    type: 'app selection',
  });

  const hasAccountPicker = accounts.length > 0;

  if (mode.type === 'account selection') {
    return (
      <AppSwitcherPopupDiv>
        <AccountSelection close={close} setMode={setMode} />
      </AppSwitcherPopupDiv>
    );
  }

  if (mode.type === 'portal selection') {
    return (
      <AppSwitcherPopupDiv>
        <PortalSelection close={close} setMode={setMode} app={mode.app} />
      </AppSwitcherPopupDiv>
    );
  }

  return (
    <AppSwitcherPopupDiv>
      <AppsSection close={close} setMode={setMode} />
      <Divider />
      <SettingsSection close={close} />
      {hasAccountPicker && (
        <>
          <Divider />
          <ChangeAccountLink setMode={setMode} />
        </>
      )}
    </AppSwitcherPopupDiv>
  );
};

export default AppSwitcherPopup;
