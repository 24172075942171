import isCommentSection from './isCommentSection';
import isGridSection from './isGridSection';
import isMetricSection from './isMetricSection';
import isPerformanceSection from './isPerformanceSection';
import isTargetProgressSection from './isTargetProgressSection';
import isPropertiesSection from './isPropertiesSection';
import isBonusCategorySection from './isBonusCategorySection';
import isRelatedDocumentsSection from './isRelatedDocumentsSection';

const sectionTypeCheckers = {
  isPerformanceSection,
  isTargetProgressSection,
  isMetricSection,
  isPropertiesSection,
  isGridSection,
  isCommentSection,
  isBonusCategorySection,
  isRelatedDocumentsSection,
};

export default sectionTypeCheckers;
