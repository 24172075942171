import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import AccountPickerContext from 'contexts/AccountPickerContext';
import STORE from 'store';
import useGetHasBonusPortalAccess from './useGetHasBonusPortalAccess';

const useBonusPortals = () => {
  const [allBonusPortals, setAllBonusPortals] = useState<BonusPortal[]>([]);
  const [availableBonusPortals, setAvailableBonusPortals] = useState<
    BonusPortal[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const getHasBonusPortalAccess = useGetHasBonusPortalAccess();

  useEffect(() => {
    let isActive = true;
    const listener = STORE.contentDistributions
      .getBonusPortalsRef({
        accountId: selectedAccountId,
      })
      .onSnapshot((snapshot) => {
        if (isActive) {
          const newApps: BonusPortal[] = [];
          snapshot.forEach((p) => {
            newApps.push(p.data());
          });
          const sortedApps = _.sortBy(
            newApps.filter((p) => !p.isHidden),
            'name',
          );
          setAllBonusPortals(sortedApps);
          setAvailableBonusPortals(sortedApps.filter(getHasBonusPortalAccess));
          setIsLoading(false);
        }
      });

    return () => {
      listener();
    };
  }, [getHasBonusPortalAccess, selectedAccountId]);

  return {
    availableBonusPortals,
    allBonusPortals,
    isLoading,
  };
};

export default useBonusPortals;
