import React, { useCallback, useContext } from 'react';
import PermissionGates from '../../../../components/PermissionGates';
import PERMISSIONS from '../../../../permissionDefinitions';
import NavItem from '../../NavItem';
import appRoutes, {
  buildAdvancedSettings,
  buildAllTargetCategories,
  buildAllUsersSettings,
  buildBillingSettings,
  buildCopyContent,
  buildCopyHistory,
  buildIntegrationsSettings,
  buildSsoConfigurationsSettings,
} from '../../../../navigation/appRoutes';
import FeatureGate, { FEATURE_GATES } from '../../../../components/FeatureGate';
import PaceSettingsNavItem from '../../PaceSettingsNavItem';
import FleetOpsStaffOnly from '../../../../components/Common/FleetOpsStaffOnly';
import NavSection from '../../NavSection';
import { PortalsContext } from '../../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';
import AnalyticsContext from '../../../../contexts/AnalyticsContext';
import AccountContext from '../../../../contexts/AccountContext';
import portalTypeCheckers from '../../../../types/portalTypeCheckers';

const SystemSettingsNav = () => {
  const { isPortalsEnabled, selectedPortal } = useContext(PortalsContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const { id } = useContext(AccountContext);
  const isCommissionsAccount = id === 'fopsgfxARfMuprvVGPvS';

  const onSsoClicked = useCallback(() => {
    trackEvent('Settings - Clicked SSO Configurations');
  }, [trackEvent]);

  const onConnectorsClicked = useCallback(() => {
    trackEvent('Settings - Clicked Connectors');
  }, [trackEvent]);

  if (!portalTypeCheckers.isAdminPortal(selectedPortal)) {
    return null;
  }

  return (
    <>
      <NavSection>
        <PermissionGates.Has
          requiredPermission={PERMISSIONS.USER_MANAGEMENT.VIEW_USER}
        >
          <NavItem
            to={buildAllUsersSettings(selectedPortal)}
            icon={'person-search'}
            label="Users"
          />
        </PermissionGates.Has>
        <PermissionGates.Has
          requiredPermission={PERMISSIONS.DATA_MANAGEMENT.SLAS}
        >
          <NavItem
            to={buildAllTargetCategories(selectedPortal)}
            label="SLAs"
            icon={'navigation-metrics'}
          />
        </PermissionGates.Has>
        <PermissionGates.Has
          requiredPermission={PERMISSIONS.BILLING.VIEW_BILLING}
        >
          <NavItem
            to={buildBillingSettings(selectedPortal)}
            icon={'billing-card'}
            label="Billing"
          />
        </PermissionGates.Has>
        <PermissionGates.Has
          requiredPermission={
            PERMISSIONS.DATA_MANAGEMENT.INTEGRATIONS_CONFIG_VIEW
          }
        >
          <NavItem
            to={buildIntegrationsSettings(selectedPortal)}
            icon={'library-add'}
            label="Connectors"
            onClick={onConnectorsClicked}
          />
        </PermissionGates.Has>
        {!isPortalsEnabled ? (
          <FeatureGate featureName={FEATURE_GATES.MICROSOFT_SSO}>
            <PermissionGates.Has
              requiredPermission={PERMISSIONS.USER_MANAGEMENT.CONFIGURE_SSO}
            >
              <NavItem
                to={buildSsoConfigurationsSettings(selectedPortal)}
                icon={'admin-panel-settings'}
                label="SSO Config"
                onClick={onSsoClicked}
              />
            </PermissionGates.Has>
          </FeatureGate>
        ) : (
          <div />
        )}
        <PaceSettingsNavItem />
        <FleetOpsStaffOnly>
          <NavItem
            to={buildAdvancedSettings(selectedPortal)}
            icon={'settings-empty'}
            label="Advanced"
          />
        </FleetOpsStaffOnly>
        <FleetOpsStaffOnly>
          <NavItem
            to={buildCopyContent(selectedPortal)}
            icon="copy"
            label="Copy Content"
          />
        </FleetOpsStaffOnly>
        <FleetOpsStaffOnly>
          <NavItem
            to={buildCopyHistory(selectedPortal)}
            icon="copy"
            label="Copy Content History"
          />
        </FleetOpsStaffOnly>
        {isCommissionsAccount ? (
          <PermissionGates.Has
            requiredPermission={PERMISSIONS.INTERNAL_USE.MODIFY_COMMISSIONS}
          >
            <NavItem
              to={appRoutes.loggedIn.commissions}
              icon={'settings-empty'}
              label="Commissions"
            />
          </PermissionGates.Has>
        ) : (
          <div />
        )}
      </NavSection>
    </>
  );
};

export default SystemSettingsNav;
