import { useContext, useEffect, useState } from 'react';
import GqlClientContext from 'contexts/GqlClientContext';
import DatasetDefinitionsContext from '../../../contexts/DatasetDefinitionsContext';

const useGetBonusCategories = ({ dataType }: { dataType: string }) => {
  const { getBonusProgram } = useContext(DatasetDefinitionsContext);
  const { client } = useContext(GqlClientContext);

  const [bonusCategories, setBonusCategories] = useState<string[]>([]);
  const [bonusStatusFields, setBonusStatusFields] = useState<string[]>([]);

  useEffect(() => {
    const bonusProgram = getBonusProgram(dataType);
    if (!bonusProgram) {
      setBonusCategories([]);
      return;
    }

    setBonusCategories(bonusProgram.categoryFields.map((f) => f.name));
    setBonusStatusFields(bonusProgram.statusFields.map((f) => f.field));
  }, [client, dataType, getBonusProgram]);

  return {
    bonusCategories,
    bonusStatusFields,
  };
};

export default useGetBonusCategories;
