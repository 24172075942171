export const GRID_PAGE_SIZE = 1000;

export const FAVOURITES_ORDER_DOC_ID = 'favouritesOrder';

export const DASHBOARDS_COLLECTION = 'dashboards-v2';

export const REPORTS_COLLECTION = 'reports-v2';

export const WALLBOARDS_COLLECTION = 'wallBoards';

export const BOARDS_COLLECTION = 'boards';

export const WORKSPACES_COLLECTION = 'workSpaces';

export const CANVAS_VERTICAL_SNAPPING_POINTS = 20;

export const CANVAS_ID = 'canvas';

export const EXCLUDE_HEIGHT = 'exclude-height';

export const MODAL_MARGIN = 60;

export const SCORECARDS_COLLECTION = 'scorecards-v2';

export const CURRENT_SCORECARD_VERSION = '3' as '3';

export const WORKSPACE_RESOURCE = 'WorkSpace' as 'WorkSpace';

export const SCORECARD_RESOURCE = 'Scorecard' as 'Scorecard';

export const PORTAL_RESOURCE = 'Portal' as 'Portal';

/**
 * Yes, "DatasetFilter" is intentional here
 * Feel free to write a migration which changes this over
 */
export const SAVED_FILTER_RESOURCE = 'DatasetFilter' as 'DatasetFilter';

export const GOAL_RESOURCE = 'Goal' as 'Goal';

export const WESTSIDE_ACCOUNT_ID = 'lzpIhynATYgKXiyA';

export const WAYNE_TRANSPORT_ACCOUNT_ID = 'sWptCNfOdoztCktzmLzDxpDN';

export const NATIONAL_CARRIERS_ACCOUNT_ID = 'KFiymixJlAlYVHyksyrVIBeO';

export const NAVAJO_ACCOUNT_ID = 'gATHOfPMQhpoxHBp';

export const STAGING_ACCOUNT_DCI_ID = 'demonci';
export const STAGING_ACCOUNT_GOGGINS_ID = 'HpxgsPKInM80U4L0KjAX';
export const STAGING_ACCOUNT_ID = 'qMGLJLdLmNlgmBtNgQuRBvVS';

export const DRIVER_BONUS_DATA_SETS = [
  'driverScoreMonth',
  'driverScoreQuarter',
];

export const TOP_BAR_HEIGHT = 54;

export const TABS_TOP_BAR_HEIGHT = TOP_BAR_HEIGHT;

export const SLIDE_OUT_ELEMENT_ID = 'fleetops-slide-out';

export const FADE_IN_ELEMENT_ID = 'fleetops-fade-in';

export const Z_INDEX = {
  SLIDE_OUT_OVERLAY_CLOSED: -100,
  SIDE_NAV_TOGGLE: 1,
  SCORECARD_COLUMN_HEADING: 1,
  GRID_SLIDE_OUT: 1,
  GENERAL_BOARD_SLIDE_OUT: 1,
  CUSTOMER_LANE_CONFIG_SLIDEOUT: 1,
  SCORECARD_KPIS: 2,
  SLIDE_OUT_OVERLAY_OPENED: 2,
  GADGET_TOP_BAR: 4,
  TABS_ROW: 9,
  WORKSPACE_DASHBOARD_MENU: 10,
  SCORECARD_SHADOWS: 10,
  SEARCH_OVERLAY_BUTTONS: 10,
  SLIDE_OUT: 11,
  WALLBOARD_PREVIEW_DASHBOARD: 10,
  TEXT_GADGET: 100,
  CHART: 300,
  INLINE_DIALOG: 10000,
  DRAG_DROP: 10001,
  TOAST: 10002,
  CONFIGURE_DASHBOARD_GADGET: 1000000,
};

export const DEFAULT_FORMATTING = {} as MetricFormatting;

export const CUSTOMER_LANE_COMMITMENTS = 'customerLaneCommitments';

export const TARGET_PERFORMANCE_APP = 'targetsApp';

export const GQL_ERROR = 'GQL Error';

export const STATUS_FIELDS_META_FIELD = '_statusFields';

export const LOCAL_STORAGE_IMPERSONATING_USER_ID = 'impersonating-user-id';
