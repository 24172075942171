import React from 'react';

interface GoalFilteringContextType {
  filteredGoals: GeneralGoal[];
  searchText: string;
  setSearchText: (text: string) => void;
  goalOwnerIds: string[];
  setGoalOwnerIds: (ownerIds: string[]) => void;
}

const GoalFilteringContext = React.createContext(
  {} as GoalFilteringContextType,
);

export default GoalFilteringContext;
