import React, { useCallback, useEffect, useState } from 'react';

import Row from 'components/Common/Row';
import getIdentifier from 'getIdentifier';
import Button from 'kingpin/atoms/Button';

const NavBonusPickerForm = ({
  item,
  onSave,
  close,
}: {
  item: PortalsNav.BonusPeriodPicker | undefined;
  onSave: (item: PortalsNav.BonusPeriodPicker) => void;
  close: () => void;
}) => {
  const [key] = useState<string>(
    item ? item.key : getIdentifier(undefined, true),
  );
  const [draftItem, setDraftItem] = useState<
    PortalsNav.BonusPeriodPicker | undefined
  >(item);

  useEffect(() => {
    setDraftItem({
      key,
      type: 'Bonus Period Picker',
    });
  }, [key]);

  const onSaveClicked = useCallback(() => {
    if (!draftItem) {
      return;
    }

    onSave(draftItem);
    close();
  }, [close, draftItem, onSave]);

  return (
    <div>
      <Row centerAlign spaceBetween>
        <Button
          size={'Small'}
          type={'Secondary'}
          label={'Cancel'}
          onClick={close}
        />
        <Button
          isDisabled={draftItem === undefined}
          onClick={onSaveClicked}
          label={item ? 'Update' : 'Add'}
          type={'Primary'}
          size={'Small'}
        />
      </Row>
    </div>
  );
};

export default NavBonusPickerForm;
