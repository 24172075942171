import React, { useContext, useEffect, useState } from 'react';

import WorkSpaceDashboardWizard from './WorkSpaceDashboardWizard';
import setWorkSpace from '../../../api/workspaces/setWorkSpace';
import DashboardsContext from '../../../contexts/DashboardsContext';
import { DateTime } from 'luxon';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import { isTargetsApp } from '../../../isWorkSpace';
import STORE from '../../../store';

const WorkSpaceDashboardWizardContainer = ({
  workSpace,
  onBackClicked,
  close,
}: {
  workSpace: WorkSpace | TargetsApp.App;
  onBackClicked: () => void;
  close: () => void;
}) => {
  const { accountRef, selectedAccountId } = useContext(AccountPickerContext);
  const { allDashboards } = useContext(DashboardsContext);
  const currentUser = useContext(CurrentUserContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const [selectedDashboard, setSelectedDashboard] = useState<
    DashboardType | undefined
  >();
  const [options, setOptions] = useState<DropdownOption[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const onAddClicked = () => {
    if (!selectedDashboard) {
      return;
    }
    setIsSaving(true);
    const newTab = {
      type: 'dashboard' as 'dashboard',
      typeId: selectedDashboard.id,
    };
    if (isTargetsApp(workSpace)) {
      const newApp = {
        ...workSpace,
        tabs: [...workSpace.tabs, newTab],
        updatedBy: currentUser.id,
        updatedOn: DateTime.utc().toISO(),
      };
      STORE.contentDistributions
        .getTargetsApps({ accountId: selectedAccountId })
        .doc(newApp.id)
        .set(newApp)
        .then(() => {
          setIsSaving(false);
          trackEvent('Targets App - Tab added', { tabType: 'dashboard' });
          close();
        });
    } else {
      const newWorkSpace = {
        ...workSpace,
        tabs: [...workSpace.tabs, newTab],
        updatedBy: currentUser.id,
        updatedOn: DateTime.utc().toISO(),
      };
      setWorkSpace(newWorkSpace, accountRef).then(() => {
        setIsSaving(false);
        trackEvent('Workspace - Tab added', { tabType: 'dashboard' });
        close();
      });
    }
  };

  useEffect(() => {
    const unusedDashboards = allDashboards.filter(
      (r) =>
        !workSpace.tabs.some(
          (t) => t.type === 'dashboard' && t.typeId === r.id,
        ),
    );

    const newOptions: DropdownOption[] = unusedDashboards
      .filter((d) => !d.isEntityDetailsTab)
      .map((r) => ({
        key: r.id,
        label: r.name,
        isSelected: !!selectedDashboard && r.id === selectedDashboard.id,
        onSelected: () => {
          setSelectedDashboard(r);
        },
      }))
      .sort((a, b) => {
        const aL = a.label.toLowerCase();
        const bL = b.label.toLowerCase();

        if (aL > bL) {
          return 1;
        } else if (aL === bL) {
          return 0;
        } else {
          return -1;
        }
      });

    setOptions(newOptions);
  }, [allDashboards, selectedDashboard, workSpace.tabs]);

  return (
    <WorkSpaceDashboardWizard
      options={options}
      selectedDashboard={selectedDashboard}
      onAddClicked={onAddClicked}
      onBackClicked={onBackClicked}
      isSaving={isSaving}
    />
  );
};

export default WorkSpaceDashboardWizardContainer;
