import portalNavItemTypeCheckers from '../../../../types/portalNavItemTypeCheckers';

const getScorecardIdsInPortal = ({
  portal,
}: {
  portal: EngagementPortal | BonusPortal | ExecutivePortal;
}) => {
  return portal.navigationSettings.sections.reduce((acc, section) => {
    const sectionIds = section.items.reduce((itemAcc, item) => {
      if (portalNavItemTypeCheckers.isScorecard(item)) {
        return [...itemAcc, item.scorecardId];
      }
      if (portalNavItemTypeCheckers.isScorecardsIndex(item)) {
        return [...itemAcc, ...item.scorecardIds];
      }
      return itemAcc;
    }, [] as string[]);

    return [...acc, ...sectionIds];
  }, [] as string[]);
};

export default getScorecardIdsInPortal;
