import React, { useCallback, useContext, useState } from 'react';

import usePopup from '../../hooks/usePopup';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import ContextMenuContext from '../../kingpin/atoms/ContextMenu/ContextMenuContext';
import InlineDialog from '../InlineDialog';
import { ContextMenuDiv, Option } from '../../kingpin/atoms/ContextMenu';
import { ModalTransition } from '@atlaskit/modal-dialog';
import ContactUsFormContainer from '../ContactUsForm';
import NavItem from '../../kingpin/navigation/NavItem';

const HELP_SITE = 'https://help.fleetops.com/';

const HelpCenterButton = () => {
  const { isOpen, open, close } = usePopup();
  const [contactUsIsOpen, setContactUsIsOpen] = useState<boolean>(false);
  const { trackEvent } = useContext(AnalyticsContext);

  const onHelpClicked = useCallback(() => {
    window.open(HELP_SITE, '_blank');
    trackEvent('Help - Contact Form Opened');
    close();
  }, [close, trackEvent]);

  const onContactSupportClicked = useCallback(() => {
    setContactUsIsOpen(true);
    close();
  }, [close]);

  return (
    <div style={{ height: 34, marginRight: 8 }}>
      <ContextMenuContext.Provider
        value={{
          closeMenu: close,
          hideMenu: close,
        }}
      >
        <InlineDialog
          content={
            <ContextMenuDiv>
              <Option
                icon="open-link"
                label={'Help Center'}
                onClick={onHelpClicked}
              />
              <Option
                icon="mail"
                label="Contact Support"
                onClick={onContactSupportClicked}
              />
            </ContextMenuDiv>
          }
          placement={'top-start'}
          isOpen={isOpen}
          onClose={close}
        >
          <NavItem
            iconColor={'#666666'}
            isActiveOverride={false}
            to={'#'}
            onClick={isOpen ? close : open}
            icon={'help'}
            testId={'support-button'}
          />
        </InlineDialog>
      </ContextMenuContext.Provider>
      <ModalTransition>
        {contactUsIsOpen && (
          <ContactUsFormContainer close={() => setContactUsIsOpen(false)} />
        )}
      </ModalTransition>
    </div>
  );
};

export default HelpCenterButton;
