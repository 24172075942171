import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridApi } from 'ag-grid-community';

import BoardContext from 'contexts/BoardContext';
import BulkGridActionsProvider from 'contextProviders/BulkGridActionsProvider';
import GqlClientProvider from 'contextProviders/SplashScreenProviders/UserAndAccountProviders/GqlClientProvider';
import FilterPlatesProvider from 'contextProviders/FilterPlatesProvider';
import useBoardOpenedTracking from 'hooks/useBoardTracking';
import useContentViewTracking from 'hooks/useContentViewTracking';
import useReasonCodes from 'hooks/useReasonCodes';
import FlexCentered from 'components/Common/FlexCentered';
import Loading from 'components/Loading';

import SlideOutProvider from '../providers/SlideOutProvider';
import SelectedEntityProvider from '../providers/SelectedEntityProvider';
import useEntityField from '../../TargetManager/hooks/useEntityField';
import useFilteringState from '../hooks/useFilteringState';
import PerformanceBoard from './PerformanceBoard';
import PeriodsContext from '../../../contexts/PeriodsContext';
import useRefreshDatasetFreshnessOnMount from '../../../hooks/useRefreshDatasetFreshnessOnMount';
import { BonusPortalContext } from '../../../contextProviders/BonusPortalProvider';

const useResetSelectedPeriod = () => {
  const bonusPortalContext = useContext(BonusPortalContext);
  const { periods, setSelectedPeriod } = useContext(PeriodsContext);

  useEffect(() => {
    if (bonusPortalContext) {
      return;
    }
    setSelectedPeriod(periods[0]);
  }, [bonusPortalContext, periods, setSelectedPeriod]);
};

const PerformanceBoardContainer = ({
  board,
  popupFilters,
  scopeFilters,
}: {
  board: PerformanceBoardTypes.Board;
  popupFilters?: FilterPlateType[];
  scopeFilters?: FilterPlateType[];
}) => {
  useResetSelectedPeriod();
  useContentViewTracking({
    type: 'board',
    name: board.name,
    typeId: board.id,
  });
  useRefreshDatasetFreshnessOnMount();
  const navigate = useNavigate();
  const entityField = useEntityField(board.dataType);
  const [sortOverride, setSortOverride] = useState<SimpleGridSort>(() =>
    board.defaultSort
      ? {
          field: board.defaultSort.field,
          direction: board.defaultSort.direction,
        }
      : { field: entityField || '', direction: 'asc' as 'asc' },
  );
  const [gridApi, setGridApi] = useState<GridApi>();
  const reasonCodes = useReasonCodes(board.dataType);

  useEffect(() => {
    if (board.defaultSort) {
      setSortOverride(board.defaultSort);
    }
  }, [board.defaultSort]);

  const onSlideOutClosed = useCallback(() => {
    const hasQueryParams = window.location.search.length > 0;
    if (hasQueryParams) {
      navigate(-1);
    }
  }, [navigate]);

  const { onManualFilterChanged, onQuickFilterChanged } =
    useBoardOpenedTracking({ board, onSlideOutClosed });
  const {
    dataTypes,
    dateRange,
    quickFilters,
    drillDowns,
    setDrillDowns,
    selectedQuickFilters,
    setSelectedQuickFilters,
    netQuickFilters,
  } = useFilteringState({
    board,
    popupFilters,
    scopeFilters,
  });

  if (!dateRange) {
    return (
      <FlexCentered>
        <Loading />
      </FlexCentered>
    );
  }

  return (
    <FilterPlatesProvider
      drillDowns={drillDowns}
      setDrillDowns={setDrillDowns}
      scope={scopeFilters}
      dataTypes={dataTypes}
      quickFilters={netQuickFilters}
    >
      <BoardContext.Provider
        value={{
          board,
          reasonCodes,
          quickFilters,
          selectedQuickFilters,
          setSelectedQuickFilters,
          isBoardOwner: false,
        }}
      >
        <BulkGridActionsProvider>
          <GqlClientProvider
            visId={board.id}
            visType={'performanceBoard'}
            visName={board.name}
          >
            <SlideOutProvider board={board}>
              <SelectedEntityProvider board={board} gridApi={gridApi}>
                <PerformanceBoard
                  board={board}
                  sortOverride={sortOverride}
                  onQuickFilterChanged={onQuickFilterChanged}
                  onManualFilterChanged={onManualFilterChanged}
                  dataTypes={dataTypes}
                  gridApi={gridApi}
                  setGridApi={setGridApi}
                />
              </SelectedEntityProvider>
            </SlideOutProvider>
          </GqlClientProvider>
        </BulkGridActionsProvider>
      </BoardContext.Provider>
    </FilterPlatesProvider>
  );
};

export default PerformanceBoardContainer;
