import React, { useMemo } from 'react';
import Icon, { IconMap } from './Icon';
import Inputs from '../../components/Inputs';
import Row from '../../components/Common/Row';
import Typography from './Typography';

const IconPicker = ({
  selectedIcon,
  setSelectedIcon,
  iconColor,
}: {
  selectedIcon?: Kingpin.Icon;
  setSelectedIcon: React.Dispatch<
    React.SetStateAction<Kingpin.Icon | undefined>
  >;
  iconColor: string;
}) => {
  const iconOptions = useMemo((): DropdownOption[] => {
    const options: DropdownOption[] = [
      {
        label: 'None',
        isSelected: !selectedIcon,
        onSelected: () => {
          setSelectedIcon(undefined);
        },
      },
    ];

    options.push(
      ...Object.entries(IconMap).map(([icon, IconComponent]) => {
        return {
          label: icon,
          isSelected: selectedIcon && icon === selectedIcon,
          onSelected: () => {
            setSelectedIcon(icon as Kingpin.Icon);
          },
          renderLabel: () => (
            <Row style={{ gap: 8 }} centerAlign>
              <IconComponent color={iconColor} width={20} height={20} />
              <Typography.Body type={'Annotation'}>{icon}</Typography.Body>
            </Row>
          ),
        };
      }),
    );

    return options;
  }, [iconColor, selectedIcon, setSelectedIcon]);

  return (
    <Inputs.Dropdown
      isSearchEnabled
      options={iconOptions}
      renderSelectedLabel={
        !selectedIcon
          ? undefined
          : () => (
              <Row style={{ gap: 8 }} centerAlign>
                <Icon icon={selectedIcon} color={iconColor} />
                <Typography.Body type={'Annotation'}>
                  {selectedIcon}
                </Typography.Body>
              </Row>
            )
      }
    />
  );
};

export default IconPicker;
