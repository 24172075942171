import React from 'react';

interface DatasetDefinitionsContextType {
  datasets: FleetOps.DatasetDefinition[];
  performanceDatasets: FleetOps.DatasetDefinition[];
  bonusPrograms: Scoring.BonusProgram[];
  bonusPeriodsLookup: { [program: string]: Scoring.BonusPeriod[] | undefined };
  getBonusProgram: (dataset: string) => Scoring.BonusProgram | undefined;
  performanceMetricIds: string[];
  isLoading: boolean;
  performanceConfigs: FleetOps.PerformanceDatasetConfig[];
  getPerformanceConfig: (
    dataset: string,
  ) => FleetOps.PerformanceDatasetConfig | undefined;
  refreshPerformanceConfigs: () => Promise<void>;
}

const DatasetDefinitionsContext =
  React.createContext<DatasetDefinitionsContextType>({
    datasets: [],
    performanceDatasets: [],
    bonusPrograms: [],
    bonusPeriodsLookup: {},
    getBonusProgram: () => undefined,
    performanceMetricIds: [],
    isLoading: true,
    performanceConfigs: [],
    getPerformanceConfig: () => undefined,
    refreshPerformanceConfigs: () => Promise.resolve(undefined),
  });

export default DatasetDefinitionsContext;
