import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import DoneCircle from './DoneCircle';
import Typography from 'kingpin/atoms/Typography';
import Row from '../Common/Row';
import moment from 'moment';
import DisplayUserFromId from '../UserFromId';

import LocalTimelineContext from '../../contexts/Timeline/LocalTimelineContext';
import LocalTasksContext from '../../contexts/Tasks/LocalTasksContext';
import ShowOnMouseOverUnlessTour from '../ShowOnMouseOverUnlessTour';
import usePopup from '../../hooks/usePopup';
import ConfirmationModal from '../ConfirmationModal';
import Button from 'kingpin/atoms/Button';
import AllTasksComplete from './AllTasksComplete';
import NoTasksYet from './NoTasksYet';
import Loading from '../Loading';
import Card from '../Common/Card';
import Colors2 from '../../theme/Colors2';

const TaskElement = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  padding: 16px;
  background-color: white;
  border: 1px solid ${Colors2.Border};
`;

const ListWrapper = styled.div<{ isFullWidth?: boolean }>`
  padding: 0px 16px 24px 16px;
  height: calc(100% - 72px);
  width: ${(props) => (props.isFullWidth ? '100%' : '800px')};
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const DeleteButton = ({ task }: { task: Tasks.Task }) => {
  const { addEvent } = useContext(LocalTimelineContext);
  const { onTaskDeleted } = useContext(LocalTasksContext);
  const { isOpen, open, close } = usePopup();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onDeleteTaskConfirmed = useCallback(async () => {
    setIsLoading(true);
    await onTaskDeleted(task);
    if (addEvent) {
      await addEvent({
        actionText: 'deleted a task',
        contextText: task.title,
      });
    }
    setIsLoading(false);
    close();
  }, [addEvent, close, onTaskDeleted, task]);

  return (
    <>
      <Button type="Secondary" size="Small" onClick={open} icon="cross" />
      <ConfirmationModal
        isOpen={isOpen}
        close={close}
        title={'Delete task?'}
        confirmText={'Delete Task'}
        body={'Are you sure? This cannot be undone.'}
        onConfirmed={onDeleteTaskConfirmed}
        isLoading={isLoading}
      />
    </>
  );
};

const TasksList = ({
  tasks,
  onOpen,
  isAllTasksComplete,
  isNoTasks,
  isLoading,
  isFullWidth,
}: {
  tasks: Tasks.Task[];
  onOpen: (task: Tasks.Task) => void;
  isAllTasksComplete: boolean;
  isNoTasks: boolean;
  isLoading: boolean;
  isFullWidth?: boolean;
}) => (
  <ListWrapper isFullWidth={isFullWidth}>
    {isLoading && <Loading />}
    {!isLoading && (
      <div style={{ overflow: 'scroll' }}>
        {isAllTasksComplete && <AllTasksComplete />}
        {isNoTasks && <NoTasksYet />}
        {tasks.map((t) => (
          <TaskElement key={t.id}>
            <div style={{ marginRight: 16 }}>
              <DoneCircle task={t} />
            </div>
            <div
              style={{ flex: 1, cursor: 'pointer', overflow: 'hidden' }}
              onClick={() => {
                onOpen(t);
              }}
            >
              <Row spaceBetween>
                <div
                  onClick={() => {
                    onOpen(t);
                  }}
                  style={{ marginBottom: 8 }}
                >
                  <Typography.Body type="Link">{t.title}</Typography.Body>
                </div>
                <ShowOnMouseOverUnlessTour>
                  <DeleteButton task={t} />
                </ShowOnMouseOverUnlessTour>
              </Row>
              <div
                style={{
                  display: 'flex',
                  marginBottom: '8px',
                  width: '90%',
                }}
              >
                <Typography.Body isEllipsis type="Body 14">
                  {t.description}
                </Typography.Body>
              </div>
              <Row spaceBetween centerAlign>
                <div>
                  <Typography.Body type="Body 14" color={Colors2.Grey['4']}>
                    {`Due: ${moment(t.dueDate).format('MM/DD/YYYY')}`}
                  </Typography.Body>
                </div>
                <DisplayUserFromId userId={t.assignedTo} />
              </Row>
            </div>
          </TaskElement>
        ))}
      </div>
    )}
  </ListWrapper>
);

export default TasksList;
