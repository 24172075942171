import React, { useContext, useMemo } from 'react';
import BonusPeriodsContext from '../../../../contexts/BonusPeriodsContext';
import Dropdown from '../../../../components/Inputs/Dropdown';
import DatasetDefinitionsContext from '../../../../contexts/DatasetDefinitionsContext';
import { NAV_ITEM_BOTTOM_MARGIN } from '../../NavItem';
import Typography from '../../../atoms/Typography';

const BonusPeriodPicker = () => {
  const { bonusPeriods, selectedBonusPeriod, setSelectedBonusPeriod } =
    useContext(BonusPeriodsContext);

  const options = useMemo((): DropdownOption[] => {
    return bonusPeriods.map((p) => ({
      key: p.id,
      label: p.label,
      isSelected: selectedBonusPeriod && selectedBonusPeriod.id === p.id,
      onSelected: () => {
        setSelectedBonusPeriod(p);
      },
    }));
  }, [bonusPeriods, selectedBonusPeriod, setSelectedBonusPeriod]);

  return <Dropdown options={options} />;
};

const BonusPeriodPickerNavItem = ({ portal }: { portal: BonusPortal }) => {
  const { bonusPrograms } = useContext(DatasetDefinitionsContext);
  const bonusProgramName = useMemo(() => {
    const p = bonusPrograms.find((bp) => bp.id === portal.bonusProgramId);
    if (p) {
      return p.name;
    }
    return undefined;
  }, [bonusPrograms, portal.bonusProgramId]);

  return (
    <div style={{ marginBottom: NAV_ITEM_BOTTOM_MARGIN }}>
      {bonusProgramName && (
        <div style={{ marginBottom: 4 }}>
          <Typography.Body type={'Body 13 Bold'}>
            {bonusProgramName}
          </Typography.Body>
        </div>
      )}

      <BonusPeriodPicker />
    </div>
  );
};

export default BonusPeriodPickerNavItem;
