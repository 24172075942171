import React, { useState } from 'react';
import PerformanceBoardSlideOutContext from '../contexts/PerformanceBoardSlideOutContext';
import useGetFieldsWhichHaveTargets from '../hooks/useGetFieldsWhichHaveTargets';
import useGetBonusCategories from '../hooks/useGetBonusCategories';

const SlideOutProvider = ({
  board,
  children,
}: {
  board: PerformanceBoardTypes.Board;
  children: JSX.Element | JSX.Element[];
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filterInput, setFilterInput] = useState<FilterInput | undefined>();
  const [navState, setNavState] =
    useState<PerformanceBoardTypes.SlideOut.NavState>({ slideoutMode: 'Home' });
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [flashMessage, setFlashMessage] = useState<string>('');

  const { fields: performanceFields } = useGetFieldsWhichHaveTargets({
    dataType: board.dataType,
  });
  const { bonusStatusFields, bonusCategories } = useGetBonusCategories({
    dataType: board.dataType,
  });

  const flash = {
    isVisible,
    setIsVisible,
    flashMessage,
    setFlashMessage,
  };

  return (
    <PerformanceBoardSlideOutContext.Provider
      value={{
        board,
        isOpen,
        setIsOpen,
        filterInput,
        setFilterInput,
        navState,
        setNavState,
        performanceFields,
        performanceDataset: board.dataType,
        flash,
        bonusCategories,
        bonusStatusFields,
      }}
    >
      {children}
    </PerformanceBoardSlideOutContext.Provider>
  );
};

export default SlideOutProvider;
