import { useCallback, useContext } from 'react';
import CurrentUserContext from '../../../../../contexts/CurrentUserContext';
import RolesContext from '../../../../../contexts/RolesContext';
import ImpersonatorContext from '../../../../../contexts/ImpersonatorContext';

const useGetHasEngagementPortalAccess = () => {
  const { currentPermissions } = useContext(RolesContext);
  const currentUser = useContext(CurrentUserContext);
  const { impersonatorId } = useContext(ImpersonatorContext);

  const getHasEngagementPortalAccess = useCallback(
    (app: EngagementPortal) => {
      const currentUserId = impersonatorId ? impersonatorId : currentUser.id;
      const isManager = app.usersIdsWithAccess.some(
        (mid) => mid === currentUserId,
      );
      const isAdmin = currentPermissions.some(
        (p) => p === 'fleetops.permissions.content_access_view_all_admin_only',
      );

      const hasAccess = isManager || isAdmin;
      return hasAccess;
    },
    [currentPermissions, currentUser.id, impersonatorId],
  );

  return getHasEngagementPortalAccess;
};

export default useGetHasEngagementPortalAccess;
