const getTypeName = (
  type: ExecutivePortalPublishableContentType | 'workSpace',
): string => {
  switch (type) {
    case 'scorecard':
      return 'Kpi List';
    case 'report':
      return 'Report';
    case 'dashboard':
      return 'Dashboard';
    case 'workSpace':
      return 'Workspace';
    default:
      return 'Content';
  }
};

export default getTypeName;
