import {
  buildDashboardShow,
  buildDriverScoringProgramReportShow,
  buildEntityAppRedirect,
  buildReportShow,
  buildScoringProgramConfigShow,
  buildShowBoard,
  buildShowGlobalKpiList,
  buildShowSignOffBoard,
} from '../../../../navigation/appRoutes';
import portalTypeCheckers from '../../../../types/portalTypeCheckers';

const getNavItemLink = ({
  item,
  section,
  portal,
}: {
  item: PortalsNav.Item;
  section: PortalSideNavigationSection;
  portal: Portal;
}) => {
  switch (item.type) {
    case 'Dashboard':
      return buildDashboardShow({ id: item.dashboardId, portal, section });
    case 'Board':
      return buildShowBoard(item.boardId);
    case 'Sign Off Board':
      if (!portalTypeCheckers.isBonusPortal(portal)) {
        return '/';
      }
      return buildShowSignOffBoard({ portal, section, boardId: item.boardId });
    case 'Dashboards Index':
      return `/${portal.urlSlug}/${section.urlSlug}/dashboards`;
    case 'Report':
      return buildReportShow({ id: item.reportId, portal, section });
    case 'Reports Index':
      return `/${portal.urlSlug}/${section.urlSlug}/reports`;
    case 'Scorecard':
      return buildShowGlobalKpiList({
        scorecardId: item.scorecardId,
        portal,
        section,
      });
    case 'Bonus Home':
      return `/${portal.urlSlug}/${section.urlSlug}/bonus-home`;
    case 'Entity App':
    case 'Mapped Entity View':
      return buildEntityAppRedirect({
        portal,
        entityId: item.entityId,
        section,
      });
    case 'Driver Scoring Program Report':
      return buildDriverScoringProgramReportShow({ section, portal });
    case 'Scoring Program Config Overview':
      return buildScoringProgramConfigShow({ section, portal });
    case 'Users':
      return `/${portal.urlSlug}/${section.urlSlug}/users`;
    case 'Targets Overview':
      return `/${portal.urlSlug}/${section.urlSlug}/targets-overview/${item.performanceDataset}`;
    case 'Targets Manager':
      return `/${portal.urlSlug}/${section.urlSlug}/targets-manager/${item.performanceDataset}`;
    case 'Scorecards Index':
      return `/${portal.urlSlug}/${section.urlSlug}/kpi-lists`;
    case 'Goals Index':
      return `/${portal.urlSlug}/${section.urlSlug}/goals`;
    case 'Tasks':
      return `/${portal.urlSlug}/${section.urlSlug}/tasks`;
    default:
      return '';
  }
};

export default getNavItemLink;
