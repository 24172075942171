import portalNavItemTypeCheckers from '../../../../types/portalNavItemTypeCheckers';

const getDashboardIdsInPortal = ({
  portal,
}: {
  portal: EngagementPortal | BonusPortal | ExecutivePortal;
}) => {
  return portal.navigationSettings.sections.reduce((acc, section) => {
    const sectionIds = section.items.reduce((itemAcc, item) => {
      if (portalNavItemTypeCheckers.isDashboard(item)) {
        return [...itemAcc, item.dashboardId];
      }
      if (portalNavItemTypeCheckers.isDashboardsIndex(item)) {
        return [...itemAcc, ...item.dashboardIds];
      }
      return itemAcc;
    }, [] as string[]);

    return [...acc, ...sectionIds];
  }, [] as string[]);
};

export default getDashboardIdsInPortal;
