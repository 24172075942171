import getPerformanceBoardsRef from './getPerformanceBoardsRef';
import getTargetsApps from './getTargetsApps';
import getEntityDetailsApps from './getEntityDetailsApps';
import getExecutivePortalsRef from './getExecutivePortalsRef';
import getEngagementPortalsRef from './getEngagementPortalsRef';
import getBonusPortalsRef from './getBonusPortalsRef';
import getBonusPortalBoardSignOffsRef from './getBonusPortalBoardSignOffsRef';
import getAppsRef from './getAppsRef';

const contentDistributions = {
  getPerformanceBoardsRef,
  getTargetsApps,
  getEntityDetailsApps,
  getExecutivePortalsRef,
  getEngagementPortalsRef,
  getBonusPortalsRef,
  getBonusPortalBoardSignOffsRef,
  getAppsRef,
};

export default contentDistributions;
