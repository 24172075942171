import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import Typography from './Typography';

type KingpinBadgeType = 'Info' | 'Default' | 'Success' | 'Warning' | 'Error';

const InfoMixin = css`
  background-color: #f7f9ff;
  border-color: #8fabff;
`;

const DefaultMixin = css`
  background-color: #ffffff;
  border-color: #e0e0e0;
`;

const SuccessMixin = css`
  background-color: #f0fdf4;
  border-color: #c0ecd0;
`;

const WarningMixin = css`
  background-color: #fff2e4;
  border-color: #ffc382;
`;

const ErrorMixin = css`
  background-color: #fef2f2;
  border-color: #fbd9da;
`;

const KingpinBadgeDiv = styled.div<{ badgeType: KingpinBadgeType }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 23px;
  flex: 1;
  border-radius: 6px;
  border: 1px solid;
  padding: 0px 4px;

  ${(props) => props.badgeType === 'Info' && InfoMixin}
  ${(props) => props.badgeType === 'Default' && DefaultMixin}
  ${(props) => props.badgeType === 'Success' && SuccessMixin}
  ${(props) => props.badgeType === 'Warning' && WarningMixin}
  ${(props) => props.badgeType === 'Error' && ErrorMixin}
`;

const KingpinBadge = ({
  text,
  badgeType,
  children,
}: {
  text?: string;
  children?: ReactNode;
  badgeType: KingpinBadgeType;
}) => {
  return (
    <KingpinBadgeDiv badgeType={badgeType}>
      {!!text && <Typography.Body type={'Badge'}>{text}</Typography.Body>}
      {children}
    </KingpinBadgeDiv>
  );
};

export default KingpinBadge;
