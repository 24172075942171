import React from 'react';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';
import Inputs from 'components/Inputs';
import List from 'components/List';
import UserListItem from './UserListItem';
import useUsersWithAccess from './useUsersWithAccess';
import Row from '../../../../components/Common/Row';
import FlexCentered from '../../../../components/Common/FlexCentered';
import Form from '../../../../kingpin/forms/Form';
import FormHeader from '../../../../kingpin/forms/FormHeader';
import FormContent from '../../../../kingpin/forms/FormContent';

const UserList = ({
  users,
  onUserRemoved,
}: {
  users: (
    | UserManagement.PendingUser
    | UserManagement.SignedUpUser
    | UserManagement.FleetOpsStaffUser
  )[];
  onUserRemoved: (
    user:
      | UserManagement.PendingUser
      | UserManagement.SignedUpUser
      | UserManagement.FleetOpsStaffUser,
  ) => Promise<void>;
}) => {
  const isNoUsers = users.length === 0;
  if (isNoUsers) {
    return (
      <FlexCentered>
        <Typography.Body type="Body 12">No Users</Typography.Body>
      </FlexCentered>
    );
  }
  return (
    <div style={{ border: '1px solid #e8e8e8' }}>
      {users.map((u, index) => (
        <UserListItem
          user={u}
          onUserRemoved={onUserRemoved}
          key={u.id}
          isLast={index === users.length - 1}
        />
      ))}
    </div>
  );
};

const ManageUsersContent = ({ close }: { close: () => void }) => {
  const { usersWithAccess, onUserAdded, onUserRemoved } = useUsersWithAccess();

  return (
    <Form>
      <FormHeader title={'Invite Your Users'} onClose={close} />
      <FormContent>
        <div style={{ marginBottom: 24 }}>
          <Inputs.AddUsersSearch
            selectedUsers={usersWithAccess}
            onUserAdded={onUserAdded}
          />
        </div>
        <List
          noBorder
          noMargin
          Headers={null}
          Body={
            <UserList users={usersWithAccess} onUserRemoved={onUserRemoved} />
          }
        />
      </FormContent>
      <Row style={{ justifyContent: 'flex-end' }}>
        <Button label="Close" onClick={close} type="Primary" size="Small" />
      </Row>
    </Form>
  );
};

export default ManageUsersContent;
