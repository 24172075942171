import React, { useContext } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';

import CreateGoalButton from './CreateGoalButton';
import usePopup from '../../../hooks/usePopup';
import GeneralGoalForm from '../GeneralGoalForm';
import WorkSpaceContext from '../../../contexts/WorkSpaceContext';
import AddGoalButton from '../AddGoalButton';

const CreateGoalButtonContainer = () => {
  const { generalWorkSpace: workSpace } = useContext(WorkSpaceContext);
  const { isOpen, open, close } = usePopup();

  if (workSpace) {
    return <AddGoalButton workSpace={workSpace} />;
  } else {
    return (
      <>
        <CreateGoalButton open={open} />
        <ModalTransition>
          {isOpen && (
            <Modal shouldScrollInViewport={false} autoFocus={false}>
              <GeneralGoalForm close={close} />
            </Modal>
          )}
        </ModalTransition>
      </>
    );
  }
};

export default CreateGoalButtonContainer;
