import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import DatasetDefinitionsContext from '../../contexts/DatasetDefinitionsContext';
import Loading from '../../components/Loading/Loading';
import BonusPeriodsContext from '../../contexts/BonusPeriodsContext';
import PeriodsContext from '../../contexts/PeriodsContext';
import BonusPortalBoardSignOffsProvider from './BonusPortalBoardSignOffsProvider';
import getDefaultBonusPeriod from './getDefaultBonusPeriod';

export const BonusPortalContext = React.createContext<
  | {
      portal: BonusPortal;
      dataset: FleetOps.DatasetDefinition;
      bonusProgram: Scoring.BonusProgram;
      selectedBonusPeriod: Scoring.BonusPeriod;
      demoOverridenStatusFields: { [elasticDocumentId: string]: boolean };
      setDemoOverridenStatusFields: React.Dispatch<
        React.SetStateAction<{ [elasticDocumentId: string]: boolean }>
      >;
    }
  | undefined
>(undefined);

const BonusPortalProvider = ({
  portal,
  children,
}: {
  portal: BonusPortal;
  children: JSX.Element | JSX.Element[];
}) => {
  const { datasets, bonusPrograms, bonusPeriodsLookup } = useContext(
    DatasetDefinitionsContext,
  );
  const [demoOverridenStatusFields, setDemoOverridenStatusFields] = useState<{
    [elasticDocumentId: string]: boolean;
  }>({});
  const program = useMemo(() => {
    return bonusPrograms.find((p) => p.id === portal.bonusProgramId);
  }, [bonusPrograms, portal.bonusProgramId]);
  const bonusPeriods = useMemo(() => {
    if (!program) {
      return undefined;
    }
    if (bonusPeriodsLookup[program.id]) {
      return bonusPeriodsLookup[program.id];
    }
    return undefined;
  }, [bonusPeriodsLookup, program]);
  const dataset = useMemo(() => {
    if (!program) {
      return undefined;
    }
    return datasets.find((d) => d.type === program.dataset);
  }, [datasets, program]);
  const getDefaultSelectedBonusPeriod = useCallback(() => {
    if (!bonusPeriods) {
      return undefined;
    }

    return getDefaultBonusPeriod(bonusPeriods);
  }, [bonusPeriods]);
  const [selectedBonusPeriod, setSelectedBonusPeriod] = useState<
    Scoring.BonusPeriod | undefined
  >(() => getDefaultSelectedBonusPeriod());

  useEffect(() => {
    const defaultPeriod = getDefaultSelectedBonusPeriod();
    if (bonusPeriods) {
      setSelectedBonusPeriod((currentSelected) => {
        if (!currentSelected) {
          return defaultPeriod;
        }

        return bonusPeriods.find((p) => p.id === currentSelected.id);
      });
    } else {
      setSelectedBonusPeriod(undefined);
    }
  }, [bonusPeriods, getDefaultSelectedBonusPeriod, program]);

  const setSelectedPeriod = useCallback(
    (period: Period | undefined) => {
      if (!bonusPeriods || !period) {
        setSelectedBonusPeriod(undefined);
        return;
      }

      const bonusPeriod = bonusPeriods.find(
        (p) => p.startDate === period.startDate,
      );
      setSelectedBonusPeriod(bonusPeriod);
    },
    [bonusPeriods],
  );

  if (!program || !dataset || !bonusPeriods || !selectedBonusPeriod) {
    return <Loading />;
  }

  return (
    <BonusPortalContext.Provider
      value={{
        portal,
        dataset,
        bonusProgram: program,
        selectedBonusPeriod,
        demoOverridenStatusFields,
        setDemoOverridenStatusFields,
      }}
    >
      <BonusPortalBoardSignOffsProvider
        portalId={portal.id}
        selectedPeriodId={selectedBonusPeriod.id}
      >
        <BonusPeriodsContext.Provider
          value={{
            bonusPeriods,
            selectedBonusPeriod,
            setSelectedBonusPeriod,
          }}
        >
          <PeriodsContext.Provider
            value={{
              setSelectedPeriod,
              periods: bonusPeriods,
              selectedPeriod: selectedBonusPeriod,
            }}
          >
            {children}
          </PeriodsContext.Provider>
        </BonusPeriodsContext.Provider>
      </BonusPortalBoardSignOffsProvider>
    </BonusPortalContext.Provider>
  );
};

export default BonusPortalProvider;
