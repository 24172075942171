import firebase from 'firebase/compat/app';
import STORE_CONSTANTS from '../constants';
import withoutNulls from '../../api/search/withoutNulls';

const APPS_DOC = 'apps';
const CURRENT_VERSION = 'v1';

const bonusPortalConverter = {
  toFirestore(
    app: Apps.PersistedGeneralApp | Apps.AnalyticsApp,
  ): firebase.firestore.DocumentData {
    return withoutNulls({ ...app });
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<
      Apps.PersistedGeneralApp | Apps.AnalyticsApp
    >,
    options: firebase.firestore.SnapshotOptions,
  ): Apps.PersistedGeneralApp | Apps.AnalyticsApp {
    const data: Apps.PersistedGeneralApp | Apps.AnalyticsApp = {
      ...snapshot.data(options),
    };

    return data;
  },
};

const getAppsRef = ({
  accountId,
  db,
}: {
  accountId: string;
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .collection(STORE_CONSTANTS.CONTENT_DISTRIBUTIONS_COLLECTION)
    .doc(APPS_DOC)
    .collection(CURRENT_VERSION)
    .withConverter(bonusPortalConverter);
};

export default getAppsRef;
