import Highcharts from 'highcharts';
import React, { useEffect, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import ReactPortal from './ReactPortal';

// This bridge allows us to use the Context API within tooltips.
const HighchartsTooltipBridge = ({
  chart,
  children,
}: {
  chart: Highcharts.Chart;
  children(
    formatterContext: Highcharts.TooltipFormatterContextObject,
  ): JSX.Element;
}) => {
  const [formatterContext, setFormatterContext] =
    useState<Highcharts.TooltipFormatterContextObject | null>(null);
  const [tooltipDivId] = useState<string>(`chart-tooltip-${chart.index}`);

  useEffect(() => {
    if (chart) {
      const formatter: Highcharts.TooltipFormatterCallbackFunction =
        function () {
          setFormatterContext(this);

          return `<div id="${tooltipDivId}"></div>`;
        };

      chart.update({
        tooltip: {
          formatter,
          useHTML: true,
        },
      });
    }
  }, [chart, tooltipDivId]);

  if (!formatterContext) {
    return null;
  }

  return (
    <ReactPortal elementId={tooltipDivId}>
      {children(formatterContext)}
    </ReactPortal>
  );
};

interface ChartProps<T> {
  options: Highcharts.Options;
  TooltipComponent: ({
    point,
    points,
  }: {
    point: T | undefined; // When tooltip.shared: false
    points: T[] | undefined; // When tooltip.shared: true
  }) => JSX.Element | null;
  AdditionalComponent?: React.ReactNode;
  testId?: string;
}

const ChartV2 = <T extends Highcharts.Point>({
  options,
  TooltipComponent,
  AdditionalComponent,
  testId,
}: ChartProps<T>) => {
  const [chart, setChart] = useState<Highcharts.Chart | undefined>();

  return (
    <>
      <HighchartsReact
        options={options}
        callback={setChart}
        highcharts={Highcharts}
        containerProps={testId ? { 'data-testid': testId } : undefined}
      />
      {!!chart && !!AdditionalComponent && <>{AdditionalComponent}</>}
      {!!chart && (
        <>
          <div id={`tooltip-${chart.index}`} />
          <HighchartsTooltipBridge chart={chart}>
            {(tooltipContext) => (
              <TooltipComponent
                point={tooltipContext.point as T | undefined}
                points={
                  tooltipContext.points
                    ? (tooltipContext.points
                        .map((p) => p.point)
                        .filter((p) => p.x !== null && p.y !== null) as T[])
                    : undefined
                }
              />
            )}
          </HighchartsTooltipBridge>
        </>
      )}
    </>
  );
};

export default ChartV2;
