import React from 'react';
import Inputs from 'components/Inputs';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';
import Row from '../../Common/Row';

const WorkSpaceBoardWizard = ({
  selectedBoardId,
  isSaving,
  onSave,
  onBackClicked,
  boardOptions,
}: {
  selectedBoardId?: string;
  isSaving: boolean;
  onSave: () => void;
  onBackClicked: () => void;
  boardOptions: DropdownOption[];
}) => (
  <div>
    <div style={{ marginBottom: 24 }}>
      <Typography.Header type={'H4'}>Add Board</Typography.Header>
    </div>
    <div style={{ marginBottom: 32 }}>
      <div style={{ marginBottom: 4 }}>
        <Typography.Body type="Label">Board</Typography.Body>
      </div>
      <Inputs.Dropdown options={boardOptions} placeholder="Select a Board" />
    </div>

    <Row spaceBetween>
      <Button
        onClick={onBackClicked}
        type="Secondary"
        size="Small"
        label={'Back'}
      />
      <Button
        type="Primary"
        size="Small"
        onClick={onSave}
        isDisabled={!selectedBoardId || isSaving}
        isLoading={isSaving}
        label="Create"
      />
    </Row>
  </div>
);

export default WorkSpaceBoardWizard;
