import React, { useCallback, useContext, useEffect, useState } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import styled from 'styled-components';

import Loading from 'components/Loading';
import Row from 'components/Common/Row';
import EntityDefinitionsContext from 'contexts/EntityDefinitionsContext';
import usePopup from 'hooks/usePopup';
import TextInput from 'kingpin/atoms/TextInput';
import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';

import useMappedUsers from './useMappedUsers';
import AddMappedUserForm from './AddMappedUserForm';
import PortalUserGridRow from './PortalUserGridRow';
import MappedUserItem from './MappedUserItem';
import portalNavItemTypeCheckers from '../../../../types/portalNavItemTypeCheckers';

const ListWrapper = styled.div`
  flex: 1;
  padding: 0px 24px 16px 24px;
  overflow-y: auto;
`;

const List = styled.div`
  max-height: 100%;
  overflow-y: scroll;
  padding: 0px;
  padding-bottom: 64px;
`;

const useUsers = (entityId: string) => {
  const { mappedUsers, setMappedUsers, isLoading } = useMappedUsers(entityId);
  const [users, setUsers] = useState<MappedUser[]>(mappedUsers);
  const [searchText, setSearchText] = useState<string>('');
  const onSearchChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [],
  );

  useEffect(() => {
    setUsers(
      mappedUsers.filter((u) =>
        u.user.displayName.toLowerCase().includes(searchText.toLowerCase()),
      ),
    );
  }, [mappedUsers, searchText]);

  return {
    users,
    searchText,
    onSearchChanged,
    setMappedUsers,
    isLoading,
  };
};
const PortalMappedUsers = ({
  entity,
  entityPortalName,
}: {
  entity: EntityDetails.Entity;
  entityPortalName: string;
}) => {
  const { isOpen, open, close } = usePopup();
  const { users, searchText, onSearchChanged, isLoading, setMappedUsers } =
    useUsers(entity.id);

  return (
    <>
      <div>
        <Row spaceBetween centerAlign style={{ paddingRight: 32 }}>
          <div style={{ width: 400, padding: '16px 24px' }}>
            <TextInput
              value={searchText}
              onChange={onSearchChanged}
              icon="search"
              inputSize="Small"
              placeholder="Search"
              autoFocus
            />
          </div>
          <Button
            size={'Small'}
            type={'Secondary'}
            label={`Add ${entityPortalName}`}
            icon="add"
            onClick={open}
          />
        </Row>
        <PortalUserGridRow
          isHeader
          NameCol={<Typography.Body type="Label">Name</Typography.Body>}
          EmailCol={<Typography.Body type="Label">Email</Typography.Body>}
          FilterValueCol={
            <Typography.Body type="Label">{entity.name}</Typography.Body>
          }
        />
        <ListWrapper>
          {isLoading && <Loading />}
          {!isLoading && (
            <List>
              {users.map((u) => (
                <MappedUserItem
                  user={u}
                  entity={entity}
                  entityPortalName={entityPortalName}
                  setMappedUsers={setMappedUsers}
                  key={u.user.id}
                  mappedUsers={users}
                />
              ))}
            </List>
          )}
        </ListWrapper>
      </div>
      <ModalTransition>
        {isOpen && (
          <Modal
            onClose={close}
            shouldScrollInViewport={false}
            autoFocus={false}
          >
            <AddMappedUserForm
              close={close}
              entity={entity}
              setMappedUsers={setMappedUsers}
              entityPortalName={entityPortalName}
              mappedUsers={users}
            />
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export const getMappedEntityNavItem = (
  portal: EngagementPortal,
  entityId: string,
) => {
  let item: PortalsNav.MappedEntityView | undefined;

  portal.navigationSettings.sections.forEach((section) => {
    section.items.forEach((i) => {
      if (portalNavItemTypeCheckers.isMappedEntityView(i)) {
        if (i.entityId === entityId) {
          item = i;
        }
      }
    });
  });

  return item;
};

const Gate = ({
  portal,
  entityId,
}: {
  portal: EngagementPortal;
  entityId: string;
}) => {
  const [entity, setEntity] = useState<EntityDetails.Entity | undefined>();
  const [entityPortalName, setEntityPortalName] = useState<
    string | undefined
  >();
  const { entityDefinitions } = useContext(EntityDefinitionsContext);

  useEffect(() => {
    const mappedEntityNavItem = getMappedEntityNavItem(portal, entityId);

    const entityName = (() => {
      if (!mappedEntityNavItem) {
        return undefined;
      }

      return mappedEntityNavItem.usersTabLabel;
    })();
    setEntityPortalName(entityName);
    setEntity(entityDefinitions.find((e) => e.id === entityId));
  }, [entityDefinitions, entityId, portal]);

  if (!entity || !entityPortalName) {
    return null;
  }

  return (
    <PortalMappedUsers entity={entity} entityPortalName={entityPortalName} />
  );
};

export default Gate;
