import React, { ChangeEvent, useEffect, useState } from 'react';
import Button from 'kingpin/atoms/Button';

import InlineDialog from '../../InlineDialog';
import styled from 'styled-components';
import Row from '../../Common/Row';
import usePopup from '../../../hooks/usePopup';
import { Label, Section } from '../../MyAccount/Profile/styles';
import Inputs from '../../Inputs';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import Typography from 'kingpin/atoms/Typography';
import TextInput from '../../../kingpin/atoms/TextInput';

const Item = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  background-color: white;
  align-items: center;
  margin-bottom: 8px;
  height: 32px;
  padding: 0 4px;

  ${(props) =>
    props.onClick &&
    `
  cursor: pointer;
  &:hover {
    background-color: #ededed;
  }  
  `}
`;

const List = styled.div`
  max-height: 300px;
  margin-top: 4px;
  overflow-y: scroll;
`;

const FieldsPicker = ({
  fieldOptions,
  fieldAliases,
  onAddField,
  onRemoveField,
  onDrop,
}: {
  fieldOptions: { label: string; value: string }[];
  fieldAliases: { field: string; alias: string }[];
  onAddField: (f: string) => void;
  onRemoveField: (f: string) => void;
  onDrop: (r: DropResult) => void;
}) => {
  const { isOpen, open, close } = usePopup();
  const [searchText, setSearchText] = useState<string>('');

  const onSearchTextChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredFields =
    searchText === ''
      ? fieldOptions
      : fieldOptions.filter((f) =>
          f.label.toLowerCase().includes(searchText.toLowerCase()),
        );

  return (
    <div style={{ marginBottom: 18 }}>
      <Label>
        <Typography.Body type="Label">Fields</Typography.Body>
      </Label>
      <DragDropContext onDragEnd={onDrop}>
        <Droppable droppableId={'seriesInput'}>
          {(provided) => (
            <div ref={provided.innerRef}>
              {fieldAliases.map((f, index) => (
                <Draggable draggableId={f.field} index={index} key={f.field}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Item key={f.field}>
                        <Typography.Body type={'Body 12'}>
                          {f.alias}
                        </Typography.Body>
                        <Button
                          onClick={() => {
                            onRemoveField(f.field);
                          }}
                          size="Small"
                          type="Ghost"
                          icon="cross"
                        />
                      </Item>
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <InlineDialog
        content={
          <div style={{ padding: '16px 24px' }}>
            <TextInput
              autoFocus
              placeholder="Search for a field"
              icon="search"
              label="Search"
              value={searchText}
              onChange={onSearchTextChanged}
            />
            <List>
              {filteredFields.map((f) => (
                <Item
                  key={`add-${f.label}`}
                  onClick={() => {
                    onAddField(f.value);
                    close();
                  }}
                >
                  <Typography.Body type="Body 12">{f.label}</Typography.Body>
                </Item>
              ))}
            </List>
          </div>
        }
        isOpen={isOpen}
        onClose={close}
      >
        <Button
          onClick={isOpen ? close : open}
          icon="add"
          label="Add Column"
          type="Secondary"
          size="Small"
        />
      </InlineDialog>
    </div>
  );
};

const SortsPicker = ({
  fields,
  sort,
  onAddSort,
  onRemoveSort,
}: {
  fields: string[];
  sort: SimpleGridSort[];
  onAddSort: (f: string, direction: SortBy) => void;
  onRemoveSort: (f: string) => void;
}) => {
  const { isOpen, open, close } = usePopup();

  const [wipField, setWipField] = useState<string | undefined>();
  const [fieldOptions, setFieldOptions] = useState<DropdownOption[]>([]);
  useEffect(() => {
    setFieldOptions(
      fields.map((f) => ({
        label: f,
        isSelected: wipField === f,
        onSelected: () => setWipField(f),
      })),
    );
  }, [fields, wipField]);

  const [wipSortBy, setWipSortBy] = useState<SortBy>('asc');
  const [sortByOptions, setSortByOptions] = useState<DropdownOption[]>([]);
  useEffect(() => {
    setSortByOptions([
      {
        label: 'Asc',
        onSelected: () => {
          setWipSortBy('asc');
        },
        isSelected: wipSortBy === 'asc',
      },
      {
        label: 'Desc',
        onSelected: () => {
          setWipSortBy('desc');
        },
        isSelected: wipSortBy === 'desc',
      },
    ]);
  }, [wipSortBy]);

  const onAdd = () => {
    if (wipField) {
      onAddSort(wipField, wipSortBy);
      setWipField(undefined);
      setWipSortBy('asc');
      close();
    }
  };

  return (
    <React.Fragment>
      <Label>
        <Typography.Body type="Label">Sorts</Typography.Body>
      </Label>
      {sort.map((s) => (
        <Item key={s.field}>
          <Typography.Body type="Label">
            {`${s.field} (${s.direction})`}
          </Typography.Body>
          <Button
            type="Ghost"
            size="Small"
            onClick={() => {
              onRemoveSort(s.field);
            }}
            icon={'cross'}
          />
        </Item>
      ))}
      <InlineDialog
        content={
          <div style={{ minWidth: 100, padding: '16px 24px' }}>
            <div style={{ marginBottom: 16 }}>
              <div style={{ marginBottom: 4 }}>
                <Typography.Body type="Label">Field</Typography.Body>
              </div>
              <Inputs.Dropdown options={fieldOptions} isSearchEnabled />
            </div>

            <div style={{ marginBottom: 16 }}>
              <div style={{ marginBottom: 4 }}>
                <Typography.Body type="Label">Order</Typography.Body>
              </div>
              <Inputs.Dropdown options={sortByOptions} />
            </div>

            <Button
              onClick={onAdd}
              isDisabled={!wipField}
              label="Add"
              type="Primary"
              size="Small"
            />
          </div>
        }
        isOpen={isOpen}
        onClose={close}
      >
        <Button
          onClick={isOpen ? close : open}
          type="Secondary"
          size="Small"
          icon="add"
          label="Add Sort"
        />
      </InlineDialog>
    </React.Fragment>
  );
};

const InputWrapper = styled.div`
  margin-bottom: 16px;
`;

const SimpleGridConfigInput = ({
  dataType,
  dataSourceOptions,
  fieldOptions,
  fields,
  onAddField,
  onRemoveField,
  sort,
  onAddSort,
  onRemoveSort,
  onDrop,
  disableLinks,
  onDisableLinksChanged,
  fieldAliases,
  onFieldAliasChanged,
}: {
  dataType?: string;
  dataSourceOptions: DropdownOption[];
  fieldOptions: { label: string; value: string }[];
  fields: string[];
  onAddField: (f: string) => void;
  onRemoveField: (f: string) => void;
  sort: SimpleGridSort[];
  onAddSort: (f: string, direction: SortBy) => void;
  onRemoveSort: (f: string) => void;
  onDrop: (result: DropResult) => void;
  disableLinks?: boolean;
  onDisableLinksChanged: (newValue: boolean) => void;
  fieldAliases: { field: string; alias: string }[];
  onFieldAliasChanged: (
    field: string,
    event: ChangeEvent<HTMLInputElement>,
  ) => void;
}) => (
  <Section>
    <Typography.Header type="H5">Grid Config</Typography.Header>
    <InputWrapper>
      <Label>
        <Typography.Body type="Label">Data Type</Typography.Body>
      </Label>
      <Inputs.Dropdown
        isSearchEnabled
        selectedLabel={(() => {
          if (dataType) {
            const selected = dataSourceOptions.find(
              (opt) => opt.value === dataType,
            );
            if (selected) {
              return selected.label;
            }
          }
        })()}
        options={dataSourceOptions}
      />
    </InputWrapper>
    <InputWrapper>
      <Label>
        <Typography.Body type="Label">Disable links</Typography.Body>
      </Label>
      <Inputs.Toggle value={!!disableLinks} onChange={onDisableLinksChanged} />
    </InputWrapper>

    <FieldsPicker
      fieldOptions={fieldOptions}
      fieldAliases={fieldAliases}
      onAddField={onAddField}
      onRemoveField={onRemoveField}
      onDrop={onDrop}
    />
    <div style={{ marginBottom: 32 }}>
      <div style={{ marginBottom: 4 }}>
        <Typography.Body type="Label">Aliases</Typography.Body>
      </div>
      {fieldAliases.map((f) => (
        <Row key={f.field} centerAlign spaceBetween style={{ marginBottom: 8 }}>
          <Typography.Body type="Body 12">{f.field}</Typography.Body>
          <Inputs.TextInput
            style={{ width: 150 }}
            value={f.alias}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onFieldAliasChanged(f.field, event)
            }
          />
        </Row>
      ))}
    </div>
    <SortsPicker
      fields={fields}
      sort={sort}
      onAddSort={onAddSort}
      onRemoveSort={onRemoveSort}
    />
  </Section>
);

export default SimpleGridConfigInput;
