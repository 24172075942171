import firebase from 'firebase/compat/app';
import STORE_CONSTANTS from '../constants';

const PERIODS_DOC = 'periods';

const converter = {
  toFirestore(program: Scoring.BonusPeriod): firebase.firestore.DocumentData {
    return program;
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<Scoring.BonusPeriod>,
    options: firebase.firestore.SnapshotOptions,
  ): Scoring.BonusPeriod {
    const data = {
      ...snapshot.data(options),
    };

    return data;
  },
};

const getScoringPeriodsRef = ({
  accountId,
  db,
  programDoc,
}: {
  accountId: string;
  db?: firebase.firestore.Firestore;
  programDoc: string;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .collection(STORE_CONSTANTS.SCORING_COLLECTION)
    .doc(programDoc)
    .collection(PERIODS_DOC)
    .withConverter(converter);
};

export default getScoringPeriodsRef;
