import React, { ChangeEvent, useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Button from 'kingpin/atoms/Button';

import usePopup from 'hooks/usePopup';
import Typography from 'kingpin/atoms/Typography';
import Row from 'components/Common/Row';
import Inputs from 'components/Inputs';
import getTimeStamp from 'getTimeStamp';
import STORE from 'store';
import CurrentUserContext from 'contexts/CurrentUserContext';
import AccountPickerContext from 'contexts/AccountPickerContext';
import TargetsAppContext from '../TargetsAppContext';

const AppNameDiv = styled.div`
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const ModalDiv = styled.div`
  padding: 32px;
`;

const ChangeNameModal = ({ close }: { close: () => void }) => {
  const { app } = useContext(TargetsAppContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { id: currentUserId } = useContext(CurrentUserContext);
  const [name, setName] = useState<string>(app.title);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const onSave = useCallback(() => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    const newApp = {
      ...app,
      title: name,
      updatedOn: getTimeStamp(),
      updatedBy: currentUserId,
    };

    STORE.contentDistributions
      .getTargetsApps({
        accountId: selectedAccountId,
      })
      .doc(app.id)
      .set(newApp)
      .then(() => {
        setIsLoading(false);
        close();
      });
  }, [app, close, currentUserId, isLoading, name, selectedAccountId]);

  const onNameChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }, []);

  return (
    <Modal onClose={close} shouldScrollInViewport={false} autoFocus={false}>
      <ModalDiv>
        <div style={{ marginBottom: 32 }}>
          <Typography.Header type="H5">Update App Name</Typography.Header>
          <div style={{ marginBottom: 4 }}>
            <Typography.Body type="Label">New Name</Typography.Body>
          </div>
          <Inputs.TextInput
            onChange={onNameChanged}
            value={name}
            data-testid={'name-input'}
          />
        </div>
        <Row spaceBetween>
          <Button onClick={close} label="Cancel" type="Tertiary" size="Small" />
          <Button
            onClick={onSave}
            label={'Update'}
            testId="save-name"
            type="Primary"
            size="Small"
          />
        </Row>
      </ModalDiv>
    </Modal>
  );
};

const AppName = () => {
  const { app } = useContext(TargetsAppContext);
  const { isOpen, open, close } = usePopup();

  return (
    <>
      <AppNameDiv onClick={open}>
        <Typography.Header type="H5">{app.title}</Typography.Header>
      </AppNameDiv>
      <ModalTransition>
        {isOpen && <ChangeNameModal close={close} />}
      </ModalTransition>
    </>
  );
};

export default AppName;
