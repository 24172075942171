import React from 'react';
import styled from 'styled-components';

import NoTasksImg from './noTasks.svg';
import Typography from 'kingpin/atoms/Typography';
import Row from '../Common/Row';
import FlexCentered from '../Common/FlexCentered';

const Img = styled.img`
  width: 130px;
  margin-bottom: 16px;
`;

const NoTasksYet = () => (
  <FlexCentered>
    <div>
      <div>
        <Img src={NoTasksImg} />
      </div>
      <Row style={{ justifyContent: 'center' }}>
        <Typography.Body type="Body 12">No tasks yet</Typography.Body>
      </Row>
    </div>
  </FlexCentered>
);

export default NoTasksYet;
