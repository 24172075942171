import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ExecutivePortalsContext } from '../../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider/ExecutivePortalsProvider';
import { EngagementPortalsContext } from '../../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider/EngagementPortalsProvider';

const useUrlSlug = (portal: EditablePortal | undefined) => {
  const { allExecutivePortals } = useContext(ExecutivePortalsContext);
  const { allEngagementPortals } = useContext(EngagementPortalsContext);

  const getReservedUrls = useCallback(() => {
    return [...allExecutivePortals, ...allEngagementPortals].map(
      (p) => p.urlSlug,
    );
  }, [allEngagementPortals, allExecutivePortals]);

  const [urlSlug, setUrlSlug] = useState<string>(portal ? portal.urlSlug : '');
  const [isUrlSlugValid, setIsUrlSlugValid] = useState(portal ? true : false);

  const onUrlSlugChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (portal) {
        return;
      }
      setUrlSlug(event.target.value);
    },
    [portal],
  );
  const getIsUrlSlugValid = useCallback(() => {
    if (portal) {
      return true;
    }
    const isReservedUrl = getReservedUrls().some((url) => url === urlSlug);
    const isEmpty = urlSlug === '';
    const hasBannedCharacters = /[^a-zA-Z/-]/g.test(urlSlug);

    return !(isReservedUrl || isEmpty || hasBannedCharacters);
  }, [getReservedUrls, portal, urlSlug]);

  useEffect(() => {
    setIsUrlSlugValid(getIsUrlSlugValid());
  }, [getIsUrlSlugValid]);

  return {
    urlSlug,
    setUrlSlug,
    onUrlSlugChanged,
    isUrlSlugValid,
  };
};

export default useUrlSlug;
