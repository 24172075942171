import { useContext, useMemo } from 'react';
import { EngagementPortalsContext } from '../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider/EngagementPortalsProvider';
import useGetMappedEntityViews from './useGetMappedEntityViews';

const usePortalWithMappedEntityId = (entityId: string | undefined) => {
  const { allEngagementPortals } = useContext(EngagementPortalsContext);
  const getMappedEntityViews = useGetMappedEntityViews();

  const portal = useMemo((): EngagementPortal | undefined => {
    if (!entityId) {
      return undefined;
    }

    return allEngagementPortals.find((p) => {
      const mappedEntities = getMappedEntityViews(p);
      return mappedEntities.some((e) => e.entityId === entityId);
    });
  }, [allEngagementPortals, entityId, getMappedEntityViews]);

  const navItem = useMemo((): PortalsNav.MappedEntityView | undefined => {
    if (!portal) {
      return undefined;
    }

    const mappedViews = getMappedEntityViews(portal);
    return mappedViews.find((m) => m.entityId === entityId);
  }, [entityId, getMappedEntityViews, portal]);

  return { portal, navItem };
};

export default usePortalWithMappedEntityId;
