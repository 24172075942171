import React, { useContext } from 'react';
import portalTypeCheckers from '../../../types/portalTypeCheckers';
import BonusPortalProvider from '../../BonusPortalProvider';
import { PortalsContext } from '../UserAndAccountProviders/PortalsProvider';

const SelectedPortalProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { selectedPortal } = useContext(PortalsContext);

  if (selectedPortal && portalTypeCheckers.isBonusPortal(selectedPortal)) {
    return (
      <BonusPortalProvider portal={selectedPortal}>
        {children}
      </BonusPortalProvider>
    );
  }

  return <>{children}</>;
};

export default SelectedPortalProvider;
