import React, { useContext } from 'react';
import { PortalsContext } from 'contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';
import portalTypeCheckers from 'types/portalTypeCheckers';
import AdminPortalNav from './AdminPortalNav';
import GeneralPortalNav from './GeneralPortalNav';

const SelectedPortalConfiguredNav = () => {
  const { selectedPortal, lastSelectedPortal } = useContext(PortalsContext);
  const portalToShow = selectedPortal ? selectedPortal : lastSelectedPortal;

  if (!portalToShow) {
    return null;
  } else if (portalTypeCheckers.isAdminPortal(portalToShow)) {
    return <AdminPortalNav />;
  } else {
    return <GeneralPortalNav portal={portalToShow} />;
  }
};

export default SelectedPortalConfiguredNav;
