import React from 'react';
import Inputs from '../../../../components/Inputs';
import portalNavItemTypeCheckers from '../../../../types/portalNavItemTypeCheckers';
import isDefined from '../../../../isDefined';

const DefaultScreenPicker = ({
  defaultScreen,
  setDefaultScreen,
  sideNavigationSections,
}: {
  defaultScreen:
    | {
        sectionKey: string;
        itemKey: string;
      }
    | undefined;
  setDefaultScreen: React.Dispatch<
    React.SetStateAction<
      | {
          sectionKey: string;
          itemKey: string;
        }
      | undefined
    >
  >;
  sideNavigationSections: PortalSideNavigationSection[];
}) => {
  const options = sideNavigationSections.reduce((a, b) => {
    const newOptions = b.items
      .map((item) => {
        if (portalNavItemTypeCheckers.isBonusPicker(item)) {
          return undefined;
        }

        return {
          key: item.label,
          label: item.label,
          isSelected:
            !!defaultScreen &&
            defaultScreen.itemKey === item.key &&
            defaultScreen.sectionKey === b.key,
          onSelected: () => {
            setDefaultScreen({
              itemKey: item.key,
              sectionKey: b.key,
            });
          },
        };
      })
      .filter(isDefined);

    return [...a, ...newOptions];
  }, [] as DropdownOption[]);

  return (
    <Inputs.Dropdown
      options={options}
      placeholder="Select a default screen"
      testId={'select-default-screen'}
    />
  );
};

export default DefaultScreenPicker;
