import React, { useCallback, useContext, useState } from 'react';
import AccountPickerContext from '../../../../contexts/AccountPickerContext';
import _ from 'lodash';
import portalTypeCheckers from '../../../../types/portalTypeCheckers';
import STORE from '../../../../store';
import Form from '../../../../kingpin/forms/Form';
import FormHeader from '../../../../kingpin/forms/FormHeader';
import FormContent from '../../../../kingpin/forms/FormContent';
import Dropdown from '../../../../components/Inputs/Dropdown';
import Row from '../../../../components/Common/Row';
import Button from '../../../../kingpin/atoms/Button';
import useUserOptions from './useUserOptions';
import SelectedUser from './SelectedUser';
import Loading from '../../../../components/Loading';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';
import getTimeStamp from '../../../../getTimeStamp';

const AddUsersPopup = ({
  portal,
  close,
}: {
  portal: ExecutivePortal | EngagementPortal;
  close: () => void;
}) => {
  const { id: currentUserId } = useContext(CurrentUserContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<
    (UserManagement.SignedUpUser | UserManagement.PendingUser)[]
  >([]);
  const { options, isLoadingOptions } = useUserOptions({
    selectedUsers,
    setSelectedUsers,
    usersIdsWithAccess: portal.usersIdsWithAccess,
  });

  const onSave = useCallback(() => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    const newUsersIdsWithAccess = _.uniq([
      ...portal.usersIdsWithAccess,
      ...selectedUsers.map((u) => u.id),
    ]);
    const newPortal: ExecutivePortal | EngagementPortal = {
      ...portal,
      usersIdsWithAccess: newUsersIdsWithAccess,
      updatedOn: getTimeStamp(),
      updatedBy: currentUserId,
    };

    if (portalTypeCheckers.isEngagementPortal(newPortal)) {
      STORE.contentDistributions
        .getEngagementPortalsRef({
          accountId: selectedAccountId,
        })
        .doc(portal.id)
        .set(newPortal)
        .then(() => {
          close();
        });
    } else if (portalTypeCheckers.isExecutivePortal(newPortal)) {
      STORE.contentDistributions
        .getExecutivePortalsRef({
          accountId: selectedAccountId,
        })
        .doc(portal.id)
        .set(newPortal)
        .then(() => {
          close();
        });
    }
  }, [
    close,
    currentUserId,
    isLoading,
    portal,
    selectedAccountId,
    selectedUsers,
  ]);

  if (isLoadingOptions) {
    return (
      <Form>
        <FormHeader
          title={`Add Executives to '${portal.name}'`}
          subTitle={`If you can't find a user, reach out to your admin`}
          onClose={close}
        />
        <FormContent>
          <Loading />
        </FormContent>
      </Form>
    );
  }

  return (
    <Form>
      <FormHeader
        title={`Add Executives to '${portal.name}'`}
        subTitle={`If you can't find a user, reach out to your admin`}
        onClose={close}
      />
      <FormContent>
        <Dropdown options={options} isSearchEnabled placeholder="Select User" />
        {selectedUsers.length > 0 && (
          <div style={{ marginTop: 24 }}>
            {selectedUsers.map((u) => (
              <SelectedUser
                user={u}
                key={u.id}
                setSelectedUsers={setSelectedUsers}
              />
            ))}
          </div>
        )}
      </FormContent>
      <Row spaceBetween centerAlign>
        <Button
          size={'Small'}
          type={'Secondary'}
          onClick={close}
          isDisabled={selectedUsers.length === 0 || isLoading}
          label={'Cancel'}
        />
        <Button
          size={'Small'}
          type={'Primary'}
          onClick={onSave}
          label={'Add Executives'}
          isLoading={isLoading}
          isDisabled={selectedUsers.length === 0}
        />
      </Row>
    </Form>
  );
};

export default AddUsersPopup;
