import React, { useContext, useEffect, useState } from 'react';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import STORE from '../../store';

type BonusBoardSignOffLookup = {
  [boardId: string]: Scoring.BoardSignOff | undefined;
};

export const BonusPortalBoardSignOffsContext = React.createContext<{
  signOffLookup: BonusBoardSignOffLookup;
  isLoading: boolean;
}>({ signOffLookup: {}, isLoading: true });

const BonusPortalBoardSignOffsProvider = ({
  children,
  portalId,
  selectedPeriodId,
}: {
  children: React.ReactNode;
  portalId: string;
  selectedPeriodId: string;
}) => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [signOffLookup, setSignOffLookup] = useState<BonusBoardSignOffLookup>(
    {},
  );

  useEffect(() => {
    let isActive = true;
    setIsLoading(true);
    setSignOffLookup({});
    const listener = STORE.contentDistributions
      .getBonusPortalBoardSignOffsRef({
        accountId: selectedAccountId,
        bonusPortalId: portalId,
      })
      .where('periodId', '==', selectedPeriodId)
      .onSnapshot((snapshot) => {
        if (!isActive) {
          return;
        }

        const newSignOffs: Scoring.BoardSignOff[] = [];
        snapshot.forEach((doc) => {
          newSignOffs.push(doc.data());
        });

        const newLookup: BonusBoardSignOffLookup = {};
        newSignOffs.forEach((s) => (newLookup[s.boardId] = s));

        setSignOffLookup(newLookup);
        setIsLoading(false);
      });

    return () => {
      isActive = false;
      listener();
    };
  }, [portalId, selectedAccountId, selectedPeriodId]);

  return (
    <BonusPortalBoardSignOffsContext.Provider
      value={{ signOffLookup, isLoading }}
    >
      {children}
    </BonusPortalBoardSignOffsContext.Provider>
  );
};

export default BonusPortalBoardSignOffsProvider;
