import React, { useContext, useEffect, useState } from 'react';
import usePopup from '../../../hooks/usePopup';
import ReasonCodesManagerPopup from '../../ReasonCodesManagerPopup';
import QuickFilters from './QuickFilters';
import Metrics from './Metrics';
import Columns from './Columns';
import BoardContext from '../../../contexts/BoardContext';
import appRoutes from '../../../navigation/appRoutes';
import deleteBoard from '../../../api/boards/deleteBoard';
import SettingsPopup from '../SettingsPopup';
import UploadData from './UploadData';
import WorkSpaceContext from '../../../contexts/WorkSpaceContext';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import isDriverPerformanceBoard from '../../../isDriverPerformanceBoard';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import PermissionGates from '../../PermissionGates';
import PERMISSIONS from '../../../permissionDefinitions';
import isPerformanceBoard from '../../../isPerformanceBoard';
import useGetUploadDataTypes from 'screens/DataManager/DatasetDefinitions/DatasetsIndex/FileUploads/hooks/useGetUploadDataTypes';
import FeatureGate, { FEATURE_GATES } from 'components/FeatureGate';
import { useNavigate } from 'react-router-dom';
import ContextMenu, { Option } from 'kingpin/atoms/ContextMenu';

const useIsUploadDataOptionAvailable = () => {
  const { isCampaignOwner } = useContext(WorkSpaceContext);
  const { isFleetOpsStaff, isAdmin } = useContext(CurrentUserContext);
  const { isBoardOwner, board } = useContext(BoardContext);
  const uploadTypes = isPerformanceBoard(board)
    ? window.emptyArray
    : board.uploadTypes || window.emptyArray;

  const getIsUploadDataOptionAvailable = () => {
    if (isDriverPerformanceBoard(board)) {
      return (
        (isBoardOwner || isCampaignOwner || isFleetOpsStaff) &&
        uploadTypes.length > 0
      );
    } else {
      return isAdmin && uploadTypes.length > 0;
    }
  };
  const isUploadDataOptionAvailable = getIsUploadDataOptionAvailable();

  return {
    isUploadDataOptionAvailable,
    uploadTypes,
  };
};

const RELEVANT_PERMISSIONS = [
  PERMISSIONS.WORKSPACES.ADD_REMOVE_TABS,
  PERMISSIONS.DATA_MANAGEMENT.UPLOAD_FILES,
  PERMISSIONS.CUSTOMER_COMMITMENTS.MANAGE_COMMITMENTS,
];

const SettingsContainer = () => {
  const nvigate = useNavigate();
  const { isDeletable, onDeleted, board } = useContext(BoardContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const { accountRef } = useContext(AccountPickerContext);
  const { isUploadDataOptionAvailable, uploadTypes } =
    useIsUploadDataOptionAvailable();

  const { dataTypes, isLoading } = useGetUploadDataTypes();

  const [uploadDataTypes, setUploadDataTypes] = useState<
    DataManager.UploadDataType[]
  >([]);
  const [selectedDataType, setSelectedDataType] =
    useState<DataManager.UploadDataType>();

  useEffect(() => {
    if (!isLoading) {
      setUploadDataTypes(
        dataTypes
          ? dataTypes.filter((dt) => uploadTypes.includes(dt.name))
          : [],
      );
    }
  }, [dataTypes, isLoading, uploadTypes]);

  const {
    isOpen: isQuickFiltersOpen,
    open: openQuickFilters,
    close: closeQuickFilters,
  } = usePopup();
  const {
    isOpen: isReasonCodesOpen,
    open: openReasonCodes,
    close: closeReasonCodes,
  } = usePopup();
  const {
    isOpen: isMetricsOpen,
    open: openMetrics,
    close: closeMetrics,
  } = usePopup();
  const {
    isOpen: isColumnsOpen,
    open: openColumns,
    close: closeColumns,
  } = usePopup();
  const {
    isOpen: isSettingsOpen,
    open: openSettings,
    close: closeSettings,
  } = usePopup();
  const {
    isOpen: isUploadDataOpen,
    open: openUploadData,
    close: closeUploadData,
  } = usePopup();

  const onUploadDataOpened = (dt: DataManager.UploadDataType) => {
    setSelectedDataType(dt);
    openUploadData();
    if (isDriverPerformanceBoard(board)) {
      trackEvent('Driver Bonus - Upload Data - Opened');
    }
  };

  const onDeleteClicked = () => {
    deleteBoard({ boardId: board.id, accountRef }).then(() => {
      if (onDeleted) {
        onDeleted();
      } else {
        nvigate(appRoutes.home);
      }
    });
  };

  return (
    <PermissionGates.HasAny requiredPermissions={RELEVANT_PERMISSIONS}>
      <ContextMenu testId={'board-context-menu'}>
        <>
          <PermissionGates.Has
            requiredPermission={PERMISSIONS.WORKSPACES.EDIT_BOARD_SETTINGS}
          >
            <>
              <Option
                onClick={() => {
                  openSettings();
                }}
                label="Settings"
              />
              <Option
                onClick={() => {
                  openQuickFilters();
                }}
                label="Quick Filters"
              />

              <Option
                onClick={() => {
                  openReasonCodes();
                }}
                label="Reason Codes"
              />
              <Option
                onClick={() => {
                  openColumns();
                }}
                label="Columns"
              />
              {isDeletable && (
                <Option
                  onClick={() => {
                    onDeleteClicked();
                  }}
                  label="Delete"
                />
              )}
            </>
          </PermissionGates.Has>
          <PermissionGates.Has
            requiredPermission={
              PERMISSIONS.CUSTOMER_COMMITMENTS.MANAGE_COMMITMENTS
            }
          >
            <>
              {board.category === 'Customer Lane Commitments' && (
                <Option
                  onClick={() => {
                    openMetrics();
                  }}
                  label="Metrics"
                />
              )}
            </>
          </PermissionGates.Has>
          <PermissionGates.Has
            requiredPermission={PERMISSIONS.DATA_MANAGEMENT.UPLOAD_FILES}
          >
            <FeatureGate featureName={FEATURE_GATES.FILE_UPLOAD}>
              <>
                {isUploadDataOptionAvailable &&
                  uploadDataTypes.map((dt) => (
                    <Option
                      key={dt.dataType}
                      onClick={() => {
                        onUploadDataOpened(dt);
                      }}
                      label={`Upload ${dt.name}`}
                      testId={`upload-data-${dt.dataType}`}
                    />
                  ))}
              </>
            </FeatureGate>
          </PermissionGates.Has>
        </>
      </ContextMenu>
      <QuickFilters isOpen={isQuickFiltersOpen} close={closeQuickFilters} />
      <ReasonCodesManagerPopup
        isOpen={isReasonCodesOpen}
        close={closeReasonCodes}
        dataType={board.dataType}
      />
      <Metrics isOpen={isMetricsOpen} close={closeMetrics} />
      <SettingsPopup isOpen={isSettingsOpen} close={closeSettings} />

      <Columns
        isOpen={isColumnsOpen}
        close={closeColumns}
        dataType={board.dataType}
      />
      <UploadData
        isOpen={isUploadDataOpen}
        close={closeUploadData}
        dataType={selectedDataType}
      />
    </PermissionGates.HasAny>
  );
};

export default SettingsContainer;
