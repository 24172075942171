import { useContext, useEffect, useState } from 'react';
import getEditorUserList from './getEditorUserList';
import CloudFunctionClientContext from '../../../../contexts/CloudFunctionClientContext';

const useEditors = () => {
  const { api } = useContext(CloudFunctionClientContext);
  const [editors, setEditors] = useState<
    (UserManagement.SignedUpUser | UserManagement.PendingUser)[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    getEditorUserList(api).then((users) => {
      setEditors(users);
      setIsLoading(false);
    });
  }, [api]);

  return {
    isLoading,
    editors,
  };
};

export default useEditors;
