import { useCallback, useContext, useEffect, useState } from 'react';
import UsersContext from '../../../contexts/UsersContext';
import userTypeCheckers from '../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/UsersProvider/userTypeCheckers';

const useCurrentManagers = (managerIds: string[]) => {
  const { allUsers } = useContext(UsersContext);

  const getCurrentManagers = useCallback((): (
    | UserManagement.SignedUpUser
    | UserManagement.PendingUser
  )[] => {
    return allUsers
      .filter((u) => managerIds.includes(u.id))
      .filter(userTypeCheckers.isSignedUpOrPendingUser);
  }, [allUsers, managerIds]);
  const [currentManagers, setCurrentManagers] = useState<
    (UserManagement.SignedUpUser | UserManagement.PendingUser)[]
  >(() => getCurrentManagers());

  useEffect(() => {
    setCurrentManagers(getCurrentManagers());
  }, [getCurrentManagers]);

  return currentManagers;
};

export default useCurrentManagers;
