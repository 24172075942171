import React, { useContext, useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import WorkSpaceReportWizard from './WorkSpaceReportWizard';
import ReportsContext from '../../../contexts/ReportsContext';
import setWorkSpace from '../../../api/workspaces/setWorkSpace';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import { isTargetsApp } from '../../../isWorkSpace';
import STORE from '../../../store';

const WorkSpaceReportWizardContainer = ({
  workSpace,
  onBackClicked,
  close,
}: {
  workSpace: WorkSpace | TargetsApp.App;
  onBackClicked: () => void;
  close: () => void;
}) => {
  const { accountRef, selectedAccountId } = useContext(AccountPickerContext);
  const { allReports } = useContext(ReportsContext);
  const currentUser = useContext(CurrentUserContext);
  const { trackEvent } = useContext(AnalyticsContext);

  const [selectedReport, setSelectedReport] = useState<
    ReportType | undefined
  >();
  const [options, setOptions] = useState<DropdownOption[]>([]);

  const isValid = !!selectedReport;

  const onAddClicked = () => {
    if (!isValid || !selectedReport) {
      return;
    }

    const newTab = {
      type: 'report' as 'report',
      typeId: selectedReport.id,
    };
    if (isTargetsApp(workSpace)) {
      const newApp = {
        ...workSpace,
        tabs: [...workSpace.tabs, newTab],
        updatedBy: currentUser.id,
        updatedOn: DateTime.utc().toISO(),
      };
      STORE.contentDistributions
        .getTargetsApps({ accountId: selectedAccountId })
        .doc(newApp.id)
        .set(newApp)
        .then(() => {
          trackEvent('Targets App - Tab added', { tabType: 'report' });
          close();
        });
    } else {
      const newWorkSpace = {
        ...workSpace,
        tabs: [...workSpace.tabs, newTab],
        updatedBy: currentUser.id,
        updatedOn: DateTime.utc().toISO(),
      } as WorkSpace;
      setWorkSpace(newWorkSpace, accountRef).then(() => {
        trackEvent('Workspace - Tab added', { tabType: 'report' });
        close();
      });
    }
  };

  useEffect(() => {
    const unusedReports = allReports.filter(
      (r) =>
        !workSpace.tabs.some((t) => t.type === 'report' && t.typeId === r.id),
    );

    const newOptions: DropdownOption[] = unusedReports
      .map((r) => ({
        key: r.id,
        label: r.name,
        isSelected: !!selectedReport && r.id === selectedReport.id,
        onSelected: () => {
          setSelectedReport(r);
        },
      }))
      .sort((a, b) => {
        const aL = a.label.toLowerCase();
        const bL = b.label.toLowerCase();

        if (aL > bL) {
          return 1;
        } else if (aL === bL) {
          return 0;
        } else {
          return -1;
        }
      });
    setOptions(newOptions);
  }, [allReports, selectedReport, workSpace.tabs]);

  return (
    <WorkSpaceReportWizard
      options={options}
      onBackClicked={onBackClicked}
      onAddClicked={onAddClicked}
      isValid={isValid}
    />
  );
};

export default WorkSpaceReportWizardContainer;
