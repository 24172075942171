import { useCallback, useContext, useEffect, useState } from 'react';
import DashboardsContext from 'contexts/DashboardsContext';
import EntityDefinitionsContext from 'contexts/EntityDefinitionsContext';
import {
  buildShowEntityApp,
  buildShowEntityAppContentTab,
} from 'navigation/appRoutes';
import BoardsContext from '../../../contexts/BoardsContext';
import ReportsContext from '../../../contexts/ReportsContext';
import isPerformanceBoard from '../../../isPerformanceBoard';
import { PortalsContext } from '../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';
import DatasetDefinitionsContext from '../../../contexts/DatasetDefinitionsContext';
import { useParams } from 'react-router-dom';

export const useBuildEntityLink = (
  entity: EntityDetails.Entity | undefined,
) => {
  const { boards } = useContext(BoardsContext);
  const { allReports } = useContext(ReportsContext);
  const { allDashboards } = useContext(DashboardsContext);
  const { entityDetailsApps } = useContext(EntityDefinitionsContext);
  const { selectedPortal } = useContext(PortalsContext);
  const { bonusPrograms } = useContext(DatasetDefinitionsContext);
  const navParams = useParams<{
    field?: string;
    tabType?: string;
    tabId?: string;
  }>();

  /**
   * If we are currently in an entity app, we want to keep the selected tab
   * active. This function will return the relevant link for the active
   * tab, if we are currently in an entity app
   */
  const getInAppLink = useCallback(
    (value: string): string | undefined => {
      const { field, tabId, tabType } = navParams;
      if (entity && entity.primaryField !== field) {
        return undefined;
      }

      if (field && tabId && tabType) {
        return buildShowEntityAppContentTab({
          field,
          tabType,
          tabId,
          portal: selectedPortal,
          value,
        });
      }

      return undefined;
    },
    [entity, navParams, selectedPortal],
  );

  const buildLink = useCallback(
    (value: string, justCreatedApp?: EntityDetails.App) => {
      if (!entity) {
        return undefined;
      }
      const inAppLink = getInAppLink(value);
      if (inAppLink) {
        return inAppLink;
      }

      const app = justCreatedApp
        ? justCreatedApp
        : entityDetailsApps.find((a) => a.entityId === entity.id);
      if (!app) {
        return undefined;
      }

      const performanceBoards = boards.filter(isPerformanceBoard);
      const firstTab = app.tabs.find((t) => {
        if (t.type === 'dashboard') {
          return !!allDashboards.find((d) => d.id === t.typeId);
        } else if (t.type === 'report') {
          return !!allReports.find((r) => r.id === t.typeId);
        } else if (t.type === 'board') {
          return !!performanceBoards.filter((b) => b.id === t.typeId);
        } else if (t.type === 'driver-scoring-program-report') {
          return bonusPrograms.some((b) => b.id === t.typeId);
        } else if (t.type === 'driver-details-tab') {
          return true;
        }
        return undefined;
      });
      if (firstTab) {
        return buildShowEntityAppContentTab({
          field: entity.primaryField,
          value,
          tabType: firstTab.type,
          tabId: firstTab.typeId,
          portal: selectedPortal,
        });
      }
      return buildShowEntityApp({
        field: entity.primaryField,
        value,
        portal: selectedPortal,
      });
    },
    [
      allDashboards,
      allReports,
      boards,
      bonusPrograms,
      entity,
      entityDetailsApps,
      getInAppLink,
      selectedPortal,
    ],
  );

  return buildLink;
};

const useEntityLink = ({
  entity,
  value,
}: {
  entity: EntityDetails.Entity;
  value: string;
}) => {
  const buildLink = useBuildEntityLink(entity);

  const [link, setLink] = useState<string | undefined>(() => buildLink(value));
  useEffect(() => {
    setLink(buildLink(value));
  }, [buildLink, value]);

  return link;
};

export default useEntityLink;
