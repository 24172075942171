import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import ShowTopBarTabs from './ShowTopBarTabs';
import ReportsContext from '../../../contexts/ReportsContext';
import DashboardsContext from '../../../contexts/DashboardsContext';
import useBoards from '../../../hooks/useBoards';
import isDefined from '../../../isDefined';
import FeatureGatesContext from '../../../contexts/FeatureGatesContext';
import {
  buildShowTargetAppContentTab,
  buildShowWorkSpace,
  buildShowWorkSpaceTab,
} from '../../../navigation/appRoutes';
import ScorecardsContext from 'contexts/ScorecardsContext';
import useIsScrolling from 'components/ShadowedList/useIsScrolling';
import WorkSpaceContext from 'contexts/WorkSpaceContext';
import { PortalsContext } from '../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';
import { isTargetsApp } from '../../../isWorkSpace';

export const buildTabLink = ({
  workSpace,
  tab,
  portal,
}: {
  workSpace?: WorkSpace | TargetsApp.App;
  tab?: WorkSpaceTab | TargetsApp.PersistedTab;
  portal?: Portal;
}): string => {
  if (!workSpace) {
    return buildShowWorkSpace('');
  }

  if (!tab) {
    return buildShowWorkSpace(workSpace.id);
  }

  if (isTargetsApp(workSpace)) {
    return buildShowTargetAppContentTab({
      targetAppId: workSpace.id,
      tabType: tab.type,
      tabId: tab.typeId,
      portal,
    });
  }

  return buildShowWorkSpaceTab({
    workSpaceId: workSpace.id,
    tabType: tab.type,
    tabId: tab.typeId,
  });
};

export const useEnrichedTabs = (
  tabs: WorkSpaceTab[],
  workSpace?: WorkSpace,
) => {
  const { selectedPortal } = useContext(PortalsContext);
  const { allReports } = useContext(ReportsContext);
  const { allDashboards } = useContext(DashboardsContext);
  const { scorecards } = useContext(ScorecardsContext);
  const boards = useBoards();
  const [enrichedTabs, setEnrichedTabs] = useState<EnrichedWorkSpaceTab[]>([]);

  useEffect(() => {
    const newEnrichedTabs = tabs
      .map((tab) => {
        const item = (() => {
          if (tab.type === 'report') {
            return allReports.find((r) => r.id === tab.typeId);
          } else if (tab.type === 'scorecards') {
            return scorecards.find((sc) => sc.id === tab.typeId);
          } else if (tab.type === 'dashboard') {
            return allDashboards.find((d) => d.id === tab.typeId);
          } else if (tab.type === 'board') {
            return boards.find((b) => b.id === tab.typeId);
          }
        })();

        const link = buildTabLink({ workSpace, tab, portal: selectedPortal });

        if (item) {
          return {
            ...tab,
            name: 'name' in item ? item.name : item.title,
            link,
          };
        } else if (tab.type === 'goals') {
          return {
            ...tab,
            name: 'Goals',
            link,
          };
        } else if (tab.type === 'tasks') {
          return {
            ...tab,
            name: 'Tasks',
            link,
          };
        } else if (tab.type === 'scorecards') {
          return {
            ...tab,
            name: 'KPI Lists',
            link,
          };
        } else {
          return undefined;
        }
      })
      .filter(isDefined);
    setEnrichedTabs(newEnrichedTabs);
  }, [
    allDashboards,
    allReports,
    boards,
    scorecards,
    selectedPortal,
    tabs,
    workSpace,
  ]);

  return {
    enrichedTabs,
  };
};

const ShowTopBarTabsContainer = ({ workSpace }: { workSpace: WorkSpace }) => {
  const { tabs } = workSpace;
  const { isTaskTabHighlighted } = useContext(WorkSpaceContext);
  const { enrichedTabs } = useEnrichedTabs(tabs, workSpace);
  const { isDriverBonusSummaryEnabled } = useContext(FeatureGatesContext);
  const isBonusTabAvailable =
    workSpace.campaignType === 'driverBonus' && isDriverBonusSummaryEnabled;
  const scrollerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const taskTabRef = useRef<HTMLDivElement | null>(null);
  const isScrolling = useIsScrolling({
    scrollerRef,
    contentRef,
    isHorizontalScroll: true,
  });
  const [isLeftVisible, setIsLeftVisible] = useState<boolean>(false);
  const [isRightVisible, setIsRightVisible] = useState<boolean>(false);

  useEffect(() => {
    setIsRightVisible(isScrolling);
  }, [isScrolling]);

  useEffect(() => {
    if (
      isTaskTabHighlighted === true &&
      taskTabRef.current &&
      !!taskTabRef.current.scrollIntoView
    ) {
      taskTabRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  }, [isTaskTabHighlighted]);

  const moveTabs = useCallback(({ right }: { right: boolean }) => {
    const tabsScroller = scrollerRef.current;
    if (!tabsScroller) {
      return;
    }
    const tabsScrollerWidth = tabsScroller.offsetWidth;
    const direction = right ? 1 : -1;

    tabsScroller.scrollBy({
      top: 0,
      left: tabsScrollerWidth * direction * 0.7,
      behavior: 'smooth',
    });

    setIsLeftVisible(
      tabsScroller.scrollLeft + tabsScrollerWidth * direction * 0.7 > 0,
    );
    setIsRightVisible(
      tabsScroller.scrollLeft +
        tabsScrollerWidth * direction * 0.7 +
        tabsScrollerWidth <
        tabsScroller.scrollWidth,
    );
  }, []);

  return (
    <ShowTopBarTabs
      workSpaceId={workSpace.id}
      tabs={enrichedTabs}
      isBonusTabAvailable={isBonusTabAvailable}
      moveTabs={moveTabs}
      isLeftVisible={isLeftVisible}
      isRightVisible={isRightVisible}
      scrollerRef={scrollerRef}
      contentRef={contentRef}
      isTaskTabHighlighted={isTaskTabHighlighted}
      taskTabRef={taskTabRef}
    />
  );
};

export default ShowTopBarTabsContainer;
