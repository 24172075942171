import { useCallback, useContext, useMemo, useState } from 'react';
import STORE from '../../../store';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import usePopup from '../../../hooks/usePopup';
import RemovePortalAccessConfirmation from './RemovePortalAccessConfirmation';
import getTimeStamp from '../../../getTimeStamp';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import portalTypeCheckers from '../../../types/portalTypeCheckers';
import portalNavItemTypeCheckers from '../../../types/portalNavItemTypeCheckers';
import isDefined from '../../../isDefined';

const useRemovePortalAccessConfirmation = ({
  portal,
  type,
  typeId,
  contentName,
}: {
  portal: ExecutivePortal | EngagementPortal | BonusPortal;
  type: ExecutivePortalPublishableContentType;
  typeId: string;
  contentName: string;
}) => {
  const { id: currentUserId } = useContext(CurrentUserContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { isOpen, open, close } = usePopup();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onRemoveConfirmed = useCallback(async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    const newNavSections: PortalSideNavigationSection[] =
      portal.navigationSettings.sections.map((section) => {
        return {
          ...section,
          items: section.items
            .map((i) => {
              if (
                portalNavItemTypeCheckers.isReport(i) &&
                type === 'report' &&
                i.reportId === typeId
              ) {
                if (portal.navigationSettings.defaultScreen.itemKey === i.key) {
                  alert(
                    `This content was not removed from ${portal.name} as it is the default screen`,
                  );
                  return i;
                }

                return undefined;
              }
              if (
                portalNavItemTypeCheckers.isReportsIndex(i) &&
                type === 'report' &&
                i.reportIds.includes(typeId)
              ) {
                return {
                  ...i,
                  reportIds: i.reportIds.filter((rid) => rid !== typeId),
                };
              }
              if (
                portalNavItemTypeCheckers.isDashboard(i) &&
                type === 'dashboard' &&
                i.dashboardId === typeId
              ) {
                if (portal.navigationSettings.defaultScreen.itemKey === i.key) {
                  alert(
                    `This content was not removed from ${portal.name} as it is the default screen`,
                  );
                  return i;
                }

                return undefined;
              }
              if (
                portalNavItemTypeCheckers.isDashboardsIndex(i) &&
                type === 'dashboard' &&
                i.dashboardIds.includes(typeId)
              ) {
                return {
                  ...i,
                  dashboardIds: i.dashboardIds.filter((dId) => dId !== typeId),
                };
              }
              if (
                portalNavItemTypeCheckers.isScorecard(i) &&
                type === 'scorecard' &&
                i.scorecardId === typeId
              ) {
                if (portal.navigationSettings.defaultScreen.itemKey === i.key) {
                  alert(
                    `This content was not removed from ${portal.name} as it is the default screen`,
                  );
                  return i;
                }

                return undefined;
              }
              if (
                portalNavItemTypeCheckers.isScorecardsIndex(i) &&
                type === 'scorecard' &&
                i.scorecardIds.includes(typeId)
              ) {
                return {
                  ...i,
                  scorecardIds: i.scorecardIds.filter((sId) => sId !== typeId),
                };
              }

              return i;
            })
            .filter(isDefined),
        };
      });

    const newPortal: ExecutivePortal | EngagementPortal | BonusPortal = {
      ...portal,
      navigationSettings: {
        ...portal.navigationSettings,
        sections: newNavSections,
      },
      updatedBy: currentUserId,
      updatedOn: getTimeStamp(),
    };

    if (portalTypeCheckers.isEngagementPortal(newPortal)) {
      await STORE.contentDistributions
        .getEngagementPortalsRef({ accountId: selectedAccountId })
        .doc(newPortal.id)
        .set(newPortal);
    } else if (portalTypeCheckers.isExecutivePortal(newPortal)) {
      await STORE.contentDistributions
        .getExecutivePortalsRef({ accountId: selectedAccountId })
        .doc(newPortal.id)
        .set(newPortal);
    } else if (portalTypeCheckers.isBonusPortal(newPortal)) {
      await STORE.contentDistributions
        .getBonusPortalsRef({ accountId: selectedAccountId })
        .doc(newPortal.id)
        .set(newPortal);
    }

    setIsLoading(false);
  }, [currentUserId, isLoading, portal, selectedAccountId, type, typeId]);

  const RemoveConfirmation = useMemo(
    () => (
      <RemovePortalAccessConfirmation
        isOpen={isOpen}
        close={close}
        portal={portal}
        onRemoveConfirmed={onRemoveConfirmed}
        isLoading={isLoading}
        type={type}
        contentName={contentName}
      />
    ),
    [close, contentName, isLoading, isOpen, onRemoveConfirmed, portal, type],
  );

  return {
    RemoveConfirmation,
    onRemoveClicked: open,
  };
};

export default useRemovePortalAccessConfirmation;
