import React, { useCallback } from 'react';
import styled from 'styled-components';

import usePopup from 'hooks/usePopup';
import InlineDialog from 'components/InlineDialog';
import Typography from 'kingpin/atoms/Typography';
import Row from 'components/Common/Row';
import DragAndDropList from 'components/DragAndDropList';
import DragHandle from 'components/DragAndDropList/DragHandle';
import Button from 'kingpin/atoms/Button';
import Card from '../../Common/Card';
import Colors2 from '../../../theme/Colors2';

const PopupDiv = styled.div`
  padding: 24px;
`;

const TabsListDiv = styled.div``;

export const TabItemDiv = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  padding: 0px 16px;
  height: 48px;
  background-color: white;
  border: 1px solid ${Colors2.Border};
`;

const TabItemLeftDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
`;

const Tab = <Tab extends TabTypes.BaseTab>({
  tab,
  onTabRemoved,
  isMovable,
}: {
  tab: Tab;
  onTabRemoved?: (t: Tab) => void;
  isMovable: boolean;
}) => {
  return (
    <TabItemDiv>
      <TabItemLeftDiv>
        <div style={{ marginRight: 8 }}>
          {!isMovable && (
            <div style={{ marginRight: 8 }}>
              <Button
                icon="pushPin"
                onClick={window.tokenFunction}
                isDisabled
                type="Ghost"
                size="Small"
              />
            </div>
          )}
          {isMovable && <DragHandle testId={`drag-${tab.key}`} />}
        </div>
        <Typography.Body type="Body 12" isEllipsis>
          {tab.name}
        </Typography.Body>
      </TabItemLeftDiv>
      {onTabRemoved && (
        <Button
          size={'Small'}
          type={'Ghost'}
          onClick={() => onTabRemoved(tab)}
          testId={`delete-${tab.key}`}
          icon="cross"
        />
      )}
    </TabItemDiv>
  );
};

const Popup = <Tab extends TabTypes.BaseTab>({
  onOrderChanged,
  onTabRemoved,
  fixedTabs,
  variableTabs,
}: {
  onOrderChanged: (newOrder: Tab[]) => void;
  onTabRemoved: (tab: Tab) => void;
  fixedTabs: Tab[];
  variableTabs: Tab[];
}) => {
  return (
    <PopupDiv>
      <Row style={{ marginBottom: 16 }}>
        <Typography.Header type="H5">{`Tabs (${
          fixedTabs.length + variableTabs.length
        })`}</Typography.Header>
      </Row>
      <TabsListDiv>
        {fixedTabs.map((t) => (
          <Tab key={t.key} tab={t} isMovable={false} />
        ))}
        <DragAndDropList
          items={variableTabs}
          onOrderChanged={onOrderChanged}
          droppableId={'tabs'}
          renderItem={(tab) => (
            <Tab tab={tab} onTabRemoved={onTabRemoved} isMovable />
          )}
        />
      </TabsListDiv>
    </PopupDiv>
  );
};

const ManageTabsButton = <Tab extends TabTypes.BaseTab>({
  onOrderChanged,
  onTabRemoved,
  fixedTabs,
  variableTabs,
}: {
  onOrderChanged: (newOrder: Tab[]) => void;
  onTabRemoved: (tab: Tab) => void;
  fixedTabs: Tab[];
  variableTabs: Tab[];
}) => {
  const { isOpen, open, close } = usePopup();
  const onClick = useCallback(() => {
    if (isOpen) {
      close();
    } else {
      open();
    }
  }, [close, isOpen, open]);

  return (
    <>
      <InlineDialog
        placement="bottom-end"
        isOpen={isOpen}
        onClose={close}
        content={
          <Popup
            fixedTabs={fixedTabs}
            variableTabs={variableTabs}
            onOrderChanged={onOrderChanged}
            onTabRemoved={onTabRemoved}
          />
        }
      >
        <Button
          type="Tertiary"
          size="Small"
          onClick={onClick}
          icon="chevron-down"
          testId="manage-tabs-button"
        />
      </InlineDialog>
    </>
  );
};

export default ManageTabsButton;
