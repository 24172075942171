import React from 'react';
import RelatedDocuments from './RelatedDocuments';
import useRelatedDocuments from './useRelatedDocuments';

const RelatedDocumentsContainer = ({
  section,
}: {
  section: PerformanceBoardTypes.SlideOut.RelatedDocumentsSection;
}) => {
  const { isLoading, documents } = useRelatedDocuments({ section });

  return (
    <RelatedDocuments
      isLoading={isLoading}
      elasticDocuments={documents}
      section={section}
    />
  );
};

export default RelatedDocumentsContainer;
