import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import getFilterValues from 'api/getFilterValues';
import GqlClientContext from 'contexts/GqlClientContext';
import AccountPickerContext from 'contexts/AccountPickerContext';
import useEntity from 'hooks/useEntity';

const usePrimaryFieldValueOptions = ({
  entityId,
  primaryFieldValue,
  setPrimaryFieldValue,
  additionalPrimaryFieldValues,
}: {
  entityId: string | undefined;
  primaryFieldValue: string | undefined;
  setPrimaryFieldValue: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  additionalPrimaryFieldValues: string[];
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { client } = useContext(GqlClientContext);
  const { selectedAccountId: accountId } = useContext(AccountPickerContext);
  const { entity } = useEntity(entityId);
  const [primaryFieldValues, setPrimaryFieldValues] = useState<string[]>([]);

  const getPrimaryFieldValues = useCallback(
    async ({
      field,
      dataset,
    }: {
      field: string;
      dataset: string;
    }): Promise<string[]> => {
      return getFilterValues({
        field,
        accountId,
        dateScope: {},
        client,
        filterInput: {
          dataType: [dataset],
        },
      });
    },
    [accountId, client],
  );

  useEffect(() => {
    if (!entity) {
      return;
    }
    let isActive = true;
    setIsLoading(true);
    getPrimaryFieldValues({
      field: entity.primaryField,
      dataset: entity.entityDataset,
    }).then((newValues) => {
      if (!isActive) {
        return;
      }

      setPrimaryFieldValues(newValues);
      setIsLoading(false);
    });

    return () => {
      isActive = false;
    };
  }, [entity, getPrimaryFieldValues]);

  const primaryFieldValueOptions = useMemo(() => {
    return primaryFieldValues
      .filter((value) => !additionalPrimaryFieldValues.includes(value))
      .map((fieldValue) => ({
        label: fieldValue,
        isSelected: fieldValue === primaryFieldValue,
        onSelected: () => {
          setPrimaryFieldValue(fieldValue);
        },
      }));
  }, [
    additionalPrimaryFieldValues,
    primaryFieldValue,
    primaryFieldValues,
    setPrimaryFieldValue,
  ]);

  return {
    primaryFieldValueOptions,
    isLoadingOptions: isLoading,
  };
};

export default usePrimaryFieldValueOptions;
