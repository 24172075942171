import React, { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalTransition } from '@atlaskit/modal-dialog';

import AccountPickerContext from 'contexts/AccountPickerContext';
import usePopup from 'hooks/usePopup';
import ContextMenu, { Option } from 'kingpin/atoms/ContextMenu';
import useConfirmation from 'screens/DataManager/DatasetDefinitions/DatasetsIndex/PerformanceDatasetWizard/useConfirmation';
import STORE from 'store';
import appRoutes from 'navigation/appRoutes';

import PerformanceBoardCopyPopup from './PerformanceBoardCopyPopup';
import PerformanceBoardSettings from '../PerformanceBoardSettings';
import FleetOpsStaffOnly from '../../Common/FleetOpsStaffOnly';

const BoardContextMenu = ({
  board,
}: {
  board: PerformanceBoardTypes.Board;
}) => {
  const navigate = useNavigate();
  const { selectedAccountId } = useContext(AccountPickerContext);
  const {
    isOpen: isSettingsOpen,
    open: openSettings,
    close: closeSettings,
  } = usePopup();
  const { isOpen: isCopyOpen, open: openCopy, close: closeCopy } = usePopup();

  const { getConfirmation, ConfirmationModal } = useConfirmation({
    title: `Delete ${board.name}?`,
    confirmText: 'Delete',
  });

  const onDeleteClicked = useCallback(async () => {
    const isConfirmed = await getConfirmation();
    if (!isConfirmed) {
      return;
    }
    await STORE.contentDistributions
      .getPerformanceBoardsRef({
        accountId: selectedAccountId,
      })
      .doc(board.id)
      .update({ isHidden: true });
    navigate(appRoutes.loggedIn.boards);
  }, [board.id, getConfirmation, navigate, selectedAccountId]);

  return (
    <>
      <ContextMenu testId={'board-context-menu'}>
        <Option label={'Settings'} onClick={openSettings} />
        <FleetOpsStaffOnly>
          <>
            <Option label={'Copy'} onClick={openCopy} />
            <Option label={'Delete'} onClick={onDeleteClicked} />
          </>
        </FleetOpsStaffOnly>
      </ContextMenu>
      <ModalTransition>
        {isSettingsOpen && <PerformanceBoardSettings close={closeSettings} />}
      </ModalTransition>
      <FleetOpsStaffOnly>
        <ModalTransition>
          {isCopyOpen && (
            <PerformanceBoardCopyPopup board={board} close={closeCopy} />
          )}
        </ModalTransition>
      </FleetOpsStaffOnly>
      {ConfirmationModal}
    </>
  );
};

export default BoardContextMenu;
