import React from 'react';
import FeatureGate, { FEATURE_GATES } from 'components/FeatureGate';
import GlobalSearch from 'components/GlobalSearch';
import AppRoutes from 'navigation/appRoutes';

import NavSection from '../../NavSection';
import MainNavFavouritesSection from '../../MainNavFavouritesSection';
import NavItem from '../../NavItem';
import PortalPicker from '../../PortalPicker';
import SelectedPortalConfiguredNav from '../SelectedPortalConfiguredNav';

const AnalyticsAppNav = () => {
  return (
    <>
      <NavSection>
        <FeatureGate featureName={FEATURE_GATES.ENTITY_DETAILS}>
          <GlobalSearch />
        </FeatureGate>
        <MainNavFavouritesSection />
        <NavItem
          to={AppRoutes.loggedIn.workspaces}
          label={'Workspaces'}
          tooltip={'Workspaces'}
          icon={'workspace'}
          onClickEvent={'Navigation Sidebar - Clicked Workspaces'}
        />
        <NavItem
          to={AppRoutes.loggedIn.shared}
          label={'Shared'}
          tooltip={'Shared'}
          icon={'shared'}
          onClickEvent={'Navigation Sidebar - Clicked Shared'}
        />
      </NavSection>
      <NavSection hideBorderBottom>
        <PortalPicker isExecOnly />
      </NavSection>
      <SelectedPortalConfiguredNav />
    </>
  );
};

export default AnalyticsAppNav;
