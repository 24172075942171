import React, { useContext, useMemo } from 'react';
import TasksProvider from 'contextProviders/TasksProvider/LocalTasksProvider';
import Row from 'components/Common/Row';
import TasksCard from 'components/WorkSpaces/TasksCard';
import LocalTimelineProvider from 'contextProviders/TimelineProvider/LocalTimelineProvider';
import Content from 'components/Common/ScreenLayout/Content';
import NavSectionContext from 'navigation/NavSectionContext';
import { ScreenWrapper } from 'navigation/styles';
import portalNavItemTypeCheckers from 'types/portalNavItemTypeCheckers';

import { PORTAL_RESOURCE } from '../../constants';
import _ from 'lodash';
import DashboardsContext from '../../contexts/DashboardsContext';
import isDefined from '../../isDefined';
import cardTypeCheckers from '../../types/cardTypeCheckers';

const PortalTasks = ({
  portal,
}: {
  portal: ExecutivePortal | EngagementPortal | BonusPortal;
}) => {
  const { allDashboards } = useContext(DashboardsContext);
  const { navSection } = useContext(NavSectionContext);

  const navItem = useMemo((): PortalsNav.Tasks | undefined => {
    if (!navSection) {
      return undefined;
    }

    return navSection.items.find(portalNavItemTypeCheckers.isTasks);
  }, [navSection]);

  const scorecardsOnPortal = useMemo((): string[] => {
    const scorecardIndexNavItems = portal.navigationSettings.sections.reduce(
      (acc, section) => {
        const sectionItems = section.items.filter(
          portalNavItemTypeCheckers.isScorecardsIndex,
        );
        return [...acc, ...sectionItems];
      },
      [] as PortalsNav.ScorecardsIndex[],
    );

    const directScorecardIds = scorecardIndexNavItems.reduce((acc, item) => {
      return [...acc, ...item.scorecardIds];
    }, [] as string[]);

    const indirectDashboardIds = portal.navigationSettings.sections.reduce(
      (acc, section) => {
        const sectionDashboardIds: string[] = [];
        section.items.forEach((item) => {
          if (portalNavItemTypeCheckers.isDashboard(item)) {
            sectionDashboardIds.push(item.dashboardId);
          }
          if (portalNavItemTypeCheckers.isDashboardsIndex(item)) {
            sectionDashboardIds.push(...item.dashboardIds);
          }
        });
        return [...acc, ...sectionDashboardIds];
      },
      [] as string[],
    );

    const indirectScorecardIds = indirectDashboardIds
      .map((id) => allDashboards.find((d) => d.id === id))
      .filter(isDefined)
      .reduce((acc, dashboard) => {
        const dashboardScorecardIds = dashboard.canvas.cards
          .filter(cardTypeCheckers.isScorecard)
          .map((card) => card.content.scorecardId);

        return [...acc, ...dashboardScorecardIds];
      }, [] as string[]);

    const scorecardIds = [...directScorecardIds, ...indirectScorecardIds];

    const uniqScorecardIds = _.uniq(scorecardIds);

    return uniqScorecardIds;
  }, [allDashboards, portal.navigationSettings.sections]);

  const goalsOnPortal = useMemo((): string[] => {
    const goalsNavItems = portal.navigationSettings.sections.reduce(
      (acc, section) => {
        const sectionItems = section.items.filter(
          portalNavItemTypeCheckers.isGoalsIndex,
        );
        return [...acc, ...sectionItems];
      },
      [] as PortalsNav.GoalsIndex[],
    );

    const goalIds = goalsNavItems.reduce((acc, item) => {
      return [...acc, ...item.goalIds];
    }, [] as string[]);

    const uniqGoalIds = _.uniq(goalIds);

    return uniqGoalIds;
  }, [portal.navigationSettings.sections]);

  const taskDestination = useMemo((): Tasks.TaskSource | undefined => {
    if (!navItem) {
      return undefined;
    }

    return {
      type: PORTAL_RESOURCE,
      id: navItem.key,
    };
  }, [navItem]);

  const taskSources = useMemo((): Tasks.TaskSource[] => {
    if (!taskDestination || !navItem) {
      return [];
    }

    const sources: Tasks.TaskSource[] = [];
    sources.push(taskDestination);
    if (navItem.includeGoalTasks) {
      goalsOnPortal.forEach((goalId) => {
        sources.push({ type: 'Goal', id: goalId });
      });
    }

    if (navItem.includeScorecardTasks) {
      scorecardsOnPortal.forEach((scorecardId) => {
        sources.push({ type: 'Scorecard', id: scorecardId });
      });
    }

    return sources;
  }, [goalsOnPortal, navItem, scorecardsOnPortal, taskDestination]);

  if (!taskDestination) {
    return null;
  }

  return (
    <ScreenWrapper>
      <LocalTimelineProvider
        sources={taskSources}
        destination={taskDestination}
      >
        <TasksProvider
          destination={taskDestination}
          sources={taskSources}
          assignableUserIds={portal.usersIdsWithAccess}
        >
          <Content style={{ flex: 1, width: '100%', padding: 16 }}>
            <Row
              style={{
                display: 'flex',
                minHeight: 'calc(50% - 8px)',
                width: '100%',
                flex: 1,
                justifyContent: 'center',
              }}
            >
              <TasksCard />
            </Row>
          </Content>
        </TasksProvider>
      </LocalTimelineProvider>
    </ScreenWrapper>
  );
};

export default PortalTasks;
