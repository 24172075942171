import React from 'react';
import TextInput from '../../../kingpin/atoms/TextInput';

const GoalSearchBar = ({
  searchText,
  setSearchText,
}: {
  searchText: string;
  setSearchText: (text: string) => void;
}) => (
  <TextInput
    placeholder="Search"
    icon="search"
    inputSize="Small"
    onChange={(event) => setSearchText(event.target.value)}
    value={searchText}
    autoFocus
  />
);

export default GoalSearchBar;
