import React, { useContext } from 'react';

import NavSideBarContext from 'contexts/NavSideBarContext';
import Row from 'components/Common/Row';
import Colors2 from 'theme/Colors2';
import styled from 'styled-components';

import MainNavBottom from '../../MainNavBottom';
import NAVIGATION from '../../constants';
import FavoritesSection from '../../FavoritesSection';
import AppsNavTop from './AppsNavTop';
import { AppsContext } from '../../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/AppsProvider';
import AnalyticsAppNav from './AnalyticsAppNav';
import ContentManagementNav from './ContentManagementNav';
import SystemSettingsNav from './SystemSettingsNav';
import SelectedPortalConfiguredNav from '../SelectedPortalConfiguredNav';

export const MainNavDiv = styled.div<{ isCollapsed: boolean }>`
  width: ${(props) =>
    props.isCollapsed
      ? NAVIGATION.COLLAPSED_NAV_WIDTH
      : NAVIGATION.OPENED_NAV_WIDTH}px;
  min-width: ${(props) =>
    props.isCollapsed
      ? NAVIGATION.COLLAPSED_NAV_WIDTH
      : NAVIGATION.OPENED_NAV_WIDTH}px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #fbfbfb;
  justify-content: space-between;
  border-right: 1px solid ${Colors2.Border};
`;

export const MainNavPrimaryContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const SelectedContentNav = () => {
  const { selectedContent } = useContext(AppsContext);

  if (!selectedContent) {
    return null;
  }

  if (selectedContent.type === 'App') {
    if (selectedContent.app.type === 'Analytics') {
      return <AnalyticsAppNav />;
    }

    if (selectedContent.app.type === 'General') {
      return <SelectedPortalConfiguredNav />;
    }

    return null;
  }

  if (selectedContent.type === 'Content Management') {
    return <ContentManagementNav />;
  }

  if (selectedContent.type === 'System Settings') {
    return <SystemSettingsNav />;
  }

  return null;
};

/**
 *
 * This is our latest product offering, powered by {@link AppsProvider}
 *
 */
const AppsMainNav = () => {
  const { isOpen } = useContext(NavSideBarContext);

  return (
    <Row>
      <MainNavDiv isCollapsed={!isOpen}>
        <MainNavPrimaryContent>
          <AppsNavTop />
          <SelectedContentNav />
        </MainNavPrimaryContent>
        <MainNavBottom isSettingsHidden />
      </MainNavDiv>
      <FavoritesSection />
    </Row>
  );
};

export default AppsMainNav;
