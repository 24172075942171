const isExecutivePortal = (
  portal: Portal | undefined,
): portal is ExecutivePortal => !!portal && portal.type === 'ExecutivePortal';

const isEngagementPortal = (
  portal: Portal | undefined,
): portal is EngagementPortal =>
  !!portal && portal.type === 'Engagement Portal';

const isAdminPortal = (portal: Portal | undefined): portal is AdminPortal =>
  !!portal && portal.type === 'Admin Portal';

const isBonusPortal = (portal: Portal | undefined): portal is BonusPortal =>
  !!portal && portal.type === 'Bonus Portal';

const isEditablePortal = (
  portal: Portal | undefined,
): portal is EditablePortal => !isAdminPortal(portal);

const portalTypeCheckers = {
  isExecutivePortal,
  isEngagementPortal,
  isAdminPortal,
  isBonusPortal,
  isEditablePortal,
};

export default portalTypeCheckers;
