import React, { useContext, useState } from 'react';
import GoalsList from './GoalsList';
import GoalFilteringContext from '../../../contexts/GoalFilteringContext';

const GoalsListContainer = () => {
  const { filteredGoals } = useContext(GoalFilteringContext);
  const [mode, setMode] = useState<'list' | 'card'>('card');

  return (
    <GoalsList filteredGoals={filteredGoals} mode={mode} setMode={setMode} />
  );
};

export default GoalsListContainer;
