import React, { useCallback, useContext, useState } from 'react';
import { ExecutivePortalsContext } from 'contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider/ExecutivePortalsProvider';
import useAdminPortal from 'contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider/useAdminPortal';

import PortalOption from './PortalOption';
import PortalSearchInput from './PortalSearchInput';
import CreatePortalButton from './CreatePortalButton';
import { OPENED_NAV_WIDTH } from '../../../navigation/styles';
import Row from '../../../components/Common/Row';
import Typography from '../../atoms/Typography';
import Button from '../../atoms/Button';
import { PortalsContext } from '../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';
import { EngagementPortalsContext } from '../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider/EngagementPortalsProvider';
import { BonusPortalsContext } from '../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider/BonusPortalsProvider';
import { AppsContext } from '../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/AppsProvider';

const MIN_WIDTH = OPENED_NAV_WIDTH - 18;

const AdminPortalLink = ({ close }: { close: () => void }) => {
  const { onPortalSelected } = useContext(PortalsContext);

  const adminPortal = useAdminPortal();

  const onClick = useCallback(() => {
    if (!adminPortal) {
      return;
    }
    onPortalSelected(adminPortal);
    close();
  }, [adminPortal, close, onPortalSelected]);

  if (!adminPortal) {
    return null;
  }

  return (
    <Button
      size={'Small'}
      type={'Ghost'}
      onClick={onClick}
      label="Admin"
      iconAfter="settings-filled"
    />
  );
};

const PortalPickerDialog = ({
  close,
  openPortalForm,
  isExecOnly,
}: {
  close: () => void;
  openPortalForm: () => void;
  isExecOnly?: boolean;
}) => {
  const { availableExecutivePortals } = useContext(ExecutivePortalsContext);
  const { availableEngagementPortals } = useContext(EngagementPortalsContext);
  const { availableBonusPortals } = useContext(BonusPortalsContext);
  const { hasAppsEnabled } = useContext(AppsContext);

  const [searchText, setSearchText] = useState('');
  const onSearchTextChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [],
  );

  const availablePortals = isExecOnly
    ? availableExecutivePortals
    : [
        ...availableExecutivePortals,
        ...availableEngagementPortals,
        ...availableBonusPortals,
      ];

  const hasNoPortals = availablePortals.length === 0;

  if (hasAppsEnabled) {
    return (
      <div
        style={{
          minWidth: MIN_WIDTH,
        }}
      >
        <Row centerAlign spaceBetween style={{ padding: '8px 8px 0px 8px' }}>
          <Typography.Body type="Body Bold">Select A Portal</Typography.Body>
          {!hasAppsEnabled && <AdminPortalLink close={close} />}
        </Row>
        <PortalSearchInput
          searchText={searchText}
          onSearchTextChanged={onSearchTextChanged}
        />
        <div style={{ maxHeight: 256, overflowY: 'scroll', marginTop: 8 }}>
          <div style={{ borderTop: '1px solid #EEEFF1' }}>
            {availableExecutivePortals.map((portal) => (
              <PortalOption portal={portal} key={portal.id} close={close} />
            ))}
          </div>
          {hasNoPortals && (
            <div
              style={{
                borderTop: '1px solid #EEEFF1',
                width: MIN_WIDTH,
                padding: 8,
              }}
            >
              <Typography.Body type="Body Bold">
                No portals have been assigned to you yet.
              </Typography.Body>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        minWidth: MIN_WIDTH,
      }}
    >
      <Row centerAlign spaceBetween style={{ padding: '8px 8px 0px 8px' }}>
        <Typography.Body type="Body Bold">Select A Portal</Typography.Body>
        {!hasAppsEnabled && <AdminPortalLink close={close} />}
      </Row>
      <PortalSearchInput
        searchText={searchText}
        onSearchTextChanged={onSearchTextChanged}
      />
      <div style={{ maxHeight: 256, overflowY: 'scroll', marginTop: 8 }}>
        {availableExecutivePortals.length > 0 && (
          <div style={{ borderTop: '1px solid #EEEFF1' }}>
            <div
              style={{
                paddingLeft: '8px',
                borderBottom: '1px solid #EEEFF1',
                height: 34,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Typography.Body type="Button Text" color="#808080">
                Executive
              </Typography.Body>
            </div>
            {availableExecutivePortals.map((portal) => (
              <PortalOption portal={portal} key={portal.id} close={close} />
            ))}
          </div>
        )}
        {!isExecOnly && availableEngagementPortals.length > 0 && (
          <div style={{ borderTop: '1px solid #EEEFF1' }}>
            <div
              style={{
                paddingLeft: '8px',
                borderBottom: '1px solid #EEEFF1',
                height: 34,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Typography.Body type="Button Text" color="#808080">
                Engagement
              </Typography.Body>
            </div>
            {availableEngagementPortals.map((portal) => (
              <PortalOption portal={portal} key={portal.id} close={close} />
            ))}
          </div>
        )}
        {!isExecOnly && availableBonusPortals.length > 0 && (
          <div style={{ borderTop: '1px solid #EEEFF1' }}>
            <div
              style={{
                paddingLeft: '8px',
                borderBottom: '1px solid #EEEFF1',
                height: 34,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Typography.Body type="Button Text" color="#808080">
                Bonus
              </Typography.Body>
            </div>
            {availableBonusPortals.map((portal) => (
              <PortalOption portal={portal} key={portal.id} close={close} />
            ))}
          </div>
        )}

        {hasNoPortals && (
          <div
            style={{
              borderTop: '1px solid #EEEFF1',
              width: MIN_WIDTH,
              padding: 8,
            }}
          >
            <Typography.Body type="Body Bold">
              No portals have been assigned to you yet.
            </Typography.Body>
          </div>
        )}
      </div>
      {!hasAppsEnabled && (
        <CreatePortalButton
          closePortalPicker={close}
          openPortalForm={openPortalForm}
        />
      )}
    </div>
  );
};

export default PortalPickerDialog;
