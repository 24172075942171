import React, { useCallback } from 'react';
import aguid from 'aguid';
import getIdentifier from '../../../../getIdentifier';

const useSetDefaultPortalTypeNavigation = ({
  setSideNavigationSections,
  setDefaultScreen,
}: {
  setSideNavigationSections: React.Dispatch<
    React.SetStateAction<PortalSideNavigationSection[]>
  >;
  setDefaultScreen: React.Dispatch<
    React.SetStateAction<
      | {
          sectionKey: string;
          itemKey: string;
        }
      | undefined
    >
  >;
}) => {
  const setDefaultExecNavigation = useCallback(() => {
    const newNav: PortalSideNavigationSection[] = [];
    newNav.push({
      title: {
        isEnabled: true,
        label: 'View',
      },
      isBottomBorderEnabled: true,
      portalTypeSpecificSettings: undefined,
      key: aguid(),
      urlSlug: 'view',
      items: [
        {
          type: 'Dashboards Index',
          icon: 'dashboard',
          label: 'Dashboards',
          key: getIdentifier(),
          dashboardIds: [],
        },
      ],
    });
    newNav.push({
      title: {
        isEnabled: true,
        label: 'Build',
      },
      isBottomBorderEnabled: true,
      portalTypeSpecificSettings: undefined,
      key: getIdentifier(),
      urlSlug: 'build',
      items: [
        {
          type: 'Users',
          icon: 'group',
          key: getIdentifier(),
          label: 'Users',
        },
      ],
    });

    const newDefault: { sectionKey: string; itemKey: string } = {
      sectionKey: newNav[0].key,
      itemKey: newNav[0].items[0].key,
    };

    setSideNavigationSections(newNav);
    setDefaultScreen(newDefault);
  }, [setDefaultScreen, setSideNavigationSections]);

  const setDefaultEngagementNavigation = useCallback(() => {
    const newNav: PortalSideNavigationSection[] = [];
    newNav.push({
      title: {
        isEnabled: true,
        label: 'View',
      },
      isBottomBorderEnabled: true,
      portalTypeSpecificSettings: undefined,
      key: aguid(),
      urlSlug: 'view',
      items: [
        {
          type: 'Dashboards Index',
          icon: 'dashboard',
          label: 'Dashboards',
          key: getIdentifier(),
          dashboardIds: [],
        },
      ],
    });
    newNav.push({
      title: {
        isEnabled: true,
        label: 'Build',
      },
      isBottomBorderEnabled: true,
      portalTypeSpecificSettings: undefined,
      key: getIdentifier(),
      urlSlug: 'build',
      items: [
        {
          type: 'Users',
          icon: 'group',
          key: getIdentifier(),
          label: 'Users',
        },
      ],
    });

    const newDefault: { sectionKey: string; itemKey: string } = {
      sectionKey: newNav[0].key,
      itemKey: newNav[0].items[0].key,
    };

    setSideNavigationSections(newNav);
    setDefaultScreen(newDefault);
  }, [setDefaultScreen, setSideNavigationSections]);

  const setDefaultBonusNavigation = useCallback(() => {
    setSideNavigationSections([]);
    setDefaultScreen(undefined);
  }, [setDefaultScreen, setSideNavigationSections]);

  const setDefaultPortalTypeNavigation = useCallback(
    (portalType: 'ExecutivePortal' | 'Engagement Portal' | 'Bonus Portal') => {
      if (portalType === 'ExecutivePortal') {
        setDefaultExecNavigation();
      } else if (portalType === 'Engagement Portal') {
        setDefaultEngagementNavigation();
      } else if (portalType === 'Bonus Portal') {
        setDefaultBonusNavigation();
      }
    },
    [
      setDefaultBonusNavigation,
      setDefaultEngagementNavigation,
      setDefaultExecNavigation,
    ],
  );

  return setDefaultPortalTypeNavigation;
};

export default useSetDefaultPortalTypeNavigation;
