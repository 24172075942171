import React from 'react';
import usePopup from '../../../../../hooks/usePopup';
import InlineDialog from '../../../../../components/InlineDialog';
import SwitcherButton from './SwitcherButton';
import AppSwitcherPopup from './AppSwitcherPopup';

const AppSwitcher = () => {
  const { isOpen, open, close } = usePopup();

  return (
    <InlineDialog
      content={<AppSwitcherPopup close={close} />}
      onClose={close}
      isOpen={isOpen}
      isAnchorDisplayFlex
    >
      <SwitcherButton onClick={isOpen ? close : open} isOpen={isOpen} />
    </InlineDialog>
  );
};

export default AppSwitcher;
