const PERCENTAGE_FIELDS = ['percent'];
const isPercentageField = (field: string) =>
  PERCENTAGE_FIELDS.some((f) => field.toLowerCase().includes(f.toLowerCase()));

const CURRENCY_FIELDS = ['revenue', 'charge', 'pay'];
const isCurrencyField = (field: string) =>
  CURRENCY_FIELDS.some((f) => field.toLowerCase().includes(f.toLowerCase())) ||
  field === 'margin' ||
  field === 'rate';

const isDescription = (field: string) =>
  field.toLowerCase().includes('description');

export const buildFormatting = (
  field: string,
  type: FleetOps.FieldType,
): MetricFormatting => {
  switch (type) {
    case 'date':
      return {
        prefix: undefined,
        postfix: 'duration',
        precision: undefined,
        positiveDeltaIsGood: undefined,
      };
    case 'float':
    case 'long':
    case 'int':
      return {
        prefix: isPercentageField(field)
          ? undefined
          : isCurrencyField(field)
            ? 'currency'
            : undefined,
        postfix: isPercentageField(field) ? 'percentage' : undefined,
        precision: type === 'int' || type === 'long' ? 0 : 2,
        positiveDeltaIsGood: true,
      };
    case 'geo_point': {
      return {
        prefix: undefined,
        postfix: undefined,
        precision: 8,
        positiveDeltaIsGood: undefined,
      };
    }
    default:
      return {
        prefix: undefined,
        postfix: undefined,
        precision: undefined,
        positiveDeltaIsGood: undefined,
      };
  }
};

const buildBaseFieldViewDefault = (
  field: FleetOps.Field,
  dataset: FleetOps.DatasetDefinition,
  scoringProgram: Scoring.BonusProgram | undefined,
): FleetOps.BaseViewField => {
  switch (field.type) {
    case 'boolean':
      const cellType: FleetOps.CellType | undefined = !scoringProgram
        ? undefined
        : scoringProgram.statusFields.some((f) => f.field === field.field)
          ? 'Scoring Status Field'
          : undefined;
      const base = {
        aggFunc: undefined,
        description: undefined,
        formatting: buildFormatting(field.field, field.type),
        nameAlias: field.alias,
        isVisible: true,
        type: field.type,
        dataset: dataset.type,
        field: field.field,
      };
      if (cellType) {
        return {
          ...base,
          cellType,
        };
      }

      return base;
    case 'date':
      return {
        aggFunc: undefined,
        description: undefined,
        formatting: buildFormatting(field.field, field.type),
        cellType: 'Date',
        dateFormat: field.field.toLowerCase().includes('date')
          ? 'MMM/DD/YY'
          : 'MMM/DD/YY HH:mm',
        nameAlias: field.alias,
        isVisible: true,
        type: field.type,
        dataset: dataset.type,
        field: field.field,
      };
    case 'float':
    case 'long':
    case 'int':
      return {
        aggFunc: isDescription(field.field)
          ? undefined
          : field.aggFuncs.find((f) => f === 'sum'),
        description: undefined,
        formatting: buildFormatting(field.field, field.type),
        precision: field.type === 'int' || field.type === 'long' ? 0 : 2,
        cellType: isPercentageField(field.field)
          ? 'Percentage'
          : isCurrencyField(field.field)
            ? 'Currency'
            : 'Number',
        nameAlias: field.alias,
        isVisible: true,
        type: field.type,
        dataset: dataset.type,
        field: field.field,
      };
    case 'geo_point':
      return {
        aggFunc: undefined,
        description: undefined,
        formatting: buildFormatting(field.field, field.type),
        precision: 8,
        cellType: 'GeoPoint',
        nameAlias: field.alias,
        isVisible: true,
        type: field.type,
        dataset: dataset.type,
        field: field.field,
        geoPointDisplay: 'long, lat',
      };
    case 'text':
    default:
      return {
        aggFunc: isDescription(field.field)
          ? undefined
          : field.aggFuncs.find((f) => f === 'count'),
        description: undefined,
        formatting: buildFormatting(field.field, field.type),
        nameAlias: field.alias,
        isVisible: true,
        type: field.type,
        dataset: dataset.type,
        field: field.field,
      };
  }
};

export default buildBaseFieldViewDefault;
