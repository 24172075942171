import React from 'react';
import NavSection from '../../NavSection';
import FeatureGate, { FEATURE_GATES } from '../../../../components/FeatureGate';
import GlobalSearch from '../../../../components/GlobalSearch';
import MainNavFavouritesSection from '../../MainNavFavouritesSection';
import NavItem from '../../NavItem';
import AppRoutes from '../../../../navigation/appRoutes';
import AdminPortalNav from '../SelectedPortalConfiguredNav/AdminPortalNav';

const ContentManagementNav = () => {
  return (
    <>
      <NavSection>
        <FeatureGate featureName={FEATURE_GATES.ENTITY_DETAILS}>
          <GlobalSearch />
        </FeatureGate>
        <MainNavFavouritesSection />
        <NavItem
          to={AppRoutes.loggedIn.workspaces}
          label={'Workspaces'}
          tooltip={'Workspaces'}
          icon={'workspace'}
          onClickEvent={'Navigation Sidebar - Clicked Workspaces'}
        />
        <NavItem
          to={AppRoutes.loggedIn.shared}
          label={'Shared'}
          tooltip={'Shared'}
          icon={'shared'}
          onClickEvent={'Navigation Sidebar - Clicked Shared'}
        />
      </NavSection>
      <AdminPortalNav />
    </>
  );
};

export default ContentManagementNav;
