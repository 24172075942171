import React, { useContext, useMemo } from 'react';
import Row from 'components/Common/Row';
import { useLocation } from 'react-router-dom';
import NavSideBarContext from 'contexts/NavSideBarContext';
import useEntity from 'hooks/useEntity';
import useEntityLink from 'components/GlobalSearch/hooks/useEntityLink';

import MainNavTop from '../MainNavTop';
import MainNavBottom from '../MainNavBottom';
import { MainNavDiv, MainNavPrimaryContent } from './PortalsMainNav';
import NavItem from '../NavItem';
import usePortalWithMappedEntityId from '../../../hooks/portals/usePortalWithMappedEntityId';

const MappingNavItem = ({
  testId,
  label,
  entity,
  primaryFieldValue,
  index,
}: {
  testId: string;
  label: string;
  entity: EntityDetails.Entity;
  primaryFieldValue: string;
  index: number;
}) => {
  const { pathname } = useLocation();

  const entityLink = useEntityLink({
    entity,
    value: primaryFieldValue,
  });

  const isActive = useMemo(() => {
    if (!entity) {
      return false;
    }

    return (
      pathname.includes(`entities/${entity.primaryField}`) ||
      pathname.includes(`entity/${entity.id}`)
    );
  }, [entity, pathname]);

  const icon = useMemo((): Kingpin.Icon => {
    if (index === 0) {
      return 'navigation-metrics';
    }

    if (index === 1) {
      return 'database';
    }

    return 'navigation-metrics';
  }, [index]);

  if (!entityLink) {
    return null;
  }

  return (
    <NavItem
      testId={testId}
      to={entityLink}
      isActiveOverride={isActive}
      label={label}
      icon={icon}
    />
  );
};

const MappingNavItemGate = ({
  mapping,
  index,
}: {
  mapping: UserManagement.EngagementContentMapping;
  index: number;
}) => {
  const { entity } = useEntity(mapping.entityId);
  const { portal, navItem } = usePortalWithMappedEntityId(mapping.entityId);

  if (!entity || !portal || !navItem) {
    return null;
  }

  return (
    <MappingNavItem
      testId={`${navItem}-ready`}
      label={navItem.label}
      entity={entity}
      primaryFieldValue={mapping.primaryFieldValue}
      index={index}
    />
  );
};

const MappedViews = ({
  contentSettings,
}: {
  contentSettings: UserManagement.EngagementContentSettings;
}) => {
  return (
    <>
      {contentSettings.mappings.map((mapping, index) => (
        <MappingNavItemGate
          mapping={mapping}
          key={`${mapping.entityId}-${mapping.primaryFieldValue}`}
          index={index}
        />
      ))}
    </>
  );
};

const EngagementModeNav = ({
  contentSettings,
}: {
  contentSettings: UserManagement.EngagementContentSettings;
}) => {
  const { isOpen } = useContext(NavSideBarContext);

  return (
    <Row>
      <MainNavDiv isCollapsed={!isOpen}>
        <MainNavPrimaryContent>
          <MainNavTop />
          <MappedViews contentSettings={contentSettings} />
        </MainNavPrimaryContent>
        <MainNavBottom />
      </MainNavDiv>
    </Row>
  );
};

export default EngagementModeNav;
