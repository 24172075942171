import React from 'react';
import NavSection from '../../NavSection';
import PortalNavItem from '../PortalNavItem';

const GeneralPortalNav = ({
  portal,
}: {
  portal: ExecutivePortal | EngagementPortal | BonusPortal;
}) => {
  return (
    <>
      {portal.navigationSettings.sections.map((section) => (
        <NavSection
          label={section.title.isEnabled ? section.title.label : undefined}
          hideBorderBottom={!section.isBottomBorderEnabled}
          key={section.key}
        >
          {section.items.map((item) => (
            <PortalNavItem
              portal={portal}
              item={item}
              key={item.key}
              section={section}
            />
          ))}
        </NavSection>
      ))}
    </>
  );
};

export default GeneralPortalNav;
