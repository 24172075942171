import React, { useCallback, useEffect, useState } from 'react';
import useEditors from './useEditors';

const useUserOptions = ({
  selectedUsers,
  setSelectedUsers,
  usersIdsWithAccess,
}: {
  selectedUsers: (UserManagement.SignedUpUser | UserManagement.PendingUser)[];
  setSelectedUsers: React.Dispatch<
    React.SetStateAction<
      (UserManagement.SignedUpUser | UserManagement.PendingUser)[]
    >
  >;
  usersIdsWithAccess: string[];
}) => {
  const { isLoading, editors } = useEditors();

  const getPossibleUsers = useCallback((): (
    | UserManagement.SignedUpUser
    | UserManagement.PendingUser
  )[] => {
    return editors.filter((u) => !usersIdsWithAccess.includes(u.id));
  }, [editors, usersIdsWithAccess]);

  const buildOptions = useCallback((): DropdownOption[] => {
    return getPossibleUsers().map((user) => {
      return {
        label: user.displayName,
        isSelected: selectedUsers.some((u) => u.id === user.id),
        onSelected: () => {
          setSelectedUsers((current) => [...current, user]);
        },
      };
    });
  }, [getPossibleUsers, selectedUsers, setSelectedUsers]);
  const [options, setOptions] = useState<DropdownOption[]>(() =>
    buildOptions(),
  );
  useEffect(() => {
    setOptions(buildOptions());
  }, [buildOptions]);

  return {
    isLoadingOptions: isLoading,
    options,
  };
};

export default useUserOptions;
