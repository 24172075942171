import STORE from '../../store';

const setTask = (task: Tasks.Task, accountId: string) => {
  return STORE.getTasksRef({
    accountId,
  })
    .doc(task.id)
    .set(task);
};

export default setTask;
