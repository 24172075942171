import React, { useCallback, useContext, useState } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';

import AnalyticsContext from '../../contexts/AnalyticsContext';
import usePopup from '../../hooks/usePopup';
import LocalTasksContext from '../../contexts/Tasks/LocalTasksContext';
import TasksList from './TasksList';
import TaskPopup from './TaskPopup';
import WorkSpaceContext from '../../contexts/WorkSpaceContext';

const TasksListContainer = ({ isFullWidth }: { isFullWidth?: boolean }) => {
  const localTasksContext = useContext(LocalTasksContext);
  const { tasks, isLoading, isNoTasks, isAllTasksComplete } = localTasksContext;
  const { trackEvent } = useContext(AnalyticsContext);
  const { workSpace } = useContext(WorkSpaceContext);
  const { isOpen, open, close } = usePopup();
  const [selectedTask, setSelectedTask] = useState<Tasks.Task | undefined>();

  const onOpen = useCallback(
    (task: Tasks.Task) => {
      setSelectedTask(task);
      open();
      const workSpaceType = workSpace ? workSpace.campaignType : undefined;
      trackEvent('Task - Opened', {
        taskType: task.taskableType,
        workSpaceType,
      });
    },
    [open, trackEvent, workSpace],
  );

  const onClose = useCallback(() => {
    setSelectedTask(undefined);
    close();
  }, [close]);

  return (
    <>
      <TasksList
        isLoading={isLoading}
        tasks={tasks}
        onOpen={onOpen}
        isNoTasks={isNoTasks}
        isAllTasksComplete={isAllTasksComplete}
        isFullWidth={isFullWidth}
      />
      <ModalTransition>
        {isOpen && selectedTask && (
          <Modal
            onClose={onClose}
            shouldScrollInViewport={false}
            autoFocus={false}
            width={1000}
          >
            <LocalTasksContext.Provider
              value={{
                ...localTasksContext,
                destination: {
                  type: selectedTask.taskableType,
                  id: selectedTask.taskableId,
                },
              }}
            >
              <TaskPopup task={selectedTask} onClose={onClose} />
            </LocalTasksContext.Provider>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default TasksListContainer;
