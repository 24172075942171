import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import AppRoutes from '../../navigation/appRoutes';
import Icon from '../atoms/Icon';
import NavSideBarContext from '../../contexts/NavSideBarContext';
import NAVIGATION from './constants';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import usePopup from '../../hooks/usePopup';
import Tooltip from '../../components/Tooltip';
import Colors2 from '../../theme/Colors2';
import { Z_INDEX } from '../../constants';
import useIsEngagementMode from '../../hooks/useIsEngagementMode';
import useEntity from '../../hooks/useEntity';
import useEntityLink from '../../components/GlobalSearch/hooks/useEntityLink';
import { CurrentUserContentSettingsContext } from '../../contextProviders/SplashScreenProviders/UserAndAccountProviders/CurrentUserContentSettingsProvider';

const MainNavTopDiv = styled.div<{ isCollapsed: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) =>
    props.isCollapsed ? 'center' : 'space-between'};
  background-color: #fbfbfb;
  padding: 0px 12px;
  margin-bottom: 9px;
  height: ${NAVIGATION.TOP_HEIGHT}px;
  border-bottom: solid 1px ${Colors2.Border};
`;

const BrandingDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }

  svg {
    height: 21px !important;
  }
`;

const Collapse = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;
  svg {
    width: 20px !important;
    height: 20px !important;
  }

  &:hover {
    opacity: 0.7;
  }
`;

const FloatingExpandButton = styled.div`
  position: absolute;
  left: ${NAVIGATION.COLLAPSED_NAV_WIDTH - 10}px;
  top: 16px;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  background-color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${Z_INDEX.TEXT_GADGET};
  &:hover {
    background-color: #f4f4f4;
  }
`;

const EngagementModeHome = ({
  entity,
  engagementContentSettings,
  mapping,
}: {
  engagementContentSettings: UserManagement.EngagementContentSettings;
  entity: EntityDetails.Entity;
  mapping: UserManagement.EngagementContentMapping;
}) => {
  const entityLink = useEntityLink({
    entity,
    value: mapping.primaryFieldValue,
  });
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    if (entityLink) {
      navigate(entityLink);
    }
  }, [entityLink, navigate]);

  return (
    <BrandingDiv onClick={onClick}>
      <Icon icon={'branding-small'} />
    </BrandingDiv>
  );
};

const EngagementModeHomeGate = ({
  engagementContentSettings,
  mapping,
}: {
  engagementContentSettings: UserManagement.EngagementContentSettings;
  mapping: UserManagement.EngagementContentMapping;
}) => {
  const { entity, app } = useEntity(
    engagementContentSettings.mappings[0].entityId,
  );

  if (!entity || !app || !mapping) {
    return (
      <BrandingDiv>
        <Icon icon={'branding-small'} />
      </BrandingDiv>
    );
  }

  return (
    <EngagementModeHome
      engagementContentSettings={engagementContentSettings}
      entity={entity}
      mapping={mapping}
    />
  );
};

const NormalHome = () => {
  const { trackEvent } = useContext(AnalyticsContext);
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    trackEvent('Navigation Sidebar - Clicked Home');
    navigate(AppRoutes.home);
  }, [navigate, trackEvent]);

  return (
    <BrandingDiv onClick={onClick}>
      <Icon icon={'branding-small'} />
    </BrandingDiv>
  );
};

const Home = () => {
  const { contentSettings } = useContext(CurrentUserContentSettingsContext);

  if (contentSettings.mode === 'engagement') {
    // TODO: We need a solution for which to use here...
    // It may become more clear after we build the nav for users
    // who have multiple mappings...
    const mapping = contentSettings.mappings[0];
    if (!mapping) {
      return null;
    }

    return (
      <EngagementModeHomeGate
        engagementContentSettings={contentSettings}
        mapping={mapping}
      />
    );
  }

  return <NormalHome />;
};

/**
 * This gets used in the Legacy rollout and also in Portal rollouts.
 * In an Apps rollout, we don't use this
 */
const MainNavTop = () => {
  const { trackEvent } = useContext(AnalyticsContext);
  const navigate = useNavigate();
  const { isOpen, open, close } = useContext(NavSideBarContext);
  const { isEngagementMode, isMultipleMappings } = useIsEngagementMode();

  const {
    isOpen: isCollapseVisible,
    open: showCollapse,
    close: hideCollapse,
  } = usePopup();
  const onClick = useCallback(() => {
    trackEvent('Navigation Sidebar - Clicked Home');
    navigate(AppRoutes.home);
  }, [navigate, trackEvent]);
  const onCollapseClicked = useCallback(() => {
    close();
    hideCollapse();
  }, [close, hideCollapse]);

  if (!isOpen) {
    return (
      <MainNavTopDiv
        isCollapsed={true}
        onMouseEnter={
          isEngagementMode && !isMultipleMappings ? undefined : showCollapse
        }
        onMouseLeave={
          isEngagementMode && !isMultipleMappings ? undefined : hideCollapse
        }
      >
        <Home />
        {isCollapseVisible && (
          <FloatingExpandButton onClick={open}>
            <Tooltip content="Expand">
              <Icon icon={'chevron-right'} width={16} height={16} />
            </Tooltip>
          </FloatingExpandButton>
        )}
      </MainNavTopDiv>
    );
  } else {
    return (
      <MainNavTopDiv isCollapsed={false}>
        <BrandingDiv onClick={onClick}>
          <Icon icon={'branding-large'} width={100} />
        </BrandingDiv>
        <Tooltip content="Collapse">
          <Collapse onClick={onCollapseClicked}>
            <Icon
              icon={'left-panel-close'}
              color="#A5A5A5"
              width={24}
              height={24}
            />
          </Collapse>
        </Tooltip>
      </MainNavTopDiv>
    );
  }
};

export default MainNavTop;
