const isReport = (item: PortalsNav.Item): item is PortalsNav.Report =>
  item.type === 'Report';

const isDashboard = (item: PortalsNav.Item): item is PortalsNav.Dashboard =>
  item.type === 'Dashboard';

const isScorecard = (item: PortalsNav.Item): item is PortalsNav.Scorecard =>
  item.type === 'Scorecard';

const isEntityApp = (item: PortalsNav.Item): item is PortalsNav.EntityApp =>
  item.type === 'Entity App';

const isReportsIndex = (
  item: PortalsNav.Item,
): item is PortalsNav.ReportsIndex => item.type === 'Reports Index';

const isDashboardsIndex = (
  item: PortalsNav.Item,
): item is PortalsNav.DashboardsIndex => item.type === 'Dashboards Index';

const isScorecardsIndex = (
  item: PortalsNav.Item,
): item is PortalsNav.ScorecardsIndex => item.type === 'Scorecards Index';

const isSignOffBoard = (
  item: PortalsNav.Item,
): item is PortalsNav.SignOffBoard => item.type === 'Sign Off Board';

const isBoard = (item: PortalsNav.Item): item is PortalsNav.Board =>
  item.type === 'Board';

const isBonusHome = (item: PortalsNav.Item): item is PortalsNav.BonusHome =>
  item.type === 'Bonus Home';

const isBonusPicker = (
  item: PortalsNav.Item,
): item is PortalsNav.BonusPeriodPicker => item.type === 'Bonus Period Picker';

const isDriverScoringProgramReport = (
  item: PortalsNav.Item,
): item is PortalsNav.DriverScoringProgramReport =>
  item.type === 'Driver Scoring Program Report';

const isScoringProgramConfigOverview = (
  item: PortalsNav.Item,
): item is PortalsNav.ScoringProgramConfigOverview =>
  item.type === 'Scoring Program Config Overview';

const isMappedEntityView = (
  item: PortalsNav.Item,
): item is PortalsNav.MappedEntityView => item.type === 'Mapped Entity View';

const isUsers = (item: PortalsNav.Item): item is PortalsNav.Users =>
  item.type === 'Users';

const isTargetsOverview = (
  item: PortalsNav.Item,
): item is PortalsNav.TargetsOverview => item.type === 'Targets Overview';

const isTargetManager = (
  item: PortalsNav.Item,
): item is PortalsNav.TargetsManager => item.type === 'Targets Manager';

const isGoalsIndex = (item: PortalsNav.Item): item is PortalsNav.GoalsIndex =>
  item.type === 'Goals Index';

const isTasks = (item: PortalsNav.Item): item is PortalsNav.Tasks =>
  item.type === 'Tasks';

const portalNavItemTypeCheckers = {
  isReport,
  isDashboard,
  isScorecard,
  isEntityApp,
  isReportsIndex,
  isDashboardsIndex,
  isScorecardsIndex,
  isSignOffBoard,
  isBoard,
  isBonusHome,
  isBonusPicker,
  isDriverScoringProgramReport,
  isScoringProgramConfigOverview,
  isMappedEntityView,
  isUsers,
  isTargetsOverview,
  isTargetManager,
  isGoalsIndex,
  isTasks,
};

export default portalNavItemTypeCheckers;
