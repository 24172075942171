import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import Row from 'components/Common/Row';
import { UserIconFromId } from 'components/Common/UserIcon';
import Typography from 'kingpin/atoms/Typography';
import CloseButton from 'components/CloseButton';

const UserDiv = styled.div<{ noBorder: boolean }>`
  height: 56px;
  padding: 0px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  box-shadow: ${(props) =>
    props.noBorder ? 'unset' : ' 0px -1px 0px 0px rgba(0, 0, 0, 0.08) inset'};
`;

const UserListItem = ({
  user,
  onUserRemoved,
  isLast,
}: {
  user:
    | UserManagement.PendingUser
    | UserManagement.SignedUpUser
    | UserManagement.FleetOpsStaffUser;
  onUserRemoved: (
    user:
      | UserManagement.PendingUser
      | UserManagement.SignedUpUser
      | UserManagement.FleetOpsStaffUser,
  ) => Promise<void>;
  isLast: boolean;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onUserRemoveClicked = useCallback(() => {
    setIsLoading(true);
    onUserRemoved(user).then(() => {
      setIsLoading(false);
    });
  }, [onUserRemoved, user]);

  return (
    <UserDiv noBorder={isLast}>
      <Row centerAlign>
        <UserIconFromId userId={user.id} />
        <Typography.Body type="Body 12">{user.displayName}</Typography.Body>
      </Row>
      <CloseButton
        close={onUserRemoveClicked}
        isNarrow
        isSaving={isLoading}
        isDisabled={isLoading}
      />
    </UserDiv>
  );
};

export default UserListItem;
