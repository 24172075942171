import React from 'react';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import FilterPlates from '../FilterPlates';
import isV5ChartDef from '../../types/visTypeCheckers/isV5ChartDef';
import V5Gadget from '../V5Gadget';
import ErrorBoundary from '../Common/ErrorBoundary';
import Row from '../Common/Row';
import ComparisonSelector from '../Report/ComparisonSelector';
import { Label } from '../MyAccount/Profile/styles';
import ReportSelector from './ReportSelector';
import DateInput from '../DateInput';
import BonusPeriodModeToggle from '../BonusPeriodModeToggle';
import isSingleMetricDateMatrix from '../../types/visTypeCheckers/isSingleMetricDateMatrix';
import SingleMetricDateMatrixContainer from '../SingleMetricDateMatrix';
import isGauge from '../../types/visTypeCheckers/isGauge';
import Gauge from '../Gauge';
import isRemindersGadget from '../../types/visTypeCheckers/isRemindersGadget';
import RemindersGadget from '../RemindersGadget';
import WeekStartsOnOverrideSelector from '../Report/WeekStartsOnOverrideSelector';
import WeekStartsOnOverrideContext from '../../contexts/WeekStartsOnOverrideContext';
import isRankingMatrix from '../../types/visTypeCheckers/isRankingMatrix';
import RankingMatrix from '../RankingMatrix';
import CardContext from 'contexts/CardContext';
import { Z_INDEX } from '../../constants';
import Card from '../Common/Card';
import EntityFilterToggle from '../EntityFilterToggle';
import FeatureGate, { FEATURE_GATES } from '../FeatureGate';
import isPaceMatrix from '../../types/visTypeCheckers/isPaceMatrix';
import PaceMatrix from '../PaceMatrix';
import DashboardGadgetDefaultFiltersToggle from './DashboardGadgetDefaultFiltersToggle';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px;
  position: relative;
`;

const ConfigureDashboardGadget = ({
  chartDef,
  reportDrillDownId,
  setReportDrillDownId,
  boardDrillDownId,
  setBoardDrillDownId,
  isBonusPeriodMode,
  onIsBonusPeriodModeChanged,
  selectedBonusPeriod,
  isValid,
  weekStartsOnOverride,
  setWeekStartsOnOverride,
  isEntityFilterEnabled,
  setIsEntityFilterEnabled,
}: {
  chartDef?: VisualisationDefinition;
  reportDrillDownId?: string;
  setReportDrillDownId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  boardDrillDownId?: string;
  setBoardDrillDownId: React.Dispatch<React.SetStateAction<string | undefined>>;
  isBonusPeriodMode: boolean;
  onIsBonusPeriodModeChanged: (newStatus: boolean) => void;
  selectedBonusPeriod?: BonusPeriod | Scoring.BonusPeriod;
  isValid: boolean;
  weekStartsOnOverride?: WeekStartsOn;
  setWeekStartsOnOverride: React.Dispatch<
    React.SetStateAction<WeekStartsOn | undefined>
  >;
  isEntityFilterEnabled: boolean;
  setIsEntityFilterEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}) => (
  <Wrapper
    onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
    }}
  >
    <div>
      <div>
        {/* Misc */}
        <div style={{ marginBottom: 24 }}>
          <Row style={{ marginBottom: 4 }}>
            {/* Date Scope */}
            <div style={{ marginRight: 16 }}>
              <Label>
                <Typography.Body type={'Label'}>Date Scope</Typography.Body>
              </Label>
              <Row centerAlign>
                <div>
                  <DateInput isDisabled={isBonusPeriodMode} />
                </div>
              </Row>
            </div>
            {/* Reporting Week*/}
            {!isRankingMatrix(chartDef) && (
              <div style={{ marginRight: 16 }}>
                <Label>
                  <Typography.Body type={'Label'}>
                    Reporting Week
                  </Typography.Body>
                </Label>
                <WeekStartsOnOverrideSelector
                  setWeekStartsOnOverride={setWeekStartsOnOverride}
                  weekStartsOnOverride={weekStartsOnOverride}
                />
              </div>
            )}
            {/* Compare With */}
            {!isRankingMatrix(chartDef) && (
              <div style={{ marginRight: 16 }}>
                <Label>
                  <Typography.Body type={'Label'}>Compare With</Typography.Body>
                </Label>
                <ComparisonSelector
                  alwaysShowCompareTo={
                    isSingleMetricDateMatrix(chartDef) || isPaceMatrix(chartDef)
                  }
                  isOnlyPreviousAllowed={isBonusPeriodMode}
                />
              </div>
            )}
            {/* Drilldown */}
            <div style={{ marginRight: 16 }}>
              <Label>
                <Typography.Body type={'Label'}>Drilldown</Typography.Body>
              </Label>
              <ReportSelector
                reportId={reportDrillDownId}
                setReportId={setReportDrillDownId}
                boardId={boardDrillDownId}
                setBoardId={setBoardDrillDownId}
              />
            </div>
          </Row>
          <FeatureGate featureName={FEATURE_GATES.DRIVER_BONUS}>
            <Row>
              <BonusPeriodModeToggle
                selectedBonusPeriod={selectedBonusPeriod}
                isBonusPeriodMode={isBonusPeriodMode}
                onIsBonusPeriodModeChanged={onIsBonusPeriodModeChanged}
              />
            </Row>
          </FeatureGate>
        </div>

        <div style={{ marginBottom: 8 }}>
          <EntityFilterToggle
            isEntityFilterEnabled={isEntityFilterEnabled}
            setIsEntityFilterEnabled={setIsEntityFilterEnabled}
          />
        </div>

        <div style={{ marginBottom: 8 }}>
          <DashboardGadgetDefaultFiltersToggle chartDef={chartDef} />
        </div>

        {/* Filters */}
        <Row
          style={{
            zIndex: Z_INDEX.CONFIGURE_DASHBOARD_GADGET,
            flexWrap: 'wrap',
            marginBottom: 32,
          }}
        >
          {/* Drilldowns */}
          {!isRemindersGadget(chartDef) && (
            <div style={{ marginRight: 16 }}>
              <Label>
                <Typography.Body type={'Label'}>
                  Run Time Filters
                </Typography.Body>
              </Label>
              <Row>
                <FilterPlates isDashboardCard label={'Add Run Time Filter'} />
              </Row>
            </div>
          )}

          {/* Scope */}
          <div>
            <Label>
              <Typography.Body type={'Label'}>Scope</Typography.Body>
            </Label>
            <Row>
              <FilterPlates isScope isDashboardCard label="Add Scope" />
            </Row>
          </div>
        </Row>
      </div>
      <Card style={{ position: 'relative', height: 400 }}>
        <WeekStartsOnOverrideContext.Provider value={{ weekStartsOnOverride }}>
          <CardContext.Provider
            value={{
              chartDefinition: chartDef,
              i: 'na',
              w: 10,
              h: 10,
              isForConfigureDashboardGadgetPopup: true,
            }}
          >
            <ErrorBoundary>
              <>
                {isV5ChartDef(chartDef) && (
                  <V5Gadget
                    chartDefinition={chartDef}
                    useSmallNoDataMessage={false}
                  />
                )}
                {isSingleMetricDateMatrix(chartDef) && (
                  <SingleMetricDateMatrixContainer gadget={chartDef} />
                )}
                {isGauge(chartDef) && <Gauge gauge={chartDef} />}
                {isRemindersGadget(chartDef) && isValid && (
                  <RemindersGadget gadget={chartDef} />
                )}
                {isRankingMatrix(chartDef) && (
                  <RankingMatrix gadget={chartDef} />
                )}
                {isPaceMatrix(chartDef) && <PaceMatrix gadget={chartDef} />}
              </>
            </ErrorBoundary>
          </CardContext.Provider>
        </WeekStartsOnOverrideContext.Provider>
      </Card>
    </div>
  </Wrapper>
);

export default ConfigureDashboardGadget;
