import Loading from '../../../../Loading';
import { useCallback, useMemo, useState } from 'react';
import Card from '../../../../../kingpin/atoms/Card';
import useValueFormatters from '../../../../../hooks/useValueFormatters';
import Row from '../../../../Common/Row';
import Typography from '../../../../../kingpin/atoms/Typography';
import useGetFieldLabel from '../../../../../hooks/useGetFieldLabel';
import Button from '../../../../../kingpin/atoms/Button';
import Tooltip from '../../../../Tooltip';

const DEFAULT_SHOW_X_FIELDS = 5;

const RelatedDocument = ({
  elasticDocument,
  section,
}: {
  elasticDocument: ElasticDocument;
  section: PerformanceBoardTypes.SlideOut.RelatedDocumentsSection;
}) => {
  const { dataset, fields } = section;
  const { formatField } = useValueFormatters();
  const { getFieldLabel } = useGetFieldLabel();

  const [isShowingMore, setIsShowingMore] = useState<boolean>(false);
  const content = useMemo((): {
    field: string;
    rawField: string;
    value: string | number;
  }[] => {
    return Object.entries(elasticDocument)
      .filter(([field]) => {
        if (field.length === 0) {
          return false;
        }

        // Never show meta data fields
        if (field[0] === '_') {
          return false;
        }

        // Only show the defined by the section
        return fields.some((f) => f === field);
      })
      .filter(([field]) => {
        // If not showing more, show the first 5 fields
        if (isShowingMore) {
          return true;
        }

        const fieldIndex = fields.indexOf(field);
        if (fieldIndex < DEFAULT_SHOW_X_FIELDS) {
          return true;
        }
        return false;
      })
      .map(([field, value]) => {
        return {
          rawField: field,
          field: getFieldLabel({ field, dataType: dataset }),
          value: formatField({ field, value, dataset }),
        };
      });
  }, [
    dataset,
    elasticDocument,
    fields,
    formatField,
    getFieldLabel,
    isShowingMore,
  ]);

  const orderedContent = useMemo(() => {
    return content.sort((a, b) => {
      const aIndex = fields.indexOf(a.rawField);
      const bIndex = fields.indexOf(b.rawField);

      if (aIndex < bIndex) {
        return -1;
      }
      if (aIndex === bIndex) {
        return 0;
      }
      return 1;
    });
  }, [content, fields]);

  const onShowMoreToggled = useCallback(() => {
    setIsShowingMore((c) => !c);
  }, []);

  const isToggleRequired = fields.length > DEFAULT_SHOW_X_FIELDS;

  return (
    <Card>
      {orderedContent.map((c) => (
        <Row spaceBetween centerAlign key={c.field}>
          <div style={{ width: 100 }}>
            <Tooltip content={c.field}>
              <Typography.Body type={'Label'} isEllipsis>
                {c.field}
              </Typography.Body>
            </Tooltip>
          </div>
          <div style={{ width: 200 }}>
            <Tooltip content={c.value}>
              <Typography.Body type={'Body 13'} isEllipsis>
                {c.value}
              </Typography.Body>
            </Tooltip>
          </div>
        </Row>
      ))}
      {isToggleRequired && (
        <Row spaceBetween centerAlign>
          <div />
          <Button
            size={'Small'}
            type={'Ghost'}
            onClick={onShowMoreToggled}
            label={isShowingMore ? 'Show Less' : 'Show More'}
          />
        </Row>
      )}
    </Card>
  );
};

const RelatedDocuments = ({
  isLoading,
  elasticDocuments,
  section,
}: {
  isLoading: boolean;
  elasticDocuments: ElasticDocument[];
  section: PerformanceBoardTypes.SlideOut.RelatedDocumentsSection;
}) => {
  if (isLoading) {
    return <Loading />;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      {elasticDocuments.map((d) => (
        <RelatedDocument elasticDocument={d} section={section} key={d.id} />
      ))}
    </div>
  );
};

export default RelatedDocuments;
