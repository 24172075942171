import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';

import Row from 'components/Common/Row';
import DragAndDropList from 'components/DragAndDropList';
import getIdentifier from 'getIdentifier';
import usePopup from 'hooks/usePopup';
import Button from 'kingpin/atoms/Button';
import TextInput from 'kingpin/atoms/TextInput';
import Typography from 'kingpin/atoms/Typography';
import Form from 'kingpin/forms/Form';
import FormHeader from 'kingpin/forms/FormHeader';
import FormContent from 'kingpin/forms/FormContent';
import FormInput from 'kingpin/forms/FormInput';

import NavItemForm from './NavItemForm';
import DragHandle from '../../../../../components/DragAndDropList/DragHandle';
import Inputs from '../../../../../components/Inputs';

const NavItem = ({
  item,
  onEditItemClicked,
  onItemRemoved,
}: {
  item: PortalsNav.Item;
  onEditItemClicked: (item: PortalsNav.Item) => void;
  onItemRemoved: (item: PortalsNav.Item) => void;
}) => {
  return (
    <Row
      key={item.key}
      spaceBetween
      centerAlign
      style={{
        marginBottom: 8,
        border: '1px solid #e0e0e0',
        borderRadius: 8,
        padding: '4px',
      }}
    >
      <Row style={{ gap: 8 }} centerAlign>
        <DragHandle />
        <Typography.Body type={'Body 12'}>
          {item.type === 'Bonus Period Picker'
            ? 'Bonus Period Picker'
            : item.label}
        </Typography.Body>
      </Row>
      <Row centerAlign style={{ gap: 8 }}>
        <Button
          size={'Small'}
          type={'Ghost'}
          icon={'edit-filled'}
          onClick={() => onEditItemClicked(item)}
        />
        <Button
          size={'Small'}
          type={'Ghost'}
          icon={'cross'}
          onClick={() => onItemRemoved(item)}
        />
      </Row>
    </Row>
  );
};

const useUrlSlug = ({
  sideNavigationSections,
  section,
}: {
  sideNavigationSections: PortalSideNavigationSection[];
  section: PortalSideNavigationSection | undefined;
}) => {
  const otherSections = useMemo(() => {
    if (!section) {
      return sideNavigationSections;
    }

    return sideNavigationSections.filter((s) => s.key !== section.key);
  }, [section, sideNavigationSections]);

  const getReservedUrls = useCallback(() => {
    return otherSections.map((p) => p.urlSlug);
  }, [otherSections]);

  const [urlSlug, setUrlSlug] = useState<string>(
    section ? section.urlSlug : '',
  );
  const [isUrlSlugValid, setIsUrlSlugValid] = useState(!!section);

  const onUrlSlugChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUrlSlug(event.target.value);
    },
    [],
  );
  const getIsUrlSlugValid = useCallback(() => {
    const isReservedUrl = getReservedUrls().some((url) => url === urlSlug);
    const isEmpty = urlSlug === '';
    const hasBannedCharacters = /[^a-zA-Z/-]/g.test(urlSlug);

    return !(isReservedUrl || isEmpty || hasBannedCharacters);
  }, [getReservedUrls, urlSlug]);

  useEffect(() => {
    setIsUrlSlugValid(getIsUrlSlugValid());
  }, [getIsUrlSlugValid]);

  return {
    urlSlug,
    setUrlSlug,
    onUrlSlugChanged,
    isUrlSlugValid,
  };
};

const SideNavigationSectionForm = ({
  portalType,
  section,
  sideNavigationSections,
  onSave,
  close,
}: {
  portalType: 'ExecutivePortal' | 'Engagement Portal' | 'Bonus Portal';
  section: PortalSideNavigationSection | undefined;
  sideNavigationSections: PortalSideNavigationSection[];
  onSave: (section: PortalSideNavigationSection) => void;
  close: () => void;
}) => {
  const [key] = useState<string>(
    section ? section.key : getIdentifier(undefined, true),
  );
  const [title, setTitle] = useState<
    | {
        isEnabled: false;
      }
    | {
        isEnabled: true;
        label: string;
      }
  >(section ? section.title : { isEnabled: false });
  const { urlSlug, onUrlSlugChanged, isUrlSlugValid } = useUrlSlug({
    sideNavigationSections,
    section,
  });
  const [isBottomBorderEnabled, setIsBottomBorderEnabled] = useState<boolean>(
    section ? section.isBottomBorderEnabled : true,
  );
  const [portalTypeSpecificSettings, setPortalTypeSpecificSettings] =
    useState<PortalTypeSpecificSettings>(
      section
        ? section.portalTypeSpecificSettings
        : portalType === 'Bonus Portal'
          ? { type: 'Bonus Portal', isPeriodFiltered: true }
          : undefined,
    );
  const [items, setItems] = useState<PortalsNav.Item[]>(
    section ? section.items : [],
  );
  const [draftSection, setDraftSection] = useState<
    PortalSideNavigationSection | undefined
  >(section);

  const {
    isOpen: isItemFormOpen,
    open: openItemForm,
    close: closeItemForm,
  } = usePopup();
  const [openedItem, setOpenedItem] = useState<PortalsNav.Item | undefined>();
  const onAddItemClicked = useCallback(() => {
    setOpenedItem(undefined);
    openItemForm();
  }, [openItemForm]);

  const onEditItemClicked = useCallback(
    (item: PortalsNav.Item) => {
      setOpenedItem(item);
      openItemForm();
    },
    [openItemForm],
  );

  const onItemRemoved = useCallback((item: PortalsNav.Item) => {
    setItems((current) => current.filter((i) => i.key !== item.key));
  }, []);

  const onItemSaved = useCallback((item: PortalsNav.Item) => {
    setItems((current) => {
      if (current.some((i) => i.key === item.key)) {
        return current.map((i) => {
          if (i.key === item.key) {
            return item;
          }
          return i;
        });
      } else {
        return [...current, item];
      }
    });
  }, []);

  useEffect(() => {
    if ((title.isEnabled && title.label === '') || items.length === 0) {
      setDraftSection(undefined);
      return;
    }

    setDraftSection({
      key,
      title,
      items,
      urlSlug,
      portalTypeSpecificSettings,
      isBottomBorderEnabled,
    });
  }, [
    isBottomBorderEnabled,
    portalTypeSpecificSettings,
    items,
    key,
    title,
    urlSlug,
  ]);

  const onSaveClicked = useCallback(() => {
    if (!draftSection) {
      return;
    }

    onSave(draftSection);
    close();
  }, [close, draftSection, onSave]);

  return (
    <>
      <Form>
        <FormHeader
          title={section ? 'Update Section' : 'Add Section'}
          onClose={close}
        />
        <FormContent>
          <FormInput label={'Title'} isInputSection>
            <FormInput label={'Enabled'}>
              <Inputs.Checkbox
                isChecked={title.isEnabled}
                onToggled={(newValue) => {
                  if (newValue) {
                    setTitle({
                      isEnabled: true,
                      label: '',
                    });
                  } else {
                    setTitle({
                      isEnabled: false,
                    });
                  }
                }}
              />
            </FormInput>
            {title.isEnabled && (
              <FormInput label={'Label'}>
                <TextInput
                  value={title.isEnabled ? title.label : ''}
                  onChange={(e) =>
                    setTitle({
                      isEnabled: true,
                      label: e.target.value,
                    })
                  }
                />
              </FormInput>
            )}
          </FormInput>
          <FormInput label={'Bottom Border'}>
            <Inputs.Checkbox
              isChecked={isBottomBorderEnabled}
              onToggled={(newValue) => {
                setIsBottomBorderEnabled(newValue);
              }}
            />
          </FormInput>
          <FormInput label={'Url Slug'}>
            <TextInput
              value={urlSlug}
              onChange={onUrlSlugChanged}
              data-testid={'section-url-slug-input'}
              state={urlSlug !== '' && !isUrlSlugValid ? 'Error' : undefined}
            />
          </FormInput>
          {portalTypeSpecificSettings &&
            portalTypeSpecificSettings.type === 'Bonus Portal' && (
              <FormInput label={'Is Filtered By Selected Period'}>
                <Inputs.Checkbox
                  isChecked={portalTypeSpecificSettings.isPeriodFiltered}
                  onToggled={(newValue) => {
                    setPortalTypeSpecificSettings({
                      type: 'Bonus Portal',
                      isPeriodFiltered: newValue,
                    });
                  }}
                />
              </FormInput>
            )}

          <FormInput label={'Items'}>
            <DragAndDropList
              items={items}
              onOrderChanged={setItems}
              droppableId={'items'}
              renderItem={(item) => (
                <NavItem
                  item={item}
                  onEditItemClicked={onEditItemClicked}
                  onItemRemoved={onItemRemoved}
                />
              )}
            />
            <Button
              testId={'add-item-button'}
              size={'Small'}
              type={'Secondary'}
              icon={'add'}
              onClick={onAddItemClicked}
            />
          </FormInput>
        </FormContent>
        <Row centerAlign spaceBetween>
          <Button
            size={'Small'}
            type={'Secondary'}
            label={'Cancel'}
            onClick={close}
          />
          <Button
            size={'Small'}
            type={'Primary'}
            testId="save-section-button"
            label={section ? 'Update Section' : 'Add Section'}
            onClick={onSaveClicked}
            isDisabled={!draftSection}
          />
        </Row>
      </Form>
      <ModalTransition>
        {isItemFormOpen && (
          <Modal
            onClose={closeItemForm}
            shouldScrollInViewport={false}
            autoFocus={false}
          >
            <NavItemForm
              close={closeItemForm}
              item={openedItem}
              onItemSaved={onItemSaved}
            />
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default SideNavigationSectionForm;
