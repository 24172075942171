import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import GlobalTasksContext from '../../contexts/Tasks/GlobalTasksContext';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import CurrentUserContext from '../../contexts/CurrentUserContext';
import STORE from '../../store';

const GlobalTasksProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { isWallboardUser } = useContext(CurrentUserContext);
  const { accountRef } = useContext(AccountPickerContext);
  const [tasks, setTasks] = useState<Tasks.Task[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (isWallboardUser) {
      return;
    }

    const listener = STORE.getTasksRef({
      accountId: selectedAccountId,
    }).onSnapshot((snapshot: any) => {
      const data: Tasks.Task[] = [];
      snapshot.docs.forEach((d: any) => data.push({ id: d.id, ...d.data() }));
      setTasks(_.sortBy(data, 'createdOn'));
      setIsLoading(false);
    });
    return () => {
      listener();
    };
  }, [accountRef, isWallboardUser, selectedAccountId]);

  return (
    <GlobalTasksContext.Provider value={{ tasks, isLoading }}>
      {children}
    </GlobalTasksContext.Provider>
  );
};

export default GlobalTasksProvider;
