import portalNavItemTypeCheckers from '../../../../types/portalNavItemTypeCheckers';

const getReportIdsInPortal = ({
  portal,
}: {
  portal: EngagementPortal | BonusPortal | ExecutivePortal;
}) => {
  return portal.navigationSettings.sections.reduce((acc, section) => {
    const sectionIds = section.items.reduce((itemAcc, item) => {
      if (portalNavItemTypeCheckers.isReport(item)) {
        return [...itemAcc, item.reportId];
      }
      if (portalNavItemTypeCheckers.isReportsIndex(item)) {
        return [...itemAcc, ...item.reportIds];
      }
      return itemAcc;
    }, [] as string[]);

    return [...acc, ...sectionIds];
  }, [] as string[]);
};

export default getReportIdsInPortal;
