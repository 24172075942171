import React, { createContext } from 'react';
import useExecutivePortals from './useExecutivePortals';

interface PortalsContextType {
  availableExecutivePortals: ExecutivePortal[];
  allExecutivePortals: ExecutivePortal[];
  isLoadingExecutivePortals: boolean;
}

export const ExecutivePortalsContext = createContext<PortalsContextType>({
  availableExecutivePortals: [],
  allExecutivePortals: [],
  isLoadingExecutivePortals: true,
});

const ExecutivePortalsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { availableExecutivePortals, allExecutivePortals, isLoading } =
    useExecutivePortals();

  return (
    <ExecutivePortalsContext.Provider
      value={{
        availableExecutivePortals,
        allExecutivePortals,
        isLoadingExecutivePortals: isLoading,
      }}
    >
      {children}
    </ExecutivePortalsContext.Provider>
  );
};

export default ExecutivePortalsProvider;
