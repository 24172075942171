import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import Inputs from 'components/Inputs';
import Typography from 'kingpin/atoms/Typography';
import { LabelDiv } from 'screens/Boards/PerformanceBoardForm';
import Row from 'components/Common/Row';
import Toggle from 'components/Inputs/Toggle';
import Tooltip from 'components/Tooltip';
import Colors2 from 'theme/Colors2';
import Dropdown from 'components/Inputs/Dropdown';
import useFullDataInterval from 'components/PerformanceBoards/hooks/useFullDataInterval';
import useIntervalConfig from './useIntervalConfig';

const IntervalPicker = ({
  widgetDateRange,
  setWidgetDateRange,
}: {
  widgetDateRange: PerformanceBoardTypes.SlideOut.SlideOutDateRange;
  setWidgetDateRange:
    | React.Dispatch<
        React.SetStateAction<PerformanceBoardTypes.SlideOut.SlideOutDateRange>
      >
    | ((
        newWidgetDateRange: PerformanceBoardTypes.SlideOut.SlideOutDateRange,
      ) => void);
}) => {
  const { interval, intervalLength } = useIntervalConfig();
  const [modeOptions, setModeOptions] = useState<DropdownOption[]>([]);

  const { dateInterval: dateRange } = useFullDataInterval(widgetDateRange);

  const getDateRangeText = (dateRange: DateRangeInput | undefined) => {
    if (!dateRange) {
      return '...';
    }
    const startText = moment(dateRange.startDate).format('MM/DD/YY');
    const endText = moment(dateRange.endDate).format('MM/DD/YY');

    return `${startText} - ${endText}`;
  };

  const onValueChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newNumber = event.target.value;
      if (!newNumber) {
        setWidgetDateRange({
          ...widgetDateRange,
          n: undefined,
        });
      } else {
        setWidgetDateRange({
          ...widgetDateRange,
          n: Number(newNumber) < 1 ? 1 : Number(newNumber),
        });
      }
    },
    [setWidgetDateRange, widgetDateRange],
  );

  const onIncludeDateChanged = useCallback(
    (newToDate: boolean) => {
      setWidgetDateRange({
        ...widgetDateRange,
        isToDate: newToDate,
      });
    },
    [setWidgetDateRange, widgetDateRange],
  );

  useEffect(() => {
    const rangeModes = ['Current Interval', 'Last'];

    const newOptions = rangeModes.map((mode) => ({
      label: mode,
      value: mode,
      isSelected: widgetDateRange.mode === mode,
      onSelected: () => {
        setWidgetDateRange({
          ...widgetDateRange,
          mode: mode as 'Current Interval' | 'Last',
          isToDate: mode === 'Current Interval',
        });
      },
    }));

    setModeOptions(newOptions);
  }, [setWidgetDateRange, widgetDateRange]);

  return (
    <>
      <LabelDiv>
        <Row style={{ gap: '0.25em' }}>
          <Typography.Body type="Label">How many complete </Typography.Body>
          <Tooltip
            content={`${intervalLength} ${interval}`}
            tag={'span'}
            position="top"
            isFlexAnchor
          >
            <Typography.Body
              type="Label"
              isUnderlined
              color={Colors2.Primary['1']}
            >
              intervals
            </Typography.Body>
          </Tooltip>
          <Typography.Body type={'Label'}>
            would you like to display
          </Typography.Body>
        </Row>
      </LabelDiv>
      <Row style={{ marginBottom: 8 }}>
        <Dropdown options={modeOptions} noRightBorder />
        {widgetDateRange.mode === 'Last' && (
          <Inputs.TextInput
            data-testid="date-value-input"
            type="number"
            state={widgetDateRange.n === undefined ? 'Error' : undefined}
            value={widgetDateRange.n ? widgetDateRange.n.toString(10) : ''}
            onChange={onValueChanged}
            style={{
              marginLeft: '-1px',
              borderBottomLeftRadius: 0,
              borderTopLeftRadius: 0,
            }}
          />
        )}
      </Row>
      <Row centerAlign style={{ marginBottom: 8 }}>
        <div style={{ marginRight: 8 }}>
          <Toggle
            value={widgetDateRange.isToDate}
            onChange={(newValue) => onIncludeDateChanged(newValue)}
          />
        </div>
        <Typography.Body type="Body 12">
          Include interval to date
        </Typography.Body>
      </Row>
      <Typography.Body type="Body 12">
        {`Selected rolling period as of today: ${getDateRangeText(dateRange)}`}
      </Typography.Body>
    </>
  );
};

export default IntervalPicker;
