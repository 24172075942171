import React, { useMemo } from 'react';

const useAccessToOtherMappedUserIdsOptions = ({
  accessToOtherMappedUserIds,
  setAccessToOtherMappedUserIds,
  mappedUsers,
}: {
  accessToOtherMappedUserIds: string[];
  setAccessToOtherMappedUserIds: React.Dispatch<React.SetStateAction<string[]>>;
  mappedUsers: MappedUser[];
}) => {
  const options = useMemo((): DropdownOption[] => {
    return mappedUsers.map((mappedUser) => {
      return {
        label: mappedUser.user.displayName,
        isSelected: accessToOtherMappedUserIds.includes(mappedUser.user.id),
        onSelected: () => {
          setAccessToOtherMappedUserIds((currentIds) => {
            const isSelected = currentIds.includes(mappedUser.user.id);
            if (isSelected) {
              return currentIds.filter(
                (mappedUserId) => mappedUserId !== mappedUser.user.id,
              );
            }
            return [...currentIds, mappedUser.user.id];
          });
        },
      };
    });
  }, [accessToOtherMappedUserIds, mappedUsers, setAccessToOtherMappedUserIds]);

  return options;
};

export default useAccessToOtherMappedUserIdsOptions;
