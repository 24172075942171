import React, { useCallback, useContext } from 'react';
import usePopup from 'hooks/usePopup';
import Button from 'kingpin/atoms/Button';
import AddTabWizard from 'components/WorkSpaces/AddTabWizard';
import AnalyticsContext from 'contexts/AnalyticsContext';
import TargetsAppContext from '../TargetsAppContext';

const AddTabButton = () => {
  const { isOpen, open, close } = usePopup();
  const { app } = useContext(TargetsAppContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const onClick = useCallback(() => {
    trackEvent('Targets App - Add Tab clicked');
    open();
  }, [open, trackEvent]);

  return (
    <>
      <Button label="Add Tab" onClick={onClick} type="Ghost" size="Small" />
      <AddTabWizard isOpen={isOpen} close={close} workSpace={app} />
    </>
  );
};

export default AddTabButton;
