import React, { useCallback, useContext, useEffect, useState } from 'react';
import Row from '../../Common/Row';
import Button from 'kingpin/atoms/Button';

import { isGeneralWorkSpace, isTargetsApp } from '../../../isWorkSpace';
import GoalsContext from '../../../contexts/GoalsContext';
import BoardsContext from '../../../contexts/BoardsContext';
import DashboardsContext from '../../../contexts/DashboardsContext';
import ScorecardsContext from 'contexts/ScorecardsContext';
import ReportsContext from '../../../contexts/ReportsContext';
import RolesContext from '../../../contexts/RolesContext';
import PERMISSIONS from '../../../permissionDefinitions';
import Typography from 'kingpin/atoms/Typography';
import Inputs from 'components/Inputs';

const useTabOptions = ({
  workSpace,
  type,
  setType,
}: {
  workSpace: WorkSpace | TargetsApp.App;
  type: string | undefined;
  setType: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const { goals } = useContext(GoalsContext);
  const { boards } = useContext(BoardsContext);
  const { currentPermissions } = useContext(RolesContext);
  const { allDashboards: dashboards } = useContext(DashboardsContext);
  const { allReports: reports } = useContext(ReportsContext);
  const { scorecards } = useContext(ScorecardsContext);

  const buildOptions = useCallback((): RadioOption[] => {
    const hasDashboards = dashboards.length > 0;
    const hasGoals = goals.length > 0;
    const hasReports = reports.length > 0;
    const hasBoards = boards.length > 0;
    const hasScorecards = scorecards.length > 0;

    const newOptions = [] as RadioOption[];
    if (hasDashboards) {
      newOptions.push({
        key: 'dashboard',
        label: 'Dashboard',
        isSelected: 'Dashboard' === type,
        onSelected: () => {
          setType('Dashboard');
        },
      });
    }
    if (hasReports) {
      newOptions.push({
        key: 'report',
        label: 'Report',
        isSelected: 'Report' === type,
        onSelected: () => {
          setType('Report');
        },
      });
    }
    if (hasScorecards) {
      newOptions.push({
        key: 'scorecard',
        label: 'KPI List',
        isSelected: 'Scorecard' === type,
        onSelected: () => {
          setType('Scorecard');
        },
      });
    }
    if (hasBoards) {
      newOptions.push({
        key: 'Board',
        label: 'Board',
        isSelected: 'Board' === type,
        onSelected: () => {
          setType('Board');
        },
      });
    }
    if (
      (isGeneralWorkSpace(workSpace) || isTargetsApp(workSpace)) &&
      !workSpace.tabs.some((t) => t.type === 'tasks')
    ) {
      newOptions.push({
        key: 'Tasks',
        label: 'Tasks',
        isSelected: 'Tasks' === type,
        onSelected: () => {
          setType('Tasks');
        },
      });
    }
    if (
      isGeneralWorkSpace(workSpace) &&
      hasGoals &&
      !workSpace.tabs.some((t) => t.type === 'goals') &&
      currentPermissions.includes(PERMISSIONS.GOALS.CRUD_GOALS)
    ) {
      newOptions.push({
        key: 'Goals',
        label: 'Goals',
        isSelected: 'Goals' === type,
        onSelected: () => {
          setType('Goals');
        },
      });
    }

    return newOptions;
  }, [
    boards.length,
    currentPermissions,
    dashboards.length,
    goals.length,
    reports.length,
    scorecards.length,
    setType,
    type,
    workSpace,
  ]);

  const [tabOptions, setTabOptions] = useState<RadioOption[]>(() =>
    buildOptions(),
  );

  useEffect(() => {
    setTabOptions(buildOptions());
  }, [buildOptions]);

  return tabOptions;
};

const TypePicker = ({
  type,
  setType,
  onNextClicked,
  close,
  workSpace,
  onSaveGoals,
  onSaveTasks,
  isSaving,
}: {
  type: string | undefined;
  setType: React.Dispatch<React.SetStateAction<string | undefined>>;
  onNextClicked: () => void;
  close: () => void;
  workSpace: WorkSpace | TargetsApp.App;
  onSaveGoals: () => void;
  onSaveTasks: () => void;
  isSaving: boolean;
}) => {
  const tabOptions = useTabOptions({ workSpace, type, setType });

  return (
    <div>
      <div style={{ marginBottom: 24 }}>
        <Typography.Header type={'H5'}>Add Tab</Typography.Header>
      </div>
      <div style={{ marginBottom: 24 }}>
        <Inputs.Radio options={tabOptions} />
      </div>
      <Row spaceBetween>
        <Button onClick={close} type="Ghost" size="Small" label="Cancel" />
        {(type === 'Tasks' || type === 'Goals') && (
          <Button
            onClick={type === 'Tasks' ? onSaveTasks : onSaveGoals}
            isDisabled={isSaving || type === undefined}
            isLoading={isSaving}
            label="Save"
            type="Primary"
            size="Small"
          />
        )}
        {type !== 'Tasks' && type !== 'Goals' && (
          <Button
            onClick={onNextClicked}
            isDisabled={type === undefined}
            type="Primary"
            size="Small"
            label="Next"
          />
        )}
      </Row>
    </div>
  );
};

export default TypePicker;
