import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import Row from 'components/Common/Row';
import Dropdown from 'components/Inputs/Dropdown';
import EntityDefinitionsContext from 'contexts/EntityDefinitionsContext';
import getIdentifier from 'getIdentifier';
import FormContent from 'kingpin/forms/FormContent';
import Button from 'kingpin/atoms/Button';
import TextInput from 'kingpin/atoms/TextInput';
import FormInput from 'kingpin/forms/FormInput';
import isDefined from 'isDefined';
import IconPicker from '../../../../atoms/IconPicker';
import { NAV_ITEM_DEFAULT_ICON_COLOR } from '../../../NavItem';

const NavEntityAppForm = ({
  item,
  onSave,
  close,
}: {
  item: PortalsNav.EntityApp | undefined;
  onSave: (item: PortalsNav.EntityApp) => void;
  close: () => void;
}) => {
  const { entityDetailsApps, entityDefinitions } = useContext(
    EntityDefinitionsContext,
  );

  const [key] = useState<string>(
    item ? item.key : getIdentifier(undefined, true),
  );
  const [label, setLabel] = useState<string>(item ? item.label : '');
  const [icon, setIcon] = useState<Kingpin.Icon | undefined>(
    item ? item.icon : undefined,
  );

  const [entityId, setEntityId] = useState<string | undefined>(
    item ? item.entityId : undefined,
  );
  const [entityAppId, setEntityAppId] = useState<string | undefined>(
    item ? item.entityAppId : undefined,
  );
  const [draftItem, setDraftItem] = useState<PortalsNav.EntityApp | undefined>(
    item,
  );

  useEffect(() => {
    if (label === '' || !entityId || !entityAppId) {
      setDraftItem(undefined);
      return;
    }

    setDraftItem({
      key,
      label,
      entityId,
      entityAppId,
      type: 'Entity App',
      icon,
    });
  }, [icon, key, label, entityId, entityAppId]);

  const onSaveClicked = useCallback(() => {
    if (!draftItem) {
      return;
    }

    onSave(draftItem);
    close();
  }, [close, draftItem, onSave]);

  const entityOptions = useMemo((): DropdownOption[] => {
    return entityDetailsApps
      .map((a) => {
        const entity = entityDefinitions.find((d) => d.id === a.entityId);
        if (!entity) {
          return undefined;
        }
        return {
          key: entity.id,
          label: entity.name,
          isSelected: entityAppId === a.id,
          onSelected: () => {
            setEntityAppId(a.id);
            setEntityId(a.entityId);
          },
        };
      })
      .filter(isDefined);
  }, [entityAppId, entityDefinitions, entityDetailsApps]);

  return (
    <div>
      <FormContent>
        <FormInput label={'Label'}>
          <TextInput
            value={label}
            onChange={(event) => {
              setLabel(event.target.value);
            }}
          />
        </FormInput>
        <FormInput label={'Icon'}>
          <IconPicker
            setSelectedIcon={setIcon}
            selectedIcon={icon}
            iconColor={NAV_ITEM_DEFAULT_ICON_COLOR}
          />
        </FormInput>
        <FormInput label={'EntityApp'}>
          <Dropdown options={entityOptions} placeholder="Select an Entity" />
        </FormInput>
      </FormContent>
      <Row centerAlign spaceBetween>
        <Button
          size={'Small'}
          type={'Secondary'}
          label={'Cancel'}
          onClick={close}
        />
        <Button
          isDisabled={draftItem === undefined}
          onClick={onSaveClicked}
          label={item ? 'Update' : 'Add'}
          type={'Primary'}
          size={'Small'}
        />
      </Row>
    </div>
  );
};

export default NavEntityAppForm;
