import { ApisauceInstance } from 'apisauce';

const getEditorUserList = (
  api: ApisauceInstance,
): Promise<(UserManagement.SignedUpUser | UserManagement.PendingUser)[]> => {
  return api
    .get<
      (UserManagement.SignedUpUser | UserManagement.PendingUser)[]
    >('/users/editorList')
    .then((response) => {
      if (response.ok && response.data) {
        return response.data;
      }
      return [];
    });
};

export default getEditorUserList;
