import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import Row from 'components/Common/Row';
import Dropdown from 'components/Inputs/Dropdown';
import getIdentifier from 'getIdentifier';
import FormContent from 'kingpin/forms/FormContent';
import FormInput from 'kingpin/forms/FormInput';
import Button from 'kingpin/atoms/Button';
import TextInput from 'kingpin/atoms/TextInput';
import IconPicker from '../../../../atoms/IconPicker';
import { NAV_ITEM_DEFAULT_ICON_COLOR } from '../../../NavItem';
import EntityDefinitionsContext from '../../../../../contexts/EntityDefinitionsContext';

const NavMappedEntityViewForm = ({
  item,
  onSave,
  close,
}: {
  item: PortalsNav.MappedEntityView | undefined;
  onSave: (item: PortalsNav.MappedEntityView) => void;
  close: () => void;
}) => {
  const { entityDefinitions } = useContext(EntityDefinitionsContext);

  const [key] = useState<string>(
    item ? item.key : getIdentifier(undefined, true),
  );
  const [label, setLabel] = useState<string>(item ? item.label : '');
  const [icon, setIcon] = useState<Kingpin.Icon | undefined>(
    item ? item.icon : undefined,
  );
  const [entityId, setEntityId] = useState<string | undefined>(
    item ? item.entityId : undefined,
  );
  const [usersTabLabel, setUsersTabLabel] = useState<string>('');
  const [draftItem, setDraftItem] = useState<
    PortalsNav.MappedEntityView | undefined
  >(item);

  useEffect(() => {
    if (label === '' || entityId === undefined || usersTabLabel === '') {
      setDraftItem(undefined);
      return;
    }

    setDraftItem({
      key,
      label,
      entityId,
      type: 'Mapped Entity View',
      usersTabLabel,
      icon,
    });
  }, [entityId, icon, key, label, usersTabLabel]);

  const onSaveClicked = useCallback(() => {
    if (!draftItem) {
      return;
    }

    onSave(draftItem);
    close();
  }, [close, draftItem, onSave]);

  const entityOptions = useMemo((): DropdownOption[] => {
    return entityDefinitions.map((r): DropdownOption => {
      return {
        key: r.id,
        label: r.name,
        isSelected: !!entityId && entityId === r.id,
        onSelected: () => {
          setEntityId(r.id);
        },
      };
    });
  }, [entityDefinitions, entityId]);

  return (
    <div>
      <FormContent>
        <FormInput label={'Label'}>
          <TextInput
            value={label}
            data-testid={'nav-item-label-input'}
            onChange={(event) => {
              setLabel(event.target.value);
            }}
          />
        </FormInput>
        <FormInput label={'Icon'}>
          <IconPicker
            setSelectedIcon={setIcon}
            selectedIcon={icon}
            iconColor={NAV_ITEM_DEFAULT_ICON_COLOR}
          />
        </FormInput>
        <FormInput label={'Entity'}>
          <Dropdown options={entityOptions} placeholder="Select an Entity..." />
        </FormInput>
        <FormInput label={'Users Tab Label'}>
          <TextInput
            data-testid={'users-tab-input'}
            value={usersTabLabel}
            onChange={(event) => {
              setUsersTabLabel(event.target.value);
            }}
          />
        </FormInput>
      </FormContent>
      <Row centerAlign spaceBetween>
        <Button
          size={'Small'}
          type={'Secondary'}
          label={'Cancel'}
          onClick={close}
        />
        <Button
          testId={draftItem ? 'confirm-nav-item' : undefined}
          isDisabled={draftItem === undefined}
          onClick={onSaveClicked}
          label={item ? 'Update' : 'Add'}
          type={'Primary'}
          size={'Small'}
        />
      </Row>
    </div>
  );
};

export default NavMappedEntityViewForm;
