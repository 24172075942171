import { ICellRendererParams } from 'ag-grid-community';
import useScoringCellBonusProgram from './useScoringCellBonusProgram';
import React, { useMemo } from 'react';
import GridCellErrorBoundary from '../GridCellErrorBoundary';
import useValueFormatters from '../../hooks/useValueFormatters';
import Cell from '../V5Gadget/Matrix/Cell';
import { ProjectedPayTooltip } from '../../screens/DriverScoringProgramReport/DriverScoringProgramReport';
import Tooltip from '../Tooltip';

import styled from 'styled-components';
import Colors2 from '../../theme/Colors2';

const RewardCellDiv = styled.div`
  flex: 1;
  display: flex;
  cursor: help;
  flex-direction: row;
  justify-content: flex-end;

  .tooltip-anchor {
    flex: 1;
  }
`;

const ScoringRewardCell = ({
  elasticDocument,
  dataset,
  actualField,
  potentialField,
  isTotalsRow,
}: {
  elasticDocument: ElasticDocument;
  dataset: string;
  actualField: string;
  potentialField: string;
  isTotalsRow: boolean;
}) => {
  const { formatField } = useValueFormatters();
  const actualValue = useMemo(() => {
    return elasticDocument[actualField];
  }, [actualField, elasticDocument]);

  const potentialValue = useMemo(() => {
    return elasticDocument[potentialField];
  }, [elasticDocument, potentialField]);

  const isShowingPotential = useMemo(() => {
    if (actualValue === undefined) {
      return true;
    }
    return potentialValue > actualValue && !isTotalsRow;
  }, [actualValue, isTotalsRow, potentialValue]);

  const actualFormatted = useMemo(() => {
    return formatField({
      field: actualField,
      value: actualValue,
      dataset,
    });
  }, [actualField, actualValue, dataset, formatField]);

  const potentialFormatted = useMemo(() => {
    return formatField({
      field: potentialField,
      value: potentialValue,
      dataset,
    });
  }, [potentialField, potentialValue, dataset, formatField]);

  if (isShowingPotential) {
    return (
      <RewardCellDiv>
        <Tooltip content={<ProjectedPayTooltip />} isFlexAnchor>
          <Cell rightAlign>
            <span style={{ color: Colors2.Grey['5'] }}>
              {potentialFormatted}
            </span>
          </Cell>
        </Tooltip>
      </RewardCellDiv>
    );
  }

  return (
    <Cell rightAlign>
      <span>{actualFormatted}</span>
    </Cell>
  );
};

const ScoringRewardCellProvider = (params: ICellRendererParams) => {
  const bonusProgram = useScoringCellBonusProgram();

  const elasticDocument = useMemo(() => {
    return params.node.data as ElasticDocument;
  }, [params]);

  const field = useMemo(() => {
    const { colDef } = params;
    if (!colDef || !colDef.field) {
      return undefined;
    }

    return colDef.field;
  }, [params]);

  const rewardFields = useMemo(() => {
    const { colDef } = params;
    if (!colDef || !colDef.field) {
      return undefined;
    }
    if (!bonusProgram) {
      return undefined;
    }

    const field = colDef.field;
    const expectationField = bonusProgram.rewardFields.find(
      (f) => f.actualField === field || f.potentialField === field,
    );
    if (!expectationField) {
      return undefined;
    }
    return expectationField;
  }, [bonusProgram, params]);

  const isTotalsRow = params.node.isRowPinned();

  if (!bonusProgram || !field || !rewardFields) {
    return null;
  }

  const { actualField, potentialField } = rewardFields;

  return (
    <ScoringRewardCell
      elasticDocument={elasticDocument}
      dataset={bonusProgram.dataset}
      actualField={actualField}
      potentialField={potentialField}
      isTotalsRow={isTotalsRow}
    />
  );
};

const Gate = (params: ICellRendererParams) => (
  <GridCellErrorBoundary
    params={params}
    childComponent={<ScoringRewardCellProvider {...params} />}
  />
);

export default Gate;
