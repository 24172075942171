import React, { useMemo } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import useScoringCellBonusProgram from './useScoringCellBonusProgram';
import GridCellErrorBoundary from '../GridCellErrorBoundary';
import { STATUS_FIELDS_META_FIELD } from '../../constants';
import useValueFormatters from '../../hooks/useValueFormatters';
import Cell from '../V5Gadget/Matrix/Cell';

const ExpectationCell = ({
  elasticDocument,
  actualField,
  expectationField,
  isPassed,
  dataset,
}: {
  elasticDocument: ElasticDocument;
  actualField: string;
  expectationField: string;
  isPassed: boolean;
  dataset: string;
}) => {
  const { formatField } = useValueFormatters();
  const formattedExpectation = useMemo(() => {
    return formatField({
      value: elasticDocument[expectationField],
      dataset,
      field: expectationField,
    });
  }, [dataset, elasticDocument, expectationField, formatField]);
  const formattedActual = useMemo(() => {
    return formatField({
      value: elasticDocument[actualField],
      dataset,
      field: actualField,
    });
  }, [actualField, dataset, elasticDocument, formatField]);

  return (
    <Cell rightAlign>
      <span
        style={{
          color: isPassed ? '#36B37E' : '#FF3D3D',
        }}
      >
        {`${formattedActual} / ${formattedExpectation}`}
      </span>
    </Cell>
  );
};

const ExpectationCellProvider = (params: ICellRendererParams) => {
  const bonusProgram = useScoringCellBonusProgram();

  const elasticDocument = useMemo(() => {
    return params.node.data as ElasticDocument;
  }, [params]);

  const field = useMemo(() => {
    const { colDef } = params;
    if (!colDef || !colDef.field) {
      return undefined;
    }

    return colDef.field;
  }, [params]);

  const expectationFields = useMemo(() => {
    const { colDef } = params;
    if (!colDef || !colDef.field) {
      return undefined;
    }
    if (!bonusProgram) {
      return undefined;
    }

    const field = colDef.field;
    const expectationField = bonusProgram.expectationFields.find(
      (f) => f.actualField === field || f.expectationField === field,
    );
    if (!expectationField) {
      return undefined;
    }
    return expectationField;
  }, [bonusProgram, params]);

  const expectation = useMemo(() => {
    const statusFields = elasticDocument[STATUS_FIELDS_META_FIELD] as
      | {
          [field: string]: Scoring.StatusFieldDocumentMetaData;
        }
      | undefined;
    if (!statusFields) {
      return undefined;
    }
    if (!expectationFields) {
      return undefined;
    }

    const allExpectations: Scoring.StatusFieldExpectation[] = Object.values(
      statusFields,
    ).reduce((a, b) => {
      return [...a, ...b.expectations];
    }, [] as Scoring.StatusFieldExpectation[]);

    return allExpectations.find(
      (e) => e.field === expectationFields.actualField,
    );
  }, [elasticDocument, expectationFields]);

  if (!bonusProgram || !field || !expectationFields || !expectation) {
    return null;
  }

  const { actualField, expectationField } = expectationFields;

  return (
    <ExpectationCell
      elasticDocument={elasticDocument}
      dataset={bonusProgram.dataset}
      actualField={actualField}
      expectationField={expectationField}
      isPassed={expectation.passed}
    />
  );
};

const Gate = (params: ICellRendererParams) => (
  <GridCellErrorBoundary
    params={params}
    childComponent={<ExpectationCellProvider {...params} />}
  />
);

export default Gate;
