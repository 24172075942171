import React from 'react';
import getNavItemLink from '../SelectedPortalConfiguredNav/getNavItemLink';
import portalNavItemTypeCheckers from '../../../../types/portalNavItemTypeCheckers';
import portalTypeCheckers from '../../../../types/portalTypeCheckers';
import PortalEntityNavItem from '../SelectedPortalConfiguredNav/EntityNavItem';
import NavItem from '../../NavItem';
import BonusPeriodPickerNavItem from './BonusPeriodPickerNavItem';
import BonusHomeNavItem from './BonusHomeNavItem';

const PortalNavItem = ({
  section,
  item,
  portal,
}: {
  section: PortalSideNavigationSection;
  item: PortalsNav.Item;
  portal: Portal;
}) => {
  const to = getNavItemLink({
    item,
    section,
    portal,
  });

  if (portalNavItemTypeCheckers.isBonusPicker(item)) {
    if (!portalTypeCheckers.isBonusPortal(portal)) {
      return null;
    }
    return <BonusPeriodPickerNavItem portal={portal} />;
  }

  if (portalNavItemTypeCheckers.isBonusHome(item)) {
    if (!portalTypeCheckers.isBonusPortal(portal)) {
      return null;
    }
    return <BonusHomeNavItem portal={portal} section={section} item={item} />;
  }

  if (
    portalNavItemTypeCheckers.isEntityApp(item) ||
    portalNavItemTypeCheckers.isMappedEntityView(item)
  ) {
    return (
      <PortalEntityNavItem
        label={item.label}
        entityId={item.entityId}
        icon={item.icon}
        section={section}
      />
    );
  }

  return <NavItem key={item.key} label={item.label} to={to} icon={item.icon} />;
};

export default PortalNavItem;
