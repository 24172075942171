import React, { useContext } from 'react';
import styled from 'styled-components';
import Row from 'components/Common/Row';
import Tabs from 'components/Tabs';
import { ResourceFavouriteButton } from 'components/ResourceTopBar';
import TargetsAppContext from '../TargetsAppContext';
import AppUsers from './AppUsers';
import AddTabButton from './AddTabButton';
import ManageTabsButton from './ManageTabsButton';
import AppName from './AppName';
import { EXCLUDE_HEIGHT, TOP_BAR_HEIGHT } from '../../../../constants';
import PermissionGates from 'components/PermissionGates';
import PERMISSIONS from '../../../../permissionDefinitions';
import Colors2 from '../../../../theme/Colors2';

export const TopBarDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  height: ${TOP_BAR_HEIGHT}px;
  min-height: ${TOP_BAR_HEIGHT}px;
  padding: 0px 24px;
  border-bottom: 1px solid ${Colors2.Border};
`;

const TopBar = ({ tab }: { tab?: TargetsApp.ClientTab }) => {
  const { app } = useContext(TargetsAppContext);
  const { tabs } = useContext(TargetsAppContext);

  return (
    <>
      <TopBarDiv
        className={tab ? `${EXCLUDE_HEIGHT}-${tab.typeId}` : undefined}
      >
        <Row centerAlign>
          <div style={{ marginRight: 8 }}>
            <AppName />
          </div>
          <div style={{ marginRight: 16 }}>
            <ResourceFavouriteButton
              type={'targetsApp'}
              typeId={app.id}
              name={app.title}
            />
          </div>
          <AppUsers />
        </Row>
        <PermissionGates.Has
          requiredPermission={PERMISSIONS.WORKSPACES.ADD_REMOVE_TABS}
        >
          <Row centerAlign>
            <div style={{ marginRight: 4 }}>
              <AddTabButton />
            </div>
            <ManageTabsButton />
          </Row>
        </PermissionGates.Has>
      </TopBarDiv>
      <TopBarDiv
        className={tab ? `${EXCLUDE_HEIGHT}-${tab.typeId}` : undefined}
      >
        <Tabs.TabsRow tabs={tabs} />
      </TopBarDiv>
    </>
  );
};

export default TopBar;
