import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import ComparisonSelector from '../../Report/ComparisonSelector';
import MetricList from './MetricList';
import AddMetricButton from './AddMetricButton';
import DateInput from '../../DateInput';
import BonusPeriodModeToggle from '../../BonusPeriodModeToggle';
import WeekStartsOnOverrideSelector from '../../Report/WeekStartsOnOverrideSelector';
import TextInput from '../../../kingpin/atoms/TextInput';
import FeatureGate, { FEATURE_GATES } from '../../FeatureGate';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 16px;
`;

const SpaceBetween = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

const FormBody = styled.div``;

const ConfigureMetricList = ({
  name,
  onNameChanged,
  metricList,
  setMetricList,
  metricListOrder,
  setMetricListOrder,
  onAddMetricItem,
  isBonusPeriodMode,
  onIsBonusPeriodModeChanged,
  selectedBonusPeriod,
  weekStartsOnOverride,
  setWeekStartsOnOverride,
}: {
  name: string;
  onNameChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  metricList: MetricListItemType[];
  setMetricList: React.Dispatch<React.SetStateAction<MetricListItemType[]>>;
  metricListOrder: string[];
  setMetricListOrder: React.Dispatch<React.SetStateAction<string[]>>;
  onAddMetricItem: (newMetricItem: MetricListItemType) => void;
  isBonusPeriodMode: boolean;
  onIsBonusPeriodModeChanged: (newStatus: boolean) => void;
  selectedBonusPeriod?: BonusPeriod | Scoring.BonusPeriod;
  weekStartsOnOverride?: WeekStartsOn;
  setWeekStartsOnOverride: React.Dispatch<
    React.SetStateAction<WeekStartsOn | undefined>
  >;
}) => (
  <Wrapper
    onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
    }}
  >
    <SpaceBetween>
      <FormBody>
        <div
          style={{
            marginBottom: 16,
          }}
        >
          <TextInput
            label={'Name'}
            value={name}
            onChange={onNameChanged}
            inputSize="Small"
          />
        </div>
        <FeatureGate featureName={FEATURE_GATES.DRIVER_BONUS}>
          <div style={{ marginBottom: 16 }}>
            <BonusPeriodModeToggle
              selectedBonusPeriod={selectedBonusPeriod}
              isBonusPeriodMode={isBonusPeriodMode}
              onIsBonusPeriodModeChanged={onIsBonusPeriodModeChanged}
            />
          </div>
        </FeatureGate>

        {!isBonusPeriodMode && (
          <div style={{ marginBottom: 24 }}>
            <div style={{ marginBottom: 12 }}>
              <DateInput />
            </div>
            <div style={{ marginBottom: 12 }}>
              <ComparisonSelector />
            </div>
            <WeekStartsOnOverrideSelector
              weekStartsOnOverride={weekStartsOnOverride}
              setWeekStartsOnOverride={setWeekStartsOnOverride}
            />
          </div>
        )}
        <MetricList
          metricList={metricList}
          setMetricList={setMetricList}
          metricListOrder={metricListOrder}
          setMetricListOrder={setMetricListOrder}
        />
        <AddMetricButton onAddMetricItem={onAddMetricItem} />
      </FormBody>
    </SpaceBetween>
  </Wrapper>
);

export default ConfigureMetricList;
