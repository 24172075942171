import { useCallback, useContext, useMemo } from 'react';
import portalTypeCheckers from '../types/portalTypeCheckers';
import STORE from '../store';
import { PortalsContext } from '../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';
import AccountPickerContext from '../contexts/AccountPickerContext';
import portalNavItemTypeCheckers from '../types/portalNavItemTypeCheckers';
import NavSectionContext from '../navigation/NavSectionContext';

const useAddNewReportToCurrentPortal = () => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { selectedPortal, locallyUpdateSelectedPortal } =
    useContext(PortalsContext);
  const { navSection } = useContext(NavSectionContext);

  const reportsNavItem = useMemo(() => {
    if (!navSection) {
      return undefined;
    }

    const item = navSection.items.find(
      portalNavItemTypeCheckers.isReportsIndex,
    );
    if (!item) {
      return undefined;
    }

    return {
      section: navSection,
      item,
    };
  }, [navSection]);

  const addNewReportToCurrentPortal = useCallback(
    async (newReportId: string) => {
      if (!selectedPortal || !reportsNavItem) {
        return;
      }
      const { section, item } = reportsNavItem;

      if (portalTypeCheckers.isAdminPortal(selectedPortal)) {
        return;
      }

      const newItem: PortalsNav.ReportsIndex = {
        ...item,
        reportIds: [...item.reportIds, newReportId],
      };

      const newSections: PortalSideNavigationSection[] =
        selectedPortal.navigationSettings.sections.map((s) => {
          if (s.key === section.key) {
            return {
              ...s,
              items: s.items.map((i) => {
                if (i.key === item.key) {
                  return newItem;
                }
                return i;
              }),
            };
          }

          return s;
        });

      const newPortal: ExecutivePortal | EngagementPortal | BonusPortal = {
        ...selectedPortal,
        navigationSettings: {
          ...selectedPortal.navigationSettings,
          sections: newSections,
        },
      };

      locallyUpdateSelectedPortal(newPortal);

      if (portalTypeCheckers.isEngagementPortal(newPortal)) {
        return STORE.contentDistributions
          .getEngagementPortalsRef({ accountId: selectedAccountId })
          .doc(newPortal.id)
          .set(newPortal);
      } else if (portalTypeCheckers.isExecutivePortal(newPortal)) {
        return STORE.contentDistributions
          .getExecutivePortalsRef({ accountId: selectedAccountId })
          .doc(newPortal.id)
          .set(newPortal);
      } else if (portalTypeCheckers.isBonusPortal(newPortal)) {
        return STORE.contentDistributions
          .getBonusPortalsRef({ accountId: selectedAccountId })
          .doc(newPortal.id)
          .set(newPortal);
      }
    },
    [
      selectedPortal,
      reportsNavItem,
      locallyUpdateSelectedPortal,
      selectedAccountId,
    ],
  );

  return addNewReportToCurrentPortal;
};

export default useAddNewReportToCurrentPortal;
