import styled from 'styled-components';
import React, { useCallback } from 'react';
import Row from '../../../../components/Common/Row';
import UserIcon from '../../../../components/Common/UserIcon';
import Typography from '../../../../kingpin/atoms/Typography';
import Button from '../../../../kingpin/atoms/Button';

const SelectedUserDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 12px;

  &:not(:last-of-type) {
    border-bottom: 1px solid #e0e0e0;
  }
`;

const SelectedUser = ({
  user,
  setSelectedUsers,
}: {
  user: UserManagement.SignedUpUser | UserManagement.PendingUser;
  setSelectedUsers: React.Dispatch<
    React.SetStateAction<
      (UserManagement.SignedUpUser | UserManagement.PendingUser)[]
    >
  >;
}) => {
  const onRemoveClicked = useCallback(() => {
    setSelectedUsers((current) => current.filter((u) => u.id !== user.id));
  }, [setSelectedUsers, user.id]);

  return (
    <SelectedUserDiv>
      <Row centerAlign>
        <div style={{ display: 'flex', marginRight: 8 }}>
          <UserIcon {...user} isSmall noMargin />
        </div>
        <Typography.Body type={'Button Text'}>
          {user.displayName}
        </Typography.Body>
      </Row>
      <Button
        size={'Small'}
        type={'Ghost'}
        icon="cross"
        onClick={onRemoveClicked}
      />
    </SelectedUserDiv>
  );
};

export default SelectedUser;
