import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ColorDot from '../ColorDot';
import getIdentifier from '../../../../getIdentifier';
import getTimeStamp from '../../../../getTimeStamp';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';
import useUrlSlug from './useUrlSlug';
import portalTypeCheckers from '../../../../types/portalTypeCheckers';
import useSetDefaultPortalTypeNavigation from './useSetDefaultPortalTypeNavigation';

const deriveUrlSlug = (name: string) => {
  return name.replace(/[^a-zA-Z]/g, '').toLowerCase();
};

const useFormState = (portal: EditablePortal | undefined) => {
  const { id: currentUserId } = useContext(CurrentUserContext);
  const [id] = useState<string>(portal ? portal.id : getIdentifier());
  const [portalType, setPortalType] = useState<
    'ExecutivePortal' | 'Engagement Portal' | 'Bonus Portal' | undefined
  >(portal ? portal.type : undefined);
  const { urlSlug, setUrlSlug, onUrlSlugChanged, isUrlSlugValid } =
    useUrlSlug(portal);
  const [name, setName] = useState<string>(portal ? portal.name : '');
  const onNameChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newName = event.target.value;
      setName((currentName) => {
        if (!portal) {
          setUrlSlug((currentSlug) => {
            const currentDerivedSlug = deriveUrlSlug(currentName);
            if (currentDerivedSlug === currentSlug) {
              // Update the slug if it is in sync
              return deriveUrlSlug(newName);
            }

            // Keep the current slug as it has been given an explicit value
            return currentSlug;
          });
        }

        // Set the new name
        return newName;
      });
    },
    [portal, setUrlSlug],
  );

  const [color, setColor] = useState<PortalColor>(
    portal ? portal.color : 'blue',
  );
  const getColorOptions = useCallback(() => {
    const colors: PortalColor[] = [
      'blue',
      'green',
      'yellow',
      'orange',
      'red',
      'magenta',
      'navy',
      'grey',
      'lightgrey',
    ];
    return colors.map((c) => ({
      isSelected: color === c,
      onSelected: () => {
        setColor(c);
      },
      key: c,
      label: '',
      renderLabel: () => <ColorDot color={c} key={c} width={20} height={20} />,
    }));
  }, [color]);
  const [colorOptions, setColorOptions] = useState<DropdownOption[]>(() =>
    getColorOptions(),
  );
  useEffect(() => {
    setColorOptions(getColorOptions());
  }, [getColorOptions]);

  // Bonus Portal State
  const [bonusProgramId, setBonusProgramId] = useState<string | undefined>(
    portalTypeCheckers.isBonusPortal(portal)
      ? portal.bonusProgramId
      : undefined,
  );
  const [sideNavigationSections, setSideNavigationSections] = useState<
    PortalSideNavigationSection[]
  >(portal ? portal.navigationSettings.sections : []);
  const [defaultScreen, setDefaultScreen] = useState<
    | {
        sectionKey: string;
        itemKey: string;
      }
    | undefined
  >(portal ? portal.navigationSettings.defaultScreen : undefined);
  const setDefaultPortalTypeNavigation = useSetDefaultPortalTypeNavigation({
    setDefaultScreen,
    setSideNavigationSections,
  });

  const portalTypeOptions = useMemo(() => {
    return [
      {
        label: 'Executive Portal',
        isSelected: portalType === 'ExecutivePortal',
        onSelected: () => {
          setPortalType('ExecutivePortal');
          setDefaultPortalTypeNavigation('ExecutivePortal');
        },
      },
      {
        label: 'Engagement Portal',
        isSelected: portalType === 'Engagement Portal',
        onSelected: () => {
          setPortalType('Engagement Portal');
          setDefaultPortalTypeNavigation('Engagement Portal');
        },
      },
      {
        label: 'Bonus Portal',
        isSelected: portalType === 'Bonus Portal',
        onSelected: () => {
          setPortalType('Bonus Portal');
          setDefaultPortalTypeNavigation('Bonus Portal');
        },
      },
    ];
  }, [portalType, setDefaultPortalTypeNavigation]);

  const [draftPortal, setDraftPortal] = useState<Portal | undefined>(portal);
  useEffect(() => {
    if (name === '' || !isUrlSlugValid || !defaultScreen) {
      setDraftPortal(undefined);
      return;
    }

    if (portalType === 'ExecutivePortal') {
      const newPortal: ExecutivePortal = {
        id,
        type: 'ExecutivePortal',
        name,
        urlSlug,
        color,
        usersIdsWithAccess: [],
        navigationSettings: {
          defaultScreen,
          sections: sideNavigationSections,
        },
        createdOn: portal ? portal.createdOn : getTimeStamp(),
        updatedOn: getTimeStamp(),
        createdBy: portal ? portal.createdBy : currentUserId,
        updatedBy: currentUserId,
      };
      setDraftPortal(newPortal);
      return;
    }

    if (portalType === 'Engagement Portal') {
      const newPortal: EngagementPortal = {
        id,
        type: 'Engagement Portal',
        name,
        urlSlug,
        color,
        usersIdsWithAccess: [],
        navigationSettings: {
          sections: sideNavigationSections,
          defaultScreen,
        },
        createdOn: portal ? portal.createdOn : getTimeStamp(),
        updatedOn: getTimeStamp(),
        createdBy: portal ? portal.createdBy : currentUserId,
        updatedBy: currentUserId,
      };
      setDraftPortal(newPortal);
      return;
    }

    if (portalType === 'Bonus Portal') {
      if (!bonusProgramId) {
        setDraftPortal(undefined);
        return;
      }
      const newPortal: BonusPortal = {
        id,
        type: 'Bonus Portal',
        name,
        urlSlug,
        color,
        bonusProgramId,
        navigationSettings: {
          sections: sideNavigationSections,
          defaultScreen,
        },
        bonusPeriodSignOffUserIds: [],
        usersIdsWithAccess: [],
        createdOn: portal ? portal.createdOn : getTimeStamp(),
        updatedOn: getTimeStamp(),
        createdBy: portal ? portal.createdBy : currentUserId,
        updatedBy: currentUserId,
      };
      setDraftPortal(newPortal);
    }
  }, [
    bonusProgramId,
    color,
    currentUserId,
    defaultScreen,
    id,
    isUrlSlugValid,
    name,
    portal,
    portalType,
    sideNavigationSections,
    urlSlug,
  ]);

  return {
    name,
    onNameChanged,
    portalType,
    portalTypeOptions,
    color,
    colorOptions,
    draftPortal,
    urlSlug,
    onUrlSlugChanged,
    isUrlSlugValid,
    bonusProgramId,
    setBonusProgramId,
    sideNavigationSections,
    setSideNavigationSections,
    defaultScreen,
    setDefaultScreen,
  };
};

export default useFormState;
