import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import ExecutivePortalsProvider from './ExecutivePortalsProvider';
import EngagementPortalsProvider from './EngagementPortalsProvider';
import useIsPortalsEnabled from './useIsPortalsEnabled';
import FlexCentered from '../../../../components/Common/FlexCentered';
import Loading from '../../../../components/Loading';
import useHasAvailablePortal from './useHasAvailablePortal';
import appRoutes from '../../../../navigation/appRoutes';
import portalTypeCheckers from '../../../../types/portalTypeCheckers';
import ToastContext from '../../../../contexts/ToastContext';
import usePublishedLookup, { PublishedLookup } from './usePublishedLookup';
import BonusPortalsProvider from './BonusPortalsProvider';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';

interface PortalsProviderContextType {
  selectedPortal?: Portal;
  lastSelectedPortal?: Portal;
  onPortalSelected: (portal: Portal) => void;
  locallyUpdateSelectedPortal: (portal: Portal) => void;
  isPortalsEnabled: boolean;
  isPortalsEnabledAtAccountLevel: boolean;
  isPortalUIToggleEnabled: boolean;
  onPreviewNewUiClicked: () => void;
  onRevertToOldUiClicked: () => void;
  hasAvailablePortal: boolean;
  availablePortals: Portal[];
  onPortalUrlSlugChanged: (portalUrlSlug: string) => void;
  publishedLookup: PublishedLookup;
  isLoadingPortals: boolean;

  /**
   * If a user has multiple tabs of fleetops open and the current tab
   * is in a state which differs from what has been persisted in local storage
   * based on activity in other tabs, link generation can have unexpected
   * results.
   *
   * eg:
   * - app.fleetops.com/admin/gadgets
   * - app.fleetops.com/gadgets
   *
   * Therefore, in some situations, we need this to be available to us
   */
  getPersistedPreviewMode: () => boolean;
}

export const PortalsContext = createContext<PortalsProviderContextType>({
  onPortalSelected: window.tokenFunction,
  isPortalsEnabled: false,
  isPortalsEnabledAtAccountLevel: false,
  isPortalUIToggleEnabled: false,
  onPreviewNewUiClicked: window.tokenFunction,
  onRevertToOldUiClicked: window.tokenFunction,
  hasAvailablePortal: false,
  availablePortals: window.emptyArray,
  onPortalUrlSlugChanged: window.tokenFunction,
  publishedLookup: {
    reports: {},
    dashboards: {},
    scorecards: {},
  },
  locallyUpdateSelectedPortal: window.tokenFunction,
  isLoadingPortals: false,
  getPersistedPreviewMode: () => false,
});

const PortalsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const navigate = useNavigate();
  const {
    isPortalsEnabledAtAccountLevel,
    isPortalsEnabled,
    isPortalUIToggleEnabled,
    isLoading,
    onPreviewNewUiClicked,
    onRevertToOldUiClicked,
    getPersistedPreviewMode,
  } = useIsPortalsEnabled();
  const { showToast } = useContext(ToastContext);
  const { isFleetOpsStaff } = useContext(CurrentUserContext);
  const [selectedPortal, setSelectedPortal] = useState<Portal | undefined>();
  const { hasAvailablePortal, availablePortals, isLoadingPortals } =
    useHasAvailablePortal();
  const publishedLookup = usePublishedLookup();

  const onPortalSelected = useCallback(
    (portal: Portal) => {
      setSelectedPortal(portal);
      navigate(`/${portal.urlSlug}`);
    },
    [navigate],
  );

  // Unselect portal when isPortalsEnabled gets toggled off
  useEffect(() => {
    if (isPortalsEnabled) {
      return;
    }

    setSelectedPortal(undefined);
  }, [isPortalsEnabled]);

  // This is handy if we need to update something in the portal immediately,
  // eg: add new report to the portal and nav to it.
  const locallyUpdateSelectedPortal = useCallback((newPortal: Portal) => {
    setSelectedPortal(newPortal);
  }, []);

  useEffect(() => {
    if (!selectedPortal) {
      return;
    }

    const latestVersionOfSelected = availablePortals.find(
      (p) => p.id === selectedPortal.id,
    );

    const isUpdateAvailable = !_.isEqual(
      latestVersionOfSelected,
      selectedPortal,
    );

    if (!isUpdateAvailable) {
      return;
    }

    setSelectedPortal(latestVersionOfSelected);
  }, [selectedPortal, availablePortals]);

  /**
   * The PortalsProvider is higher in the React tree than the Route where
   * the portalUrlSlug is defined. Therefore we need to provide
   * this via context
   */
  const onPortalUrlSlugChanged = useCallback(
    (portalUrlSlug: string) => {
      if (selectedPortal && portalTypeCheckers.isAdminPortal(selectedPortal)) {
        return;
      }

      if (selectedPortal && selectedPortal.urlSlug === portalUrlSlug) {
        return;
      }

      const newSelectedPortal = availablePortals.find(
        (p) => p.urlSlug === portalUrlSlug,
      );

      if (!newSelectedPortal) {
        showToast('We could not find that portal');
        navigate(appRoutes.home);
        return;
      }

      setSelectedPortal(newSelectedPortal);
    },
    [availablePortals, navigate, selectedPortal, showToast],
  );

  /**
   * For FleetOps staff, select the admin portal by default
   * We need to sniff the pathname here as we have a dodgy flow where
   * this context provider has its state set via onPortalUrlSlugChanged
   * deeper in the render tree, from {@link PortalsSwitch}
   *
   * If this proves to be awkward to maintain going forward, the
   * selectedPortal state should be moved into its own context provider,
   * available after we know there is a :/portal/ in the url
   */
  useEffect(() => {
    if (!isFleetOpsStaff) {
      return;
    }

    if (!isPortalsEnabled) {
      return;
    }

    if (selectedPortal) {
      return;
    }

    // We don't want to run this logic on a /:portal/ url
    if (window.location.pathname !== '/') {
      return;
    }

    const adminPortal = availablePortals.find(portalTypeCheckers.isAdminPortal);
    if (adminPortal) {
      onPortalSelected(adminPortal);
    }
  }, [
    availablePortals,
    isFleetOpsStaff,
    isPortalsEnabled,
    onPortalSelected,
    selectedPortal,
  ]);

  if (isLoading) {
    return (
      <FlexCentered>
        <Loading />
      </FlexCentered>
    );
  }

  return (
    <PortalsContext.Provider
      value={{
        onPortalSelected,
        selectedPortal,
        isPortalsEnabledAtAccountLevel: !!isPortalsEnabledAtAccountLevel,
        isPortalsEnabled,
        isPortalUIToggleEnabled,
        onPreviewNewUiClicked,
        onRevertToOldUiClicked,
        hasAvailablePortal,
        availablePortals,
        onPortalUrlSlugChanged,
        publishedLookup,
        locallyUpdateSelectedPortal,
        isLoadingPortals,
        getPersistedPreviewMode,
      }}
    >
      {children}
    </PortalsContext.Provider>
  );
};

const Gate = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return (
    <ExecutivePortalsProvider>
      <EngagementPortalsProvider>
        <BonusPortalsProvider>
          <PortalsProvider>{children}</PortalsProvider>
        </BonusPortalsProvider>
      </EngagementPortalsProvider>
    </ExecutivePortalsProvider>
  );
};

export default Gate;
