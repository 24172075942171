import { useCallback, useContext, useEffect, useState } from 'react';
import UsersContext from '../../../../contexts/UsersContext';
import userTypeCheckers from '../../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/UsersProvider/userTypeCheckers';
import STORE from '../../../../store';
import AccountPickerContext from '../../../../contexts/AccountPickerContext';
import isDefined from '../../../../isDefined';
import useLockedDebouncedEffect from '../../../../hooks/useLockedDebouncedEffect';

interface GetUnMappedUsersArgs {
  allUsers: (UserManagement.SignedUpUser | UserManagement.PendingUser)[];
}

const useUnMappedUsers = (entityId: string) => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { allUsers, isLoading: isLoadingUsers } = useContext(UsersContext);
  const [unMappedUsers, setUnMappedUsers] = useState<
    (UserManagement.PendingUser | UserManagement.SignedUpUser)[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getUnMappedUsers = useCallback(
    async (
      args: GetUnMappedUsersArgs,
    ): Promise<
      (UserManagement.PendingUser | UserManagement.SignedUpUser)[]
    > => {
      setIsLoading(true);
      const { allUsers } = args;

      const promises = allUsers
        .filter((u) => !u.isDeleted)
        .map(
          async (
            user,
          ): Promise<
            undefined | UserManagement.SignedUpUser | UserManagement.PendingUser
          > => {
            const userContentSettings = await STORE.users
              .getUserContentSettingsRef({
                accountId: selectedAccountId,
                userId: user.id,
              })
              .get();

            if (!userContentSettings.exists) {
              return user;
            }
            const settings = userContentSettings.data();
            if (!settings) {
              return user;
            }
            if (settings.mode === 'normal') {
              return user;
            }

            const isThisUsedMappedToThisEntity = settings.mappings.some(
              (mapping) => mapping.entityId === entityId,
            );
            if (!isThisUsedMappedToThisEntity) {
              return user;
            }

            return undefined;
          },
        );

      const results = await Promise.all(promises);
      return results.filter(isDefined);
    },
    [entityId, selectedAccountId],
  );

  const buildArgs = useCallback((): GetUnMappedUsersArgs | undefined => {
    if (isLoadingUsers) {
      return undefined;
    }

    return {
      allUsers: allUsers.filter(userTypeCheckers.isSignedUpOrPendingUser),
    };
  }, [allUsers, isLoadingUsers]);
  const [args, setArgs] = useState<GetUnMappedUsersArgs | undefined>(() =>
    buildArgs(),
  );
  useEffect(() => {
    setArgs(buildArgs());
  }, [buildArgs]);

  const handleResponse = useCallback(
    (
      response: (UserManagement.PendingUser | UserManagement.SignedUpUser)[],
    ) => {
      setUnMappedUsers(response);
      setIsLoading(false);
    },
    [],
  );

  useLockedDebouncedEffect({
    args,
    responseHandler: handleResponse,
    callback: getUnMappedUsers,
  });

  return { unMappedUsers, isLoading };
};

export default useUnMappedUsers;
