const buildBonusPeriodComparisonDate = ({
  bonusPeriods,
  selectedBonusPeriod,
}: {
  selectedBonusPeriod?: BonusPeriod | Scoring.BonusPeriod;
  bonusPeriods?: BonusPeriod[] | Scoring.BonusPeriod[];
}): DateRangeInput | undefined => {
  if (selectedBonusPeriod && bonusPeriods) {
    const selectedPeriodIndex = bonusPeriods.findIndex(
      (p) => p.id === selectedBonusPeriod.id,
    );
    const comparePeriodIndex = selectedPeriodIndex + 1;
    if (comparePeriodIndex < bonusPeriods.length) {
      const comparePeriod = bonusPeriods[comparePeriodIndex];
      return {
        startDate: comparePeriod.startDate,
        endDate: comparePeriod.endDate,
      };
    }
  }
};

export default buildBonusPeriodComparisonDate;
