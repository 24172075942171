import React, { useCallback } from 'react';
import styled from 'styled-components';

import Row from '../../Common/Row';
import GoalSearchBar from '../GoalSearchBar';
import Colors from '../../../theme/colors';
import Flexed from '../GoalListItem/GeneralGoalListItem/Flexed';
import CreateGoalButton from '../CreateGoalButton';

import GoalCard from '../GoalCard';
import ListIconSrc from './ListIcon.png';
import CardIconSrc from './CardIcon.png';
import Tooltip from '../../Tooltip';
import GoalCardWrapper from '../GoalCardWrapper';
import GoalProvider from '../../../contextProviders/GoalProvider';
import GoalListItem from '../GoalListItem';
import PermissionGates from '../../PermissionGates';
import PERMISSIONS from '../../../permissionDefinitions';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const CardsWrapper = styled.div`
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  padding: 1px;
`;

const Cards = ({ goals }: { goals: GeneralGoal[] }) => {
  return (
    <CardsWrapper className="hiding-scrollbar">
      {goals.map((g) => (
        <GoalCardWrapper key={g.id}>
          <GoalProvider goal={g}>
            <GoalCard goal={g} />
          </GoalProvider>
        </GoalCardWrapper>
      ))}
    </CardsWrapper>
  );
};

const ButtonWrapper = styled.div`
  padding: 8px;

  cursor: pointer;
  border: 1px solid #e9e9e9;
  border-radius: 3px;

  &:hover {
    opacity: 0.7;
  }
`;

const ButtonImg = styled.img``;

const ModeButton = ({
  mode,
  setMode,
}: {
  mode: 'list' | 'card';
  setMode: React.Dispatch<React.SetStateAction<'list' | 'card'>>;
}) => {
  const onClick = useCallback(() => {
    setMode((currentMode) => (currentMode === 'list' ? 'card' : 'list'));
  }, [setMode]);

  return (
    <Tooltip content={mode === 'list' ? 'Card View' : 'List View'}>
      <ButtonWrapper onClick={onClick}>
        <ButtonImg src={mode === 'list' ? CardIconSrc : ListIconSrc} />
      </ButtonWrapper>
    </Tooltip>
  );
};

const List = ({ filteredGoals }: { filteredGoals: GeneralGoal[] }) => (
  <>
    <Row style={{ color: Colors.TITLE_TEXT, fontWeight: 500 }}>
      <Flexed flex={5}>Title</Flexed>
      <Flexed flex={3} style={{ alignItems: 'center' }}>
        Current Value
      </Flexed>
      <Flexed flex={4}>Performance</Flexed>
      <Flexed flex={2}>Goal Value</Flexed>
      <Flexed flex={1}>Accountable</Flexed>
      <Flexed flex={3} />
    </Row>
    {filteredGoals.map((g) => (
      <GoalProvider goal={g} key={g.id}>
        <GoalListItem goal={g} />
      </GoalProvider>
    ))}
  </>
);

const GoalsList = ({
  filteredGoals,
  mode,
  setMode,
}: {
  filteredGoals: GeneralGoal[];
  mode: 'list' | 'card';
  setMode: React.Dispatch<React.SetStateAction<'list' | 'card'>>;
}) => (
  <Wrapper>
    <Row spaceBetween style={{ marginBottom: 16 }}>
      <div style={{ width: 400 }}>
        <GoalSearchBar />
      </div>
      <Row centerAlign>
        <PermissionGates.Has requiredPermission={PERMISSIONS.GOALS.CRUD_GOALS}>
          <div style={{ marginRight: 32 }}>
            <CreateGoalButton />
          </div>
        </PermissionGates.Has>

        <ModeButton mode={mode} setMode={setMode} />
      </Row>
    </Row>
    {mode === 'list' && <List filteredGoals={filteredGoals} />}
    {mode === 'card' && <Cards goals={filteredGoals} />}
  </Wrapper>
);

export default GoalsList;
