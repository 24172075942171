import React, { useContext, useEffect, useState } from 'react';

import GoalFilteringContext from '../contexts/GoalFilteringContext';
import GoalsContext from '../contexts/GoalsContext';
import CurrentUserContext from '../contexts/CurrentUserContext';
import isDefined from '../isDefined';

const GoalFilteringProvider = ({
  children,
  goalIdsGreenList,
}: {
  children: JSX.Element | JSX.Element[];
  goalIdsGreenList: string[] | undefined;
}) => {
  const { goals: baseGoals } = useContext(GoalsContext);
  const currentUser = useContext(CurrentUserContext);

  const [goals, setGoals] = useState<GeneralGoal[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [goalOwnerIds, setGoalOwnerIds] = useState<string[]>([]);

  useEffect(() => {
    if (goalIdsGreenList) {
      const newGoals = goalIdsGreenList
        .map((gid) => baseGoals.find((g) => g.id === gid))
        .filter(isDefined);
      setGoals(newGoals);
    } else {
      setGoals(baseGoals);
    }
  }, [baseGoals, goalIdsGreenList]);

  const filteredGoals = (() => {
    if (!currentUser) {
      return [];
    }
    const filtered = [...goals];

    if (searchText !== '') {
      return filtered.filter((o) =>
        o.title.toLowerCase().includes(searchText.toLowerCase()),
      );
    }

    return filtered;
  })();

  return (
    <GoalFilteringContext.Provider
      value={{
        filteredGoals,
        searchText,
        setSearchText,
        goalOwnerIds,
        setGoalOwnerIds,
      }}
    >
      {children}
    </GoalFilteringContext.Provider>
  );
};

export default GoalFilteringProvider;
