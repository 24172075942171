import { useCallback, useContext, useEffect, useState } from 'react';
import TargetsAppContext from '../TargetsAppContext';
import isDefined from '../../../../isDefined';
import UsersContext from '../../../../contexts/UsersContext';
import userTypeCheckers from '../../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/UsersProvider/userTypeCheckers';

const useUsersWithAccess = () => {
  const { app, updateApp } = useContext(TargetsAppContext);
  const { allUsers } = useContext(UsersContext);
  const [usersWithAccess, setUsersWithAccess] = useState<
    (
      | UserManagement.PendingUser
      | UserManagement.SignedUpUser
      | UserManagement.FleetOpsStaffUser
    )[]
  >([]);

  const getUsersFromIds = useCallback(
    (userIds: string[]) =>
      userIds
        .map((uid) => allUsers.find((u) => u.id === uid))
        .filter(isDefined)
        .filter(userTypeCheckers.isNotWallboardUser),
    [allUsers],
  );

  useEffect(() => {
    const userIds = app.access.userIds || [];
    setUsersWithAccess(getUsersFromIds(userIds));
  }, [app.access.userIds, getUsersFromIds]);

  const onUserAdded = useCallback(
    async (
      user:
        | UserManagement.PendingUser
        | UserManagement.SignedUpUser
        | UserManagement.FleetOpsStaffUser,
    ) => {
      const newUsers = [...(app.access.userIds || []), user.id];
      const newAccess = {
        ...app.access,
        userIds: newUsers,
      };
      const newApp = {
        ...app,
        access: newAccess,
      };
      setUsersWithAccess(getUsersFromIds(newUsers));
      return updateApp(newApp);
    },
    [app, getUsersFromIds, updateApp],
  );

  const onUserRemoved = useCallback(
    async (
      user:
        | UserManagement.PendingUser
        | UserManagement.SignedUpUser
        | UserManagement.FleetOpsStaffUser,
    ) => {
      const newUsers = [...(app.access.userIds || [])].filter(
        (uid) => uid !== user.id,
      );
      const newAccess = {
        ...app.access,
        userIds: newUsers,
      };
      const newApp = {
        ...app,
        access: newAccess,
      };
      setUsersWithAccess(getUsersFromIds(newUsers));
      return updateApp(newApp);
    },
    [app, getUsersFromIds, updateApp],
  );

  return { usersWithAccess, onUserAdded, onUserRemoved };
};

export default useUsersWithAccess;
