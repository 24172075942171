import React from 'react';
import { ListItemWrapper, ListHeaderDiv } from 'components/IndexListItem';
import styled from 'styled-components';

const Col = styled.div<{ flex: number }>`
  display: flex;
  flex: ${(props) => props.flex};
  flex-direction: row;
  align-items: center;
`;

const PortalUserGridRow = ({
  isHeader,
  NameCol,
  EmailCol,
  FilterValueCol,
  ButtonsCol,
}: {
  isHeader?: boolean;
  NameCol: React.ReactElement;
  EmailCol: React.ReactElement;
  FilterValueCol?: React.ReactElement;
  ButtonsCol?: React.ReactElement;
}) => {
  const WrapperDiv = isHeader ? ListHeaderDiv : ListItemWrapper;
  return (
    <WrapperDiv style={{ gap: 16, padding: '0px 16px' }}>
      <Col flex={5}>{NameCol}</Col>
      <Col flex={4}>{EmailCol}</Col>
      <Col flex={4}>{FilterValueCol}</Col>
      <div style={{ width: 80 }}>{ButtonsCol}</div>
    </WrapperDiv>
  );
};

export default PortalUserGridRow;
