import React from 'react';
import Typography from '../../../kingpin/atoms/Typography';
import Row from '../../../components/Common/Row';
import ResourceTopBar from '../../../components/ResourceTopBar';
import portalTypeCheckers from '../../../types/portalTypeCheckers';

const TopBar = ({ portal }: { portal: ExecutivePortal | EngagementPortal }) => {
  if (portalTypeCheckers.isExecutivePortal(portal)) {
    return (
      <>
        <ResourceTopBar>
          <Row
            centerAlign
            spaceBetween
            style={{ height: '100%', width: '100%' }}
          >
            <Typography.Header type="H3">Users</Typography.Header>
          </Row>
        </ResourceTopBar>
      </>
    );
  }

  return null;
};

export default TopBar;
