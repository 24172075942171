import React, { useCallback, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import Typography from 'kingpin/atoms/Typography';
import styled from 'styled-components';
import Icon from '../atoms/Icon';
import NavSideBarContext from '../../contexts/NavSideBarContext';
import Tooltip from '../../components/Tooltip';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import NAVIGATION from './constants';
import Loading from '../../components/Loading/Loading';

export const NAV_ITEM_BOTTOM_MARGIN = 2;

const NavItemDiv = styled.div<{
  isSelected: boolean;
  hasLabel: boolean;
  isOpen: boolean;
  isTall?: boolean;
  alternativeHighLight?: boolean;
  isLoading?: boolean;
}>`
  background-color: ${(props) =>
    props.isSelected
      ? props.alternativeHighLight
        ? '#E5ECF9'
        : '#EDEDED'
      : 'unset'};

  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (props.isOpen ? 'flex-start' : 'center')};

  padding: 0px 8px;
  height: ${(props) => (props.isTall ? 38 : NAVIGATION.ITEM_HEIGHT)}px;
  gap: 6px;
  align-self: stretch;
  border-radius: 6px;
  margin-bottom: ${NAV_ITEM_BOTTOM_MARGIN}px;

  cursor: ${(props) => (props.isLoading ? 'progress' : 'pointer')};

  &:hover {
    background-color: #f6f6f6;
  }
`;

interface NavItemProps {
  to: string;
  icon?: Kingpin.Icon | JSX.Element;
  label?: string;
  tooltip?: string;
  iconColor?: string;
  onClick?: () => void;
  isActiveOverride?: boolean;
  isOpenedInNewTab?: boolean;
  renderExtra?: JSX.Element;
  onClickEvent?: Analytics.EventType;
  isTall?: boolean;
  alternativeHighLight?: boolean;
  isLoading?: boolean;
  testId?: string;
}

export const NAV_ITEM_DEFAULT_ICON_COLOR = '#1E1A26';

const NavItem = ({
  icon,
  label,
  to,
  iconColor,
  tooltip,
  onClick,
  isActiveOverride,
  isOpenedInNewTab,
  renderExtra,
  onClickEvent,
  isTall,
  alternativeHighLight,
  isLoading,
  testId,
}: NavItemProps) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const { isOpen } = useContext(NavSideBarContext);
  const hasLabel = !!label && label.length > 0 && isOpen;

  const onLinkClick = useCallback(() => {
    if (onClickEvent) {
      trackEvent(onClickEvent);
    }
    if (onClick) {
      onClick();
    }
  }, [onClick, onClickEvent, trackEvent]);

  const isKingpinIcon = (
    icon: Kingpin.Icon | JSX.Element,
  ): icon is Kingpin.Icon => typeof icon === 'string';

  return (
    <Tooltip content={isOpen ? undefined : tooltip} position="right">
      <NavLink
        data-testid={testId ? testId : `nav-${label}`}
        to={to}
        style={{ textDecoration: 'none' }}
        onClick={onLinkClick}
        target={isOpenedInNewTab ? '_blank' : undefined}
      >
        {({ isActive }) => (
          <NavItemDiv
            isLoading={isLoading}
            alternativeHighLight={alternativeHighLight}
            isSelected={
              isActiveOverride !== undefined ? isActiveOverride : isActive
            }
            hasLabel={hasLabel}
            isOpen={isOpen}
            isTall={isTall}
          >
            {icon !== undefined && !isKingpinIcon(icon) && icon}
            {icon && isKingpinIcon(icon) && (
              <div style={{ display: 'flex' }}>
                <Icon
                  icon={icon}
                  color={iconColor || NAV_ITEM_DEFAULT_ICON_COLOR}
                  width={16}
                  height={16}
                />
              </div>
            )}
            {hasLabel && (icon === undefined || isKingpinIcon(icon)) && (
              <Typography.Body
                type="Button Text"
                isEllipsis
                ellipsisPosition="right"
              >
                {label}
              </Typography.Body>
            )}
            {renderExtra && renderExtra}
            {isLoading && <Loading isTiny />}
          </NavItemDiv>
        )}
      </NavLink>
    </Tooltip>
  );
};

export default NavItem;
