import React from 'react';

/**
 * This is for the legacy programs over at NCI and Navjo
 */
interface DriverBonusContextType {
  workspace?: WorkSpace;
}

/**
 * This is for the legacy programs over at NCI and Navjo
 */
const DriverBonusConfigContext = React.createContext<DriverBonusContextType>(
  {} as DriverBonusContextType,
);

export default DriverBonusConfigContext;
