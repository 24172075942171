import firebase from 'firebase/compat/app';
import STORE_CONSTANTS from '../constants';

const converter = {
  toFirestore(program: Scoring.BonusProgram): firebase.firestore.DocumentData {
    return program;
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<Scoring.BonusProgram>,
    options: firebase.firestore.SnapshotOptions,
  ): Scoring.BonusProgram {
    const data = {
      ...snapshot.data(options),
    };

    return data;
  },
};

const getScoringProgramsRef = ({
  accountId,
  db,
}: {
  accountId: string;
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .collection(STORE_CONSTANTS.SCORING_COLLECTION)
    .withConverter(converter);
};

export default getScoringProgramsRef;
