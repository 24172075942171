import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import DateInputContext from '../../contexts/DateInputContext';
import moment from 'moment';
import _ from 'lodash';

import Buttons from './Buttons';
import InlineDialog from '../InlineDialog';
import RelativeInput from './RelativeInput';
import CalendarInput from './CalendarInput';
import {
  CURRENT_QUARTER,
  LAST_MONTH,
  NEXT_WEEK,
  YEAR_TO_DATE,
  YESTERDAY,
} from './constants';
import useDateScope from '../../hooks/useDateScope';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import PopupContext from '../../contexts/PopupContext';
import WorkSpaceContext from '../../contexts/WorkSpaceContext';
import CardContext from '../../contexts/CardContext';
import CanvasContext from '../../contexts/CanvasContext';
import PeriodSelector from '../Board/PeriodSelector';
import WallboardContext from '../../contexts/WallboardContext';
import { PortalsContext } from '../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';
import portalTypeCheckers from '../../types/portalTypeCheckers';
import BonusPortalPeriodFilterContext from '../../navigation/BonusPortalPeriodFilterContext';

const useLeftText = () => {
  const { relativeDateRange, advancedRelativeDateRange } =
    useContext(DateInputContext);

  if (relativeDateRange) {
    if (_.isEqual(relativeDateRange, YESTERDAY)) {
      return 'Yesterday';
    } else if (_.isEqual(relativeDateRange, LAST_MONTH)) {
      return 'Last Month';
    } else if (_.isEqual(relativeDateRange, YEAR_TO_DATE)) {
      return 'Year to Date';
    } else if (_.isEqual(relativeDateRange, CURRENT_QUARTER)) {
      return 'Current Quarter';
    } else if (_.isEqual(relativeDateRange, NEXT_WEEK)) {
      return 'Next Week';
    } else {
      return 'Rolling';
    }
  }

  if (advancedRelativeDateRange) {
    return 'Rolling';
  }

  return 'Custom';
};

const useRightText = () => {
  const dateScope = useDateScope({});

  const startText = moment(dateScope.startDate).format('MM/DD');
  const endText = moment(dateScope.endDate).format('MM/DD');

  return `${startText} - ${endText}`;
};

const DateInputContainer = ({
  isBonusPeriodMode,
  isDashboardCard,
  isReport,
  isDisabled,
  onDatePickerOpen,
  onDatePickerClose,
}: {
  isBonusPeriodMode?: boolean;
  isDashboardCard?: boolean;
  isReport?: boolean;
  isDisabled?: boolean;
  onDatePickerOpen?: () => void;
  onDatePickerClose?: () => void;
}) => {
  const [selectedSide, setSelectedSide] = useState<
    'left' | 'right' | undefined
  >();
  const { workSpace } = useContext(WorkSpaceContext);
  const { isWallboard } = useContext(WallboardContext);
  const { isOpen: isPopupReportOpen } = useContext(PopupContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const { i, w } = useContext(CardContext);
  const { setCardOnTopI } = useContext(CanvasContext);
  const scheduleUpdateRef = useRef<() => void>();

  const leftText = useLeftText();
  const rightText = useRightText();

  const updateDialogLayout = useCallback(() => {
    setTimeout(() => {
      if (scheduleUpdateRef.current) {
        scheduleUpdateRef.current();
      }
    }, 50);
  }, []);

  const onClosed = useCallback(() => {
    setSelectedSide(undefined);
    setCardOnTopI(undefined);
    if (onDatePickerClose) {
      onDatePickerClose();
    }
  }, [onDatePickerClose, setCardOnTopI]);

  const onLeftClicked = useCallback(() => {
    if (selectedSide === 'left') {
      setSelectedSide(undefined);
      onClosed();
      return;
    }

    setSelectedSide(undefined);
    setSelectedSide('left');
    updateDialogLayout();
    setCardOnTopI(i);
    if (onDatePickerOpen) {
      onDatePickerOpen();
    }
  }, [
    i,
    onClosed,
    onDatePickerOpen,
    selectedSide,
    setCardOnTopI,
    updateDialogLayout,
  ]);

  const onRightClicked = useCallback(() => {
    if (selectedSide === 'right') {
      setSelectedSide(undefined);
      onClosed();
      return;
    }

    setSelectedSide(undefined);
    setSelectedSide('right');
    updateDialogLayout();
    setCardOnTopI(i);
    if (onDatePickerOpen) {
      onDatePickerOpen();
    }
  }, [
    i,
    onClosed,
    onDatePickerOpen,
    selectedSide,
    setCardOnTopI,
    updateDialogLayout,
  ]);

  useEffect(() => {
    if (selectedSide) {
      if (isDashboardCard) {
        trackEvent('Dashboard Card - Opened Date Filter', {
          isWallboardSlide: isWallboard ? 'true' : 'false',
        });
      } else if (isReport) {
        if (isPopupReportOpen) {
          trackEvent('Popup Report - Opened Date Filter');
        } else {
          trackEvent('Report - Opened Date Filter');
        }
      }
    }
  }, [
    isDashboardCard,
    isPopupReportOpen,
    isReport,
    isWallboard,
    selectedSide,
    trackEvent,
  ]);

  if (isBonusPeriodMode) {
    if (workSpace) {
      return null;
    } else {
      return (
        <div>
          <PeriodSelector
            isWorkSpaceTopBar={false}
            isBonusPeriodMode
            onDatePickerOpen={onDatePickerOpen}
            onDatePickerClose={onDatePickerClose}
          />
        </div>
      );
    }
  } else if (workSpace && workSpace.campaignType === 'driverBonus') {
    return null;
  } else {
    return (
      <InlineDialog
        placement={'bottom-start'}
        content={
          selectedSide === 'left' ? (
            <RelativeInput updateDialogLayout={updateDialogLayout} />
          ) : (
            <CalendarInput onClosed={onClosed} />
          )
        }
        isOpen={!!selectedSide}
        onClose={onClosed}
      >
        <Buttons
          isOpen={!!selectedSide}
          isDisabled={isDisabled}
          leftText={leftText}
          isLeftSelected={selectedSide === 'left'}
          onLeftClicked={onLeftClicked}
          rightText={rightText}
          isRightSelected={selectedSide === 'right'}
          onRightClicked={onRightClicked}
          isNarrowCard={w ? w <= 3 : false}
          isDashboardCard={w !== undefined}
        />
      </InlineDialog>
    );
  }
};

const Gate = ({
  isBonusPeriodMode,
  isDashboardCard,
  isReport,
  isDisabled,
  onDatePickerOpen,
  onDatePickerClose,
}: {
  isBonusPeriodMode?: boolean;
  isDashboardCard?: boolean;
  isReport?: boolean;
  isDisabled?: boolean;
  onDatePickerOpen?: () => void;
  onDatePickerClose?: () => void;
}) => {
  const { selectedPortal } = useContext(PortalsContext);
  const { isPeriodFilterEnabled } = useContext(BonusPortalPeriodFilterContext);

  if (
    portalTypeCheckers.isBonusPortal(selectedPortal) &&
    isPeriodFilterEnabled
  ) {
    return null;
  }

  return (
    <DateInputContainer
      isBonusPeriodMode={isBonusPeriodMode}
      isDashboardCard={isDashboardCard}
      isReport={isReport}
      isDisabled={isDisabled}
      onDatePickerOpen={onDatePickerOpen}
      onDatePickerClose={onDatePickerClose}
    />
  );
};

export default Gate;
