import styled from 'styled-components';

const AppIconDiv = styled.div`
  border-radius: 6px;

  box-shadow: 0px 0px 0px 1px #1c284005 inset;

  box-shadow: 0px 4px 8px -4px #1c284014;

  box-shadow: 0px 4px 12px -2px #1c284014;
`;

export default AppIconDiv;
