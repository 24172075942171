import React, { useCallback, useContext } from 'react';
import DriverSummarySection from './DriverSummarySection';
import useDriverBonusFeedback from '../../../hooks/useDriverBonusFeedback';
import AnalyticsContext from '../../../contexts/AnalyticsContext';

const DriverSummarySectionContainer = ({
  category,
  driver,
  bonusPeriod,
  isTooltip,
}: {
  category: string;
  driver: string;
  bonusPeriod: BonusPeriod | Scoring.BonusPeriod;
  isTooltip?: boolean;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const { data, isCategoryPassed } = useDriverBonusFeedback(
    category,
    driver,
    bonusPeriod,
  );
  const onInfoTooltipRead = useCallback(() => {
    if (isTooltip) {
      return;
    }

    trackEvent('Driver Bonus - Bonus info tooltip opened', {
      category,
      driver,
    });
  }, [category, driver, isTooltip, trackEvent]);

  return (
    <DriverSummarySection
      data={data}
      category={category}
      isCategoryPassed={isCategoryPassed}
      isTooltip={isTooltip}
      onInfoTooltipRead={onInfoTooltipRead}
    />
  );
};

export default DriverSummarySectionContainer;
