import { useCallback, useContext, useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import AuthenticationContext from '../../../../contexts/AuthenticationContext';
import isDefined from '../../../../isDefined';
import USER_ROLES from '../../../../roles';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';
import { AUTH_ERROR_MSG_KEY } from '../../../../screens/Login/ErrorBanner';
import STORE from '../../../../store';

const useUserAccounts = () => {
  const { uid, isSuperAdmin, isWallboardUser, wallBoardAccountId } = useContext(
    AuthenticationContext,
  );
  const { isFleetOpsStaff } = useContext(CurrentUserContext);
  const [accounts, setAccounts] = useState<FleetOps.UserAccountAccess[]>([]);
  const [quoteAccessAccounts, setQuoteAccessAccounts] = useState<
    FleetOps.UserAccountAccess[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getAccountInfo = useCallback(async (accountId: string) => {
    const doc = await STORE.getAccountRef({ accountId }).get();
    if (doc.exists) {
      const data = doc.data();
      if (data) {
        if (data.status === 'active' || data.status === 'pending') {
          return {
            accountName: data.carrier,
            isTemplateAccount: !!data.isTemplateAccount,
            isDemoAccount: !!data.isDemoAccount,
            tms: data.tms,
          };
        }
      }
    }
  }, []);

  const getSuperAdminAccounts = useCallback(async () => {
    const allAccounts = await (async () => {
      const docs = await STORE.getAccountsRef({}).get();
      const accounts: FleetOps.UserAccountAccess[] = [];
      docs.forEach((doc) => {
        const data = doc.data();
        if (data.status === 'active' || data.status === 'pending') {
          accounts.push({
            accountName: data.carrier,
            accountId: doc.id,
            isTemplateAccount: !!data.isTemplateAccount,
            isDemoAccount: !!data.isDemoAccount,
            permissions: [],
            roles: [],
            tms: data.tms,
          });
        }
      });

      return accounts;
    })();

    const newAccounts: FleetOps.UserAccountAccess[] = [];
    allAccounts.forEach((a) => {
      newAccounts.push({
        accountId: a.accountId,
        accountName: a.accountName,
        isTemplateAccount: !!a.isTemplateAccount,
        isDemoAccount: a.isDemoAccount,
        roles: ['fleetops.roles.superadmin'],
        permissions: [],
        tms: a.tms,
      });
    });

    return newAccounts;
  }, []);

  const getQuoteAccessAccounts = useCallback(async () => {
    const allAccounts = await (async () => {
      const docs = await STORE.getAccountsRef({}).get();
      const accounts = [] as { id: string; name: string }[];
      docs.forEach((doc) => {
        const data = doc.data();
        if (data.status === 'active' || data.status === 'pending') {
          accounts.push({
            id: doc.id,
            name: data.carrier,
          });
        }
      });

      return accounts;
    })();

    const newAccounts: FleetOps.UserAccountAccess[] = [];
    allAccounts.forEach((a) => {
      newAccounts.push({
        accountId: a.id,
        accountName: a.name,
        isTemplateAccount: false,
        isDemoAccount: false,
        roles: [USER_ROLES.FLEETOPS_SALES],
        permissions: [],
        tms: 'NA',
      });
    });

    return newAccounts;
  }, []);

  const getWallboardAccounts = useCallback(async () => {
    if (!wallBoardAccountId) {
      return undefined;
    }

    const doc = await STORE.getAccountRef({
      accountId: wallBoardAccountId,
    }).get();

    const account = doc.data();
    if (!account) {
      return;
    }

    return [
      {
        accountId: doc.id,
        accountName: account.carrier,
        isTemplateAccount: !!account.isTemplateAccount,
        isDemoAccount: !!account.isDemoAccount,
        roles: [],
        permissions: [],
        tms: account.tms,
      },
    ];
  }, [wallBoardAccountId]);

  useEffect(() => {
    if (!isFleetOpsStaff) {
      return;
    }
    getQuoteAccessAccounts().then((newAccounts) => {
      setQuoteAccessAccounts(newAccounts);
    });
  }, [getQuoteAccessAccounts, isFleetOpsStaff]);

  const refreshUserAccounts = useCallback(() => {
    if (isSuperAdmin) {
      getSuperAdminAccounts().then((newAccounts) => {
        setAccounts(newAccounts);
        setIsLoading(false);
      });
      return;
    }
    if (isWallboardUser) {
      getWallboardAccounts().then((newAccounts) => {
        if (newAccounts) {
          setAccounts(newAccounts);
        } else {
          setAccounts([]);
        }
        setIsLoading(false);
      });

      return;
    }

    if (!uid) {
      setAccounts([]);
      setIsLoading(true);
      return;
    }

    STORE.getUserProfilesRef({ userId: uid }).onSnapshot((doc) => {
      if (doc.exists) {
        const profile = doc.data();
        if (!profile) {
          return;
        }
        const promises = Object.entries(profile.accounts).map(
          async ([key, access]) => {
            if (access === null) {
              return undefined;
            }
            const info = await getAccountInfo(key);
            if (!info) {
              return undefined;
            }
            const { accountName, isTemplateAccount, isDemoAccount, tms } = info;

            return {
              accountId: key,
              accountName,
              isTemplateAccount,
              roles: access.roles,
              permissions: access.permissions,
              isDemoAccount,
              tms,
            };
          },
        );
        Promise.all(promises).then((accountInfo) => {
          setAccounts(accountInfo.filter(isDefined));
          setIsLoading(false);
        });
      } else {
        window.localStorage.setItem(
          AUTH_ERROR_MSG_KEY,
          'You do not have access to FleetOps. Contact your IT admin',
        );
        firebase.auth().signOut();
      }
    });
  }, [
    getAccountInfo,
    getSuperAdminAccounts,
    getWallboardAccounts,
    isSuperAdmin,
    isWallboardUser,
    uid,
  ]);

  useEffect(() => {
    refreshUserAccounts();
  }, [refreshUserAccounts]);

  return { isLoading, accounts, quoteAccessAccounts, refreshUserAccounts };
};

export default useUserAccounts;
