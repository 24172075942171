import React, { useCallback, useContext } from 'react';
import { ScreenWrapper } from '../../../navigation/styles';
import TopBar from './TopBar';
import useFilteredManagers from './useFilteredManagers';
import TextInput from '../../../kingpin/atoms/TextInput';
import Typography from '../../../kingpin/atoms/Typography';
import styled from 'styled-components';
import Button from '../../../kingpin/atoms/Button';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import portalTypeCheckers from '../../../types/portalTypeCheckers';
import STORE from '../../../store';
import Row from '../../../components/Common/Row';
import usePopup from '../../../hooks/usePopup';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import AddUsersPopup from './AddUsersPopup';
import PermissionGates from '../../../components/PermissionGates';
import PERMISSIONS from '../../../permissionDefinitions';
import PortalUserGridRow from '../EngagementPortalUsers/PortalMappedUsers/PortalUserGridRow';

const ListWrapper = styled.div`
  flex: 1;
  padding: 0px 24px 16px 24px;
  overflow-y: auto;
`;

const List = styled.div`
  max-height: 100%;
  overflow-y: scroll;
  padding: 0px;
  padding-bottom: 64px;
`;

const UserListItem = ({
  portal,
  user,
}: {
  portal: ExecutivePortal | EngagementPortal;
  user: UserManagement.PendingUser | UserManagement.SignedUpUser;
}) => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const onRemoved = useCallback(() => {
    const newManagers = portal.usersIdsWithAccess.filter(
      (mId) => mId !== user.id,
    );
    const newPortal: ExecutivePortal | EngagementPortal = {
      ...portal,
      usersIdsWithAccess: newManagers,
    };

    if (portalTypeCheckers.isExecutivePortal(newPortal)) {
      STORE.contentDistributions
        .getExecutivePortalsRef({ accountId: selectedAccountId })
        .doc(portal.id)
        .set(newPortal);
    } else if (portalTypeCheckers.isEngagementPortal(newPortal)) {
      STORE.contentDistributions
        .getEngagementPortalsRef({ accountId: selectedAccountId })
        .doc(portal.id)
        .set(newPortal);
    }
  }, [portal, selectedAccountId, user.id]);

  return (
    <PortalUserGridRow
      NameCol={
        <Typography.Body type="Label">{user.displayName}</Typography.Body>
      }
      EmailCol={<Typography.Body type="Label">{user.email}</Typography.Body>}
      ButtonsCol={
        <Button
          size={'Small'}
          type={'Ghost'}
          icon={'cross'}
          onClick={onRemoved}
        />
      }
    />
  );
};

const AddUsersButton = ({
  portal,
}: {
  portal: ExecutivePortal | EngagementPortal;
}) => {
  const { isOpen, open, close } = usePopup();
  const noun = portalTypeCheckers.isExecutivePortal(portal)
    ? 'Executive'
    : 'Manager';

  return (
    <>
      <PermissionGates.Has
        requiredPermission={PERMISSIONS.CONTENT_ACCESS.ADD_USERS_TO_PORTAL}
      >
        <Button
          size={'Small'}
          type={'Secondary'}
          icon={'add'}
          label={`Add ${noun}`}
          onClick={open}
        />
      </PermissionGates.Has>
      <ModalTransition>
        {isOpen && (
          <Modal
            onClose={close}
            shouldScrollInViewport={false}
            autoFocus={false}
          >
            <AddUsersPopup portal={portal} close={close} />
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

const PortalUsers = ({
  portal,
}: {
  portal: ExecutivePortal | EngagementPortal;
}) => {
  const { searchText, onSearchTextChanged, filteredManagers } =
    useFilteredManagers(portal);

  return (
    <>
      <TopBar portal={portal} />
      <Row spaceBetween centerAlign style={{ paddingRight: 32 }}>
        <div style={{ width: 400, padding: '16px 24px' }}>
          <TextInput
            value={searchText}
            onChange={onSearchTextChanged}
            icon="search"
            inputSize="Small"
            placeholder="Search"
            autoFocus
          />
        </div>
        <AddUsersButton portal={portal} />
      </Row>
      <PortalUserGridRow
        isHeader
        NameCol={<Typography.Body type="Label">Name</Typography.Body>}
        EmailCol={<Typography.Body type="Label">Email</Typography.Body>}
      />
      <ListWrapper>
        <List>
          {filteredManagers.map((manager) => (
            <UserListItem portal={portal} user={manager} key={manager.id} />
          ))}
        </List>
      </ListWrapper>
    </>
  );
};

/**
 * We use this to manage usersIdsWithAccess
 */
const Gate = ({
  portal,
}: {
  portal: ExecutivePortal | EngagementPortal | BonusPortal;
}) => {
  if (portalTypeCheckers.isExecutivePortal(portal)) {
    return (
      <ScreenWrapper>
        <PortalUsers portal={portal} />
      </ScreenWrapper>
    );
  }

  if (portalTypeCheckers.isBonusPortal(portal)) {
    return (
      <ScreenWrapper>
        <Typography.Header type={'H3'}>
          This screen is still being developed
        </Typography.Header>
      </ScreenWrapper>
    );
  }

  return <PortalUsers portal={portal} />;
};

export default Gate;
