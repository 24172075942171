import { useCallback, useContext, useMemo, useState } from 'react';
import usePerformanceFilterInput from '../../../hooks/usePerformanceSlideOutFilterInput';
import { usePlatesToFilterInput } from '../../../../../screens/GoalShow/useGoalData';
import useFullDataInterval from '../../../hooks/useFullDataInterval';
import fetchRecords from './fetchRecords';
import GqlClientContext from '../../../../../contexts/GqlClientContext';
import useLockedDebouncedEffect from '../../../../../hooks/useLockedDebouncedEffect';

const useRelatedDocuments = ({
  section,
}: {
  section: PerformanceBoardTypes.SlideOut.RelatedDocumentsSection;
}) => {
  const { client } = useContext(GqlClientContext);
  const [documents, setDocuments] = useState<ElasticDocument[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const platesToFilterInput = usePlatesToFilterInput();
  const performanceFilterInput = usePerformanceFilterInput();

  const filterInput = useMemo(() => {
    const fi: FilterInput[] = [];
    if (performanceFilterInput) {
      fi.push(performanceFilterInput);
    }

    const sectionFilter = platesToFilterInput(section.scope);
    fi.push(sectionFilter);

    return fi;
  }, [performanceFilterInput, platesToFilterInput, section.scope]);

  const { dateScope } = useFullDataInterval({
    mode: section.dateRange.mode,
    n: section.dateRange.n,
    isToDate: section.dateRange.isToDate,
  });

  const args: RelatedDocumentsTypes.FetchDocumentsArgs | undefined =
    useMemo(() => {
      if (!dateScope) {
        return undefined;
      }

      return {
        filters: filterInput,
        dateScope: dateScope,
        dataType: section.dataset,
        client,
      };
    }, [client, dateScope, filterInput, section.dataset]);

  const getDocuments = useCallback(
    async (args: RelatedDocumentsTypes.FetchDocumentsArgs | undefined) => {
      if (!args) {
        return [];
      }
      setIsLoading(true);
      const newDocs = await fetchRecords(args);
      return newDocs;
    },
    [],
  );

  const responseHandler = useCallback((docs: ElasticDocument[]) => {
    setDocuments(docs);
    setIsLoading(false);
  }, []);

  useLockedDebouncedEffect({
    args,
    callback: getDocuments,
    responseHandler,
  });

  return {
    isLoading,
    documents,
  };
};

export default useRelatedDocuments;
