import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import Row from 'components/Common/Row';
import Dropdown from 'components/Inputs/Dropdown';
import DashboardsContext from 'contexts/DashboardsContext';
import getIdentifier from 'getIdentifier';
import FormContent from 'kingpin/forms/FormContent';
import FormInput from 'kingpin/forms/FormInput';
import Button from 'kingpin/atoms/Button';
import TextInput from 'kingpin/atoms/TextInput';
import IconPicker from '../../../../atoms/IconPicker';
import { NAV_ITEM_DEFAULT_ICON_COLOR } from '../../../NavItem';

const NavDashboardForm = ({
  item,
  onSave,
  close,
}: {
  item: PortalsNav.Dashboard | undefined;
  onSave: (item: PortalsNav.Dashboard) => void;
  close: () => void;
}) => {
  const { allDashboards } = useContext(DashboardsContext);

  const [key] = useState<string>(
    item ? item.key : getIdentifier(undefined, true),
  );
  const [label, setLabel] = useState<string>(item ? item.label : '');
  const [icon, setIcon] = useState<Kingpin.Icon | undefined>(
    item ? item.icon : undefined,
  );
  const [dashboardId, setDashboardId] = useState<string | undefined>(
    item ? item.dashboardId : undefined,
  );
  const [draftItem, setDraftItem] = useState<PortalsNav.Dashboard | undefined>(
    item,
  );

  useEffect(() => {
    if (label === '' || !dashboardId) {
      setDraftItem(undefined);
      return;
    }

    setDraftItem({
      key,
      label,
      dashboardId,
      type: 'Dashboard',
      icon,
    });
  }, [key, label, dashboardId, icon]);

  const onSaveClicked = useCallback(() => {
    if (!draftItem) {
      return;
    }

    onSave(draftItem);
    close();
  }, [close, draftItem, onSave]);

  const dashboardOptions = useMemo((): DropdownOption[] => {
    return allDashboards.map((r) => ({
      key: r.id,
      label: r.name,
      isSelected: dashboardId === r.id,
      onSelected: () => {
        setDashboardId(r.id);
      },
    }));
  }, [allDashboards, dashboardId]);

  return (
    <div>
      <FormContent>
        <FormInput label={'Label'}>
          <TextInput
            value={label}
            onChange={(event) => {
              setLabel(event.target.value);
            }}
          />
        </FormInput>
        <FormInput label={'Icon'}>
          <IconPicker
            setSelectedIcon={setIcon}
            selectedIcon={icon}
            iconColor={NAV_ITEM_DEFAULT_ICON_COLOR}
          />
        </FormInput>
        <FormInput label={'Dashboard'}>
          <Dropdown
            options={dashboardOptions}
            placeholder="Select a Dashboard..."
            isSearchEnabled
          />
        </FormInput>
      </FormContent>
      <Row centerAlign spaceBetween>
        <Button
          size={'Small'}
          type={'Secondary'}
          label={'Cancel'}
          onClick={close}
        />
        <Button
          isDisabled={draftItem === undefined}
          onClick={onSaveClicked}
          label={item ? 'Update' : 'Add'}
          type={'Primary'}
          size={'Small'}
        />
      </Row>
    </div>
  );
};

export default NavDashboardForm;
