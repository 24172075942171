const isPersistedGeneralApp = (
  app: Apps.PersistedGeneralApp | Apps.AnalyticsApp | Apps.GeneralApp,
): app is Apps.PersistedGeneralApp => {
  return app.type === 'General' && !('portals' in app);
};

const isGeneralApp = (
  app: Apps.PersistedGeneralApp | Apps.AnalyticsApp | Apps.GeneralApp,
): app is Apps.GeneralApp => {
  return app.type === 'General' && 'portals' in app;
};

const isAnalyticsApp = (app: Apps.AnalyticsApp): app is Apps.AnalyticsApp => {
  return app.type === 'Analytics';
};

const appTypeCheckers = {
  isPersistedGeneralApp,
  isGeneralApp,
  isAnalyticsApp,
};

export default appTypeCheckers;
