import React, { useCallback, useEffect, useState } from 'react';
import useCurrentManagers from './useCurrentManagers';

const useFilteredManagers = (portal: ExecutivePortal | EngagementPortal) => {
  const currentManagers = useCurrentManagers(portal.usersIdsWithAccess);
  const [searchText, setSearchText] = useState<string>('');
  const onSearchTextChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [],
  );
  const getFilteredManagers = useCallback(() => {
    return currentManagers.filter((u) =>
      u.displayName.toLowerCase().includes(searchText.toLowerCase()),
    );
  }, [currentManagers, searchText]);

  const [filteredManagers, setFilteredManagers] = useState<
    (UserManagement.PendingUser | UserManagement.SignedUpUser)[]
  >(() => getFilteredManagers());

  useEffect(() => {
    setFilteredManagers(getFilteredManagers());
  }, [getFilteredManagers]);

  return {
    filteredManagers,
    searchText,
    onSearchTextChanged,
  };
};

export default useFilteredManagers;
