import React from 'react';

interface BonusPeriodsContextType {
  bonusPeriods: BonusPeriod[] | Scoring.BonusPeriod[];
  selectedBonusPeriod?: BonusPeriod | Scoring.BonusPeriod;
  setSelectedBonusPeriod: (
    bp: BonusPeriod | Scoring.BonusPeriod | undefined,
  ) => void;
}

const BonusPeriodsContext = React.createContext<BonusPeriodsContextType>({
  bonusPeriods: [],
  setSelectedBonusPeriod: () => {},
});

export default BonusPeriodsContext;
