import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import Row from 'components/Common/Row';
import Dropdown from 'components/Inputs/Dropdown';
import getIdentifier from 'getIdentifier';
import FormContent from 'kingpin/forms/FormContent';
import FormInput from 'kingpin/forms/FormInput';
import Button from 'kingpin/atoms/Button';
import TextInput from 'kingpin/atoms/TextInput';
import IconPicker from 'kingpin/atoms/IconPicker';
import ScorecardsContext from 'contexts/ScorecardsContext';
import { NAV_ITEM_DEFAULT_ICON_COLOR } from '../../../NavItem';

const NavScorecardsIndexForm = ({
  item,
  onSave,
  close,
}: {
  item: PortalsNav.ScorecardsIndex | undefined;
  onSave: (item: PortalsNav.ScorecardsIndex) => void;
  close: () => void;
}) => {
  const { scorecards } = useContext(ScorecardsContext);

  const [key] = useState<string>(
    item ? item.key : getIdentifier(undefined, true),
  );
  const [label, setLabel] = useState<string>(item ? item.label : '');
  const [icon, setIcon] = useState<Kingpin.Icon | undefined>(
    item ? item.icon : undefined,
  );
  const [scorecardIds, setScorecardsIds] = useState<string[]>(
    item ? item.scorecardIds : [],
  );
  const [draftItem, setDraftItem] = useState<
    PortalsNav.ScorecardsIndex | undefined
  >(item);

  useEffect(() => {
    if (label === '' || scorecardIds.length === 0) {
      setDraftItem(undefined);
      return;
    }

    setDraftItem({
      key,
      label,
      scorecardIds,
      type: 'Scorecards Index',
      icon,
    });
  }, [icon, key, label, scorecardIds]);

  const onSaveClicked = useCallback(() => {
    if (!draftItem) {
      return;
    }

    onSave(draftItem);
    close();
  }, [close, draftItem, onSave]);

  const scorecardOptions = useMemo((): DropdownOption[] => {
    return scorecards.map((r) => {
      return {
        key: r.id,
        label: r.title,
        isSelected: scorecardIds.includes(r.id),
        onSelected: () => {
          setScorecardsIds((current) => {
            const isSelected = current.includes(r.id);
            if (isSelected) {
              return current.filter((rid) => rid !== r.id);
            }
            return [...current, r.id];
          });
        },
      };
    });
  }, [scorecardIds, scorecards]);

  return (
    <div>
      <FormContent>
        <FormInput label={'Label'}>
          <TextInput
            value={label}
            onChange={(event) => {
              setLabel(event.target.value);
            }}
          />
        </FormInput>
        <FormInput label={'Icon'}>
          <IconPicker
            setSelectedIcon={setIcon}
            selectedIcon={icon}
            iconColor={NAV_ITEM_DEFAULT_ICON_COLOR}
          />
        </FormInput>
        <FormInput label={'KPI Lists'}>
          <Dropdown
            options={scorecardOptions}
            isMulti
            isSearchEnabled
            placeholder="Select KPI Lists..."
          />
        </FormInput>
      </FormContent>
      <Row centerAlign spaceBetween>
        <Button
          size={'Small'}
          type={'Secondary'}
          label={'Cancel'}
          onClick={close}
        />
        <Button
          isDisabled={draftItem === undefined}
          onClick={onSaveClicked}
          label={item ? 'Update' : 'Add'}
          type={'Primary'}
          size={'Small'}
        />
      </Row>
    </div>
  );
};

export default NavScorecardsIndexForm;
