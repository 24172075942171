import React, { useCallback, useContext, useEffect, useState } from 'react';
import GridLayout, { Layout } from 'react-grid-layout';
import _ from 'lodash';
import ReportCanvas from './ReportCanvas';
import useWindowSize from '../../hooks/useWindowSize';
import buildLayout from './buildLayout';
import CanvasContext from '../../contexts/CanvasContext';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import DashboardContext from '../../contexts/DashboardContext';
import EmptyReport from './EmptyReport';
import EmptyDashboard from './EmptyDashboard';
import isDefined from '../../isDefined';
import {
  CANVAS_VERTICAL_SNAPPING_POINTS,
  EXCLUDE_HEIGHT,
} from '../../constants';
import ReportContext from '../../contexts/ReportContext';
import WallboardContext from '../../contexts/WallboardContext';
import useCanvasWidth from './useCanvasWidth';
import {
  CANVAS_ITEM_MARGIN,
  CANVAS_PADDING_VERTICAL,
  POPUP_REPORT_HEIGHT,
} from './canvasConstants';
import PopupInnerContext from '../../contexts/PopupInnerContext';

export const getCanvasMode = ({
  isMobile,
}: {
  isMobile: boolean;
}): CanvasMode => {
  if (isMobile) {
    return 'mobile';
  } else {
    return 'desktop';
  }
};

const ReportCanvasContainer = ({
  currentCanvas,
  setCurrentCanvas,
  isEditing,
  previewCanvasMode,
  startEditing,
}: {
  currentCanvas: Canvas;
  setCurrentCanvas: React.Dispatch<React.SetStateAction<Canvas>>;
  isEditing: boolean;
  previewCanvasMode?: CanvasMode;
  startEditing: () => void;
}) => {
  const { isPopup } = useContext(PopupInnerContext);
  const { dashboard } = useContext(DashboardContext);
  const { report } = useContext(ReportContext);
  const { isWallboard, dashboardZoom } = useContext(WallboardContext);
  const isDashboard = dashboard !== undefined;
  const { isMobile } = useWindowSize();
  const [cardOnTopI, setCardOnTopI] = useState<string | undefined>();
  const [draggingI, setDraggingI] = useState<string | undefined>();
  const [isWindowResizing, setIsWindowResizing] = useState<boolean>(false);
  const { trackEvent } = useContext(AnalyticsContext);
  const [canvasRowHeight, setCanvasRowHeight] = useState<number>(
    Math.floor((window.innerHeight - 300) / CANVAS_VERTICAL_SNAPPING_POINTS),
  );
  const { canvasWidth, canvasDivRef } = useCanvasWidth(currentCanvas);

  const canvasMode =
    previewCanvasMode ||
    getCanvasMode({
      isMobile,
    });
  const { cards } = currentCanvas;
  const cardsToUse = cards.filter((c) => Object.keys(c.layout).length > 1);
  const layout = buildLayout({
    cards: cardsToUse,
    isEditing,
    canvasMode:
      previewCanvasMode ||
      getCanvasMode({
        isMobile,
      }),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDragEnd = useCallback(
    _.debounce(() => {
      setIsWindowResizing(false);
    }, 1500),
    [],
  );

  const onResize = useCallback(() => {
    const idPostFix = (() => {
      if (report) {
        return report.id;
      }

      if (dashboard) {
        return dashboard.id;
      }
    })();

    setIsWindowResizing(true);

    const excludeHeights = document.getElementsByClassName(
      `${EXCLUDE_HEIGHT}-${idPostFix}`,
    );
    let heightReduction = 0;
    for (let i = 0; i < excludeHeights.length; i++) {
      heightReduction += excludeHeights[i].clientHeight;
    }
    const pageHeight = (() => {
      if (isPopup) {
        const r = document.getElementById('report');
        if (!r) {
          return (
            document.body.clientHeight *
            POPUP_REPORT_HEIGHT.CANVAS_SIZE_MULTIPLIER
          );
        }
        return r.clientHeight;
      }

      return document.body.clientHeight;
    })();
    const canvasHeight =
      (pageHeight - heightReduction) / (isWallboard ? dashboardZoom : 1);
    const marginsPerPage = CANVAS_VERTICAL_SNAPPING_POINTS - 1;
    const rowHeight = Math.floor(
      (canvasHeight -
        marginsPerPage * CANVAS_ITEM_MARGIN -
        CANVAS_PADDING_VERTICAL * 2) /
        CANVAS_VERTICAL_SNAPPING_POINTS,
    );
    setCanvasRowHeight(rowHeight);

    onDragEnd();
  }, [dashboard, isPopup, isWallboard, onDragEnd, report, dashboardZoom]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      return;
    }
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
    // We want this to trigger when isEditing changes
  }, [onResize, isEditing]);

  const onLayoutChange = useCallback(
    (newLayout: GridLayout.Layout[]) => {
      const sanitizeLayout = (layout: GridLayout.Layout[]) => {
        return layout.filter((l) => l.i !== undefined && l.i !== 'null');
      };

      if (
        !isEditing ||
        _.isEqual(sanitizeLayout(newLayout), sanitizeLayout(layout))
      ) {
        return;
      }

      if (isDashboard) {
        trackEvent('Dashboard - Edit - Card Moved', {
          isWallboardSlide: isWallboard ? 'true' : 'false',
        });
      } else {
        trackEvent('Report - Edit - Card Moved');
      }

      setCurrentCanvas(
        (can) =>
          ({
            ...can,
            cards: can.cards
              .map((c) => {
                const l = newLayout.find((l) => l.i === c.layout.i);
                if (!l) {
                  return undefined;
                }
                return {
                  ...c,
                  layout: {
                    ...l,
                    static: false,
                  },
                } as CanvasCard.Card;
              })
              .filter(isDefined),
          }) as Canvas,
      );
    },
    [isDashboard, isEditing, isWallboard, layout, setCurrentCanvas, trackEvent],
  );

  const removeGadget = (i: string) => {
    if (isDashboard) {
      trackEvent('Dashboard - Edit - Card Removed', {
        isWallboardSlide: isWallboard ? 'true' : 'false',
      });
    } else {
      trackEvent('Report - Edit - Card Removed');
    }

    const newCards = currentCanvas.cards
      .filter((c) => {
        return c.layout.i !== i;
      })
      .filter((c) => Object.keys(c.layout).length > 1);

    setCurrentCanvas({
      ...currentCanvas,
      cards: newCards,
    });
  };

  const onResizeStart = (layout: Layout, oldItem: any, newItem: any) => {
    setDraggingI(newItem.i);
  };

  const onResizeStop = () => {
    setDraggingI(undefined);
  };

  const updateCard = useCallback(
    (newCard: CanvasCard.Card) => {
      setCurrentCanvas((can) => ({
        ...can,
        cards: can.cards
          .map((c: CanvasCard.Card) => {
            if (c.layout.i === newCard.layout.i) {
              return newCard;
            }
            return c;
          })
          .filter(isDefined)
          .filter((c: CanvasCard.Card) => Object.keys(c.layout).length > 1),
      }));
    },
    [setCurrentCanvas],
  );

  if (!isDashboard && cards.length === 0) {
    return <EmptyReport />;
  }

  if (isDashboard && cards.length === 0) {
    return (
      <EmptyDashboard
        startEditing={startEditing}
        setCurrentCanvas={setCurrentCanvas}
        canvasMode={canvasMode}
      />
    );
  }

  return (
    <div
      style={{
        position: 'relative',
      }}
      ref={canvasDivRef}
    >
      <CanvasContext.Provider
        value={{
          cardOnTopI,
          setCardOnTopI,
          isEditing,
          canvas: currentCanvas,
          updateCard,
        }}
      >
        {canvasWidth !== undefined && (
          <ReportCanvas
            cards={cardsToUse}
            layout={layout}
            onLayoutChange={onLayoutChange}
            removeGadget={removeGadget}
            isEditing={isEditing}
            cardOnTopI={cardOnTopI}
            canvasMode={canvasMode}
            onResizeStart={onResizeStart}
            onResizeStop={onResizeStop}
            draggingI={draggingI}
            isWindowResizing={isWindowResizing}
            canvasRowHeight={canvasRowHeight}
            canvasWidth={canvasWidth}
          />
        )}
      </CanvasContext.Provider>
    </div>
  );
};

export default ReportCanvasContainer;
