import React from 'react';
import Card from '../Common/Card';

const GoalCardWrapper = ({
  children,
  isCompact,
  isScorecard,
  isWallboard,
}: {
  children: JSX.Element | JSX.Element[];
  isCompact?: boolean;
  isScorecard?: boolean;
  isWallboard?: boolean;
}) => {
  return (
    <Card
      style={{
        padding: 16,
        paddingBottom: isCompact ? 12 : 0,
        marginRight: isWallboard ? 0 : 32,
        marginBottom: isCompact ? 0 : 16,
        width: isCompact ? '100%' : 'calc(50% - 32px)',
        minHeight: isCompact ? 90 : isScorecard ? 300 : 550,
        height: isWallboard ? 106 : isCompact ? 90 : isScorecard ? 300 : 550,
      }}
      compactShadow={isCompact}
    >
      {children}
    </Card>
  );
};

export default GoalCardWrapper;
