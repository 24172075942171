import React from 'react';

import TasksListHeader from '../TasksList/TasksListHeader';
import TasksList from '../TasksList';
import useWindowSize from '../../hooks/useWindowSize';
import Card from '../Common/Card';

const TasksCard = ({ isFullWidth }: { isFullWidth?: boolean }) => {
  const { isMobile } = useWindowSize();

  return (
    <Card
      style={{
        minHeight: 400,
        flex: 1,
        marginBottom: isMobile ? 16 : 0,
        height: '100%',
        maxWidth: isFullWidth ? '100%' : 800,
        position: 'relative',
      }}
    >
      <TasksListHeader />
      <TasksList isFullWidth={isFullWidth} />
    </Card>
  );
};

export default TasksCard;
