import StatusFlagCell from '../StatusFlagCell';
import JobCellRenderer from './JobNoCellRenderer';
import CustomerLaneCellRenderer from '../CustomerLaneCellRenderer';
import ReasonCodeCellRenderer, {
  ReasonCodePickerRenderer,
} from '../ReasonCodeCellRenderer';
import OnTargetCellRenderer from '../OnTargetCellRenderer';
import ContractOnTargetCellRenderer from '../ContractOnTargetCellRenderer';
import ProgressCellRenderer from '../ProgressCellRenderer';
import ContractProgressCellRenderer from '../ContractProgressCell';
import CustomerWithHoverMenuRenderer from '../CustomerWithHoverMenuRenderer';
import CurrencyCell from '../CurrencyCell';
import PercentageCell from '../PercentageCell';
import NumberCell from '../NumberCell';
import DateCell from '../DateCell';
import DrillDownCell from '../DrillDownCell';
import MetricHeaderCell from '../MetricHeaderCell';
import SalesCommissionCell from './SalesCommissionCell';
import { BULK_SALES_COMM_CELL } from '../../hooks/useColumnDefs';
import BulkSalesCommissionCell from './BulkSalesCommissionCell';
import GroupingCellRenderer from '../V5Gadget/Matrix/GroupingCellRenderer';
import { HISTOGRAM_CELL_RENDERER } from '../SingleMetricDateMatrix/SingleMetricDateMatrixContent/constants';
import HistogramCellRenderer from '../SingleMetricDateMatrix/SingleMetricDateMatrixContent/HistogramCellRenderer';
import {
  KPI_NAME_RENDERER,
  KpiNameRenderer,
  TREND_CELL_RENDERER,
  TrendCellRenderer,
} from '../../screens/GoalShow/GoalKpiVis/GoalKpiVis';
import NormalCellRenderer from '../V5Gadget/Matrix/NormalCellRenderer';
import PerformanceEntityCell from '../PerformanceBoards/PerformanceEntityCell';
import {
  PERFORMANCE_ENTITY_CELL,
  PERFORMANCE_STATUS_CELL,
} from '../PerformanceBoards/constants';
import GeoPointCell from '../GeoPointCell';
import EntityCell from './EntityCell';
import {
  DATE_CELL,
  DRILL_DOWN_CELL,
  ENTITY_CELL,
  GEO_POINT_CELL,
  NUMBER_CELL,
  SCORING_EXPECTATION_CELL,
  SCORING_REWARD_CELL,
  SCORING_STATUS_CELL,
} from './constants';
import DriverCell from 'components/DriverBonus/DriverCell';
import { PACE_CELLS } from '../PaceMatrix/PaceMatrixContent/constants';
import PaceDiffCell from '../PaceMatrix/PaceMatrixContent/PaceDiffCell';
import PaceCell from '../PaceMatrix/PaceMatrixContent/PaceCell';
import PerformanceStatusCell from '../PerformanceBoards/PerformanceBoard/Grid/PerformanceStatusCell';
import ScoringStatusCell from './ScoringStatusCell';
import ScoringExpectationCell from './ScoringExpectationCell';
import ScoringRewardCell from './ScoringRewardCell';

const GridFrameworkComponents = {
  statusFlagCell: StatusFlagCell,
  driverCell: DriverCell,
  jobCell: JobCellRenderer,
  laneCell: CustomerLaneCellRenderer,
  reasonCodeCell: ReasonCodeCellRenderer,
  reasonCodePicker: ReasonCodePickerRenderer,
  onTargetCell: OnTargetCellRenderer,
  contractOnTargetCell: ContractOnTargetCellRenderer,
  progressCell: ProgressCellRenderer,
  contractProgressCell: ContractProgressCellRenderer,
  customerWithHoverMenuCell: CustomerWithHoverMenuRenderer,
  currencyCell: CurrencyCell,
  percentageCell: PercentageCell,
  [NUMBER_CELL]: NumberCell,
  [DATE_CELL]: DateCell,
  [DRILL_DOWN_CELL]: DrillDownCell,
  metricHeaderCell: MetricHeaderCell,
  salesCommissionCell: SalesCommissionCell,
  [BULK_SALES_COMM_CELL]: BulkSalesCommissionCell,
  groupingCellRenderer: GroupingCellRenderer,
  [HISTOGRAM_CELL_RENDERER]: HistogramCellRenderer,
  [TREND_CELL_RENDERER]: TrendCellRenderer,
  [KPI_NAME_RENDERER]: KpiNameRenderer,
  [PERFORMANCE_ENTITY_CELL]: PerformanceEntityCell,
  normalCellRenderer: NormalCellRenderer,
  [GEO_POINT_CELL]: GeoPointCell,
  [ENTITY_CELL]: EntityCell,
  [PACE_CELLS.DIFF]: PaceDiffCell,
  [PACE_CELLS.CELL]: PaceCell,
  [PERFORMANCE_STATUS_CELL]: PerformanceStatusCell,
  [SCORING_STATUS_CELL]: ScoringStatusCell,
  [SCORING_EXPECTATION_CELL]: ScoringExpectationCell,
  [SCORING_REWARD_CELL]: ScoringRewardCell,
};

export default GridFrameworkComponents;
