import { useContext, useEffect, useState } from 'react';
import AccountPickerContext from '../../../../contexts/AccountPickerContext';
import getBonusPeriodsRef from './getBonusPeriodsRef';

const useBonusPeriods = () => {
  const [bonusPeriods, setBonusPeriods] = useState<
    BonusPeriod[] | Scoring.BonusPeriod[]
  >([]);
  const [currentBonusPeriod, setCurrentBonusPeriod] = useState<
    BonusPeriod | Scoring.BonusPeriod | undefined
  >();
  const { accountRef } = useContext(AccountPickerContext);

  useEffect(() => {
    const listener = getBonusPeriodsRef(accountRef).onSnapshot(
      (snapshot: any) => {
        const data: BonusPeriod[] | Scoring.BonusPeriod[] = [];
        snapshot.docs.forEach((d: any) =>
          data.push({
            ...d.data(),
          }),
        );
        setBonusPeriods(
          data.map((bp) => {
            if (!bp.status) {
              const x: BonusPeriod | Scoring.BonusPeriod = {
                // @ts-ignore
                ...bp,
                status: 'in review' as 'in review',
              };
              return x;
            }
            return bp;
          }),
        );
        setCurrentBonusPeriod(data.find((bp) => bp.status === 'active'));
      },
    );
    return () => {
      listener();
    };
  }, [accountRef]);

  return {
    bonusPeriods,
    currentBonusPeriod,
  };
};

export default useBonusPeriods;
