import React from 'react';
import AppSwitcher from '../AppSwitcher';
import styled from 'styled-components';
import NAVIGATION from '../../../constants';
import Colors2 from '../../../../../theme/Colors2';

const AppsNavTopDiv = styled.div`
  height: ${NAVIGATION.TOP_HEIGHT}px;
  border-bottom: solid 1px ${Colors2.Border};
  padding: 4px 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AppsNavTop = () => (
  <AppsNavTopDiv>
    <AppSwitcher />
  </AppsNavTopDiv>
);

export default AppsNavTop;
