import portalTypeCheckers from '../types/portalTypeCheckers';

const getPortalSlug = (
  portal: Portal | undefined,
  section?: PortalSideNavigationSection,
) => {
  if (!portal) {
    return '';
  }

  if (portalTypeCheckers.isAdminPortal(portal)) {
    return 'admin/';
  }

  if (section) {
    return `${portal.urlSlug}/${section.urlSlug}/`;
  }

  return `${portal.urlSlug}/`;
};

export default getPortalSlug;
