import React, { useCallback, useEffect, useRef, useState } from 'react';
import Row from 'components/Common/Row';
import { NabTabLinkMargin, NavTabLink } from 'components/NavTab';
import useIsScrolling from 'components/ShadowedList/useIsScrolling';

import { TOP_BAR_HEIGHT, Z_INDEX } from '../../../constants';
import Button from '../../../kingpin/atoms/Button';

const isReportTab = <Tab extends TabTypes.BaseTab>(
  tab: Tab | TargetsApp.ClientTab,
): tab is TargetsApp.ReportTab => {
  if (tab.type === 'report') {
    return true;
  }
  return false;
};

const Tabs = <Tab extends TabTypes.BaseTab>({ tabs }: { tabs: Tab[] }) => {
  const scrollerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const isScrolling = useIsScrolling({
    scrollerRef,
    contentRef,
    isHorizontalScroll: true,
  });
  const [isLeftVisible, setIsLeftVisible] = useState<boolean>(false);
  const [isRightVisible, setIsRightVisible] = useState<boolean>(false);
  useEffect(() => {
    setIsRightVisible(isScrolling);
  }, [isScrolling]);

  const moveTabs = useCallback(({ right }: { right: boolean }) => {
    const tabsScroller = scrollerRef.current;
    if (!tabsScroller) {
      return;
    }
    const tabsScrollerWidth = tabsScroller.offsetWidth;
    const direction = right ? 1 : -1;

    tabsScroller.scrollBy({
      top: 0,
      left: tabsScrollerWidth * direction * 0.7,
      behavior: 'smooth',
    });

    setIsLeftVisible(
      tabsScroller.scrollLeft + tabsScrollerWidth * direction * 0.7 > 0,
    );
    setIsRightVisible(
      tabsScroller.scrollLeft +
        tabsScrollerWidth * direction * 0.7 +
        tabsScrollerWidth <
        tabsScroller.scrollWidth,
    );
  }, []);

  return (
    <Row
      ref={scrollerRef}
      style={{
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      {isLeftVisible && (
        <Row
          style={{
            height: TOP_BAR_HEIGHT,
            position: 'sticky',
            left: '-1px',
            zIndex: Z_INDEX.TABS_ROW,
          }}
          centerAlign
        >
          <div style={{ backgroundColor: 'white' }}>
            <Button
              size="Medium"
              type="Ghost"
              icon="chevron-left"
              onClick={() => moveTabs({ right: false })}
            />
          </div>
          <div
            style={{
              width: '40px',
              height: TOP_BAR_HEIGHT - 1,
              background: 'linear-gradient(to left, transparent, #fff)',
            }}
          />
        </Row>
      )}
      <Row ref={contentRef}>
        {tabs.map((tab) => (
          <div key={tab.key} style={{ marginRight: NabTabLinkMargin }}>
            <NavTabLink
              title={tab.name}
              to={tab.link}
              tabInfo={
                isReportTab(tab)
                  ? { typeId: tab.report.id, type: tab.type }
                  : undefined
              }
            />
          </div>
        ))}
      </Row>

      {isRightVisible && (
        <Row
          style={{
            height: TOP_BAR_HEIGHT,
            position: 'sticky',
            right: '-1px',
            zIndex: Z_INDEX.TABS_ROW,
          }}
          centerAlign
        >
          <div
            style={{
              width: '40px',
              height: TOP_BAR_HEIGHT - 1,
              background: 'linear-gradient(to right, transparent, #fff)',
            }}
          />
          <div style={{ backgroundColor: 'white' }}>
            <Button
              size="Medium"
              type="Ghost"
              icon="chevron-right"
              onClick={() => moveTabs({ right: true })}
            />
          </div>
        </Row>
      )}
    </Row>
  );
};

export default Tabs;
