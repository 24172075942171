import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import RelatedDocumentsSlideoutWizard from './RelatedDocumentsSlideoutWizard';
import CurrentUserContext from '../../../../../contexts/CurrentUserContext';
import getIdentifier from '../../../../../getIdentifier';
import getTimeStamp from '../../../../../getTimeStamp';
import useSaveWidget from '../../../hooks/useSaveWidget';
import DatasetDefinitionsContext from '../../../../../contexts/DatasetDefinitionsContext';
import useGetDatasetLabel from '../../../../Inputs/MetricPicker/MetricPickerPopup/hooks/useGetDatasetLabel';
import FilterPlatesProvider from '../../../../../contextProviders/FilterPlatesProvider';
import useGetFieldLabel from '../../../../../hooks/useGetFieldLabel';

const RelatedDocumentsSlideoutWizardContainer = ({
  close,
  editingSection,
}: {
  close: () => void;
  editingSection:
    | PerformanceBoardTypes.SlideOut.RelatedDocumentsSection
    | undefined;
}) => {
  const { id: currentUserId } = useContext(CurrentUserContext);
  const { datasets } = useContext(DatasetDefinitionsContext);
  const getDatasetLabel = useGetDatasetLabel();
  const { getFieldLabel } = useGetFieldLabel();

  const [id] = useState<string>(
    editingSection ? editingSection.id : getIdentifier(),
  );
  const [createdBy] = useState<string>(
    editingSection ? editingSection.createdBy : currentUserId,
  );
  const [createdOn] = useState<string>(
    editingSection ? editingSection.createdOn : getTimeStamp(),
  );
  const [title, setTitle] = useState<string>(
    editingSection ? editingSection.title : '',
  );
  const [fields, setFields] = useState<string[]>(
    editingSection ? editingSection.fields : [],
  );
  const [widgetDateRange, setWidgetDateRange] =
    useState<PerformanceBoardTypes.SlideOut.SlideOutDateRange>({
      mode: editingSection ? editingSection.dateRange.mode : 'Current Interval',
      n:
        editingSection && editingSection.dateRange.mode === 'Last'
          ? editingSection.dateRange.n
          : 1,
      isToDate: editingSection ? editingSection.dateRange.isToDate : false,
    });
  const [dataset, setDataset] = useState<string | undefined>(
    editingSection ? editingSection.dataset : undefined,
  );
  const [scope, setScope] = useState<FilterPlateType[]>(
    editingSection ? editingSection.scope : [],
  );
  const [section, setSection] = useState<
    PerformanceBoardTypes.SlideOut.RelatedDocumentsSection | undefined
  >();

  const saveWidget = useSaveWidget({
    section,
    editingSection,
  });

  const onTitleChanged = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setTitle(event.target.value);
  };

  useEffect(() => {
    if (fields.length === 0 || title === '' || !dataset) {
      setSection(undefined);
      return;
    }

    const newSection: PerformanceBoardTypes.SlideOut.RelatedDocumentsSection = {
      id: id,
      createdBy: createdBy,
      updatedBy: currentUserId,
      createdOn: createdOn,
      updatedOn: getTimeStamp(),
      sectionType: 'Related Documents',
      title: title,
      fields: fields,
      dateRange: widgetDateRange,
      dataset,
      scope,
    };

    setSection(newSection);
  }, [
    createdBy,
    createdOn,
    currentUserId,
    dataset,
    editingSection,
    fields,
    id,
    scope,
    title,
    widgetDateRange,
  ]);

  const datasetOptions = useMemo(() => {
    return datasets.map((dataType): DropdownOption => {
      return {
        label: getDatasetLabel(dataType),
        isSelected: dataType.type === dataset,
        onSelected: () => {
          setDataset(dataType.type);
          setFields([]);
          setScope([]);
        },
      };
    });
  }, [dataset, datasets, getDatasetLabel]);

  const fieldOptions = useMemo((): DropdownOption[] => {
    if (!dataset) {
      return [];
    }

    const dataType = datasets.find((d) => d.type === dataset);
    if (!dataType) {
      return [];
    }

    return dataType.fields
      .filter((f) => !fields.includes(f.field))
      .map((f) => ({
        label: getFieldLabel({ field: f.field, dataType: dataset }),
        isSelected: false,
        onSelected: () => {
          setFields((selectedFields) => {
            if (selectedFields.includes(f.field)) {
              return selectedFields.filter((sf) => sf !== f.field);
            }
            return [...selectedFields, f.field];
          });
        },
      }));
  }, [dataset, datasets, fields, getFieldLabel]);

  return (
    <FilterPlatesProvider dataType={dataset} scope={scope} setScope={setScope}>
      <RelatedDocumentsSlideoutWizard
        title={title}
        onTitleChanged={onTitleChanged}
        datasetOptions={datasetOptions}
        fieldOptions={fieldOptions}
        fields={fields}
        setFields={setFields}
        widgetDateRange={widgetDateRange}
        setWidgetDateRange={setWidgetDateRange}
        draftSection={section}
        saveWidget={saveWidget}
        close={close}
      />
    </FilterPlatesProvider>
  );
};

export default RelatedDocumentsSlideoutWizardContainer;
