import styled from 'styled-components';

export const KINGPIN_CARD_PADDING = 24;
export const KINGPIN_CARD_BORDER = 1;

const KingpinCard = styled.div`
  padding: ${KINGPIN_CARD_PADDING}px;
  border: ${KINGPIN_CARD_BORDER}px solid #ededed;
  border-radius: 8px;
`;

export default KingpinCard;
