import React, { useContext } from 'react';
import usePopup from 'hooks/usePopup';
import InlineDialog from 'components/InlineDialog';
import { DropdownButton } from 'components/Inputs/Dropdown';

import PortalPickerDialog from './PortalPickerDialog';
import { PortalsContext } from '../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import CreatePortalForm from './CreatePortalForm';
import PERMISSIONS from '../../../permissionDefinitions';
import PermissionGates from 'components/PermissionGates';
import NavSideBarContext from '../../../contexts/NavSideBarContext';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';
import Colors2 from '../../../theme/Colors2';
import Row from 'components/Common/Row';
import ColorDot from './ColorDot';

const ButtonInnerContent = ({ selectedPortal }: { selectedPortal: Portal }) => {
  return (
    <Row centerAlign spaceBetween>
      <div style={{ marginRight: 8 }}>
        <ColorDot color={selectedPortal.color} width={8} height={8} />
      </div>
      <Typography.Body type={'Placeholder'} color={Colors2.Grey['1']}>
        {selectedPortal.name}
      </Typography.Body>
    </Row>
  );
};

const PortalPicker = ({ isExecOnly }: { isExecOnly?: boolean }) => {
  const { isOpen: isNavOpen } = useContext(NavSideBarContext);
  const { selectedPortal } = useContext(PortalsContext);
  const { isOpen, open, close } = usePopup();
  const {
    isOpen: isPortalFormOpen,
    open: openPortalForm,
    close: closePortalForm,
  } = usePopup();

  return (
    <>
      <InlineDialog
        content={
          <PortalPickerDialog
            close={close}
            openPortalForm={openPortalForm}
            isExecOnly={isExecOnly}
          />
        }
        onClose={close}
        isOpen={isOpen}
      >
        <div style={{ marginTop: 8 }}>
          {isNavOpen && (
            <DropdownButton
              isOpen={isOpen}
              open={open}
              close={close}
              label={selectedPortal ? undefined : 'Select a Portal'}
              renderSelectedLabel={
                !selectedPortal
                  ? undefined
                  : () => <ButtonInnerContent selectedPortal={selectedPortal} />
              }
            />
          )}
          {!isNavOpen && (
            <Button
              size="Small"
              type="Secondary"
              onClick={isOpen ? close : open}
              icon={isOpen ? 'chevron-up' : 'chevron-down'}
            />
          )}
        </div>
      </InlineDialog>
      {/* Portal Creation Form */}
      <PermissionGates.Has
        requiredPermission={PERMISSIONS.CONTENT_ACCESS.CREATE_PORTALS}
      >
        <ModalTransition>
          {isPortalFormOpen && (
            <Modal
              onClose={closePortalForm}
              shouldScrollInViewport={false}
              autoFocus={false}
            >
              <CreatePortalForm close={closePortalForm} />
            </Modal>
          )}
        </ModalTransition>
      </PermissionGates.Has>
    </>
  );
};

export default PortalPicker;
