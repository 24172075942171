import React, { useContext, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Tabs from 'components/Tabs';
import Typography from 'kingpin/atoms/Typography';
import { TopBarDiv } from '../../TargetsAppShow/TargetsAppConfigured/TopBar';
import PortalUsers from '../GeneralPortalUsers';
import PortalMappedUsers from './PortalMappedUsers';
import styled from 'styled-components';
import NavSideBarContext from '../../../contexts/NavSideBarContext';
import useGetMappedEntityViews from '../../../hooks/portals/useGetMappedEntityViews';
import NavSectionContext from '../../../navigation/NavSectionContext';

interface ManagersTab extends TabTypes.BaseTab {
  type: 'managers';
}

interface MappedUsersTab extends TabTypes.BaseTab {
  type: 'mapped-users';
  entityId: string;
}

type UsersTab = ManagersTab | MappedUsersTab;

const UsersScreenDiv = styled.div<{ isNavOpen: boolean }>`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const useTabs = (portal: EngagementPortal) => {
  const { type: typeParam, entityId: entityIdParam } = useParams<{
    type: 'managers' | 'mapped-users';
    entityId?: string;
  }>();
  const { navSection } = useContext(NavSectionContext);
  const getMappedEntityViews = useGetMappedEntityViews();
  const mappedEntityViews = useMemo(() => {
    return getMappedEntityViews(portal);
  }, [getMappedEntityViews, portal]);

  const tabs = useMemo((): UsersTab[] => {
    if (mappedEntityViews.length === 0 || !navSection) {
      return [];
    }

    const entityTabs: UsersTab[] = mappedEntityViews.map((entityLink) => {
      const tab: UsersTab = {
        key: `users-${entityLink.entityId}`,
        type: `mapped-users`,
        link: `/${portal.urlSlug}/${navSection.urlSlug}/users/mapped-users/${entityLink.entityId}`,
        isSelected:
          typeParam === 'mapped-users' && entityIdParam === entityLink.entityId,
        name: entityLink.usersTabLabel,
        entityId: entityLink.entityId,
      };

      return tab;
    });

    return [
      ...entityTabs,
      {
        key: 'managers',
        type: 'managers',
        link: `/${portal.urlSlug}/${navSection.urlSlug}/users/managers`,
        isSelected: typeParam === 'managers',
        name: 'Portal Managers',
      },
    ];
  }, [entityIdParam, mappedEntityViews, navSection, portal.urlSlug, typeParam]);

  return tabs;
};

const ShowSelectedTab = ({
  tab,
  portal,
}: {
  tab: UsersTab;
  portal: EngagementPortal;
}) => {
  if (tab.type === 'managers') {
    return <PortalUsers portal={portal} />;
  }

  if (tab.type === 'mapped-users') {
    return <PortalMappedUsers portal={portal} entityId={tab.entityId} />;
  }

  return null;
};

const EngagementPortalUsers = ({ portal }: { portal: EngagementPortal }) => {
  const tabs = useTabs(portal);
  const selectedTab = tabs.find((t) => t.isSelected);
  const { isOpen: isNavOpen } = useContext(NavSideBarContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedTab && tabs.length > 0) {
      navigate(tabs[0].link);
    }
  }, [navigate, selectedTab, tabs]);

  return (
    <UsersScreenDiv isNavOpen={isNavOpen}>
      <TopBarDiv>
        <Typography.Header type="H5">Users</Typography.Header>
      </TopBarDiv>
      <TopBarDiv>
        <Tabs.TabsRow tabs={tabs} />
      </TopBarDiv>
      {!!selectedTab && <ShowSelectedTab tab={selectedTab} portal={portal} />}
    </UsersScreenDiv>
  );
};

export default EngagementPortalUsers;
