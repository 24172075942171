import React, { useContext, useEffect, useState } from 'react';
import useApps from './useApps';
import { PortalsContext } from '../PortalsProvider';
import portalTypeCheckers from '../../../../types/portalTypeCheckers';

/**
 * This is the latest definition of Apps,
 * which is different from what we see on {@link PerformanceAppsIndex}
 */
export const AppsContext = React.createContext<{
  apps: Apps.App[];
  selectedContent?: Apps.SelectedContent;
  setSelectedContent: React.Dispatch<
    React.SetStateAction<Apps.SelectedContent | undefined>
  >;
  isLoadingApps: boolean;
  hasAppsEnabled: boolean;
}>({
  apps: [],
  setSelectedContent: () => {},
  isLoadingApps: true,
  hasAppsEnabled: false,
});

const AppsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { selectedPortal } = useContext(PortalsContext);
  const [selectedContent, setSelectedContent] = useState<
    Apps.SelectedContent | undefined
  >();
  const { apps, isLoading, hasAppsEnabled } = useApps();

  // Set initially selected content
  useEffect(() => {
    if (selectedContent) {
      return;
    }

    if (!selectedPortal) {
      return;
    }

    if (portalTypeCheckers.isAdminPortal(selectedPortal)) {
      setSelectedContent({ type: 'Content Management' });
      return;
    }

    if (portalTypeCheckers.isExecutivePortal(selectedPortal)) {
      const analyticsApp = apps.find((a) => a.type === 'Analytics');
      if (analyticsApp) {
        setSelectedContent({ type: 'App', app: analyticsApp });
      }
      return;
    }

    const selectApp = apps.find(
      (a) =>
        a.type === 'General' &&
        a.portals.some((p) => p.id === selectedPortal.id),
    );
    if (selectApp) {
      setSelectedContent({ type: 'App', app: selectApp });
      return;
    }

    setSelectedContent(undefined);
  }, [apps, selectedContent, selectedPortal]);

  return (
    <AppsContext.Provider
      value={{
        apps,
        isLoadingApps: isLoading,
        hasAppsEnabled,
        selectedContent,
        setSelectedContent,
      }}
    >
      {children}
    </AppsContext.Provider>
  );
};

export default AppsProvider;
