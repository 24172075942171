import React, { ChangeEvent } from 'react';

interface EntityDetailsContextType {
  app: EntityDetails.App;
  entityFilter: FilterInput;
  entityFilterAsFilterPlates: FilterPlateType[];
  selectedEntity: EntityDetails.SelectedEntity | undefined;
  selectedEntityLabel: string;
  entityOptions: DropdownOption[];
  onEntitySelected: (newEntity: string) => void;
  tabs: EntityDetails.ClientTab[];
  selectedTab: EntityDetails.ClientTab | undefined;
  entitySearchText: string;
  onEntitySearchTextChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  isLoadingEntityOptions: boolean;
  /**
   * isDriverDetails:
   *
   * When enabled, this will show an area above the navigation tabs which
   * provides further info regarding a selected driver.
   */
  //
  isDriverDetails: boolean;
}

const EntityDetailsContext = React.createContext<
  EntityDetailsContextType | undefined
>(undefined);

export default EntityDetailsContext;
