import React, { useContext } from 'react';
import usePopup from 'hooks/usePopup';
import Button from 'kingpin/atoms/Button';
import ManageUsersPopup from '../ManageUsersPopup';
import TargetsAppContext from '../TargetsAppContext';
import { PortalsContext } from '../../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';

const ButtonX = ({
  open,
  userCount,
}: {
  open: () => void;
  userCount: number;
}) => {
  const text =
    userCount === 1
      ? '1 User'
      : userCount > 1
        ? `${userCount} Users`
        : 'No users added';

  return (
    <Button
      label={text}
      icon="share"
      onClick={open}
      type={userCount > 0 ? 'Ghost' : 'Secondary'}
      size="Small"
    />
  );
};

const AppUsers = () => {
  const { isPortalsEnabled } = useContext(PortalsContext);
  const { app } = useContext(TargetsAppContext);
  const { isOpen, open, close } = usePopup();

  if (isPortalsEnabled) {
    return null;
  }

  return (
    <>
      <ButtonX open={open} userCount={(app.access.userIds || []).length} />
      <ManageUsersPopup isOpen={isOpen} close={close} />
    </>
  );
};

export default AppUsers;
