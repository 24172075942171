import React, { useCallback, useMemo, useState } from 'react';
import DragAndDropList from '../../../../../components/DragAndDropList';
import SideNavigationSectionForm from './SideNavigationSectionForm';
import Button from '../../../../atoms/Button';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import usePopup from '../../../../../hooks/usePopup';
import Row from '../../../../../components/Common/Row';
import Typography from '../../../../atoms/Typography';
import DragHandle from '../../../../../components/DragAndDropList/DragHandle';

const Section = ({
  section,
  onEditSectionClicked,
  onSectionRemoved,
  portalType,
}: {
  section: PortalSideNavigationSection;
  onEditSectionClicked: (item: PortalSideNavigationSection) => void;
  onSectionRemoved: (item: PortalSideNavigationSection) => void;
  portalType: 'ExecutivePortal' | 'Engagement Portal' | 'Bonus Portal';
}) => {
  const isPeriodFiltered = useMemo(() => {
    if (!section.portalTypeSpecificSettings) {
      return false;
    }

    return section.portalTypeSpecificSettings.isPeriodFiltered;
  }, [section.portalTypeSpecificSettings]);

  return (
    <Row
      key={section.key}
      spaceBetween
      centerAlign
      style={{
        marginBottom: 8,
        border: '1px solid #e0e0e0',
        borderRadius: 8,
        padding: '4px',
      }}
    >
      <Row style={{ gap: 8 }} centerAlign>
        <DragHandle />
        <Typography.Body type={'Body 12'}>
          {section.title.isEnabled ? section.title.label : '[Untitled]'}
        </Typography.Body>
        {portalType === 'Bonus Portal' && (
          <Typography.Body type={'Annotation'}>
            {`Period Filtering: ${isPeriodFiltered ? 'Enabled' : 'Disabled'}`}
          </Typography.Body>
        )}
      </Row>
      <Row centerAlign style={{ gap: 8 }}>
        <Button
          size={'Small'}
          type={'Ghost'}
          icon={'edit-filled'}
          onClick={() => onEditSectionClicked(section)}
        />
        <Button
          size={'Small'}
          type={'Ghost'}
          icon={'cross'}
          onClick={() => onSectionRemoved(section)}
        />
      </Row>
    </Row>
  );
};

const SideNavigationBuilder = ({
  sideNavigationSections,
  setSideNavigationSections,
  portalType,
}: {
  sideNavigationSections: PortalSideNavigationSection[];
  setSideNavigationSections: React.Dispatch<
    React.SetStateAction<PortalSideNavigationSection[]>
  >;
  portalType: 'ExecutivePortal' | 'Engagement Portal' | 'Bonus Portal';
}) => {
  const {
    isOpen: isSectionFormOpen,
    open: openSectionForm,
    close: closeSectionForm,
  } = usePopup();
  const [openedSection, setOpenedSection] = useState<
    PortalSideNavigationSection | undefined
  >();

  const onAddSectionClicked = useCallback(() => {
    setOpenedSection(undefined);
    openSectionForm();
  }, [openSectionForm]);

  const onEditSectionClicked = useCallback(
    (section: PortalSideNavigationSection) => {
      setOpenedSection(section);
      openSectionForm();
    },
    [openSectionForm],
  );

  const onSectionRemoved = useCallback(
    (section: PortalSideNavigationSection) => {
      setSideNavigationSections((current) => {
        return current.filter((s) => s.key !== section.key);
      });
    },
    [setSideNavigationSections],
  );

  const onSectionSaved = useCallback(
    (section: PortalSideNavigationSection) => {
      setSideNavigationSections((current) => {
        if (current.some((s) => s.key === section.key)) {
          return current.map((s) => {
            if (s.key === section.key) {
              return section;
            }
            return s;
          });
        }
        return [...current, section];
      });
    },
    [setSideNavigationSections],
  );

  return (
    <div>
      <div style={{ marginBottom: 8 }}>
        <DragAndDropList
          droppableId={'nav-sections'}
          items={sideNavigationSections}
          onOrderChanged={setSideNavigationSections}
          renderItem={(item) => (
            <Section
              section={item}
              onEditSectionClicked={onEditSectionClicked}
              onSectionRemoved={onSectionRemoved}
              portalType={portalType}
            />
          )}
        />
      </div>
      <Button
        size={'Small'}
        type={'Secondary'}
        label={'Add Section'}
        onClick={onAddSectionClicked}
      />
      <ModalTransition>
        {isSectionFormOpen && (
          <Modal
            onClose={closeSectionForm}
            shouldScrollInViewport={false}
            autoFocus={false}
          >
            <SideNavigationSectionForm
              close={closeSectionForm}
              onSave={onSectionSaved}
              section={openedSection}
              sideNavigationSections={sideNavigationSections}
              portalType={portalType}
            />
          </Modal>
        )}
      </ModalTransition>
    </div>
  );
};

export default SideNavigationBuilder;
