import { useContext, useMemo } from 'react';
import GridContext from '../../contexts/GridContext';
import BoardContext from '../../contexts/BoardContext';
import isPerformanceBoard from '../../isPerformanceBoard';
import DatasetDefinitionsContext from '../../contexts/DatasetDefinitionsContext';

const useScoringCellBonusProgram = () => {
  const { dataType } = useContext(GridContext);
  const { board } = useContext(BoardContext);

  const currentDataType = useMemo(() => {
    if (dataType) {
      return dataType;
    }

    if (board && isPerformanceBoard(board)) {
      return board.dataType;
    }

    return undefined;
  }, [board, dataType]);

  const { getBonusProgram } = useContext(DatasetDefinitionsContext);
  const bonusProgram = useMemo(() => {
    if (!currentDataType) {
      return undefined;
    }
    return getBonusProgram(currentDataType);
  }, [currentDataType, getBonusProgram]);

  return bonusProgram;
};

export default useScoringCellBonusProgram;
