import React from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import ManageUsersContent from './ManagerUsersContent';

const ManageUsersPopup = ({
  close,
  isOpen,
}: {
  close: () => void;
  isOpen: boolean;
}) => {
  return (
    <ModalTransition>
      {isOpen && (
        <Modal
          width={'40vw'}
          height={'70vh'}
          onClose={close}
          shouldScrollInViewport={false}
          autoFocus={false}
        >
          <ManageUsersContent close={close} />
        </Modal>
      )}
    </ModalTransition>
  );
};

export default ManageUsersPopup;
