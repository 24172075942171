import React, { useContext, useMemo } from 'react';
import Inputs from '../../../../components/Inputs';
import DatasetDefinitionsContext from '../../../../contexts/DatasetDefinitionsContext';

const BonusProgramPicker = ({
  bonusProgramId,
  setBonusProgramId,
}: {
  bonusProgramId: string | undefined;
  setBonusProgramId: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const { bonusPrograms } = useContext(DatasetDefinitionsContext);
  const options = useMemo((): DropdownOption[] => {
    return bonusPrograms.map((bp) => {
      const isSelected = bonusProgramId === bp.id;
      return {
        label: bp.name,
        isSelected,
        onSelected: () => {
          setBonusProgramId(bp.id);
        },
      };
    });
  }, [bonusProgramId, bonusPrograms, setBonusProgramId]);

  return <Inputs.Dropdown options={options} />;
};

export default BonusProgramPicker;
