const getDefaultBonusPeriod = (
  bonusPeriods: Scoring.BonusPeriod[],
): Scoring.BonusPeriod | undefined => {
  const firstPendingSignOff = bonusPeriods.find(
    (p) => p.status === 'pending sign off',
  );
  if (firstPendingSignOff) {
    return firstPendingSignOff;
  }

  const firstInReview = bonusPeriods.find((p) => p.status === 'in review');
  if (firstInReview) {
    return firstInReview;
  }

  const firstActive = bonusPeriods.find((p) => p.status === 'active');
  if (firstActive) {
    return firstActive;
  }

  const firstClosed = bonusPeriods.find((p) => p.status === 'closed');
  if (firstClosed) {
    return firstClosed;
  }
};

export default getDefaultBonusPeriod;
