import React, { useCallback, useContext } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';

import Row from 'components/Common/Row';
import AccountPickerContext from 'contexts/AccountPickerContext';
import usePopup from 'hooks/usePopup';
import STORE from 'store';
import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';

import useConfirmation from '../../../DataManager/DatasetDefinitions/DatasetsIndex/PerformanceDatasetWizard/useConfirmation';
import AddMappedUserForm from './AddMappedUserForm';
import PortalUserGridRow from './PortalUserGridRow';

const MappedUserItem = ({
  user,
  entity,
  setMappedUsers,
  entityPortalName,
  mappedUsers,
}: {
  user: MappedUser;
  entity: EntityDetails.Entity;
  setMappedUsers: React.Dispatch<React.SetStateAction<MappedUser[]>>;
  entityPortalName: string;
  mappedUsers: MappedUser[];
}) => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { isOpen, open, close } = usePopup();
  const { getConfirmation, ConfirmationModal } = useConfirmation({
    confirmText: 'Remove',
    title: `Remove ${user.user.displayName}?`,
    body: `By removing ${user.user.displayName} you will return them to the standard mode of FleetOps`,
  });

  const onRemoveMappingClicked = useCallback(async () => {
    const isConfirmed = await getConfirmation();
    if (isConfirmed) {
      const newSettings: UserManagement.NormalContentSettings = {
        mode: 'normal',
      };

      await STORE.users
        .getUserContentSettingsRef({
          accountId: selectedAccountId,
          userId: user.user.id,
        })
        .set(newSettings)
        .then(() => {
          setMappedUsers((current) => {
            return current.filter((u) => u.user.id !== user.user.id);
          });
        });
    }
  }, [getConfirmation, selectedAccountId, setMappedUsers, user.user.id]);

  return (
    <>
      <PortalUserGridRow
        NameCol={
          <Typography.Body type="Label">
            {user.user.displayName}
          </Typography.Body>
        }
        EmailCol={
          <Typography.Body type="Label">{user.user.email}</Typography.Body>
        }
        FilterValueCol={
          <Typography.Body type="Label">{user.filterValue}</Typography.Body>
        }
        ButtonsCol={
          <Row centerAlign style={{ gap: 8 }}>
            <Button
              size={'Small'}
              type={'Ghost'}
              icon={'edit-filled'}
              onClick={open}
              testId={`edit-${user.user.id}-mapping`}
            />
            <Button
              size={'Small'}
              type={'Ghost'}
              icon={'cross'}
              onClick={onRemoveMappingClicked}
            />
          </Row>
        }
      />

      <ModalTransition>
        {isOpen && (
          <Modal
            onClose={close}
            shouldScrollInViewport={false}
            autoFocus={false}
          >
            <AddMappedUserForm
              close={close}
              entity={entity}
              setMappedUsers={setMappedUsers}
              entityPortalName={entityPortalName}
              mappedUser={user}
              mappedUsers={mappedUsers}
            />
          </Modal>
        )}
      </ModalTransition>
      {ConfirmationModal}
    </>
  );
};

export default MappedUserItem;
