import React from 'react';
import portalTypeCheckers from 'types/portalTypeCheckers';
import EngagementPortalUsers from './EngagementPortalUsers';
import ExecutiveAndBonusPortalUsers from './GeneralPortalUsers';

const PortalUsers = ({
  portal,
}: {
  portal: EngagementPortal | ExecutivePortal | BonusPortal;
}) => {
  if (portalTypeCheckers.isEngagementPortal(portal)) {
    return <EngagementPortalUsers portal={portal} />;
  }

  return <ExecutiveAndBonusPortalUsers portal={portal} />;
};

export default PortalUsers;
