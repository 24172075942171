import React from 'react';
import AccountPickerProvider from './AccountPickerProvider';
import AccountProvider from './AccountProvider';
import CloudFunctionClientProvider from './CloudFunctionClientProvider';
import UsersProvider from './UsersProvider';
import ImpersonatorProvider from './ImpersonatorProvider';
import CurrentUserProvider from './CurrentUserProvider';
import CurrentUserContentSettingsProvider from './CurrentUserContentSettingsProvider';
import FeatureGatesProvider from './FeatureGatesProvider';
import RolesProvider from './RolesProvider';
import PortalsProvider from './PortalsProvider';
import AnalyticsProvider from './AnalyticsProvider';
import GqlClientProvider from './GqlClientProvider';
import BreadcrumbsProvider from './BreadcrumbsProvider';
import AppsProvider from './AppsProvider';

const UserAndAccountProviders = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => (
  <AccountPickerProvider>
    <AccountProvider>
      <CloudFunctionClientProvider>
        <UsersProvider>
          <ImpersonatorProvider>
            <CurrentUserProvider>
              <CurrentUserContentSettingsProvider>
                <FeatureGatesProvider>
                  <RolesProvider>
                    <PortalsProvider>
                      <AppsProvider>
                        <BreadcrumbsProvider>
                          <AnalyticsProvider>
                            <GqlClientProvider>{children}</GqlClientProvider>
                          </AnalyticsProvider>
                        </BreadcrumbsProvider>
                      </AppsProvider>
                    </PortalsProvider>
                  </RolesProvider>
                </FeatureGatesProvider>
              </CurrentUserContentSettingsProvider>
            </CurrentUserProvider>
          </ImpersonatorProvider>
        </UsersProvider>
      </CloudFunctionClientProvider>
    </AccountProvider>
  </AccountPickerProvider>
);

export default UserAndAccountProviders;
