import buildPeriodComparisonDate from './buildPeriodComparisonDate';
import buildBonusPeriodComparisonDate from './buildBonusPeriodComparisonDate';

const getComparisonPeriod = ({
  comparison,
  selectedBonusPeriod,
  bonusPeriods,
  selectedPeriod,
  periods,
  isScorecardPopupReport,
}: {
  comparison?: PersistedComparisonType;
  selectedBonusPeriod?: BonusPeriod | Scoring.BonusPeriod;
  bonusPeriods?: BonusPeriod[] | Scoring.BonusPeriod[];
  selectedPeriod?: Period;
  periods: Period[];
  isScorecardPopupReport?: boolean;
}) => {
  if (!comparison || isScorecardPopupReport) {
    return undefined;
  }
  const periodComparison = buildPeriodComparisonDate({
    selectedPeriod,
    periods,
  });
  if (periodComparison) {
    return periodComparison;
  }

  const bonusPeriodComparison = buildBonusPeriodComparisonDate({
    selectedBonusPeriod,
    bonusPeriods,
  });

  if (bonusPeriodComparison) {
    return bonusPeriodComparison;
  }

  return undefined;
};

export default getComparisonPeriod;
