import React, { useCallback, useEffect, useState } from 'react';

import Row from 'components/Common/Row';
import getIdentifier from 'getIdentifier';
import FormContent from 'kingpin/forms/FormContent';
import FormInput from 'kingpin/forms/FormInput';
import Button from 'kingpin/atoms/Button';
import TextInput from 'kingpin/atoms/TextInput';
import IconPicker from '../../../../atoms/IconPicker';
import { NAV_ITEM_DEFAULT_ICON_COLOR } from '../../../NavItem';

const NavBonusHomeForm = ({
  item,
  onSave,
  close,
}: {
  item: PortalsNav.BonusHome | undefined;
  onSave: (item: PortalsNav.BonusHome) => void;
  close: () => void;
}) => {
  const [key] = useState<string>(
    item ? item.key : getIdentifier(undefined, true),
  );
  const [label, setLabel] = useState<string>(item ? item.label : '');
  const [icon, setIcon] = useState<Kingpin.Icon | undefined>(
    item ? item.icon : undefined,
  );
  const [draftItem, setDraftItem] = useState<PortalsNav.BonusHome | undefined>(
    item,
  );

  useEffect(() => {
    if (label === '') {
      setDraftItem(undefined);
      return;
    }

    setDraftItem({
      key,
      label,
      type: 'Bonus Home',
      icon,
    });
  }, [icon, key, label]);

  const onSaveClicked = useCallback(() => {
    if (!draftItem) {
      return;
    }

    onSave(draftItem);
    close();
  }, [close, draftItem, onSave]);

  return (
    <div>
      <FormContent>
        <FormInput label={'Label'}>
          <TextInput
            value={label}
            onChange={(event) => {
              setLabel(event.target.value);
            }}
          />
        </FormInput>
        <FormInput label={'Icon'}>
          <IconPicker
            setSelectedIcon={setIcon}
            selectedIcon={icon}
            iconColor={NAV_ITEM_DEFAULT_ICON_COLOR}
          />
        </FormInput>
      </FormContent>
      <Row centerAlign spaceBetween>
        <Button
          size={'Small'}
          type={'Secondary'}
          label={'Cancel'}
          onClick={close}
        />
        <Button
          isDisabled={draftItem === undefined}
          onClick={onSaveClicked}
          label={item ? 'Update' : 'Add'}
          type={'Primary'}
          size={'Small'}
        />
      </Row>
    </div>
  );
};

export default NavBonusHomeForm;
