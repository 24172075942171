import React from 'react';
import SettingsWizard from './SettingsWizard/SettingsWizard';
import PerformanceBoardSettingsProvider from './providers/PerformanceBoardSettingsProvider';

const PerformanceBoardSettingsContainer = ({
  close,
}: {
  close: () => void;
}) => {
  return (
    <PerformanceBoardSettingsProvider>
      <SettingsWizard close={close} />
    </PerformanceBoardSettingsProvider>
  );
};

export default PerformanceBoardSettingsContainer;
