import React, { useContext } from 'react';
import { IRowNode } from 'ag-grid-community';

import Cell from '../../V5Gadget/Matrix/Cell';
import BoardSlideOutContext from '../../../contexts/BoardSlideOutContext';
import BoardContext from '../../../contexts/BoardContext';
import BonusPeriodsContext from '../../../contexts/BonusPeriodsContext';
import Row from '../../Common/Row';
import Colors2 from '../../../theme/Colors2';
import Tooltip from '../../Tooltip';
import useDriverScore from '../../../hooks/useDriverScore';

import Typography from 'kingpin/atoms/Typography';
import toSentenceCase from '../../../services/toSentenceCase';
import useIsGridLinksDisabled from '../../../hooks/useIsGridLinksDisabled';
import useUser from '../../../hooks/useUser';
import useValueFormatters from 'hooks/useValueFormatters';
import useObfuscator from '../../../hooks/useObfuscator';
import getUserDisplayName from '../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/UsersProvider/getUserDisplayName';
import {
  FleetopsGridCellRendererParams,
  FleetOpsGridColDef,
} from '../../../types/agGrid';
import EntityLink from '../../V5Gadget/Matrix/GroupingCellRenderer/EntityLink';
import EntityButtons from 'components/EntityButtons';
import Icon from '../../../kingpin/atoms/Icon';

interface Override {
  overriddenBy: string;
  category: string;
  comment: string;
}

export const OverriddenBy = ({ userId }: { userId: string }) => {
  const { user } = useUser(userId);

  return (
    <Typography.Body type="Body 12">
      {`Overridden by: ${user ? getUserDisplayName(user) : '-'}`}
    </Typography.Body>
  );
};

const OverridesCell = ({
  driver,
  bonusPeriod,
}: {
  driver: string;
  bonusPeriod: BonusPeriod | Scoring.BonusPeriod;
}) => {
  const { driverScore } = useDriverScore({
    driver,
    bonusPeriod,
  });
  if (!driverScore) {
    return null;
  }
  const overrides = driverScore.overrides as Override[];

  if (!overrides || overrides.length === 0 || !Array.isArray(overrides)) {
    return null;
  }

  return (
    <Tooltip
      content={
        <div style={{ maxWidth: 350 }}>
          {overrides.map((o) => (
            <div
              key={Math.random()}
              style={{ marginBottom: 8, padding: '12px 16px' }}
            >
              <Typography.Header type="H5">
                {`${toSentenceCase(o.category)} for ${driver}`}
              </Typography.Header>
              <OverriddenBy userId={o.overriddenBy} />
              <Typography.Body type="Body 12">
                {`Note: ${o.comment}`}
              </Typography.Body>
            </div>
          ))}
        </div>
      }
      isAltTooltip
    >
      <div style={{ display: 'flex' }}>
        <Icon icon="info" color={Colors2.Grey['4']} />
      </div>
    </Tooltip>
  );
};

const DriverCellContainer = ({
  driver,
  bonusPeriod,
  selectDriver,
  node,
  colDef,
}: {
  driver: string;
  bonusPeriod?: BonusPeriod | Scoring.BonusPeriod;
  boardId: string;
  selectDriver: (driver: string) => void;
  node: IRowNode<any>;
  colDef: FleetOpsGridColDef;
}) => {
  const { isObfuscating, obfuscatedValue } = useObfuscator({
    field: 'driver',
    value: driver,
  });
  const isLinksDisabled = useIsGridLinksDisabled();
  const onClick = (event: any) => {
    event.stopPropagation();
    selectDriver(driver);
    node.setSelected(true, true);
  };
  const valueToShow = isObfuscating ? obfuscatedValue : driver;

  if (isLinksDisabled) {
    return (
      <Cell>
        <Row centerAlign spaceBetween>
          <Row centerAlign>
            <Tooltip content={valueToShow}>
              <span>{valueToShow}</span>
            </Tooltip>
            {bonusPeriod && (
              <OverridesCell driver={driver} bonusPeriod={bonusPeriod} />
            )}
          </Row>

          <EntityLink colDef={colDef} value={driver} node={node} />
        </Row>
      </Cell>
    );
  }

  return (
    <Cell>
      <Row centerAlign spaceBetween>
        <Row centerAlign>
          <EntityButtons.Interaction
            onClick={onClick}
            text={valueToShow}
            tooltip={valueToShow}
          />
          {bonusPeriod && (
            <OverridesCell driver={driver} bonusPeriod={bonusPeriod} />
          )}
        </Row>

        <EntityLink colDef={colDef} value={driver} node={node} />
      </Row>
    </Cell>
  );
};

const Gate = ({ value, node, colDef }: FleetopsGridCellRendererParams) => {
  const { isObfuscating, obfuscatedValue } = useObfuscator({
    field: 'driver',
    value:
      typeof value === 'string' || Array.isArray(value) ? value : undefined,
  });
  const boardSlideOutContext = useContext(BoardSlideOutContext);
  const { formatValue } = useValueFormatters();

  const { board } = useContext(BoardContext);
  const { selectedBonusPeriod } = useContext(BonusPeriodsContext);

  if (!boardSlideOutContext || typeof value !== 'string' || !board) {
    if (isObfuscating) {
      return (
        <Cell>
          <span>{obfuscatedValue}</span>
        </Cell>
      );
    } else if (Array.isArray(value)) {
      return (
        <Cell>
          <span>{value.join(', ')}</span>
        </Cell>
      );
    } else if (typeof value === 'number') {
      return (
        <Cell>
          <span>{formatValue({ value, formatting: {} })}</span>
        </Cell>
      );
    } else {
      return (
        <Cell>
          <span>{value}</span>
        </Cell>
      );
    }
  }

  return (
    <DriverCellContainer
      driver={value as string}
      bonusPeriod={selectedBonusPeriod}
      boardId={board.id}
      selectDriver={boardSlideOutContext.selectFieldValue}
      node={node}
      colDef={colDef}
    />
  );
};

export default Gate;
