import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import Row from 'components/Common/Row';
import Dropdown from 'components/Inputs/Dropdown';
import ScorecardsContext from 'contexts/ScorecardsContext';
import getIdentifier from 'getIdentifier';
import FormInput from 'kingpin/forms/FormInput';
import FormContent from 'kingpin/forms/FormContent';
import Button from 'kingpin/atoms/Button';
import TextInput from 'kingpin/atoms/TextInput';
import IconPicker from '../../../../atoms/IconPicker';
import { NAV_ITEM_DEFAULT_ICON_COLOR } from '../../../NavItem';

const NavScorecardForm = ({
  item,
  onSave,
  close,
}: {
  item: PortalsNav.Scorecard | undefined;
  onSave: (item: PortalsNav.Scorecard) => void;
  close: () => void;
}) => {
  const { scorecards } = useContext(ScorecardsContext);

  const [key] = useState<string>(
    item ? item.key : getIdentifier(undefined, true),
  );
  const [label, setLabel] = useState<string>(item ? item.label : '');
  const [icon, setIcon] = useState<Kingpin.Icon | undefined>(
    item ? item.icon : undefined,
  );
  const [scorecardId, setScorecardId] = useState<string | undefined>(
    item ? item.scorecardId : undefined,
  );
  const [draftItem, setDraftItem] = useState<PortalsNav.Scorecard | undefined>(
    item,
  );

  useEffect(() => {
    if (label === '' || !scorecardId) {
      setDraftItem(undefined);
      return;
    }

    setDraftItem({
      key,
      label,
      scorecardId,
      type: 'Scorecard',
      icon,
    });
  }, [icon, key, label, scorecardId]);

  const onSaveClicked = useCallback(() => {
    if (!draftItem) {
      return;
    }

    onSave(draftItem);
    close();
  }, [close, draftItem, onSave]);

  const scorecardOptions = useMemo((): DropdownOption[] => {
    return scorecards.map((r) => ({
      key: r.id,
      label: r.title,
      isSelected: scorecardId === r.id,
      onSelected: () => {
        setScorecardId(r.id);
      },
    }));
  }, [scorecards, scorecardId]);

  return (
    <div>
      <FormContent>
        <FormInput label={'Label'}>
          <TextInput
            value={label}
            onChange={(event) => {
              setLabel(event.target.value);
            }}
          />
        </FormInput>
        <FormInput label={'Icon'}>
          <IconPicker
            setSelectedIcon={setIcon}
            selectedIcon={icon}
            iconColor={NAV_ITEM_DEFAULT_ICON_COLOR}
          />
        </FormInput>
        <FormInput label={'KPI List'}>
          <Dropdown
            options={scorecardOptions}
            isSearchEnabled
            placeholder="Select a KPI List"
          />
        </FormInput>
      </FormContent>
      <Row centerAlign spaceBetween>
        <Button
          size={'Small'}
          type={'Secondary'}
          label={'Cancel'}
          onClick={close}
        />
        <Button
          isDisabled={draftItem === undefined}
          onClick={onSaveClicked}
          label={item ? 'Update' : 'Add'}
          type={'Primary'}
          size={'Small'}
        />
      </Row>
    </div>
  );
};

export default NavScorecardForm;
