import React, { useMemo, useState } from 'react';
import Dropdown from 'components/Inputs/Dropdown';
import Form from 'kingpin/forms/Form';
import FormHeader from 'kingpin/forms/FormHeader';
import FormContent from 'kingpin/forms/FormContent';
import FormInput from 'kingpin/forms/FormInput';
import Alert from 'kingpin/Alert';
import portalNavItemTypeCheckers from 'types/portalNavItemTypeCheckers';

import NavReportForm from './NavReportForm';
import NavDashboardForm from './NavDashboardForm';
import NavScorecardForm from './NavScorecardForm';
import NavSignOffBoardForm from './NavSignOffBoardForm';
import NavBoardForm from './NavBoardForm';
import NavEntityAppForm from './NavEntityAppForm';
import NavReportsIndexForm from './NavReportsIndexForm';
import NavDashboardsIndexForm from './NavDashboardsIndexForm';
import NavScorecardsIndexForm from './NavScorecardsIndexForm';
import NavBonusHomeForm from './NavBonusHomeForm';
import NavBonusPickerForm from './NavBonusPickerForm';
import NavDriverScoringProgramReportForm from './NavDriverScoringProgramReportForm';
import NavScoringProgramConfigOverviewForm from './NavScoringProgramConfigOverviewForm';
import NavMappedEntityViewForm from './NavMappedEntityViewForm';
import NavTargetManagerForm from './NavTargetManagerForm';
import NavUsersForm from './NavUsersForm';
import NavTargetOverviewForm from './NavTargetOverviewForm';
import NavGoalsIndexForm from './NavGoalsIndexForm';
import NavTasksForm from './NavTasksForm';

const TYPES: PortalsNav.NavItemType[] = [
  'Report',
  'Dashboard',
  'Scorecard',
  'Entity App',
  'Reports Index',
  'Dashboards Index',
  'Scorecards Index',
  'Board',
  'Sign Off Board',
  'Bonus Home',
  'Bonus Period Picker',
  'Driver Scoring Program Report',
  'Scoring Program Config Overview',
  'Mapped Entity View',
  'Targets Manager',
  'Targets Overview',
  'Goals Index',
  'Tasks',
];

const NavItemForm = ({
  item,
  onItemSaved,
  close,
}: {
  item?: PortalsNav.Item;
  onItemSaved: (item: PortalsNav.Item) => void;
  close: () => void;
}) => {
  const [type, setType] = useState<PortalsNav.NavItemType | undefined>(
    item ? item.type : undefined,
  );
  const typeOptions = useMemo((): DropdownOption[] => {
    return TYPES.map((optionType) => ({
      label: optionType,
      isSelected: type === optionType,
      onSelected: () => {
        setType(optionType);
      },
    }));
  }, [type]);

  const FormBody = useMemo(() => {
    if (!type) {
      return null;
    }

    if (type === 'Report') {
      return (
        <NavReportForm
          close={close}
          onSave={onItemSaved}
          item={
            item && portalNavItemTypeCheckers.isReport(item) ? item : undefined
          }
        />
      );
    }

    if (type === 'Dashboard') {
      return (
        <NavDashboardForm
          item={
            item && portalNavItemTypeCheckers.isDashboard(item)
              ? item
              : undefined
          }
          onSave={onItemSaved}
          close={close}
        />
      );
    }

    if (type === 'Scorecard') {
      return (
        <NavScorecardForm
          item={
            item && portalNavItemTypeCheckers.isScorecard(item)
              ? item
              : undefined
          }
          onSave={onItemSaved}
          close={close}
        />
      );
    }

    if (type === 'Board') {
      return (
        <NavBoardForm
          item={
            item && portalNavItemTypeCheckers.isBoard(item) ? item : undefined
          }
          onSave={onItemSaved}
          close={close}
        />
      );
    }

    if (type === 'Sign Off Board') {
      return (
        <NavSignOffBoardForm
          item={
            item && portalNavItemTypeCheckers.isSignOffBoard(item)
              ? item
              : undefined
          }
          onSave={onItemSaved}
          close={close}
        />
      );
    }

    if (type === 'Entity App') {
      return (
        <NavEntityAppForm
          item={
            item && portalNavItemTypeCheckers.isEntityApp(item)
              ? item
              : undefined
          }
          onSave={onItemSaved}
          close={close}
        />
      );
    }

    if (type === 'Reports Index') {
      return (
        <NavReportsIndexForm
          item={
            item && portalNavItemTypeCheckers.isReportsIndex(item)
              ? item
              : undefined
          }
          onSave={onItemSaved}
          close={close}
        />
      );
    }

    if (type === 'Dashboards Index') {
      return (
        <NavDashboardsIndexForm
          item={
            item && portalNavItemTypeCheckers.isDashboardsIndex(item)
              ? item
              : undefined
          }
          onSave={onItemSaved}
          close={close}
        />
      );
    }

    if (type === 'Scorecards Index') {
      return (
        <NavScorecardsIndexForm
          item={
            item && portalNavItemTypeCheckers.isScorecardsIndex(item)
              ? item
              : undefined
          }
          onSave={onItemSaved}
          close={close}
        />
      );
    }

    if (type === 'Bonus Home') {
      return (
        <NavBonusHomeForm
          item={
            item && portalNavItemTypeCheckers.isBonusHome(item)
              ? item
              : undefined
          }
          onSave={onItemSaved}
          close={close}
        />
      );
    }

    if (type === 'Bonus Period Picker') {
      return (
        <NavBonusPickerForm
          item={
            item && portalNavItemTypeCheckers.isBonusPicker(item)
              ? item
              : undefined
          }
          onSave={onItemSaved}
          close={close}
        />
      );
    }

    if (type === 'Driver Scoring Program Report') {
      return (
        <NavDriverScoringProgramReportForm
          item={
            item && portalNavItemTypeCheckers.isDriverScoringProgramReport(item)
              ? item
              : undefined
          }
          onSave={onItemSaved}
          close={close}
        />
      );
    }

    if (type === 'Scoring Program Config Overview') {
      return (
        <NavScoringProgramConfigOverviewForm
          item={
            item &&
            portalNavItemTypeCheckers.isScoringProgramConfigOverview(item)
              ? item
              : undefined
          }
          onSave={onItemSaved}
          close={close}
        />
      );
    }

    if (type === 'Mapped Entity View') {
      return (
        <NavMappedEntityViewForm
          item={
            item && portalNavItemTypeCheckers.isMappedEntityView(item)
              ? item
              : undefined
          }
          onSave={onItemSaved}
          close={close}
        />
      );
    }

    if (type === 'Targets Manager') {
      return (
        <NavTargetManagerForm
          item={
            item && portalNavItemTypeCheckers.isTargetManager(item)
              ? item
              : undefined
          }
          onSave={onItemSaved}
          close={close}
        />
      );
    }

    if (type === 'Targets Overview') {
      return (
        <NavTargetOverviewForm
          item={
            item && portalNavItemTypeCheckers.isTargetsOverview(item)
              ? item
              : undefined
          }
          onSave={onItemSaved}
          close={close}
        />
      );
    }

    if (type === 'Users') {
      return (
        <NavUsersForm
          item={
            item && portalNavItemTypeCheckers.isUsers(item) ? item : undefined
          }
          onSave={onItemSaved}
          close={close}
        />
      );
    }

    if (type === 'Goals Index') {
      return (
        <NavGoalsIndexForm
          item={
            item && portalNavItemTypeCheckers.isGoalsIndex(item)
              ? item
              : undefined
          }
          onSave={onItemSaved}
          close={close}
        />
      );
    }

    if (type === 'Tasks') {
      return (
        <NavTasksForm
          item={
            item && portalNavItemTypeCheckers.isTasks(item) ? item : undefined
          }
          onSave={onItemSaved}
          close={close}
        />
      );
    }

    return (
      <Alert
        type={'Warning'}
        title={`Unexpected Type: ${type}`}
        body={'Please try something different'}
      />
    );
  }, [close, item, onItemSaved, type]);

  return (
    <Form>
      <FormHeader
        title={item ? 'Update Nav Item' : 'Add Nav Item'}
        onClose={close}
      />
      <FormContent>
        <FormInput label={'Type'}>
          <Dropdown options={typeOptions} placeholder="Select a screen" />
        </FormInput>
        {FormBody}
      </FormContent>
    </Form>
  );
};

export default NavItemForm;
