import React from 'react';
import Typography from '../atoms/Typography';
import styled from 'styled-components';

const InputSection = styled.div`
  border: 1px solid #e0e0e0;
  background: #fff;
  border-radius: 8px;
  gap: 8px;
  padding: 3px 12px;

  box-shadow:
    0px 3px 2px -1px rgba(0, 0, 0, 0.02),
    0px 1px 1px -1px rgba(0, 0, 0, 0.04);
`;

const FormInput = ({
  label,
  isInputSection,
  children,
}: {
  label: string;
  isInputSection?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <div style={{ marginBottom: 16 }}>
      <div
        style={{
          marginBottom: 4,
        }}
      >
        <Typography.Body type={'Label'}>{label}</Typography.Body>
      </div>
      {isInputSection && <InputSection>{children}</InputSection>}
      {!isInputSection && <>{children}</>}
    </div>
  );
};

export default FormInput;
