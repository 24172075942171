import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import _ from 'lodash';
import Row from 'components/Common/Row';
import Form from 'kingpin/forms/Form';
import FormHeader from 'kingpin/forms/FormHeader';
import FormContent from 'kingpin/forms/FormContent';
import Dropdown from 'components/Inputs/Dropdown';
import Typography from 'kingpin/atoms/Typography';
import Loading from 'components/Loading';
import Button from 'kingpin/atoms/Button';
import STORE from 'store';
import AccountPickerContext from 'contexts/AccountPickerContext';
import ToastContext from 'contexts/ToastContext';

import useUnMappedUsers from './useUnMappedUsers';
import FilterPlatesProvider from '../../../../contextProviders/FilterPlatesProvider';
import FilterPlates from '../../../../components/FilterPlates';
import filterPlateTypeCheckers from '../../../../types/filterPlateTypeCheckers';
import usePrimaryFieldValueOptions from './usePrimaryFieldValueOptions';
import Colors2 from '../../../../theme/Colors2';
import Icon from '../../../../kingpin/atoms/Icon';
import filterTypeCheckers from '../../../../components/FilterPlateForm/filterTypeCheckers';
import useAccessToOtherMappedUserIdsOptions from './useAccessToOtherMappedUserIdsOptions';

const useUserOptions = ({
  mappedUser,
  entityId,
}: {
  mappedUser: MappedUser | undefined;
  entityId: string;
}) => {
  const [selectedUser, setSelectedUser] = useState<
    UserManagement.SignedUpUser | UserManagement.PendingUser | undefined
  >(mappedUser ? mappedUser.user : undefined);
  const { unMappedUsers, isLoading } = useUnMappedUsers(entityId);
  const [options, setOptions] = useState<DropdownOption[]>([]);

  const getOptions = useCallback((): DropdownOption[] => {
    const baseUsers = unMappedUsers.map((user) => ({
      label: user.displayName,
      isSelected: selectedUser && selectedUser.id === user.id,
      onSelected: () => {
        setSelectedUser(user);
      },
      key: user.id,
    }));

    if (mappedUser) {
      baseUsers.push({
        label: mappedUser.user.displayName,
        isSelected: true,
        onSelected: () => {},
        key: mappedUser.user.id,
      });
    }

    return baseUsers;
  }, [mappedUser, selectedUser, unMappedUsers]);

  useEffect(() => {
    setOptions(getOptions());
  }, [getOptions]);

  return {
    options,
    isLoading: options.length === 0 || isLoading,
    selectedUser,
  };
};

const AddMappedUserForm = ({
  entity,
  entityPortalName,
  close,
  setMappedUsers,
  mappedUser,
  mappedUsers,
}: {
  entity: EntityDetails.Entity;
  setMappedUsers: React.Dispatch<React.SetStateAction<MappedUser[]>>;
  entityPortalName: string;
  close: () => void;
  mappedUser?: MappedUser;
  mappedUsers: MappedUser[];
}) => {
  const { showToast } = useContext(ToastContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const {
    options: userOptions,
    isLoading: isLoadingUsers,
    selectedUser,
  } = useUserOptions({ mappedUser, entityId: entity.id });
  const [primaryFieldValue, setPrimaryFieldValue] = useState<
    string | undefined
  >(mappedUser ? mappedUser.filterValue : undefined);
  const [accessToOtherMappedUserIds, setAccessToOtherMappedUserIds] = useState<
    string[]
  >(() => {
    if (mappedUser) {
      return mappedUser.accessToOtherMappedUserIds;
    }
    return [];
  });
  const mappedUsersOtherThanCurrentUser = useMemo((): MappedUser[] => {
    if (!mappedUser) {
      return mappedUsers;
    }
    return mappedUsers.filter((mu) => mu.user.id !== mappedUser.user.id);
  }, [mappedUser, mappedUsers]);
  const accessToOtherMappedUserIdsOptions =
    useAccessToOtherMappedUserIdsOptions({
      accessToOtherMappedUserIds,
      setAccessToOtherMappedUserIds,
      mappedUsers: mappedUsersOtherThanCurrentUser,
    });
  const [plates, setPlates] = useState<FilterPlateType[]>(() => {
    if (!mappedUser) {
      return [];
    }

    if (!mappedUser.additionalFilters) {
      return [];
    }

    return mappedUser.additionalFilters;
  });
  const additionalPrimaryFieldValues = useMemo(() => {
    if (!entity) {
      return [];
    }

    return plates.reduce((acc, plate) => {
      if (
        plate.type === 'Fixed' &&
        plate.fixedValue.field === entity.primaryField
      ) {
        const { fixedValue } = plate;
        if (!filterTypeCheckers.isTextFilter(fixedValue)) {
          return acc;
        }

        return _.uniq([...acc, ...fixedValue.keywordValues]);
      }
      return acc;
    }, [] as string[]);
  }, [entity, plates]);
  const [isAdvancedOpen, setIsAdvancedOpen] = useState<boolean>(false);

  const { primaryFieldValueOptions, isLoadingOptions } =
    usePrimaryFieldValueOptions({
      entityId: entity.id,
      primaryFieldValue,
      setPrimaryFieldValue,
      additionalPrimaryFieldValues,
    });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isValid = !!selectedUser && !!primaryFieldValue;

  const getCurrentMappings = useCallback(
    async ({
      selectedUser,
    }: {
      selectedUser: UserManagement.SignedUpUser | UserManagement.PendingUser;
    }): Promise<UserManagement.EngagementContentMapping[]> => {
      const doc = await STORE.users
        .getUserContentSettingsRef({
          accountId: selectedAccountId,
          userId: selectedUser.id,
        })
        .get();
      const data = doc.data();
      if (!data) {
        return [];
      }
      if (data.mode === 'normal') {
        return [];
      }
      return data.mappings;
    },
    [selectedAccountId],
  );

  const onSaveClicked = useCallback(async () => {
    if (!isValid || isLoading || !selectedUser || !primaryFieldValue) {
      return;
    }

    const additionalFilters = plates.filter(filterPlateTypeCheckers.isFixed);
    const currentMappings = await getCurrentMappings({ selectedUser });

    const newContentSettings: UserManagement.ContentSettings = {
      mode: 'engagement',
      mappings: [
        ...currentMappings.filter((m) => m.entityId !== entity.id),
        {
          primaryFieldValue,
          entityId: entity.id,
          additionalFilters,
          accessToOtherMappedUserIds,
        },
      ],
    };

    STORE.users
      .getUserContentSettingsRef({
        accountId: selectedAccountId,
        userId: selectedUser.id,
      })
      .set(newContentSettings)
      .then(() => {
        setMappedUsers((mappedUsers) => {
          if (mappedUsers.some((m) => m.user.id === selectedUser.id)) {
            return mappedUsers.map((u) => {
              if (u.user.id === selectedUser.id) {
                return {
                  ...u,
                  filterValue: primaryFieldValue,
                  additionalFilters,
                  accessToOtherMappedUserIds,
                  entityId: entity.id,
                };
              } else {
                return u;
              }
            });
          } else {
            return [
              ...mappedUsers,
              {
                user: selectedUser,
                filterValue: primaryFieldValue,
                additionalFilters,
                accessToOtherMappedUserIds,
                entityId: entity.id,
              },
            ];
          }
        });
        showToast(`${entityPortalName} added`);
        setIsLoading(false);
        close();
      });
  }, [
    accessToOtherMappedUserIds,
    close,
    entity.id,
    entityPortalName,
    getCurrentMappings,
    isLoading,
    isValid,
    plates,
    primaryFieldValue,
    selectedAccountId,
    selectedUser,
    setMappedUsers,
    showToast,
  ]);

  const textFieldModeGreenList = useMemo(() => {
    if (!entity) {
      return undefined;
    }

    return {
      [entity.primaryField]: ['is one of' as 'is one of'],
    };
  }, [entity]);

  const datasetGreenList = useMemo(() => {
    return [entity.entityDataset];
  }, [entity.entityDataset]);

  if (isLoadingUsers || isLoadingOptions) {
    return (
      <Form>
        <FormHeader title={``} onClose={close} />
        <FormContent>
          <Loading />
        </FormContent>
      </Form>
    );
  }

  return (
    <Form>
      <FormHeader
        title={
          mappedUser ? `Update ${entityPortalName}` : `Add ${entityPortalName}`
        }
        onClose={close}
      />
      <FormContent>
        <div style={{ marginBottom: 16 }}>
          <Row centerAlign style={{ gap: 16 }}>
            <div>
              <div style={{ marginBottom: 4 }}>
                <Typography.Body type="Label">User</Typography.Body>
              </div>
              <Dropdown
                options={userOptions}
                placeholder="Select a User"
                isSearchEnabled
                isDisabled={!!mappedUser}
              />
            </div>
            <div>
              <div style={{ marginBottom: 4, opacity: 0 }}>
                <Typography.Body type="Label">x</Typography.Body>
              </div>
              <Icon icon={'arrow-right'} color={'#8E8E8E'} />
            </div>
            <div>
              <div style={{ marginBottom: 4 }}>
                <Typography.Body type="Label">{`${entityPortalName}`}</Typography.Body>
              </div>
              <Dropdown
                testId={`primary-field-picker`}
                options={primaryFieldValueOptions}
                placeholder={`Select a ${entityPortalName}`}
                isSearchEnabled
              />
            </div>
          </Row>
        </div>
        <div style={{ marginBottom: 16 }}>
          <Row spaceBetween>
            <div />
            <Button
              size="Small"
              label="Advanced"
              iconAfter={isAdvancedOpen ? 'chevron-up' : 'chevron-down'}
              type={'Ghost'}
              onClick={() => {
                setIsAdvancedOpen((c) => !c);
              }}
            />
          </Row>
        </div>
        {isAdvancedOpen && (
          <>
            <div style={{ marginBottom: 16 }}>
              <div>
                <Typography.Body type="Label">{`Add More Data`}</Typography.Body>
              </div>
              <div style={{ marginBottom: 4 }}>
                <Typography.Body type="Annotation" color={Colors2.Grey['3']}>
                  {`Allow access the following ${entityPortalName}s views`}
                </Typography.Body>
              </div>
              <Dropdown
                options={accessToOtherMappedUserIdsOptions}
                placeholder={`Select additional ${entityPortalName}s`}
                isSearchEnabled
                isMulti
              />
            </div>
            <div style={{ marginBottom: 16 }}>
              <div>
                <Typography.Body type="Label">Filters</Typography.Body>
              </div>
              <div style={{ marginBottom: 4 }}>
                <Typography.Body type="Annotation" color={Colors2.Grey['3']}>
                  {`Additional filters for ${selectedUser ? `${selectedUser.displayName}'s` : 'this'} view`}
                </Typography.Body>
              </div>
              <FilterPlatesProvider
                drillDowns={plates}
                setDrillDowns={setPlates}
                dataTypes={datasetGreenList}
                textFieldModeGreenList={textFieldModeGreenList}
              >
                <FilterPlates />
              </FilterPlatesProvider>
            </div>
          </>
        )}
      </FormContent>
      <Row centerAlign spaceBetween>
        <div />
        <Button
          type="Primary"
          onClick={onSaveClicked}
          isDisabled={!isValid}
          label={mappedUser ? 'Update User' : 'Add User'}
          size={'Small'}
        />
      </Row>
    </Form>
  );
};

export default AddMappedUserForm;
