import React, { useCallback, useContext, useMemo, useState } from 'react';

import Row from 'components/Common/Row';
import AccessUsers from 'components/AccessUsers/AccessUsers';
import Tooltip from 'components/Tooltip';
import UsersContext from 'contexts/UsersContext';
import CurrentUserContext from 'contexts/CurrentUserContext';
import ImpersonatorContext from 'contexts/ImpersonatorContext';
import AccountPickerContext from 'contexts/AccountPickerContext';
import { BonusPortalContext } from 'contextProviders/BonusPortalProvider';
import userTypeCheckers from 'contextProviders/SplashScreenProviders/UserAndAccountProviders/UsersProvider/userTypeCheckers';
import { BonusPortalBoardSignOffsContext } from 'contextProviders/BonusPortalProvider/BonusPortalBoardSignOffsProvider';
import isoDateToAmerican from 'isoDateToAmerican';
import getTimeStamp from 'getTimeStamp';
import Button from 'kingpin/atoms/Button';
import KingpinBadge from 'kingpin/atoms/Badge';
import portalNavItemTypeCheckers from 'types/portalNavItemTypeCheckers';
import useConfirmation from 'screens/DataManager/DatasetDefinitions/DatasetsIndex/PerformanceDatasetWizard/useConfirmation';
import STORE from 'store';
import Typography from '../../../kingpin/atoms/Typography';
import Dropdown from '../../Inputs/Dropdown';

const Owners = ({
  owners,
  portal,
  signOffBoardNavItem,
}: {
  owners: (UserManagement.SignedUpUser | UserManagement.PendingUser)[];
  portal: BonusPortal;
  signOffBoardNavItem: PortalsNav.SignOffBoard;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { allUsers } = useContext(UsersContext);

  const updateOwners = useCallback(
    async (newOwnerIds: string[]) => {
      const newNavItem: PortalsNav.SignOffBoard = {
        ...signOffBoardNavItem,
        signOff: {
          ...signOffBoardNavItem.signOff,
          ownerIds: newOwnerIds,
        },
      };

      const newPortal: BonusPortal = {
        ...portal,
        navigationSettings: {
          ...portal.navigationSettings,
          sections: portal.navigationSettings.sections.map((s) => {
            if (s.items.some((i) => i.key === signOffBoardNavItem.key)) {
              return {
                ...s,
                items: s.items.map((i) => {
                  if (i.key === signOffBoardNavItem.key) {
                    return newNavItem;
                  }
                  return i;
                }),
              };
            }
            return s;
          }),
        },
      };

      await STORE.contentDistributions
        .getBonusPortalsRef({
          accountId: selectedAccountId,
        })
        .doc(newPortal.id)
        .set(newPortal);
    },
    [portal, selectedAccountId, signOffBoardNavItem],
  );

  const assignBoardOwner = useCallback(
    async (user: UserManagement.SignedUpUser | UserManagement.PendingUser) => {
      if (isLoading) {
        alert('Currently loading. Please try again in a moment.');
        return;
      }
      setIsLoading(true);
      const newOwners = [...signOffBoardNavItem.signOff.ownerIds, user.id];
      await updateOwners(newOwners);
      setIsLoading(false);
    },
    [isLoading, signOffBoardNavItem.signOff.ownerIds, updateOwners],
  );

  const removeBoardOwner = useCallback(
    async (user: UserManagement.SignedUpUser | UserManagement.PendingUser) => {
      if (isLoading) {
        alert('Currently loading. Please try again in a moment.');
        return;
      }
      setIsLoading(true);
      const newOwners = signOffBoardNavItem.signOff.ownerIds.filter(
        (uid) => uid !== user.id,
      );
      await updateOwners(newOwners);
      setIsLoading(false);
    },
    [isLoading, signOffBoardNavItem.signOff.ownerIds, updateOwners],
  );

  const ownerOptions: DropdownOption[] = useMemo(() => {
    return allUsers
      .filter(userTypeCheckers.isSignedUpOrPendingUser)
      .filter((u) => !u.isDeleted)
      .map((user) => {
        const isCurrentOwner = owners.some((o) => o.id === user.id);
        return {
          label: user.displayName,
          isSelected: isCurrentOwner,
          onSelected: () => {
            if (isCurrentOwner) {
              removeBoardOwner(user);
            } else {
              assignBoardOwner(user);
            }
          },
        };
      });
  }, [allUsers, assignBoardOwner, owners, removeBoardOwner]);

  return (
    <Dropdown
      isDisabled={isLoading}
      isLoading={isLoading}
      options={ownerOptions}
      renderSelectedLabel={() => (
        <Row centerAlign style={{ gap: 8 }}>
          <div>
            <Typography.Body
              type={'Body 14'}
            >{`Owner${owners.length > 1 ? 's' : ''}:`}</Typography.Body>
          </div>
          <AccessUsers type={'Users'} users={owners} />
        </Row>
      )}
    />
  );
};

export const getSignOffId = ({
  period,
  signOffBoardNavItem,
}: {
  period: Scoring.BonusPeriod;
  signOffBoardNavItem: PortalsNav.SignOffBoard;
}) => `${period.id}-${signOffBoardNavItem.boardId}`;

const BoardSignOffButton = ({
  period,
  signOffBoardNavItem,
  portal,
  isCurrentUserOwner,
}: {
  period: Scoring.BonusPeriod;
  signOffBoardNavItem: PortalsNav.SignOffBoard;
  portal: BonusPortal;
  isCurrentUserOwner: boolean;
}) => {
  const { signOffLookup, isLoading: isLoadingSignOffs } = useContext(
    BonusPortalBoardSignOffsContext,
  );
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { id: currentUserId } = useContext(CurrentUserContext);
  const { allUsers } = useContext(UsersContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getConfirmation, ConfirmationModal } = useConfirmation({
    title: `Sign off ${signOffBoardNavItem.label} for ${period.label}`,
    confirmText: 'Sign Off',
  });

  const signOffForPeriod = useMemo(() => {
    return signOffLookup[signOffBoardNavItem.boardId];
  }, [signOffBoardNavItem.boardId, signOffLookup]);

  const onSignOffClicked = useCallback(async () => {
    if (isLoading) {
      return;
    }

    const isConfirmed = await getConfirmation();
    if (!isConfirmed) {
      return;
    }

    setIsLoading(true);
    const id = getSignOffId({ period, signOffBoardNavItem });
    STORE.contentDistributions
      .getBonusPortalBoardSignOffsRef({
        accountId: selectedAccountId,
        bonusPortalId: portal.id,
      })
      .doc(id)
      .set({
        id,
        signedOffOn: getTimeStamp(),
        signedOffById: currentUserId,
        boardId: signOffBoardNavItem.boardId,
        periodId: period.id,
      })
      .then(() => {
        setIsLoading(false);
      });
  }, [
    currentUserId,
    getConfirmation,
    isLoading,
    period,
    portal.id,
    selectedAccountId,
    signOffBoardNavItem,
  ]);

  const signedOffBy = useMemo(() => {
    if (!signOffForPeriod) {
      return undefined;
    }

    return allUsers
      .filter(userTypeCheckers.isSignedUpOrPendingUser)
      .find((u) => u.id === signOffForPeriod.signedOffById);
  }, [allUsers, signOffForPeriod]);

  if (isLoadingSignOffs) {
    return null;
  }

  if (!signOffForPeriod) {
    if (isCurrentUserOwner) {
      return (
        <>
          <Button
            size={'Small'}
            type={'Primary'}
            onClick={onSignOffClicked}
            label={'Sign Off'}
            isLoading={isLoading}
          />
          {ConfirmationModal}
        </>
      );
    }

    return (
      <Tooltip
        content={'Only one of the the listed owners may sign this board off'}
      >
        <KingpinBadge badgeType={'Info'} text={`Pending Sign Off`} />
      </Tooltip>
    );
  }

  return (
    <div>
      <Tooltip
        content={
          signedOffBy
            ? `Signed off by ${signedOffBy.displayName} on ${isoDateToAmerican(signOffForPeriod.signedOffOn)}`
            : undefined
        }
      >
        <KingpinBadge badgeType={'Default'} text={`Signed off`} />
      </Tooltip>
    </div>
  );
};

const BonusBoardSignOff = ({
  portal,
  period,
  signOffBoardNavItem,
}: {
  portal: BonusPortal;
  period: Scoring.BonusPeriod;
  signOffBoardNavItem: PortalsNav.SignOffBoard;
}) => {
  const { id: currentUserId } = useContext(CurrentUserContext);
  const { impersonatorId } = useContext(ImpersonatorContext);
  const { allUsers } = useContext(UsersContext);

  const owners = useMemo(() => {
    return allUsers
      .filter((u) => signOffBoardNavItem.signOff.ownerIds.includes(u.id))
      .filter(userTypeCheckers.isSignedUpOrPendingUser)
      .filter((u) => !u.isDeleted);
  }, [allUsers, signOffBoardNavItem.signOff.ownerIds]);

  const isCurrentUserOwner = useMemo(() => {
    if (impersonatorId) {
      return owners.some((o) => o.id === impersonatorId);
    }
    return owners.some((o) => o.id === currentUserId);
  }, [currentUserId, impersonatorId, owners]);

  return (
    <Row centerAlign style={{ gap: 8, marginRight: 8 }}>
      <Owners
        owners={owners}
        signOffBoardNavItem={signOffBoardNavItem}
        portal={portal}
      />
      {period.status === 'pending sign off' && (
        <BoardSignOffButton
          portal={portal}
          period={period}
          signOffBoardNavItem={signOffBoardNavItem}
          isCurrentUserOwner={isCurrentUserOwner}
        />
      )}
    </Row>
  );
};

const SignOffBoardNavItemProvider = ({
  board,
  portal,
  period,
}: {
  board: PerformanceBoardTypes.Board;
  portal: BonusPortal;
  period: Scoring.BonusPeriod;
}) => {
  const signOffBoardNavItem = useMemo(() => {
    return portal.navigationSettings.sections
      .reduce((a, b) => {
        return [
          ...a,
          ...b.items.filter(portalNavItemTypeCheckers.isSignOffBoard),
        ];
      }, [] as PortalsNav.SignOffBoard[])
      .find((item) => item.boardId === board.id);
  }, [board.id, portal.navigationSettings]);

  if (!signOffBoardNavItem) {
    return null;
  }

  return (
    <BonusBoardSignOff
      portal={portal}
      period={period}
      signOffBoardNavItem={signOffBoardNavItem}
    />
  );
};

/**
 * This flow is for the new bonus system, from 2025
 *
 * Take a look at {@link BonusSignOffGate} for the bonus system implemented in 2020.
 */
const BonusBoardSignOffGate = ({
  board,
}: {
  board: PerformanceBoardTypes.Board;
}) => {
  const bonusPortalContext = useContext(BonusPortalContext);
  if (!bonusPortalContext) {
    return null;
  }

  return (
    <SignOffBoardNavItemProvider
      board={board}
      portal={bonusPortalContext.portal}
      period={bonusPortalContext.selectedBonusPeriod}
    />
  );
};

export default BonusBoardSignOffGate;
