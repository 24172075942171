import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import CurrentUserContext from '../../../../../contexts/CurrentUserContext';
import getIdentifier from '../../../../../getIdentifier';
import getTimeStamp from '../../../../../getTimeStamp';
import PerformanceBoardSlideOutContext from '../../../contexts/PerformanceBoardSlideOutContext';
import ShadowedList from '../../../../ShadowedList';
import { WidgetWrapper } from '../MetricSlideoutWizard';
import { LabelDiv } from '../../../../../screens/Boards/PerformanceBoardForm';
import Typography from '../../../../../kingpin/atoms/Typography';
import Inputs from '../../../../Inputs';
import EmptyVis from '../../Sections/EmptyVis';
import WidgetFooter from '../../WidgetFooter';
import useSaveWidget from '../../../hooks/useSaveWidget';
import BonusCategories from '../../Sections/BonusCategories';

const BonusCategorySlideoutWizard = ({
  close,
  editingSection,
}: {
  close: () => void;
  editingSection:
    | PerformanceBoardTypes.SlideOut.BonusCategorySection
    | undefined;
}) => {
  const { bonusStatusFields } = useContext(PerformanceBoardSlideOutContext);
  const { id: currentUserId } = useContext(CurrentUserContext);
  const [id] = useState<string>(
    editingSection ? editingSection.id : getIdentifier(),
  );
  const [createdBy] = useState<string>(
    editingSection ? editingSection.createdBy : currentUserId,
  );
  const [createdOn] = useState<string>(
    editingSection ? editingSection.createdOn : getTimeStamp(),
  );
  const [title, setTitle] = useState<string>(
    editingSection ? editingSection.title : '',
  );
  const [statusField, setStatusField] = useState<string | undefined>(
    editingSection ? editingSection.statusField : undefined,
  );
  const [section, setSection] = useState<
    PerformanceBoardTypes.SlideOut.BonusCategorySection | undefined
  >();

  const onTitleChanged = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setTitle(event.target.value);
  };

  useEffect(() => {
    if (title === '' || !statusField) {
      setSection(undefined);
      return;
    }

    const newSection: PerformanceBoardTypes.SlideOut.BonusCategorySection = {
      id: id,
      createdBy: createdBy,
      updatedBy: currentUserId,
      createdOn: createdOn,
      updatedOn: getTimeStamp(),
      sectionType: 'Bonus Category',
      title: title,
      statusField,
    };

    setSection(newSection);
  }, [
    createdBy,
    createdOn,
    currentUserId,
    editingSection,
    id,
    statusField,
    title,
  ]);

  const statusFieldOptions = useMemo((): DropdownOption[] => {
    return bonusStatusFields.map((c) => ({
      label: c,
      isSelected: !!statusField && statusField === c,
      onSelected: () => {
        setStatusField(c);
      },
    }));
  }, [bonusStatusFields, statusField]);

  const saveWidget = useSaveWidget({
    section,
    editingSection,
  });

  return (
    <>
      <ShadowedList>
        <WidgetWrapper>
          <div style={{ marginBottom: 24 }}>
            <LabelDiv>
              <Typography.Body type="Label">Widget Title</Typography.Body>
            </LabelDiv>
            <Inputs.TextInput
              value={title}
              placeholder="Enter Title"
              onChange={onTitleChanged}
              maxLength={40}
              autoFocus
              data-testid={'title'}
            />
          </div>
          <div style={{ marginBottom: 24 }}>
            <LabelDiv>
              <Typography.Body type="Label">Status Field</Typography.Body>
            </LabelDiv>
            <Inputs.Dropdown options={statusFieldOptions} />
          </div>
          {!section ? (
            <EmptyVis />
          ) : (
            <>
              <LabelDiv>
                <Typography.Body type="Label">Preview</Typography.Body>
              </LabelDiv>
              <BonusCategories section={section} />
            </>
          )}
        </WidgetWrapper>
      </ShadowedList>
      <WidgetFooter
        saveWidget={saveWidget}
        sectionType="Metric Widget"
        isDisabled={!section}
        close={close}
      />
    </>
  );
};

export default BonusCategorySlideoutWizard;
