import React, { useContext } from 'react';
import GeneralPeriodSelector from '../../GeneralPeriodSelector';
import BoardContext from '../../../contexts/BoardContext';
import isDriverPerformanceBoard from '../../../isDriverPerformanceBoard';
import BonusPeriodSelector from './BonusPeriodSelector';
import WorkSpaceContext from '../../../contexts/WorkSpaceContext';
import isGeneralBoard from '../../../isBoardWithSlideOut';
import { isDriverBonusWorkSpace } from '../../../isWorkSpace';
import isPerformanceBoard from '../../../isPerformanceBoard';
import { PortalsContext } from '../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';
import portalTypeCheckers from '../../../types/portalTypeCheckers';

const PeriodSelectorContainer = ({
  isWorkSpaceTopBar,
  isBonusPeriodMode,
  onDatePickerOpen,
  onDatePickerClose,
}: {
  isWorkSpaceTopBar: boolean;
  isBonusPeriodMode?: boolean;
  onDatePickerOpen?: () => void;
  onDatePickerClose?: () => void;
}) => {
  const { board } = useContext(BoardContext);
  const { workSpace } = useContext(WorkSpaceContext);

  if (isBonusPeriodMode) {
    return (
      <BonusPeriodSelector
        onDatePickerOpen={onDatePickerOpen}
        onDatePickerClose={onDatePickerClose}
      />
    );
  }
  if (!board && !workSpace) {
    return null;
  }
  if (isWorkSpaceTopBar && !!workSpace) {
    if (isDriverBonusWorkSpace(workSpace)) {
      return (
        <BonusPeriodSelector
          onDatePickerOpen={onDatePickerOpen}
          onDatePickerClose={onDatePickerClose}
        />
      );
    }
  } else {
    if (workSpace && workSpace.campaignType === 'driverBonus') {
      return null;
    }

    if ((board && isGeneralBoard(board)) || isPerformanceBoard(board)) {
      return (
        <GeneralPeriodSelector
          onDatePickerOpen={onDatePickerOpen}
          onDatePickerClose={onDatePickerClose}
        />
      );
    }
    if (board && isDriverPerformanceBoard(board)) {
      return (
        <BonusPeriodSelector
          onDatePickerOpen={onDatePickerOpen}
          onDatePickerClose={onDatePickerClose}
        />
      );
    }
  }

  return null;
};

const Gate = ({
  isWorkSpaceTopBar,
  isBonusPeriodMode,
  onDatePickerOpen,
  onDatePickerClose,
}: {
  isWorkSpaceTopBar: boolean;
  isBonusPeriodMode?: boolean;
  onDatePickerOpen?: () => void;
  onDatePickerClose?: () => void;
}) => {
  const { selectedPortal } = useContext(PortalsContext);
  if (portalTypeCheckers.isBonusPortal(selectedPortal)) {
    return null;
  }

  return (
    <PeriodSelectorContainer
      isWorkSpaceTopBar={isWorkSpaceTopBar}
      isBonusPeriodMode={isBonusPeriodMode}
      onDatePickerOpen={onDatePickerOpen}
      onDatePickerClose={onDatePickerClose}
    />
  );
};

export default Gate;
