import firebase from 'firebase/compat/app';
import STORE_CONSTANTS from '../constants';
import withoutNulls from '../../api/search/withoutNulls';

const ENGAGEMENT_PORTALS_DOC = 'engagementPortals';
const CURRENT_VERSION = 'v2';

const engagementAppConverter = {
  toFirestore(app: EngagementPortal): firebase.firestore.DocumentData {
    return withoutNulls({ ...app });
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<EngagementPortal>,
    options: firebase.firestore.SnapshotOptions,
  ): EngagementPortal {
    const data: EngagementPortal = {
      ...snapshot.data(options),
    };

    return data;
  },
};

const getEngagementPortalsRef = ({
  accountId,
  db,
  version = CURRENT_VERSION,
}: {
  accountId: string;
  db?: firebase.firestore.Firestore;
  version?: string;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .collection(STORE_CONSTANTS.CONTENT_DISTRIBUTIONS_COLLECTION)
    .doc(ENGAGEMENT_PORTALS_DOC)
    .collection(version)
    .withConverter(engagementAppConverter);
};

export default getEngagementPortalsRef;
