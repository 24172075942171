import { useLocation } from 'react-router-dom';
import NavItem from '../../NavItem';
import React from 'react';
import appRoutes from '../../../../navigation/appRoutes';

const BonusHomeNavItem = ({
  section,
  item,
  portal,
}: {
  section: PortalSideNavigationSection;
  item: PortalsNav.BonusHome;
  portal: BonusPortal;
}) => {
  const { pathname } = useLocation();

  return (
    <NavItem
      key={item.key}
      label={item.label}
      to={`/${portal.urlSlug}/${section.urlSlug}${appRoutes.loggedIn.bonusHome}`}
      isActiveOverride={
        pathname ===
        `/${portal.urlSlug}/${section.urlSlug}${appRoutes.loggedIn.bonusHome}`
      }
      icon={item.icon}
    />
  );
};

export default BonusHomeNavItem;
